import type { ResourceTypeModel } from '@/api/t1/model/resourceModel'

export default class ResourceType implements ResourceTypeModel {
  Id: number
  Type: string
  AllowedFileTypes: string[]
  Icon: string
  VisibleInResourcesList: number
  CreatedBy: number
  UpdatedBy: number
  CreatedDate: Date
  UpdatedDate: Date

  constructor(resourceType: ResourceTypeModel) {
    this.Id = resourceType.Id
    this.Type = resourceType.Type
    this.AllowedFileTypes = resourceType.AllowedFileTypes
    this.Icon = resourceType.Icon
    this.VisibleInResourcesList = resourceType.VisibleInResourcesList
    this.CreatedBy = resourceType.CreatedBy
    this.UpdatedBy = resourceType.UpdatedBy
    this.CreatedDate = new Date(resourceType.CreatedDate)
    this.UpdatedDate = new Date(resourceType.UpdatedDate)
  }
}
