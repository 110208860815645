import { Size } from './articleSize'
import { Price } from './articlePrice'
import { Segmentation } from './articleSegmentation'
import { ArticleCrd } from './articleDeliveryDate'
import type CatalogAttribute from './catalogAttribute'
import { AttributeType } from './catalogAttribute'
import utils from '@/services/utils'

export default class Article {
  Id: number
  CatalogCode: number
  CatalogArticleId: string
  ArticleNumber: string
  ArticleName: string
  SortOrder: number
  StateId: number
  StateRank: number
  StateName: string
  ColorId: number | null
  ColorName: string | null
  ColorCode: string | null
  HexCode: string | null
  ColorGroup: string | null
  DropReason: string | null
  Status: number
  AllowRetailWindowUpdate: number
  PShipmentStartDate: Date | null
  PShipmentEndDate: Date | null
  ShipmentStartDate: Date | null
  ShipmentEndDate: Date | null
  RetailIntroMonth: string | null
  RetailExitMonth: string | null
  Period: string | null
  SizeScaleId: number | null
  SizeScale: string | null
  MasterSizeScaleId: number | null
  ShipmentWindowRangeId: number | null
  BlockImage: number
  ModelId: number
  ModelNumber: string
  ModelName: string
  ModelStateRank: number
  ModelStateName: string
  Created_By_User: string
  Created_By: number
  Updated_By: number
  CreatedDate: Date
  UpdatedDate: Date
  CreatedByUserName: string
  UpdatedByUserName: string
  ParentModelName: string
  ParentModelNumber: string
  ArticleLifecycle: string
  ModelLifecycle: string
  Locked: number
  PS: number
  IsAccessible: number
  SourceRequestId: number | null
  SourceRequestNumber: string | null
  ColoredUpBy: string | null
  ColoredUpDate: Date | null
  SeasonalSequence: number
  LocalUpdatedDate: Date | null
  Updated_By_User: string
  PRetailIntroMonth: string | null
  PRetailExitMonth: string | null
  PPeriod: string | null
  PShipmentWindowRangeId: number | null
  IsOutOfShippingRange: number
  ModelStateId: number
  AllowRetailWindowUpdateOnChild: number
  ModelSourceSeason: string | null
  ArticleSourceSeason: string | null
  ActivationDate: Date | null
  DropDate: Date | null
  ModelCreatedDate: Date
  ModelActivationDate: Date | null
  ModelDropDate: Date | null
  RAID: number | null
  RAN: string | null
  PRAID: number | null
  PRAN: string | null
  ArticleSource: string | null
  PSizeScale: string | null
  PSizeScaleId: number | null
  MasterSizeScale: string | null
  StatesTimestamp: Record<number, Date>

  [param: string]: string | number | Date | boolean | any[] | Function | Record<string | number | symbol, any> | null

  _Sizes: Size[]
  _Prices: Record<number, Price>
  _Segmentations: Record<number, Segmentation>
  _DeliveryDates: ArticleCrd[]

  // TODO: Indexed other entities like sizes crds, etc it is being use in 2 places, 1 load data in memory, 2 consolidate article and save in indexed db, probably pass a flag and when loading in memory
  // or better way to create 2 different class Article for memory and DexieArticle
  constructor(catalogCode: number, attributes: CatalogAttribute[], articleObj: Article, dataSourceTypeId: number) {
    this.Id = articleObj.Id
    this.CatalogCode = catalogCode // TODO: Should we maybe remove the catalog code from the article since it exists on the model?
    this.CatalogArticleId = articleObj.CatalogArticleId?.toString()
    this.ArticleNumber = articleObj.ArticleNumber
    this.ArticleName = articleObj.ArticleName
    this.SortOrder = articleObj.SortOrder
    this.Status = (dataSourceTypeId === 3 && articleObj.PS === 0) ? 3 : (dataSourceTypeId === 3 && articleObj.PS === 1 && articleObj.Status === 0) ? 2 : articleObj.Status
    this.AllowRetailWindowUpdate = articleObj.AllowRetailWindowUpdate
    this.PShipmentStartDate = articleObj.PShipmentStartDate ? new Date(articleObj.PShipmentStartDate) : null
    this.PShipmentEndDate = articleObj.PShipmentEndDate ? new Date(articleObj.PShipmentEndDate) : null
    this.ShipmentStartDate = articleObj.ShipmentStartDate ? new Date(articleObj.ShipmentStartDate) : null
    this.ShipmentEndDate = articleObj.ShipmentEndDate ? new Date(articleObj.ShipmentEndDate) : null
    this.SizeScaleId = articleObj.SizeScaleId
    this.SizeScale = articleObj.SizeScale
    this.ShipmentWindowRangeId = articleObj.ShipmentWindowRangeId
    this.DropReason = articleObj.DropReason
    this.BlockImage = articleObj.BlockImage
    this.StateId = articleObj.StateId
    this.StateRank = articleObj.StateRank
    this.StateName = articleObj.StateName
    this.ColorId = articleObj.ColorId
    this.ColorName = articleObj.ColorName
    this.HexCode = articleObj.HexCode
    this.ColorCode = articleObj.ColorCode
    this.ColorGroup = articleObj.ColorGroup
    this.RetailIntroMonth = articleObj.RetailIntroMonth
    this.RetailExitMonth = articleObj.RetailExitMonth
    this.Period = articleObj.Period
    this.MasterSizeScaleId = articleObj.MasterSizeScaleId
    this.ModelId = articleObj.ModelId
    this.ModelName = articleObj.ModelName
    this.ModelNumber = articleObj.ModelNumber
    this.ModelStateRank = articleObj.ModelStateRank
    this.ModelStateName = articleObj.ModelStateName
    this.Created_By_User = articleObj.Created_By_User
    this.Updated_By_User = articleObj.Updated_By_User
    this.Created_By = articleObj.Created_By
    this.Updated_By = articleObj.Updated_By
    this.CreatedDate = new Date(articleObj.CreatedDate)
    this.UpdatedDate = new Date(articleObj.UpdatedDate)
    this.CreatedByUserName = articleObj.CreatedByUserName
    this.UpdatedByUserName = articleObj.UpdatedByUserName
    this.ParentModelName = articleObj.ParentModelName
    this.ParentModelNumber = articleObj.ParentModelNumber
    this.ArticleLifecycle = articleObj.ArticleLifecycle
    this.ModelLifecycle = articleObj.ModelLifecycle
    this.Locked = articleObj.Locked
    this.PS = articleObj.PS
    this.IsAccessible = articleObj.IsAccessible
    this.SourceRequestId = articleObj.SourceRequestId
    this.SourceRequestNumber = articleObj.SourceRequestNumber
    this.ColoredUpBy = articleObj.ColoredUpBy
    this.ColoredUpDate = articleObj.ColoredUpDate
    this.SeasonalSequence = articleObj.SeasonalSequence
    this.LocalUpdatedDate = articleObj.LocalUpdatedDate
    this.PRetailIntroMonth = articleObj.PRetailIntroMonth
    this.PRetailExitMonth = articleObj.PRetailExitMonth
    this.PPeriod = articleObj.PPeriod
    this.PShipmentWindowRangeId = articleObj.PShipmentWindowRangeId
    this.IsOutOfShippingRange = articleObj.IsOutOfShippingRange
    this.ModelStateId = articleObj.ModelStateId
    this.AllowRetailWindowUpdateOnChild = articleObj.AllowRetailWindowUpdateOnChild
    this.ModelSourceSeason = articleObj.ModelSourceSeason
    this.ArticleSourceSeason = articleObj.ArticleSourceSeason
    this.ActivationDate = articleObj.ActivationDate
    this.DropDate = articleObj.DropDate
    this.ModelCreatedDate = articleObj.ModelCreatedDate
    this.ModelActivationDate = articleObj.ModelActivationDate
    this.ModelDropDate = articleObj.ModelDropDate
    this.RAID = articleObj.RAID
    this.RAN = articleObj.RAN
    this.PRAID = articleObj.PRAID
    this.PRAN = articleObj.PRAN
    this.ArticleSource = articleObj.ArticleSource
    this.PSizeScale = articleObj.PSizeScale
    this.PSizeScaleId = articleObj.PSizeScaleId
    this.MasterSizeScale = articleObj.MasterSizeScale
    this.StatesTimestamp = articleObj.StatesTimestamp
    this._Sizes = articleObj._Sizes?.map(s => new Size(s)) || []
    this._Prices = {}
    if (articleObj._Prices) {
      for (const pgId in articleObj._Prices) {
        this._Prices[pgId] = new Price(articleObj._Prices[pgId])
      }
    }
    this._Segmentations = {}
    if (articleObj._Segmentations) {
      for (const segId in articleObj._Segmentations) {
        if (articleObj._Segmentations[segId]) {
          this._Segmentations[segId] = new Segmentation(articleObj._Segmentations[segId])
        }
      }
    }
    this._DeliveryDates = articleObj._DeliveryDates?.map(d => new ArticleCrd(d)) || []
    // const attrs = attributes && attributes.length > 0 ? attributes : Object.keys(articleObj)
    attributes.forEach((att) => {
      if (articleObj.hasOwnProperty(att.AttributeSystemName)) {
        let articleValue = articleObj[att.AttributeSystemName]
        // for color palette, we are using name
        if (att.AttributeTypeId === AttributeType.ColorPalette && utils.isDefined(articleValue)) {
          const parsedValue = utils.tryParse(articleValue.toString())
          if (parsedValue) {
            const values = Object.values(parsedValue) as string[]
            articleValue = values.length > 0 ? values[0] : ''
          }
        }
        this[att.AttributeSystemName] = articleValue
      }
    })
  }
}
