import type { Ref } from 'vue'
import { computed, onUnmounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import * as statusBarStore from '@/store/status'
import usePriceGroupsLabel from '@/shared/composables/priceGroupsLabel'
import { getBrowseByList } from '@/services/browseByFactory'
import { useBrowseByStore } from '@/store/browseBy'
import { useUserStore } from '@/store/userData'
import useEventsBus from '@/shared/composables/eventBus'
import utils from '@/services/utils'

export default function useBrowseBy(enableBrowseBy: Ref<boolean>) {
  const userStore = useUserStore()
  const browseByStore = useBrowseByStore()
  const { t } = useI18n()
  const { priceGroupsLabel } = usePriceGroupsLabel()
  const { emitter } = useEventsBus()

  const browseByList = computed(() => {
    let browseByList = [] as Array<IBrowseBy>
    if (enableBrowseBy.value) {
      browseByList = getBrowseByList(userStore.activeCatalog!.Config.BrowseByAttribute, userStore.activeCatalog!.Config.EnableBrowseByModel, userStore.myAttributes!, priceGroupsLabel.value, t)
    }
    return browseByList
  })

  const browseBy = computed({
    get: () => browseByStore.browseBy,
    set: (val) => {
      if (!browseByStore.browseBy || browseByStore.browseBy.key !== val?.key) {
        if (val == null && browseByList.value.length) {
          // if reset the browse by assign browseByArticle
          browseByStore.browseBy = browseByList.value.find(record => record.key === 'browseByArticle')
        }
        else {
          browseByStore.browseBy = val
        }
        // Added the delay so that articlesList and bucket list view rendered before the live query is called
        utils.delay(10).then(() => {
          emitter('browseByChanged', { old: browseByStore.browseBy, new: val })
        })
      }
    },
  })

  const isShowingBucketsListView = computed(() => browseByStore.isShowingBucketsListView)

  // set default value
  if (browseByList.value.length && browseBy.value == null) {
    browseBy.value = browseByList.value.find(record => record.key === 'browseByArticle')
  }

  watch([() => browseBy.value, () => isShowingBucketsListView.value], () => {
    if (browseByStore.isBrowseByModel) {
      statusBarStore.updateItemLabel('ttlArts', t('status.totalModels'))
      statusBarStore.updateItemLabel('listedArts', t('status.listedModels'))
      statusBarStore.updateItemLabel('selectedArticlesCount', t('status.selectedModels'))
    }
    else {
      statusBarStore.updateItemLabel('ttlArts', t('status.totalArticles'))
      statusBarStore.updateItemLabel('listedArts', t('status.listedArticles'))
      statusBarStore.updateItemLabel('selectedArticlesCount', t('status.selectedArticles'))
    }
    if (isShowingBucketsListView.value) {
      if (!statusBarStore.hasItem('listedGroups')) {
        statusBarStore.addItem({ id: 'listedGroups', label: t('status.listedGroups'), icon: '', value: '0' }, 1)
      }
    }
    else if (statusBarStore.hasItem('listedGroups')) {
      statusBarStore.removeItem('listedGroups')
    }
  })

  onUnmounted(() => {
    browseBy.value = undefined
  })

  return {
    browseBy,
    browseByList,
    isShowingBucketsListView,
  }
}
