<template>
  <div class="flex flex-col" :class="{ 'opacity-40': !node.enabled }">
    <div
      class="px-4 py-2.5 flex items-center rounded-md cursor-pointer"
      :class="{ 'bg-gray-100 hover:bg-gray-300 hover:border-primary': node.enabled, 'bg-gray-300 border-primary': node.enabled && selected, 'bg-gray-50': !node.enabled }"
      @click="doClick"
    >
      <div class="w-6 h-6 mr-4 opacity-60">
        <font-awesome-icon :icon="node.faicon" class="w-6 h-6" />
      </div>
      <div class="grow flex flex-col">
        <span class="text-md font-medium opacity-80">{{ node.title }}</span>
        <span v-if="node.subTitle && node.subTitle.length > 0" class="text-sm font-medium opacity-80">{{ node.subTitle }}</span>
      </div>
      <div v-if="hasChildren" class="w-6 h-6 flex">
        <font-awesome-icon :icon="`fa-light ${expanded ? 'fa-chevron-down' : 'fa-chevron-right'}`" class="w-6 h-6" />
      </div>
    </div>
    <div v-if="hasChildren" v-show="node.expanded">
      <tx-nav-tree-node
        v-for="child in node.children?.filter(n => n.visible)" :key="child.id" :node="child"
        :selected-node="selectedNode" @update:expanded="updateChildExpanded" @click="doNodeClicked"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { ITxNavTreeNode } from './TxNavTree.types'

const props = defineProps<{
  node: ITxNavTreeNode
  selectedNode?: ITxNavTreeNode
  expanded?: boolean
}>()

const emits = defineEmits<{
  (e: 'update:expanded', node: ITxNavTreeNode, expanded: boolean): void
  (e: 'click', node: ITxNavTreeNode, parentNode: ITxNavTreeNode): void
}>()

const hasChildren = computed(() => props.node.children && props.node.children.length > 0)
const selected = computed(() => props.selectedNode?.id === props.node.id)
const expanded = ref(props.node.expanded)

function doClick() {
  if (!props.node.enabled) { return }

  if (hasChildren.value) {
    expanded.value = !expanded.value
    emits('update:expanded', props.node, expanded.value)
  }
  else {
    doNodeClicked(props.node)
  }
}

function doNodeClicked(node: ITxNavTreeNode) {
  emits('click', node, props.node)
}

function updateChildExpanded(childNode: ITxNavTreeNode, expanded: boolean) {
  childNode.expanded = expanded
}
</script>
