import { defineStore } from 'pinia'

export const useAdminStore = defineStore({
  id: 'adminStore',
  state: () => ({
    lastUsedFile: null as File | null,
  }),
  actions: {
    setLastUsedFile(file: File) {
      this.lastUsedFile = file
    },
  },
})
