import type { Directive, DirectiveHook } from 'vue'
import { throttle } from 'lodash-es'

const POINTER_START_EVENTS = ['mousedown', 'touchstart']
const POINTER_MOVE_EVENTS = ['mousemove', 'touchmove']
const POINTER_END_EVENTS = ['mouseup', 'touchend']

const init: DirectiveHook<any, null, any> = function (el, binding, _vnode) {
  // Default parameters
  let target = el // the element to apply the dragScroll on
  let active = true // enable/disable dragScroll
  let container: Window | HTMLElement = window
  let pos = { top: 0, left: 0, x: 0, y: 0 }

  // config type: boolean
  // Example: v-dragScroll="true" or v-dragScroll="false"
  if (typeof binding.value === 'boolean') {
    active = binding.value
  }
  else if (typeof binding.value === 'object') {
    // config type: object
    // Example: v-dragScroll="{ active: true , target: "child" }"

    // parameter: target
    if (typeof binding.value.target === 'string') {
      target = el.querySelector(binding.value.target)
      if (!target) {
        console.error('There is no element with the current target value.')
      }
    }
    else if (typeof binding.value.target !== 'undefined') {
      console.error('The parameter "target" should be either \'undefined\' or \'string\'.')
    }
    // parameter: container
    if (typeof binding.value.container === 'string') {
      container = document.querySelector(binding.value.container)
      if (!container) {
        console.error('There is no element with the current container value.')
      }
    }
    else if (typeof binding.value.container !== 'undefined') {
      console.error('The parameter "container" should be be either \'undefined\' or \'string\'.')
    }

    // parameter: active
    if (typeof binding.value.active === 'boolean') {
      // eslint-disable-next-line unused-imports/no-unused-vars
      active = binding.value.active
    }
    else if (typeof binding.value.active !== 'undefined') {
      console.error('The parameter "active" value should be either \'undefined\', \'true\' or \'false\'.')
    }
  }
  else if (typeof binding.value !== 'undefined') {
    // Throw an error if invalid parameters
    console.error('The passed value should be either \'undefined\', \'true\' or \'false\' or \'object\'.')
  }

  el.md = function (e: MouseEvent | TouchEvent) {
    el.style.cursor = 'grabbing'
    el.style.userSelect = 'none'

    pos = {
      left: el.scrollLeft,
      top: el.scrollTop,
      // Get the current mouse position
      x: e instanceof MouseEvent ? e.clientX : e.touches[0].clientX,
      y: e instanceof MouseEvent ? e.clientY : e.touches[0].clientY,
    }

    addEventListeners(document, POINTER_MOVE_EVENTS, el.mm)
    addEventListeners(document, POINTER_END_EVENTS, el.mu)
  }

  el.mm = throttle((e: MouseEvent | TouchEvent) => {
    // How far the mouse has been moved
    const x = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX
    const y = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY
    const dx = x - pos.x
    const dy = y - pos.y

    // Scroll the element
    el.scroll({ top: pos.top - dy, left: pos.left - dx, behavior: 'smooth' })
  }, 150)

  el.mu = function () {
    el.style.cursor = 'grab'
    el.style.removeProperty('user-select')

    removeEventListeners(document, POINTER_MOVE_EVENTS, el.mm)
    removeEventListeners(document, POINTER_END_EVENTS, el.mu)
  }

  addEventListeners(el, POINTER_START_EVENTS, el.md)
}

function addEventListeners(el: any, events: any, handler: any) {
  for (let i = 0, len = events.length; i < len; i++) {
    el.addEventListener(events[i], handler, { passive: false })
  }
}

function removeEventListeners(el: any, events: any, handler: any) {
  for (let i = 0, len = events.length; i < len; i++) {
    el.removeEventListener(events[i], handler, { passive: false })
  }
}

// function emitEvent(vnode: any, eventName: string, eventDetail?: any) {
//   // If vnode is a Vue component instance, use $emit. Otherwise use a native HTML event.
//   if (vnode.componentInstance) {
//     vnode.componentInstance.$emit(eventName, eventDetail)
//   }
//   else {
//     let event
//     if (typeof (window.CustomEvent) === 'function') {
//       event = new window.CustomEvent(eventName, { detail: eventDetail })
//     }
//     else {
//       // Deprecated fallback for IE
//       event = document.createEvent('CustomEvent')
//       event.initCustomEvent(eventName, true, true, eventDetail)
//     }
//     vnode.el.dispatchEvent(event)
//   }
// }

export default {
  created(el, binding, vnode) {
    init(el, binding, vnode, null)
  },
  updated(el, binding, vnode, _oldVnode) {
    // update the component only if the parameters change
    if (JSON.stringify(binding.value) !== JSON.stringify(binding.oldValue)) {
      init(el, binding, vnode, null)
    }
  },
  unmounted(el, _binding, _vnode) {
    const target = el as any
    removeEventListeners(target, POINTER_START_EVENTS, target.md)
    removeEventListeners(document, POINTER_END_EVENTS, target.mu)
    removeEventListeners(document, POINTER_MOVE_EVENTS, target.mm)
  },
} as Directive<HTMLElement>
