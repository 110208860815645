<template>
  <div class="relative flex flex-col grow overflow-hidden">
    <loader v-if="loading" />
    <div v-if="!loading" class="border-b bg-gray-50 shrink grow-0 flex flex-row">
      <tx-input
        v-model="filter" rounded faicon="fa-light fa-magnifying-glass" clearable :placeholder="t('general.typeToFilter')"
        class="my-4 mx-2"
      />
      <tx-actions-button
        :items="mySlidesListActions"
        class="my-auto mr-1"
      />
    </div>
    <div class="overflow-y-auto">
      <tx-tree
        v-if="!loading" ref="refMySlideTree" class="tree" :context-menu="contextMenu" show-checkbox="onHoverOrOtherChecked" :filter="filter" :allow-toggle-on-node-click="true" :data="mySlideTree" :show-slide-index="true" :draggable="true" @node-click="onTreeItemClick" @action-click="onTreeActionClick"
        @check-change="OnNodeCheckedChange" @context-menu-node="setContextMenuNode" @context-menu-item-click="contextMenuItemClick" @drop="OnDrop"
      />
    </div>
    <!-- create Folder Dialog -->
    <create-folder-dialog ref="createFolderDialogRef" :slide-tree="mySlideTree" :merch="props.merch" />
    <!-- create Folder Dialog -->
    <create-slide-dialog ref="createSlideDialogRef" :slide-tree="mySlideTree" :merch="props.merch" @create="onCreateSlide" />
    <!-- Duplicate Slide Dialog -->
    <tx-dialog
      v-model="showDuplicateSlideDialog" :show-ok-cancel="true" :title="t('merch.dialog.duplicateSlide.title')" confirm-text="general.ok" width="450px" height="250px"
      :loading="showDuplicateSlideLoading" :ok-state="(isDuplicateBulk ? selectedFolderForBulkDuplicateSlide !== '' : duplicateSlideName !== '') ? 'enabled' : 'disabled'" @ok="duplicateSlide" @cancel="showDuplicateSlideDialog = false"
    >
      <div v-if="!isDuplicateBulk" class="mt-6">
        <tx-input
          v-model.trim="duplicateSlideName" :label="t('merch.dialog.duplicateSlide.fields.slideName')" required
        />
      </div>
      <div v-else>
        <parent-folder-editor v-model="mySlideTree[0].key" :slide-tree="mySlideTree" :merch="props.merch" @update="updateFolderForDuplicateSlide" />
      </div>
    </tx-dialog>
    <!-- Generate frame dialog -->
    <generate-dialog ref="refGenerateMerchDialog" v-model="generateDialogVisible" :merch="merch" :slide-tree="mySlideTree" />
    <!-- edit slide -->
    <edit-slide-dialog ref="refEditSlideDialog" :merch="props.merch" :slide-tree="mySlideTree" :allow-editing="true" />
    <!-- warning for sharing slides -->
    <tx-dialog
      v-model="showDeleteWarningMessage" :title="t('general.alert')"
      show-ok-cancel @click="showDeleteWarningMessage = false" @ok="confirmDeleteSlides"
    >
      <div class="text-xl" v-text="deletedNode && deletedNode.isFolder ? t('merch.dialog.deleteSlides.folderWarning') : t('merch.dialog.deleteSlides.slideWarning')" />
    </tx-dialog>
    <!-- Edit Folder Dialog -->
    <tx-dialog
      v-model="showEditFolderDialog" :show-ok-cancel="true" :title="t('merch.dialog.editFolder.title')" confirm-text="general.ok" width="450px" height="250px"
      :loading="showEditFolderLoading" :ok-state="(folderNameValidation.length || existingFolderName === folderName) ? 'disabled' : 'enabled'" @ok="onEditFolder" @cancel="showEditFolderDialog = false"
    >
      <div class="mt-6">
        <tx-input
          v-model.trim="folderName" :label="t('merch.dialog.editFolder.fields.enterNewName')" required
        />
        <span v-for="(error, index) in folderNameValidation" :key="index" class="text-red-500">{{ error }}</span>
      </div>
    </tx-dialog>
    <!-- Import Slides Dialog -->
    <input id="file-upload" ref="fileInputRef" type="file" class="hidden" accept=".merch" @change="onMerchFileChange">
    <!-- ALERTS DIALOG -->
    <tx-dialog v-model="showAlertDialog" :show-ok="true" :title="alertDialogTitle" confirm-text="general.ok" width="450px" height="250px" @ok="showAlertDialog = false" @closed="onAlertDialogClosed">
      <div class="whitespace-pre-line">
        {{ alertDialogMessage }}
      </div>
    </tx-dialog>
  </div>
</template>

<script lang="ts" setup>
import type { Ref } from 'vue'
import { computed, nextTick, onMounted, onUnmounted, ref, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { clone } from 'lodash-es'
import { findAllChildNodes, findParentPath, getAllPathsWithParents, searchNodeInTree } from '../utils'
import CreateFolderDialog from './CreateFolderDialog.vue'
import CreateSlideDialog from './CreateSlideDialog.vue'
import ParentFolderEditor from './ParentFolderEditor.vue'
import EditSlideDialog from './EditSlideDialog.vue'
import TxActionsButton from '@/shared/components/TxActionsButton.vue'
import TxTree from '@/shared/components/TxTree.vue'
import { merchConstants } from '@/models/constants'
import utils from '@/services/utils'
import type Merch from '@/modules/merch/services/merch'
import Loader from '@/shared/components/Loader.vue'
import TxInput from '@/shared/components/TxInput.vue'
import * as sBar from '@/store/status'
import TxDialog from '@/shared/components/TxDialog.vue'
import { useNotificationStore } from '@/store/notification'
import type MerchSlide from '@/modules/merch/services/merchSlide'
import GenerateDialog from '@/shared/components/GenerateDialog.vue'
import appConfig from '@/services/appConfig'
import { useUserStore } from '@/store/userData'
import { useBrowseStore } from '@/store/browse'

const props = defineProps<{
  merch: Merch | undefined
  showGenerateDialog: boolean
  showGenerateDialogViaBrowse: boolean
  activeTab: string
}>()
const emit = defineEmits<{
  (e: 'nodeClick', node: string | number): void
  (e: 'slideTree', folders: ITreeNode[]): void
  (e: 'shareSlides', nodeId: string): void
  (e: 'clearActiveSlide'): void
}>()

const { t } = useI18n()
const notificationStore = useNotificationStore()
const userStore = useUserStore()
const browseStore = useBrowseStore()

const refMySlideTree = ref<InstanceType<typeof TxTree>>()
const mySlideTree = ref<ITreeNode[]>([])
const loading = ref(false)
const filter = ref('')
const createFolderDialogRef = ref<InstanceType<typeof CreateFolderDialog>>()
const createSlideDialogRef = ref<InstanceType<typeof CreateSlideDialog>>()
const fileInputRef = ref<HTMLInputElement>()
const showDuplicateSlideDialog = ref(false)
const duplicateSlideName = ref('')
const selectedFolderForBulkDuplicateSlide = ref('')
const actionNode = ref<ITreeNode>()
const showDuplicateSlideLoading = ref(false)
// const editMode = ref(false)
const checkedNodes = ref<ITreeNode[]>([])
const isDuplicateBulk = ref(false)
const refGenerateMerchDialog = ref<InstanceType<typeof GenerateDialog>>()
const refEditSlideDialog = ref<InstanceType<typeof EditSlideDialog>>()
const generateDialogVisible = ref(false)
const contextNode = ref<ITreeNode | null>(null)
const selectedEditFolderNode = ref<ITreeNode | null>(null)
const showDeleteWarningMessage = ref(false)
const deletedNode = ref<ITreeNode | null>(null)
const showAlertDialog = ref(false)
const alertDialogTitle = ref('')
const alertDialogMessage = ref('')
const showEditFolderDialog = ref(false)
const folderName = ref('')
const showEditFolderLoading = ref(false)
const existingFolderName = ref('')

watchEffect(() => {
  if (props.merch) {
    attach(props.merch)
  }
})
watch(() => props.showGenerateDialog, () => {
  refGenerateMerchDialog.value?.showDialog()
})
watch(() => props.showGenerateDialogViaBrowse, (value) => {
  if (value) {
    refGenerateMerchDialog.value?.showDialog(browseStore.dataObjectForMerch)
  }
})

watch(() => props.activeTab, (value) => {
  if (value === 'mySlides') {
    loading.value = true
    onMySlidesLoaded()
  }
})

watch(() => mySlideTree.value, (value) => {
  emit('slideTree', value)
}, { deep: true })

onUnmounted(() => {
  if (props.merch) {
    detach(props.merch)
  }
})

const mySlidesListActions = computed(() => {
  return [
    { key: 'createFolder', disabled: false, visible: true, label: t('merch.addFolder'), icon: 'fa-light fa-folder-plus', onClick: openCreateFolderDialog },
    { key: 'createSlide', disabled: false, visible: true, label: t('merch.addSlide'), icon: 'fa-light fa-plus', onClick: openCreateSlideDialog },
    { key: 'duplicateBulkSlides', disabled: checkedNodes.value.length === 0, visible: true, label: t('merch.duplicate'), icon: 'fa-light fa-copy', onClick: duplicateBulkSlides },
  ] as ITxActionItem[]
})

const contextMenu = computed(() => {
  let menu: IContextMenuItem[] = []
  if (utils.isDefined(contextNode.value)) {
    if (contextNode.value.isFolder) {
      menu = [{
        key: 'editFolder',
        label: t('merch.slideListAction.editFolder'),
        icon: 'fa-edit',
        disabled: false,
        visible: true,
      }, {
        key: 'deleteFolder',
        label: t('merch.slideListAction.deleteFolder'),
        icon: 'fa-trash-can',
        disabled: false,
        visible: true,
      }, {
        key: 'exportSlides',
        label: t('merch.slideListAction.exportSlides'),
        icon: 'fa-search',
        disabled: false,
        visible: true,
      }, {
        key: 'importSlides',
        label: t('merch.slideListAction.importSlides'),
        icon: 'fa-search',
        disabled: false,
        visible: true,
      }]
    }
    else {
      menu = [{
        key: 'editSlide',
        label: t('merch.slideListAction.editSlide'),
        icon: 'fa-edit',
        disabled: false,
        visible: true,
      }, {
        key: 'duplicateSlide',
        label: t('merch.slideListAction.duplicateSlide'),
        icon: 'fa-copy',
        disabled: false,
        visible: true,
      }, {
        key: 'deleteSlide',
        label: t('merch.slideListAction.deleteSlide'),
        icon: 'fa-trash-can',
        disabled: false,
        visible: true,
      }, {
        key: 'sharing',
        label: t('merch.slideListAction.sharing'),
        icon: 'fa-share',
        disabled: false,
        visible: true,
      }, {
        key: 'exportSlide',
        label: t('merch.slideListAction.exportSlide'),
        icon: 'fa-search',
        disabled: false,
        visible: true,
      }, {
        key: 'exportAsTemplate',
        label: t('merch.slideListAction.exportAsTemplate'),
        icon: 'fa-search',
        disabled: false,
        visible: true,
      }]
    }
  }
  return menu
})

const parentFolders = computed(() => {
  let folders: any[] = []
  if (mySlideTree.value && mySlideTree.value.length !== 0) {
    folders = getAllPathsWithParents(mySlideTree.value)
    folders = folders.map(folder => folder.path)
  }
  return folders
})
const folderNameValidation = computed(() => {
  const errors: string[] = []
  if (existingFolderName.value !== folderName.value) {
    if (!folderName.value.trim()) {
      errors.push(t('merch.dialog.editFolder.validations.folderNameRequired'))
    }
    // maxLength
    if (folderName.value.trim().length > 250) {
      errors.push(t('merch.dialog.editFolder.validations.maxLength', { max: '250' }))
    }
    // Check if folder name already exists
    if (utils.isValidStringValue(folderName.value)) {
      const isFolderNameExist = parentFolders.value.some((parentFolder) => {
        const nameList = parentFolder.split(merchConstants.folderPathSeparator).map(namePath => namePath.trim())
        return nameList.includes(folderName.value.trim())
      })
      if (isFolderNameExist) {
        errors.push(t('merch.dialog.editFolder.validations.duplicateFolderName'))
      }
    }
  }
  return errors
})

function attach(obj: Merch) {
  obj.on('mySlides-loaded', onMySlidesLoaded)
  obj.on('object-removed', onSlideObjectRemoved)
  obj.on('slide-dirty', onSlideDirtyChange)
  obj.on('object-modified', onObjectModified)
  obj.on('slide-added', onSlideAdded)
  obj.on('add-folder', onCreateFolder)
  obj.on('slide-shared-changed', onSlideSharingSharedUserIconUpdate)
  obj.on('update-slide', onUpdateSlide)
  obj.on('slides-saved', onSlidesSaved)
}

function detach(obj: Merch) {
  obj.off('mySlides-loaded', onMySlidesLoaded)
  obj.off('object-removed', onSlideObjectRemoved)
  obj.off('slide-dirty', onSlideDirtyChange)
  obj.off('object-modified', onObjectModified)
  obj.off('slide-added', onSlideAdded)
  obj.off('add-folder', onCreateFolder)
  obj.off('slide-shared-changed', onSlideSharingSharedUserIconUpdate)
  obj.off('update-slide', onUpdateSlide)
  obj.off('slides-saved', onSlidesSaved)
}
async function buildTree(treeData: Ref<ITreeNode[]>, slidesData) {
  mySlideTree.value = []
  slidesData.forEach((slideData) => {
    const target = createFolderTrailAndGetTargetNode(slideData, treeData.value)
    if (!Array.isArray(target)) {
      const node: ITreeNode = {
        key: slideData.SlideId,
        labelKey: slideData.SlideName.toLowerCase(),
        label: slideData.SlideName,
        faicon: 'fa-light fa-frame',
        faicon2: slideData.SharedUsers.length !== 0 || slideData.SharedUsersGroups.length !== 0 ? 'fa-light fa-user-group' : null,
        sortOrder: slideData.SortOrder || 0,
        checked: false,
        expanded: false,
        isFolder: false,
        path: [],
        children: [],
        slideIndex: target.children.filter(slide => slide.isFolder === false).length + 1,
        sharedUsers: slideData.SharedUsers,
        sharedUsersGroups: slideData.SharedUsersGroups,
        badgeValue: slideData.unavailableArticleCount,
        isDirty: slideData.isDirty,
        actions: ['Edit', 'Duplicate', 'Delete'],
      }
      node.parent = target
      utils.insertSorted(node, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
    }
  })
  loading.value = false
}
function setStatusBar() {
  if (props.merch?.merchSlides && props.merch?.merchSlides.value && Object.keys(props.merch?.merchSlides.value).length) {
    sBar.setItemValue('ttlSlides', Object.keys(props.merch?.merchSlides.value).length.toLocaleString())
  }
}
function onMySlidesLoaded() {
  setStatusBar()
  if (props.merch?.merchSlides && Object.keys(props.merch?.merchSlides.value).length) {
    buildTree(mySlideTree, Object.values(props.merch?.merchSlides.value))
    mySlideTree.value.forEach((node) => {
      rebuildNodeIndexes(node)
    })
  }
  else {
    loading.value = false
  }
}
function createFolderTrailAndGetTargetNode(slideData, target: ITreeNode[] | ITreeNode) {
  const folderIdPathList = slideData.FolderId.split(merchConstants.folderPathSeparator)
  const folderNamePathList = slideData.FolderName.split(merchConstants.folderPathSeparator)
  for (let i = 0; i < folderIdPathList.length; i++) {
    const currentTarget = !Array.isArray(target) ? target.children : target
    let subFolder = currentTarget.find(folder => folder.key === folderIdPathList[i])
    if (!subFolder) {
      subFolder = {
        key: folderIdPathList[i],
        label: folderNamePathList[i].trim(),
        labelKey: folderNamePathList[i].trim().toLowerCase(),
        faicon: 'fa-light fa-folder',
        sortOrder: slideData.FolderIdSortOrder ? slideData.FolderIdSortOrder : 0,
        checked: false,
        expanded: false,
        isFolder: true,
        path: [],
        children: [],
        badgeValue: slideData.unavailableArticleCount,
        isDirty: slideData.isDirty,
        actions: ['Edit', 'Delete'],
      }
      if (!Array.isArray(target)) {
        subFolder.parent = target
        utils.insertSorted(subFolder, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
      }
      else {
        utils.insertSorted(subFolder, target, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
      }
    }
    else {
      subFolder.badgeValue += slideData.unavailableArticleCount
      subFolder.isDirty = slideData.isDirty
    }
    target = subFolder
  }
  return target
}
function onTreeItemClick(nodeData: ITreeNode) {
  if (!nodeData.isFolder) {
    emit('nodeClick', nodeData.key)
  }
}
// function toggleEditMode() {
//   editMode.value = !editMode.value
//   if (!editMode.value) {
//     checkedNodes.value = []
//     resetCheckedNodes(mySlideTree.value)
//   }
// }
// function resetCheckedNodes(nodes: ITreeNode[]) {
//   if (nodes.length > 0) {
//     nodes.forEach((node) => {
//       node.checked = false
//       resetCheckedNodes(node.children)
//     })
//   }
// }
function duplicateBulkSlides() {
  if (checkedNodes.value.length !== 0) {
    selectedFolderForBulkDuplicateSlide.value = ''
    showDuplicateSlideDialog.value = true
    isDuplicateBulk.value = true
  }
}
function updateFolderForDuplicateSlide(modelValue: string) {
  selectedFolderForBulkDuplicateSlide.value = modelValue
}
function expandTree(node: ITreeNode) {
  let currentNode = node.parent
  while (utils.isDefined(currentNode)) {
    currentNode.expanded = true
    currentNode = currentNode.parent // Move to the parent node
  }
}
function onTreeActionClick(item: ITreeNode, action: string) {
  if (action === 'Duplicate') {
    showDuplicateSlideDialog.value = true
    actionNode.value = item
    duplicateSlideName.value = ''
    isDuplicateBulk.value = false
  }
  else if (action === 'Edit') {
    selectedEditFolderNode.value = item
    if (item.isFolder === true) {
      showEditFolderDialog.value = true
      folderName.value = item.label
      existingFolderName.value = clone(item.label)
    }
    else { // edit slide
      refEditSlideDialog.value?.showDialog(item.key)
    }
  }
  else if (action === 'Delete') {
    deletedNode.value = item
    showDeleteWarningMessage.value = true
  }
}
function OnNodeCheckedChange() {
  const checkedData = refMySlideTree.value?.getCheckedNodes(true)
  checkedNodes.value = []
  if (utils.isDefined(checkedData) && checkedData.length > 0) {
    checkedNodes.value = checkedData.filter(node => node.isFolder === false)
  }
}
function openCreateSlideDialog() {
  createSlideDialogRef.value?.showDialog()
}
function openCreateFolderDialog() {
  createFolderDialogRef.value?.showDialog()
}
function onCreateFolder(data) {
  const name = data.name.trim()
  const id = data.id || utils.randomId()
  const parentFolderName = data.path
  const path = parentFolderName.split(merchConstants.folderPathSeparator)
  const parentFolderNodeId = path.pop() || ''
  const parentNode = searchNodeInTree(mySlideTree.value, parentFolderNodeId.trim())
  const subFolder: ITreeNode = {
    key: id,
    label: name,
    labelKey: name.toLowerCase(),
    faicon: 'fa-light fa-folder',
    sortOrder: 0,
    checked: false,
    expanded: false,
    isFolder: true,
    path: [],
    children: [],
    actions: ['Edit', 'Delete'],
  }
  if (parentNode !== undefined) {
    subFolder.sortOrder = Math.max(...parentNode!.children.map(child => child.sortOrder), 0) + 1
    subFolder.parent = parentNode
    utils.insertSorted(subFolder, parentNode!.children, (a, b) => utils.comparer(a, b, ['sortOrder']))
  }
  else {
    subFolder.sortOrder = Math.max(...mySlideTree.value.map(child => child.sortOrder), 0) + 1
    utils.insertSorted(subFolder, mySlideTree.value, (a, b) => utils.comparer(a, b, ['sortOrder']))
  }
  createFolderDialogRef.value!.visible = false
  createFolderDialogRef.value!.loading = false
}

async function onCreateSlide(formModel, resourceUrl) {
  const path = formModel.folder.split(merchConstants.folderPathSeparator)
  const parentFolderNodeId = path.pop() || ''
  const parentNode = searchNodeInTree(mySlideTree.value, parentFolderNodeId.trim(), 'key')
  if (parentNode !== undefined) {
    let templateObjects = []
    // according to template get the template objects
    if (resourceUrl !== null) {
    // download the resource file to get the template objects
      const res = await axios.get(`${resourceUrl}`, { responseType: 'arraybuffer' })
      const bufferView = new Uint8Array(res.data)
      const templateText = new TextDecoder().decode(bufferView)
      const templateObj = JSON.parse(templateText)
      if (utils.isDefined(templateObj) && templateObj.objects) {
        templateObjects = templateObj.objects
      }
    }
    await addSlide(parentNode, templateObjects, formModel.slide.toString().trim(), formModel.slideSize, formModel.imageScaleFactor)
  }
  createSlideDialogRef.value!.visible = false
  createSlideDialogRef.value!.loading = false
}
async function addSlide(parentNode: ITreeNode, templateObjects: any[], slideName, slideSize, imageScaleFactor, sortOrderValue?) {
  const sortOrder = sortOrderValue || Math.max(...parentNode.children.map(child => child.sortOrder), 0) + 1
  const folderStructure = findParentPath(parentNode)
  const slideData = await props.merch!.addSlide(folderStructure.id, folderStructure.name, parentNode.sortOrder, slideName, templateObjects, slideSize, imageScaleFactor, sortOrder)
  nextTick(() => {
    if (slideData) {
      refMySlideTree.value?.setCurrentKey(slideData.SlideId)
      emit('nodeClick', slideData.SlideId)
    }
  })
}
async function duplicateSlide() {
  showDuplicateSlideLoading.value = true
  let slideIds: string[] = []
  let parentNode: ITreeNode | undefined | null
  if (!isDuplicateBulk.value && duplicateSlideName.value !== '' && actionNode.value) {
    parentNode = actionNode.value.parent
    if (parentNode !== undefined) {
      slideIds = [actionNode.value.key] as string[]
    }
  }
  else if (isDuplicateBulk.value && selectedFolderForBulkDuplicateSlide.value !== '' && checkedNodes.value.length !== 0) {
    const path = selectedFolderForBulkDuplicateSlide.value.split(merchConstants.folderPathSeparator)
    const parentFolderNodeId = path.pop() || ''
    parentNode = searchNodeInTree(mySlideTree.value, parentFolderNodeId.trim(), 'key')
    if (parentNode !== undefined) {
      slideIds = checkedNodes.value.map(node => node.key) as string[]
    }
  }
  if (slideIds.length) {
    const slidesData = await props.merch?.getSlidesData(slideIds)
    slideIds.forEach((slideId) => {
      if (slidesData && slidesData[slideId]) {
        const merchSlide = slidesData[slideId]
        if (typeof merchSlide === 'object') {
          addSlide(parentNode!, merchSlide?.objects || [], isDuplicateBulk.value ? merchSlide.SlideName : duplicateSlideName.value, merchSlide?.SlideSize || merchConstants.slideSize.wideScreen, merchSlide?.ImageSize || merchConstants.slideImageDefaultScaleFactors.large, isDuplicateBulk.value ? merchSlide.SortOrder : undefined)
        }
        else {
          console.error(slidesData[slideId])
          notificationStore.addNotification({ message: t('merch.errors.slideContentInvalid'), type: 'Alert' })
        }
      }
    })
  }
  showDuplicateSlideLoading.value = false
  showDuplicateSlideDialog.value = false
  duplicateSlideName.value = ''
  selectedFolderForBulkDuplicateSlide.value = ''
}
function setContextMenuNode(item) {
  contextNode.value = item
}
async function contextMenuItemClick(option: IContextMenuItem) {
  if (contextNode.value) {
    const nodeId = contextNode.value.key.toString()
    if (option.key === 'sharing') {
      emit('shareSlides', nodeId)
    }
    else if (option.key === 'editSlide') {
      selectedEditFolderNode.value = contextNode.value
      refEditSlideDialog.value?.showDialog(contextNode.value.key)
    }
    else if (option.key === 'exportSlides' || option.key === 'exportSlide') {
      loading.value = true
      try {
        const slides: any[] = []
        let slideIds: string[] = []
        if (contextNode.value.isFolder) {
          let childNodes: ITreeNode[] = []
          childNodes = findAllChildNodes(contextNode.value, childNodes)
          slideIds = childNodes.map(node => node.key) as string[]
        }
        else {
          slideIds.push(contextNode.value.key.toString())
        }
        const slidesData = await props.merch?.getSlidesData(slideIds)
        for (const slideId of slideIds) {
          if (slidesData && slidesData[slideId]) {
            const merchSlide = slidesData[slideId] as MerchSlide
            const templateObjects = await translateSlideObjectForExport(merchSlide.objects)
            slides.push({ ...merchSlide, objects: templateObjects })
          }
        }

        if (slides.length) {
          const slidesJson = JSON.stringify(slides, null, 2)
          const blob = new Blob([slidesJson], { type: 'application/json' })
          const fileName = contextNode.value.isFolder ? `${contextNode.value.labelKey}.merch` : `${slides[0].SlideName}.merch`
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', fileName)
          loading.value = false
          link.click()
          notificationStore.addNotification({ message: t('merch.messages.slidesExportSuccess'), type: 'Success' })
        }
      }
      catch (error) {
        loading.value = false
        notificationStore.addNotification({ message: t('merch.messages.slidesExportError'), type: 'Alert', details: utils.getErrorMessage(error) })
      }
    }
    else if (option.key === 'importSlides') {
      fileInputRef.value?.click()
    }
    else if (option.key === 'exportAsTemplate') {
      loading.value = true
      try {
        const slides: any[] = []
        const slidesData = await props.merch?.getSlidesData([contextNode.value.key.toString()])
        if (slidesData && slidesData[contextNode.value.key.toString()]) {
          const merchSlide = slidesData[contextNode.value.key.toString()] as MerchSlide
          let containsArticle = false
          const slideGroups = merchSlide.objects.filter(slideObject => slideObject.type === 'group')
          for (let index = 0; index < slideGroups.length; index++) {
            const groupObjects = slideGroups[index].objects || slideGroups[index]._objects || []
            for (let objectIndex = 0; objectIndex < groupObjects.length; objectIndex++) {
              const groupObject = groupObjects[objectIndex]
              if (groupObject.type.toLowerCase() === 'articleimage' || groupObject.type.toLowerCase() === 'articledetails') {
                containsArticle = true
                break
              }
            }
            if (containsArticle) {
              break
            }
          }
          if (containsArticle || merchSlide.objects.findIndex(obj => obj.type.toLowerCase() === 'articleimage' || obj.type.toLowerCase() === 'articledetails') >= 0) {
            loading.value = false
            showAlertDialog.value = true
            alertDialogTitle.value = t('merch.dialog.noArticlesInTemplate.title')
            alertDialogMessage.value = t('merch.dialog.noArticlesInTemplate.body')
            return
          }
          else {
            // Set all 'locked' objects to 'template' objects
            merchSlide.objects.forEach((obj) => {
              if (obj.locked) {
                obj.templateObject = true
              }
            })
            slides.push({ ...merchSlide, objects: merchSlide.objects })
          }
        }

        if (slides.length) {
          const slidesJson = JSON.stringify(slides, null, 2)
          const blob = new Blob([slidesJson], { type: 'application/json' })
          const fileName = `${slides[0].SlideName}.json`
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', fileName)
          loading.value = false
          link.click()
          notificationStore.addNotification({ message: t('merch.messages.slidesExportSuccess'), type: 'Success' })
        }
      }
      catch (error) {
        loading.value = false
        notificationStore.addNotification({ message: t('merch.messages.slidesExportError'), type: 'Alert', details: utils.getErrorMessage(error) })
      }
    }
    else if (option.key === 'deleteSlide') {
      deletedNode.value = contextNode.value
      showDeleteWarningMessage.value = true
    }
    else if (option.key === 'deleteFolder') {
      deletedNode.value = contextNode.value
      showDeleteWarningMessage.value = true
    }
    else if ('editFolder') {
      selectedEditFolderNode.value = contextNode.value
      showEditFolderDialog.value = true
      folderName.value = contextNode.value.label
    }
  }
}
function confirmDeleteSlides() {
  showDeleteWarningMessage.value = false
  let slideToDelete: string[] = []
  if (deletedNode.value && props.merch) {
    if (deletedNode.value.isFolder) {
      let slideNodesToDelete: ITreeNode[] = []
      slideNodesToDelete = findAllChildNodes(deletedNode.value, slideNodesToDelete)
      slideToDelete = slideNodesToDelete.map(slideNode => slideNode.key.toString())
    }
    else {
      slideToDelete.push(deletedNode.value.key.toString())
    }
    if (slideToDelete.length) {
      props.merch.deleteSlides(slideToDelete)
    }
    if (props.merch.activeSlide && slideToDelete.includes(props.merch.activeSlide.SlideId)) {
      emit('clearActiveSlide')
    }
    OnDeleteSlides()
  }
}
function OnDeleteSlides() {
  if (utils.isDefined(deletedNode.value)) {
    let currentNode = deletedNode.value.parent
    if (currentNode !== undefined && utils.isDefined(deletedNode.value.badgeValue)) {
      while (utils.isDefined(currentNode)) {
        if (utils.isDefined(currentNode.badgeValue)) {
          currentNode.badgeValue! -= deletedNode.value.badgeValue!
        }
        currentNode = currentNode.parent
      }
    }
    const parentNode = deletedNode.value.parent
    if (utils.isDefined(parentNode)) {
      const nodeIndex = parentNode.children.findIndex(node => deletedNode.value && node.key === deletedNode.value.key)
      if (nodeIndex !== -1) {
        parentNode.children.splice(nodeIndex, 1)
      }
    }
    else {
      const nodeIndex = mySlideTree.value.findIndex(node => deletedNode.value && node.key === deletedNode.value.key)
      if (nodeIndex !== -1) {
        mySlideTree.value.splice(nodeIndex, 1)
      }
    }
    setStatusBar()
  }
}
function onEditFolder() {
  if (selectedEditFolderNode.value) {
    showEditFolderLoading.value = true
    selectedEditFolderNode.value.label = folderName.value
    selectedEditFolderNode.value.labelKey = folderName.value.toLowerCase()

    let childNodes: ITreeNode[] = []
    childNodes = findAllChildNodes(selectedEditFolderNode.value, childNodes)
    const slideIdNewFolderNameMap = {}
    childNodes.forEach((node) => {
      const folderStructure = findParentPath(node)
      slideIdNewFolderNameMap[node.key.toString()] = folderStructure.name.trim()
    })

    props.merch?.renameFolder(slideIdNewFolderNameMap)
    showEditFolderLoading.value = false
  }
  showEditFolderDialog.value = false
}
async function translateSlideObjectForExport(slideObjects) {
  for (const object of slideObjects) {
    if (object.type.toLowerCase() === 'articleimage') {
      const article = await appConfig.DB!.articles.get({ CatalogCode: userStore.activeCatalog!.CatalogCode, Id: object.articleId })
      if (utils.isDefined(article)) {
        object.articleNumber = article.ArticleNumber
      }
      else {
        console.warn('Invalid Object: This condition should never happen', object)
      }
    }
  }
  return slideObjects
}
function onMerchFileChange(event: Event) {
  const nodeId = contextNode.value ? contextNode.value.key.toString() : ''
  const parentNode = searchNodeInTree(mySlideTree.value, nodeId, 'key', false)
  const files = (event.target as HTMLInputElement).files
  if (files && files.length > 0 && parentNode) {
    const file = files[0]
    const reader = new FileReader()
    reader.onload = async (e) => {
      const fileContent = e.target!.result
      try {
        const slides = JSON.parse(fileContent as string)
        for (const slide of slides) {
          slide.objects = await translateSlideObjectWhileImport(slide.objects)
          slide.SortOrder = Math.max(...parentNode.children.map(child => child.sortOrder), 0) + 1
          await addSlide(parentNode, slide.objects, slide.SlideName.toString().trim(), slide.SlideSize, slide.ImageSize, slide.SortOrder)
        }
        notificationStore.addNotification({ message: t('merch.messages.slidesImportSuccess'), type: 'Success' })
      }
      catch (error) {
        notificationStore.addNotification({ message: t('merch.messages.slidesImportError'), type: 'Alert', details: utils.getErrorMessage(error) })
      }
    }
    reader.readAsText(file)
  }
}
async function translateSlideObjectWhileImport(slideObjects) {
  const validObjects: any[] = []
  for (const object of slideObjects) {
    if (object.type.toLowerCase() === 'articleimage' || object.type.toLowerCase() === 'articledetails') {
      if (utils.isDefined(object.articleNumber) && object.articleNumber.toString().trim() !== '') {
        const article = await appConfig.DB!.articles.get({ CatalogCode: userStore.activeCatalog!.CatalogCode, ArticleNumber: object.articleNumber })
        if (utils.isDefined(article)) {
          object.articleId = article.Id
          validObjects.push(object)
        }
        else {
          console.warn('Invalid Object: article does not exist in the current Catalog', object)
        }
      }
      else {
        console.warn('Exported slide file is not correct, missing data', object)
      }
    }
    else {
      validObjects.push(object)
    }
  }
  return validObjects
}
function updateUnavailableArticleCount(slideId: string, operation: 'add' | 'remove') {
  let currentNode = searchNodeInTree(mySlideTree.value, slideId, 'key', false)
  if (currentNode !== undefined && currentNode.badgeValue !== undefined && props.merch?.merchSlides && props.merch?.merchSlides.value && props.merch?.merchSlides.value[slideId]) {
    if (currentNode.badgeValue !== props.merch?.merchSlides.value[slideId].unavailableArticleCount) {
      const difference = operation === 'remove' ? currentNode.badgeValue - props.merch?.merchSlides.value[slideId].unavailableArticleCount : props.merch.merchSlides.value[slideId].unavailableArticleCount - currentNode.badgeValue
      currentNode.badgeValue = props.merch?.merchSlides.value[slideId].unavailableArticleCount
      currentNode = currentNode.parent
      while (utils.isDefined(currentNode)) {
        if (operation === 'remove') {
          currentNode.badgeValue! -= difference
        }
        else {
          currentNode.badgeValue! += difference
        }
        currentNode = currentNode.parent // Move to the parent node
      }
    }
  }
}
function onSlideDirtyChange(obj) {
  const slideId = obj.slideId
  const value = obj.value
  if (slideId) {
    const node = searchNodeInTree(mySlideTree.value, slideId.toString(), 'key', false)
    if (node !== undefined) {
      node.isDirty = value
      let parentNode = node.parent
      if (parentNode !== undefined && !parentNode.isDirty) {
        while (utils.isDefined(parentNode)) {
          parentNode.isDirty = value
          parentNode = parentNode.parent
        }
      }
    }
  }
}
function onSlidesSaved() {
  clearDirtyFlag(mySlideTree.value)
}
function clearDirtyFlag(nodes: ITreeNode[]) {
  nodes.forEach((node) => {
    if (node.isFolder && node.isDirty && node.children.length) {
      clearDirtyFlag(node.children)
    }
    node.isDirty = false
  })
}
function onSlideObjectRemoved(obj) {
  const slideId = obj.slideId
  updateUnavailableArticleCount(slideId, 'remove')
}

function onObjectModified(object) {
  if (props.merch && props.merch.activeSlide) {
    updateUnavailableArticleCount(props.merch.activeSlide.SlideId, object.countOperation)
  }
}

function onSlideAdded(obj) {
  console.log('f')
  const slideData = obj.slide
  if (slideData) {
    const path = slideData.FolderId.split(merchConstants.folderPathSeparator)
    const parentFolderNodeId = path.pop() || ''
    let parentNode = searchNodeInTree(mySlideTree.value, parentFolderNodeId.trim(), 'key')
    if (parentNode === undefined) {
      createFolderTrailAndGetTargetNode(slideData, mySlideTree.value)
    }
    parentNode = searchNodeInTree(mySlideTree.value, parentFolderNodeId.trim(), 'key')
    if (parentNode !== undefined) {
      const nodeData: ITreeNode = {
        key: slideData.SlideId,
        labelKey: slideData.SlideName.toLowerCase(),
        label: slideData.SlideName,
        faicon: 'fa-light fa-frame',
        faicon2: slideData.SharedUsers.length !== 0 || slideData.SharedUsersGroups.length !== 0 ? 'fa-light fa-user-group' : null,
        sortOrder: slideData.SortOrder || 0,
        checked: false,
        expanded: false,
        isFolder: false,
        path: [],
        children: [],
        slideIndex: parentNode.children.filter(slide => slide.isFolder === false).length + 1,
        sharedUsers: slideData.SharedUsers,
        sharedUsersGroups: slideData.SharedUsersGroups,
        badgeValue: slideData.unavailableArticleCount,
        actions: ['Edit', 'Duplicate', 'Delete'],
        isDirty: true,
      }
      nodeData.parent = parentNode
      utils.insertSorted(nodeData, parentNode.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
      if (parentNode !== undefined) {
        rebuildNodeIndexes(parentNode)
        setStatusBar()
        while (utils.isDefined(parentNode)) {
          parentNode.isDirty = true
          parentNode.badgeValue += slideData.unavailableArticleCount
          parentNode = parentNode.parent
        }
        expandTree(nodeData)
      }
    }
  }
}
function rebuildNodeIndexes(node: ITreeNode) {
  if (node && node.children) {
    for (let i = 0; i < node.children.length; i++) {
      if (!node.children[i].isFolder) {
        node.children[i].slideIndex = i + 1
      }
    }
  }
}
function onSlideSharingSharedUserIconUpdate(obj) {
  obj.slideIds.forEach((slideId) => {
    const node = searchNodeInTree(mySlideTree.value, slideId.toString(), 'key', false)
    if (node !== undefined) {
      node.sharedUsers = obj.users
      node.sharedUsersGroups = obj.groups
      node.faicon2 = obj.users.length !== 0 || obj.groups.length !== 0 ? 'fa-light fa-user-group' : null
    }
  })
}
async function onUpdateSlide(updatedObjects) {
  const selectedSlideId = updatedObjects.slideId
  const slideData = updatedObjects.slideData
  const updatedKeys = updatedObjects.updatedKeys
  const slideNode = searchNodeInTree(mySlideTree.value, selectedSlideId, 'key', false)
  if (utils.isDefined(slideNode)) {
    if (updatedKeys.includes('folder')) {
      const parentNode = slideNode.parent
      if (parentNode) {
        const nodeIndex = parentNode.children.findIndex(node => node.key === selectedSlideId)
        if (nodeIndex !== -1) {
          parentNode.children.splice(nodeIndex, 1)
        }
      }
      onSlideAdded({ slide: slideData })
    }
    else if (updatedKeys.includes('slideName')) {
      slideNode.labelKey = slideData.SlideName.toLowerCase()
      slideNode.label = slideData.SlideName
    }
  }
  refEditSlideDialog.value!.visible = false
  refEditSlideDialog.value!.loading = false
}

function onAlertDialogClosed() {
  showAlertDialog.value = false
  alertDialogTitle.value = ''
  alertDialogMessage.value = ''
}
function allowDrop(draggedNode, targetNode, dropPosition) {
  let allowDrop = true
  if (draggedNode.key === targetNode.key) { // drag and drop location cannot be same
    allowDrop = false
  }
  if (dropPosition === 'inner' && !targetNode.isFolder) { // when move inside the node, it should be folder only
    allowDrop = false
  }
  if (dropPosition !== 'inner' && !draggedNode.isFolder && targetNode.isFolder && !utils.isDefined(targetNode.parent)) { // cant add slide at root node
    allowDrop = false
  }
  return allowDrop
}
function updateChildrenParent(node: ITreeNode) {
  // Recursively update the parent property of all children
  if (node.children) {
    node.children.forEach((child) => {
      child.parent = node
      updateChildrenParent(child)
    })
  }
}
function OnDrop(draggedNodeKey, targetNode, dropPosition) {
  const draggedNode = searchNodeInTree(mySlideTree.value, draggedNodeKey, 'key', false)
  if (utils.isDefined(draggedNode) && props.merch) {
    // if ((!draggedN ode.isFolder && targetNode.parent !== undefined) || draggedNode.isFolder) {
    if (allowDrop(draggedNode, targetNode, dropPosition)) {
      let newSort = 0
      if (dropPosition === 'inner') {
        if (targetNode.isFolder) {
          newSort = Math.max(...targetNode.children.map(child => child.sortOrder), 0) + 1
        }
      }
      else if (dropPosition === 'before') {
        const previousSibling = refMySlideTree.value?.getPreviousSibling(targetNode)
        newSort = previousSibling ? (previousSibling.sortOrder + targetNode.sortOrder) / 2 : targetNode.sortOrder / 2
      }
      else {
        if (targetNode.isFolder && targetNode.expanded && targetNode.children.length) { // its same like moving inside the folder at top
          newSort = targetNode.children[0].sortOrder ? targetNode.children[0].sortOrder / 2 : 0
        }
        else {
          const nextSibling = refMySlideTree.value?.getNextSibling(targetNode)
          if (utils.isDefined(nextSibling)) {
            newSort = (targetNode.sortOrder + nextSibling.sortOrder) / 2
          }
          else {
            newSort = targetNode.sortOrder + 1
          }
        }
      }
      if (draggedNode.isFolder) {
        let childNodes: ITreeNode[] = []
        const updatedNode = updateNodePosition(dropPosition, draggedNode, targetNode, newSort)
        if (updatedNode) {
          childNodes = findAllChildNodes(updatedNode, childNodes)
          const slidesData = {}
          childNodes.forEach((node) => {
            slidesData[node.key] = findParentPath(node)
          })
          // update in merch the folder id and folder name and sort value
          props.merch.updateSortDetailsOnSlides(slidesData, newSort, null) // local slides update and
        }
      }
      else {
        const isSortOrderChanged = draggedNode.parent && targetNode.parent && draggedNode.parent.key === targetNode.parent.key
        const updatedNode = updateNodePosition(dropPosition, draggedNode, targetNode, newSort)
        if (updatedNode) {
          const slidesData = {}
          slidesData[updatedNode.key] = findParentPath(updatedNode)
          props.merch.updateSortDetailsOnSlides(slidesData, null, newSort, isSortOrderChanged)
        }
      }
    }
  }
}
function updateNodePosition(dropPosition, node: ITreeNode, targetNode, newSort) {
  let removedFromTree = false
  const parentNode = node.parent
  const newNode = clone(node)
  if (utils.isDefined(parentNode)) {
    const index = parentNode.children.findIndex(nodes => node.key === nodes.key)
    if (index !== -1) {
      parentNode.children.splice(index, 1)
      removedFromTree = true
    }
  }
  else {
    const index = mySlideTree.value.findIndex(nodes => node.key === nodes.key)
    if (index !== -1) {
      mySlideTree.value.splice(index, 1)
      removedFromTree = true
    }
  }
  newNode.sortOrder = newSort
  let updatedInTree = false
  if (removedFromTree) {
    if ((dropPosition === 'inner' && targetNode.isFolder) || (dropPosition === 'after' && targetNode.expanded && targetNode.children.length)) {
      newNode.parent = targetNode
      utils.insertSorted(newNode, targetNode.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
      updatedInTree = true
      rebuildNodeIndexes(targetNode)
    }
    else {
      if (targetNode && targetNode.parent) {
        newNode.parent = targetNode.parent
        utils.insertSorted(newNode, targetNode.parent.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
        updatedInTree = true
        rebuildNodeIndexes(targetNode.parent)
      }
      else {
        console.log('if root')
        // if root
        newNode.parent = undefined
        utils.insertSorted(newNode, mySlideTree.value, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
        updatedInTree = true
      }
    }
  }
  if (updatedInTree) {
    updateChildrenParent(newNode)
    return newNode
  }
  else {
    return null
  }
}
onMounted(() => {
  loading.value = true
})
</script>
