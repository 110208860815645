import type { IWhiteboardTemplate } from './IWhiteboardTemplate'
import standard from './standard'
import visualLineBuilder from './visualLineBuilder'
import sketchBoard from './sketchBoard'

// TODO: Might need to load them dynamically

const templates: Record<number, IWhiteboardTemplate> = {}
templates[standard.id] = standard
templates[visualLineBuilder.id] = visualLineBuilder
templates[sketchBoard.id] = sketchBoard

export default templates
