<template>
  <div class="w-full">
    <div v-if="label.length > 0" class="flex">
      <label
        v-if="label.length > 0" class="text-xs tracking-wide uppercase label" :class="{ required }"
        v-text="label"
      />
    </div>
    <div class="relative" :class="props.class">
      <div :class="{ 'border-red-300 bg-red-50 placeholder-red-200 text-red-900': hasError }">
        <datepicker
          :model-value="getDateWithoutTimeZoneChange(modelValue)" v-bind="$attrs" class="text-base bg-transparent h-9"
          :class="{ 'bg-gray-200 rounded': disabled }" :disabled="disabled" :required="required"
          :enable-time-picker="enableTimePicker" :format="customDateFormat ?? format" :min-date="minDate"
          :max-date="maxDate" input-class-name="text-base font-normal leading-none h-9 border-form shadow-input pr-7"
          :placeholder="dateRange" :start-date="startDate" :end-date="endDate" @update:model-value="onChange"
        />
      </div>
      <div v-if="hasError">
        <p v-for="error in errors" :key="error.$uid" class="text-xs italic text-red-500">
          {{ error.$message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import '@vuepic/vue-datepicker/dist/main.css'
import Datepicker from '@vuepic/vue-datepicker'
import type { ErrorObject } from '@vuelidate/core'
import { computed, ref } from 'vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<IProps>(), { label: '', errors: () => [] as ErrorObject[], disabled: false, required: false, enableTimePicker: false, class: '', minDate: '', maxDate: '' })

const emit = defineEmits<{
  (e: 'update:modelValue', val: any[] | string | null | Date): void
  (e: 'change', val: any[] | string | null | Date): void
}>()

interface IProps {
  modelValue?: any[] | string | null | Date
  label?: string
  errors?: ErrorObject[]
  disabled?: boolean
  required?: boolean
  enableTimePicker?: boolean
  customDateFormat?: string
  class?: string
  minDate?: string
  maxDate?: string
}
const refDatepicker = ref<InstanceType<typeof Datepicker>>()

const format = computed(() => props.enableTimePicker ? 'MMM dd yyyy HH:mm:ss' : 'MMM dd yyyy')

const hasError = computed(() => props.errors.length)
let dateRange: any = ''
let startDate: Date | '' = ''
let endDate: Date | '' = ''

function onChange(date) {
  emit('change', date)
  emit('update:modelValue', date)
}

function focus() {
  refDatepicker.value?.openMenu()
}

// to fix time zone issue
function getDateWithoutTimeZoneChange(date) {
  if (date != null && Array.isArray(date) && date.length === 2) { // check if it's a date range
    const date1 = new Date(date[0])
    const date2 = new Date(date[1])
    startDate = date1
    endDate = date2

    if (!props.enableTimePicker) {
      date1.setMinutes(date1.getMinutes() + date1.getTimezoneOffset())
    }

    if (!props.enableTimePicker) {
      date2.setMinutes(date2.getMinutes() + date2.getTimezoneOffset())
    }

    const formattedDate1 = date1.toLocaleDateString()
    const formattedDate2 = date2.toLocaleDateString()
    dateRange = `${formattedDate1} - ${formattedDate2}`
    return dateRange
  }
  else if (date != null && date.toString().trim() !== '') {
    const dateValue = new Date(date)
    if (props.enableTimePicker) {
      dateValue.setUTCFullYear(dateValue.getUTCFullYear())
      dateValue.setUTCMonth(dateValue.getUTCMonth())
      dateValue.setUTCDate(dateValue.getUTCDate())
      return dateValue
    }
    else {
      return dateValue.setMinutes(dateValue.getMinutes() + dateValue.getTimezoneOffset())
    }
  }
  else {
    return null
  }
}

defineExpose({
  focus,
})
</script>
