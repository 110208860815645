export default class ArticleSegmentation {
  CatalogCode: number
  Id: number
  Segmentations: Segmentation[]

  constructor(catalogCode: number, articleSegmentation: any) {
    this.CatalogCode = catalogCode
    this.Id = articleSegmentation.Id
    this.Segmentations = articleSegmentation.Segmentations.map((itm: any) => new Segmentation(itm))
  }
}

export class Segmentation {
  Id: number
  PlanningValue: string

  constructor(seg: any) {
    this.Id = seg.Id
    this.PlanningValue = seg.PlanningValue
  }
}
