<template>
  <div class="container flex flex-col w-full h-full px-2 pt-4">
    <div class="header" />
    <!-- BODY -->
    <div class="px-10 mt-4 alerts">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    </div>
    <div class="w-full px-10 mb-2 overflow-hidden body">
      <div class="w-full mt-4">
        <div class="h-3 text-xs leading-3 tracking-wide uppercase label required" v-text="t('assignResourceForm.selectResources')" />
        <div style="height: calc(100vh - 500px)" class="overflow-y-auto">
          <tx-tree ref="refTree" class="tree" :data="resourceTree" show-checkbox="onHoverOrOtherChecked" @check-change="onTreeCheckChange" />
        </div>
      </div>
    </div>
    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="t('general.assign')" :primary-disabled="selectedResourceTreeNodes.length === 0 " :secondary-disabled="loading"
      :primary-loading="loading" @primary-click="onUpdate" @secondary-click="onCancel"
    />
  </div>
</template>

<script setup lang="ts">
import { clone, union, unionBy } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { onMounted, ref, watch } from 'vue'
import TxAlert from '../TxAlert.vue'
import TxTree from '../TxTree.vue'
import type MyArticle from '@/models/myArticle'
import type Resource from '@/models/resource'
import { assignArticlesToResource } from '@/api/t1/resource'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'

const props = defineProps<{
  articles: MyArticle[]
  isModel?: boolean
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[]): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()

const loading = ref(false)
const refTree = ref<InstanceType<typeof TxTree>>()
const resourceTree = ref<ITreeNode[]>([])
const selectedResourceTreeNodes = ref<ITreeNode[]>([])
const articles = ref<MyArticle[]>([])

let indexedResources: Record<number, Resource> = {}

function onTreeCheckChange() {
  const checkedNodes = refTree.value?.getCheckedNodes(true)
  selectedResourceTreeNodes.value = []
  if (utils.isDefined(checkedNodes) && checkedNodes.length > 0) {
    selectedResourceTreeNodes.value = checkedNodes
  }
}

function onCancel() {
  emit('cancel')
}

async function onUpdate() {
  if (userStore.activeCatalog && articles.value.length > 0 && selectedResourceTreeNodes.value.length > 0) {
    loading.value = true
    const selectedArticleIds = articles.value.map(a => a.Id)
    const promises: Promise<any>[] = []
    const updatedResources: Resource[] = []
    const failedResources: string[] = []

    for (let i = 0; i < selectedResourceTreeNodes.value.length; i++) {
      const resourceId = selectedResourceTreeNodes.value[i].key as number
      if (utils.isDefined(indexedResources[resourceId])) {
        const resourceClone = clone(indexedResources[resourceId])
        const requestArticleIds = union(selectedArticleIds, resourceClone.Articles)
        promises.push(assignArticlesToResource(userStore.activeCatalog.CatalogCode, resourceId, requestArticleIds)
          .then(() => {
            resourceClone.Articles = requestArticleIds
            updatedResources.push(resourceClone)
            // update article resource
            articles.value.forEach(article => article._Resources = unionBy(article._Resources, [resourceClone], 'Id'))
          })
          .catch(() => failedResources.push(resourceClone.ResourceName)),
        )
      }
    }
    Promise.all(promises)
      .then(async () => {
        if (updatedResources.length) {
          await appConfig.DB!.resources.bulkPut(updatedResources)
          emit('updated', articles.value)
        }
        if (failedResources.length) {
          errorMessage.value = t('assignResourceForm.resourceAssignedFailed', { resources: failedResources.join(', ') })
        }
      })
      .catch((e) => {
        console.error(e)
        errorMessage.value = t('general.unexpectedError')
      })
      .finally(() => loading.value = false)
  }
}

async function reset() {
  loading.value = false
  errorMessage.value = ''
  selectedResourceTreeNodes.value = []
  if (props.isModel) {
    if (!userStore || (!userStore.activeCatalog)) {
      console.warn('Unable to load model details by modelNumber')
      return null
    }
    const catalogDetails = userStore.activeCatalog
    const modelNumbers = props.articles.map(s => s.ModelNumber)
    const modelArticles = await appConfig.DB!.getMyArticlesByModelNumbers(modelNumbers, catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
    if (modelArticles && modelArticles.length) {
      articles.value = modelArticles
    }
  }
  else {
    articles.value = props.articles
  }
}

watch(() => props.articles, reset)

onMounted(async () => {
  reset()
  if (userStore.activeCatalog) {
    const resources = await appConfig.DB!.resources.where({ CatalogCode: userStore.activeCatalog.CatalogCode }).toArray()
    indexedResources = utils.arrayToNumberDictionary(resources, 'ResourceId')
    resourceTree.value = await appConfig.DB!.buildResourceTree(userStore.activeCatalog.CatalogCode)
  }
})
</script>
