<template>
  <div v-show="visible" class="flex flex-col w-full h-full">
    <div class="flex h-full overflow-hidden">
      <model-articles-list
        v-show="showModelArticlesList" ref="modelArticlesListRef"
        :create-type="createType"
        :articles="modelArticles"
        class="h-full mt-2 mb-3 mr-3"
        :class="{ 'w-[70%]': showModelArticlesList }"
        :is-loading="loadingModelArticles"
        :visible="showModelArticlesList"
        @selection-changed="onArticleSelectionChanged"
      />
      <periods-form
        ref="periodsFormRef" :create-type="createType"
        class="flex-grow flex-shrink"
        :class="{ 'w-[30%]': showModelArticlesList }"
        :retail-window-criteria-attribute-value="retailWindowCriteriaAttributeValue"
        :sorted-shipment-window-range="sortedShipmentWindowRange"
        :request-articles="requestArticles"
        :visible="visible"
        @form-updated="onPeriodsFormUpdated"
      />
    </div>

    <!-- FOOTER -->
    <div class="flex flex-row justify-end flex-shrink-0 mx-24 mb-16 flex-nowrap">
      <tx-button type="cancel" :text="t('general.cancel')" @click="onCancel" />
      <tx-button
        class="ml-5" type="confirm" :disabled="Object.keys(sortedCriteriaMatchedPeriods).length === 0 || (periodsFormRef?.v$.$invalid && (!showModelArticlesList || modelArticlesListRef?.v$.$invalid))"
        :text="t('general.next')" @click="nextStep"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue'
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { uniqBy } from 'lodash-es'
import ModelArticlesList from './ModelArticlesList.vue'
import PeriodsForm from './PeriodsForm.vue'
import type Article from '@/models/article'
import AppConfig from '@/services/appConfig'
import type CatalogShipmentWindowRange from '@/models/catalogShipmentWindowRange'
import MyArticle from '@/models/myArticle'
import TxButton from '@/shared/components/TxButton.vue'
import useErrorMessage from '@/shared/composables/errorMessage'
import type { ArticleStateModel } from '@/api/t1/model/articleModel'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import { useUserStore } from '@/store/userData'
import utils from '@/services/utils'

const props = defineProps<{
  createType: StyleCreateType
  contextArticle?: MyArticle
  modelFormModel: Record<string, any>
  retailWindowCriteriaAttributeValue: any | -999
  selectedArticles?: MyArticle[]
  requestArticles?: MyArticle[]
  sortedShipmentWindowRange: CatalogShipmentWindowRange[]
  visible: boolean
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'next', payload: Record<string, any>): void
  (e: 'back'): void
}>()

// DATA
const { getArticlesMaxStateDetails, skipRestrictingPropertyUpdateBasedOnArticleState } = useArticleFormHelper()
const { errorMessage } = useErrorMessage()
const { t } = useI18n()
const userStore = useUserStore()

let formInitialized = false
const loadingModelArticles = ref(false)
const modelArticles = ref<MyArticle[]>([])
const modelArticlesModel = ref<MyArticle[]>([])
let periodsFormModel = reactive<Record<string, number>>({})
const periodsFormRef = ref<InstanceType<typeof PeriodsForm> | null>(null)
const modelArticlesListRef = ref<InstanceType<typeof ModelArticlesList> | null>(null)
// DATA-END

// COMPUTED
const contextArticleState = computed(() => {
  if (props.contextArticle) {
    return getArticlesMaxStateDetails([props.contextArticle]) as ArticleStateModel
  }
  else {
    return undefined
  }
})

const showModelArticlesList = computed(() => {
  let showModelArticlesList = false
  if (props.createType === 'childModel'
    && (
      props.contextArticle != null && contextArticleState.value != null
      && (
        (!contextArticleState.value.NonEditableAttributes.length && contextArticleState.value.IsModelNameEditable)
        || skipRestrictingPropertyUpdateBasedOnArticleState
      )
    )
  ) {
    showModelArticlesList = true
  }
  return showModelArticlesList
})

const sortedCriteriaMatchedPeriods: ComputedRef<CatalogShipmentWindowRange[]> = computed(() => {
  let sortedCriteriaMatchedPeriods = props.sortedShipmentWindowRange

  if (props.retailWindowCriteriaAttributeValue !== -999) {
    sortedCriteriaMatchedPeriods = props.sortedShipmentWindowRange.filter(period => utils.haveEqualStringValue(period.CriteriaAttributeValue, props.retailWindowCriteriaAttributeValue))
  }
  return Object.values(sortedCriteriaMatchedPeriods.reduce((acu, cur) => {
    const periodLower = cur.Period != null && cur.Period.toString().trim().length ? cur.Period.toString().toLowerCase().trim() : null
    if (periodLower && !acu.hasOwnProperty(periodLower)) {
      acu[periodLower] = cur
    }
    return acu
  }, {}))
})
// COMPUTED-END

// WATCHERS
watch(() => props.createType, () => {
  formInitialized = false
})

watchEffect(() => {
  if (props.visible && !formInitialized) {
    initForm()
    formInitialized = true
  }
})
// WATCHERS-ENDS

async function initForm() {
  if (showModelArticlesList.value) {
    modelArticles.value = []
    if (props.createType === 'childModel') {
      if (props.contextArticle != null) {
        loadingModelArticles.value = true
        const queryCriterion: Array<[number, number, string]> = [[userStore.activeCatalog!.CatalogCode, 1, props.contextArticle!.ModelNumber]]
        Object.keys(userStore.linkedCatalogDetails).forEach((linkedCatalogCode) => {
          queryCriterion.push([+linkedCatalogCode, 1, props.contextArticle!.ModelNumber])
        })
        try {
          let articles = await AppConfig.DB?.getArticlesByCatalogAndModelCriterion(queryCriterion)
          if (articles && articles.length) {
            // hide placeholder articles from create child model form, place holders are not allowed to move from one model to another
            const articlesWithPrimaryColor = articles.filter(article => article.ColorId !== null && article.ColorId !== 0)
            articles = articlesWithPrimaryColor.sort((a, b) => (b.UpdatedDate as unknown as number) - (a.UpdatedDate as unknown as number))
          }
          const uniqueModelArticles = uniqBy(articles, 'ArticleNumber') as Array<Article>
          const modelMaxStateRank = getArticlesMaxStateDetails(uniqueModelArticles)
          if (modelMaxStateRank == null || (modelMaxStateRank.IsModelNameEditable && !modelMaxStateRank.NonEditableAttributes.length)) {
            uniqueModelArticles?.forEach((article) => {
              modelArticles.value.push(getMyArticleInstance(article))
            })
          }
        }
        catch (error) {
          console.warn('unable to load model article list\n', error)
        }
        finally {
          loadingModelArticles.value = false
        }
      }
    }
  }
}

function getMyArticleInstance(article: Article): MyArticle {
  let retailPrice = 0
  let wholesalePrice = 0
  let outletPrice = 0
  if (article.CatalogCode === userStore.activeCatalog!.CatalogCode) { // if article belongs to active catalog
    if (userStore.priceGroups.retail) {
      retailPrice = article._Prices[userStore.priceGroups.retail.Id].Price
    }
    if (userStore.priceGroups.wholesale) {
      wholesalePrice = article._Prices[userStore.priceGroups.wholesale.Id].Price
    }
    if (userStore.priceGroups.outlet) {
      outletPrice = article._Prices[userStore.priceGroups.outlet.Id].Price
    }
  }
  else { // if catalog belongs to linked catalog
    const linkedCatalog = userStore.linkedCatalogDetails[article.CatalogCode]
    if (userStore.priceGroups.retail) {
      const linkedPriceGroup = linkedCatalog._IndexedCatalogPriceGroupByLowercaseName[userStore.priceGroups.retail.Name.toString().trim().toLowerCase()]
      if (linkedPriceGroup) {
        retailPrice = article._Prices[linkedPriceGroup.Id].Price
      }
    }
    if (userStore.priceGroups.wholesale) {
      const linkedPriceGroup = linkedCatalog._IndexedCatalogPriceGroupByLowercaseName[userStore.priceGroups.wholesale.Name.toString().trim().toLowerCase()]
      if (linkedPriceGroup) {
        wholesalePrice = article._Prices[linkedPriceGroup.Id].Price
      }
    }
    if (userStore.priceGroups.outlet) {
      const linkedPriceGroup = linkedCatalog._IndexedCatalogPriceGroupByLowercaseName[userStore.priceGroups.outlet.Name.toString().trim().toLowerCase()]
      if (linkedPriceGroup) {
        outletPrice = article._Prices[linkedPriceGroup.Id].Price
      }
    }
  }
  return new MyArticle(article, userStore.activeCatalog!.AssignedCatalogAttributes, retailPrice, wholesalePrice, outletPrice, [], true, [], [], [], userStore.activeCatalog!.Season, userStore.activeCatalog!.DataSourceTypeId, userStore.activeCatalog!.Config.ArticlesCustomSortOrder)
}

function onArticleSelectionChanged(selectedArticles) {
  modelArticlesModel.value = selectedArticles
}

function onPeriodsFormUpdated(model) {
  periodsFormModel = model
}

function onCancel() {
  emit('cancel')
}

async function nextStep() {
  if (!await modelArticlesListRef.value?.v$.$validate() && !await periodsFormRef.value?.v$.$validate()) {
    errorMessage.value = t('validations.formInvalid')
    return
  }
  const modelArticlesAndPeriodsFormModel = {
    periodsFormModel,
    modelArticlesListModel: modelArticlesModel.value,
  }
  emit('next', { modelFormModel: props.modelFormModel, modelArticlesAndPeriodsFormModel })
}
</script>
