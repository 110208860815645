export default class CatalogAttribute {
  AllowedValues?: string[]
  AttributeDisplayName: string
  AttributeSize: number
  AttributeSource: string
  AttributeSystemName: string
  AttributeTypeId: AttributeType
  Attributeid: number
  Creatable: boolean
  // CreatedBy: number
  // CreatedDate: Date
  IsSeasonlessModelAttribute: boolean
  Criteria: string
  Editable: boolean
  InitialValueAttribute: string
  IsRequired: boolean
  IsSeasonless: boolean
  Overridable: boolean
  ShowInEdit: boolean
  SortOrder: number
  SupportFilterLookup: boolean
  ValidationExpression: string
  ValidationMessage: string
  Visible: boolean
  VisibleInListByDefault: boolean
  DefaultValue: any
  ReadOnly: boolean
  IsModelLevel: boolean
  Status: number

  constructor({
    AllowedValues,
    AttributeDisplayName,
    AttributeSize,
    AttributeSource,
    AttributeSystemName,
    AttributeTypeId,
    Attributeid,
    Creatable,
    Criteria,
    Editable,
    InitialValueAttribute,
    IsRequired,
    IsSeasonless,
    Overridable,
    ShowInEdit,
    SortOrder,
    SupportFilterLookup,
    ValidationExpression,
    ValidationMessage,
    Visible,
    VisibleInListByDefault,
    IsSeasonlessModelAttribute,
    DefaultValue,
    ReadOnly,
    Status,
  }: CatalogAttribute, isModelLevel) {
    this.AllowedValues = AllowedValues
    this.AttributeDisplayName = AttributeDisplayName
    this.AttributeSource = AttributeSource
    this.AttributeSize = AttributeSize
    this.AttributeSystemName = AttributeSystemName
    this.AttributeTypeId = AttributeTypeId
    this.Attributeid = Attributeid
    this.Criteria = Criteria
    this.Creatable = Creatable
    this.Editable = Editable
    this.InitialValueAttribute = InitialValueAttribute
    this.IsSeasonlessModelAttribute = IsSeasonlessModelAttribute
    this.IsRequired = IsRequired
    this.IsSeasonless = IsSeasonless
    this.Overridable = Overridable
    this.ShowInEdit = ShowInEdit
    this.SortOrder = SortOrder
    this.SupportFilterLookup = SupportFilterLookup
    this.ValidationExpression = ValidationExpression
    this.ValidationMessage = ValidationMessage
    this.Visible = Visible
    this.VisibleInListByDefault = VisibleInListByDefault
    this.DefaultValue = DefaultValue
    this.ReadOnly = ReadOnly
    this.IsModelLevel = isModelLevel
    this.Status = Status
  }
}

export enum AttributeType {
  Nvarchar = 1,
  Int = 2,
  Date = 3,
  DateTime = 4,
  Bool = 5,
  Calc = 6,
  DateOption = 7,
  MultiValue = 8,
  Decimal = 9,
  ArticleNumber = 10,
  LinkedCatalogArticleNumber = 11,
  ColorPalette = 12,
  ModelNumber = 13,
  LookupTable = 14,
  SellerModel = 140,
  SizeScale = 150,
  MasterSizeScale = 160,
  Status = 20,
  TimeAgo = 10000,
  ColorPicker = 170,
  // admin module
  ArticleStatus = 50,
  Lookup = 51,
  BoolInt = 52, // 0,1
  Url = 53,
  IntBool = 54, // '0', '1'
  BoolStr = 55,
  DateUtc = 56,
  LookupMany = 57,
  // check all the bool functionality there is mismatch
}
