import CatalogAttribute from './catalogAttribute'
import CatalogCrd from './catalogCrd'
import CatalogPriceGroup from './catalogPriceGroup'
import CatalogSegmentation from './catalogSegmentation'
import CatalogConfiguration from './catalogConfiguration'
import CatalogRequestAttribute from './catalogRequestAttribute'
import CatalogShipmentWindowRange from './catalogShipmentWindowRange'
import CatalogConfig from './catalogConfig'
import type CatalogTreeDef from './catalogTreeDef'
import LinkedCatalog from './linkedCatalog'
import { appConstants } from './constants'
import CatalogBucketAttribute from './catalogBucketAttribute'
import type UserProfile from './userProfile'
import type AttributeValue from './attributeValue'
import utils from '@/services/utils'
import type { IRolesBasedRestrictedBucketAttributes } from '@/api/t1/model/buckets'
import type { ICatalogDetails, ICatalogImportMapping, IRoleBasedRestrictedRetailWindow, IRoleBasedRestrictedSegmentation, IRolesBasedRestrictedAttributes, IRolesBasedRestrictedRetailWindows, IRolesBasedRestrictedSegmentations } from '@/api/t1/model/catalog'
import type { UserRoleModel } from '@/api/t1/model/userModel'

export default class CatalogDetails {
  AccountId: number
  // ArticleCascading: number
  ArticleLocking: number
  AssignedCatalogAttributes: CatalogAttribute[]
  AttributeGroupId: number
  // AttributeGroupName: string
  BuyerLimitPriceGroupId?: number
  BuyerLimitPriceGroupName?: string
  CatalogCRDList: CatalogCrd[]
  CatalogCode: number
  CatalogName: string
  CatalogPriceGroupList: CatalogPriceGroup[]
  CatalogSegmentationList: CatalogSegmentation[]
  CatalogSpecificConfigurationList: CatalogConfiguration[]
  // ChangeLog: number
  ContextReadKey: string
  ContextReadWriteKey: string
  // CreatedDate: Date
  // DataSourceType: string
  DataSourceTypeId: number
  DuneContext: string
  // EnableOrderSync: number
  Id: number
  // ImageFile?: string
  // ImageName?: string
  // ImagePath?: string
  ImportMapping: ICatalogImportMapping
  IndependentSizes: number
  IsLinePlan: number
  IsPriceByStyleApply: number
  IsShippingWindowApply: number
  IsStockApply: number
  IsVasInUse: number
  LastVersion: number
  // LinkedAttributeList: []
  LinkedCatalogList: LinkedCatalog[]
  MasterCatalogCode: number
  // MirrorSegmentation: number
  ModelLevelAttributeList: CatalogAttribute[]
  ArticleLevelAttributeList: CatalogAttribute[]
  // OrderPluginId?: number
  // OrderPluginSource?: number
  OrganizationId: number
  PriceCascading: number
  ReceiveOrders: number | boolean | string
  ReceiveOrdersWithoutPrice: number
  ReferenceCatalogCode: number
  RequestAttributeList: CatalogRequestAttribute[]
  BucketAttributeList: CatalogBucketAttribute[]
  RequestsEnabled: number
  AllowRequestedArticleCreation: number
  AcceptRequests: number
  Season: string
  SeasonalSequence: number
  // SegmentationCascading: number
  // SegmentationGroupList[]
  SegmentationPlanningEnable: number
  ShipmentWindowRangeList: CatalogShipmentWindowRange[]
  State: string
  Status: number
  // TargettedAttributeList[]
  TreeDefinitions?: CatalogTreeDef[]// TreeDefination[]
  // UpdatedBy: number
  // UpdatedDate: Date
  // UserId: number
  Year: number

  AttributeVettingList: AttributeValue[]
  RetailWindowAttribute: string
  CustomerRequiredDateValidation: null | string
  OrderBySizeCurve: null | number
  ModelAttribute: string
  ArticleAttribute: string
  IsLive: number

  _IndexedCatalogPriceGroup: Record<number, CatalogPriceGroup>
  _IndexedCatalogPriceGroupByLowercaseName: Record<string, CatalogPriceGroup>
  _IndexedCatalogSegmentation: Record<number, CatalogSegmentation>
  _IndexedCatalogCRD: Record<number, CatalogCrd>
  _IndexedShipmentWindowRange: Record<number, CatalogShipmentWindowRange>

  _AllRelatedCatalogCodes: number[]

  _RoleRestrictedRetailWindow: IRoleBasedRestrictedRetailWindow
  _RoleRestrictedSegmentations: IRoleBasedRestrictedSegmentation[]
  _userRoles: undefined | UserRoleModel[]
  _config: undefined | CatalogConfig

  get ContextKey(): string {
    return utils.isValidStringValue(this.ContextReadWriteKey) ? this.ContextReadWriteKey : this.ContextReadKey
  }

  get Config(): CatalogConfig {
    if (!this._config) {
      this._config = new CatalogConfig(this.CatalogSpecificConfigurationList, this._userRoles || [])
    }
    return this._config
  }

  constructor(obj: ICatalogDetails, userRoles: UserRoleModel[]) {
    this._userRoles = userRoles
    this.AccountId = obj.AccountId
    this.AttributeGroupId = obj.AttributeGroupId
    this.CatalogCRDList = obj.CatalogCRDList.map(x => new CatalogCrd(x))
    this.CatalogCode = obj.CatalogCode
    this.CatalogName = obj.CatalogName
    this.CatalogPriceGroupList = obj.CatalogPriceGroupList.map(x => new CatalogPriceGroup(x))
    this.CatalogSegmentationList = obj.CatalogSegmentationList.map(x => new CatalogSegmentation(x))
    this.CatalogSpecificConfigurationList = obj.CatalogSpecificConfigurationList.map(x => new CatalogConfiguration(x))
    this.ContextReadKey = obj.ContextReadKey
    this.ContextReadWriteKey = obj.ContextReadWriteKey
    this.DataSourceTypeId = obj.DataSourceTypeId
    this.DuneContext = obj.DuneContext
    this.Id = obj.Id
    this.ImportMapping = typeof obj.ImportMapping === 'string' ? utils.tryParse(obj.ImportMapping) : obj.ImportMapping
    this.IndependentSizes = obj.IndependentSizes
    this.IsLinePlan = obj.IsLinePlan
    this.IsPriceByStyleApply = obj.IsPriceByStyleApply
    this.IsShippingWindowApply = obj.IsShippingWindowApply
    this.IsStockApply = obj.IsStockApply
    this.IsVasInUse = obj.IsVasInUse
    this.LastVersion = obj.LastVersion
    this.MasterCatalogCode = obj.MasterCatalogCode
    const activeLinkedCatalogList = obj.LinkedCatalogList.filter(catalog => catalog.Status > 0)
    const sortedCatalog = utils.sort(activeLinkedCatalogList, ['SeasonalSequence'], false).map(c => new LinkedCatalog(c))
    this.LinkedCatalogList = sortedCatalog.slice(0, (sortedCatalog.length < 4 ? sortedCatalog.length : 4))
    this.OrganizationId = obj.OrganizationId
    this.PriceCascading = obj.PriceCascading
    this.ReceiveOrders = obj.ReceiveOrders
    this.ReceiveOrdersWithoutPrice = obj.ReceiveOrdersWithoutPrice
    this.ReferenceCatalogCode = obj.ReferenceCatalogCode
    this.RequestAttributeList = obj.RequestAttributeList.map(x => new CatalogRequestAttribute(x))
    this.BucketAttributeList = obj.BucketAttributeList.map(x => new CatalogBucketAttribute(x))
    this.RequestsEnabled = obj.RequestsEnabled
    this.AllowRequestedArticleCreation = obj.AllowRequestedArticleCreation
    this.AcceptRequests = obj.AcceptRequests
    this.Season = obj.Season
    this.SeasonalSequence = obj.SeasonalSequence
    this.SegmentationPlanningEnable = obj.SegmentationPlanningEnable
    this.ShipmentWindowRangeList = obj.ShipmentWindowRangeList.map(x => new CatalogShipmentWindowRange(x))
    this.State = obj.State
    this.Status = obj.Status
    this.Year = obj.Year
    this.AttributeVettingList = []
    this.ArticleLocking = obj.ArticleLocking
    // this.Attributes = utils.arrayToStringDictionary<CatalogAttribute>(this.AssignedCatalogAttributes, 'AttributeSystemName')
    // console.log('Attributes', this.Attributes)
    this.RetailWindowAttribute = obj.RetailWindowAttribute
    this.CustomerRequiredDateValidation = obj.CustomerRequiredDateValidation
    this.OrderBySizeCurve = obj.OrderBySizeCurve

    this.ModelAttribute = obj.ModelAttribute
    this.ArticleAttribute = obj.ArticleAttribute
    this.IsLive = obj.IsLive

    this.ArticleLevelAttributeList = []
    this.ModelLevelAttributeList = []
    this.AssignedCatalogAttributes = []

    this._RoleRestrictedRetailWindow = {} as IRoleBasedRestrictedRetailWindow
    this._RoleRestrictedSegmentations = []

    this._AllRelatedCatalogCodes = [obj.CatalogCode]
    if (this.LinkedCatalogList.length) {
      const linkedCatalogUsed = this.LinkedCatalogList.slice(0, (this.LinkedCatalogList.length < 2 ? this.LinkedCatalogList.length : 2))
      this._AllRelatedCatalogCodes.push(...linkedCatalogUsed.map(c => c.CatalogCode))
    }
    const modelAttrs = obj.ModelLevelAttributeList.filter(modLevelAttribute => modLevelAttribute.Status > 0).map(itm => itm.AttributeSystemName)
    obj.AssignedCatalogAttributes.forEach((attribute) => {
      if (attribute.Status > 0) {
        const catalogAttributeObj = new CatalogAttribute(attribute, modelAttrs.includes(attribute.AttributeSystemName))
        if (!modelAttrs.includes(catalogAttributeObj.AttributeSystemName)) {
          this.ArticleLevelAttributeList.push(catalogAttributeObj)
        }
        else {
          this.ModelLevelAttributeList.push(catalogAttributeObj)
        }
        this.AssignedCatalogAttributes.push(catalogAttributeObj)
      }
    })

    // Generating Indexes
    this._IndexedCatalogPriceGroup = {}
    this._IndexedCatalogPriceGroupByLowercaseName = {}
    obj.CatalogPriceGroupList.forEach((priceGroup) => {
      this._IndexedCatalogPriceGroup[priceGroup.Id] = priceGroup
      this._IndexedCatalogPriceGroupByLowercaseName[priceGroup.Name.toString().trim().toLowerCase()] = priceGroup
    })

    this._IndexedCatalogSegmentation = {}
    obj.CatalogSegmentationList.forEach((seg) => {
      this._IndexedCatalogSegmentation[seg.Id] = seg
    })

    this._IndexedCatalogCRD = {}
    obj.CatalogCRDList.forEach((crd) => {
      this._IndexedCatalogCRD[crd.Id] = crd
    })

    this._IndexedShipmentWindowRange = {}
    this.ShipmentWindowRangeList.forEach((shipmentWindow) => {
      this._IndexedShipmentWindowRange[shipmentWindow.Id] = shipmentWindow
    })

    // UPDATE IsModelLevel for prices
    if (this.IsPriceByStyleApply) {
      appConstants.staticAttributes._WholesalePrice.IsModelLevel = true
      appConstants.staticAttributes._RetailPrice.IsModelLevel = true
      appConstants.staticAttributes._OutletPrice.IsModelLevel = true
    }
  }

  updateBucketAttributeListBasedOnUserRoleRestriction(roleBasedRestrictedBucketAttributes: IRolesBasedRestrictedBucketAttributes, userProfile: UserProfile) {
    // apply restriction only for non admin users
    if (userProfile.AccountDetails.AccountTypeId !== 1) {
      const currentUserAssignedRoleIds = new Set<number>()
      const visitedAttributes = new Set<string>()
      const bucketAttributeListIndex = this.BucketAttributeList.reduce((acu, cur, index) => (acu[cur.AttributeSystemName] = index) && acu, {})
      userProfile.Roles.forEach((role) => {
        currentUserAssignedRoleIds.add(role.UserRoleId)
      })
      roleBasedRestrictedBucketAttributes.Roles.forEach((roleRestrictedBucketAttributes) => {
        if (currentUserAssignedRoleIds.has(roleRestrictedBucketAttributes.RoleId)) {
          (roleRestrictedBucketAttributes.Attributes || []).forEach((restrictedAttribute) => {
            if (!visitedAttributes.has(restrictedAttribute.AttributeSystemName)) {
              visitedAttributes.add(restrictedAttribute.AttributeSystemName)
              if (bucketAttributeListIndex.hasOwnProperty(restrictedAttribute.AttributeSystemName)) {
                const index = bucketAttributeListIndex[restrictedAttribute.AttributeSystemName]
                this.BucketAttributeList[index].Visible = !restrictedAttribute.NotViewable
                this.BucketAttributeList[index].Editable = !restrictedAttribute.NotEditable
              }
            }
          })
        }
      })
    }
  }

  updateRolesBasedRestrictedAttributes(roleBasedRestrictedAttributes: IRolesBasedRestrictedAttributes, userProfile: UserProfile) {
    // apply restriction only for non admin users
    if (userProfile.AccountDetails.AccountTypeId !== 1) {
      const currentUserAssignedRoleIds = new Set<number>()
      const visitedAttributes = new Set<string>()
      const attributeListIndex = this.AssignedCatalogAttributes.reduce((acu, cur, index) => {
        acu[cur.AttributeSystemName] = index
        return acu
      }, {} as Record<string, number>)
      userProfile.Roles.forEach((role) => {
        currentUserAssignedRoleIds.add(role.UserRoleId)
      })
      roleBasedRestrictedAttributes.Roles.forEach((roleRestrictedAttributes) => {
        if (currentUserAssignedRoleIds.has(roleRestrictedAttributes.RoleId)) {
          (roleRestrictedAttributes.Attributes || []).forEach((restrictedAttribute) => {
            if (!visitedAttributes.has(restrictedAttribute.AttributeSystemName)) {
              visitedAttributes.add(restrictedAttribute.AttributeSystemName)
              if (restrictedAttribute.AttributeSystemName in attributeListIndex) {
                const index = attributeListIndex[restrictedAttribute.AttributeSystemName]
                this.AssignedCatalogAttributes[index].Visible = !restrictedAttribute.NotViewable
                this.AssignedCatalogAttributes[index].Editable = !restrictedAttribute.NotEditable
              }
            }
          })
        }
      })
    }
  }

  updateRolesBasedRestrictedRetailWindows(roleBasedRestrictedRetailWindows: IRolesBasedRestrictedRetailWindows, userProfile: UserProfile) {
    // apply restriction only for non admin users
    if (userProfile.AccountDetails.AccountTypeId !== 1) {
      const currentUserAssignedRoleIds = new Set<number>()
      userProfile.Roles.forEach((role) => {
        currentUserAssignedRoleIds.add(role.UserRoleId)
      })
      roleBasedRestrictedRetailWindows.Roles.forEach((roleBasedRestrictedRetailWindow) => {
        if (currentUserAssignedRoleIds.has(roleBasedRestrictedRetailWindow.RoleId)) {
          // retail window has only one item
          (roleBasedRestrictedRetailWindow.Attributes || []).forEach((restrictedRetailWindow) => {
            this._RoleRestrictedRetailWindow = {
              Name: restrictedRetailWindow.Name,
              NotViewable: restrictedRetailWindow.NotViewable,
              NotEditable: restrictedRetailWindow.NotEditable,
            }
          })
        }
      })
    }
  }

  updateRolesBasedRestrictedSegmentations(roleBasedRestrictedSegmentations: IRolesBasedRestrictedSegmentations, userProfile: UserProfile) {
    // apply restriction only for non admin users
    if (userProfile.AccountDetails.AccountTypeId !== 1) {
      const currentUserAssignedRoleIds = new Set<number>()
      userProfile.Roles.forEach((role) => {
        currentUserAssignedRoleIds.add(role.UserRoleId)
      })
      roleBasedRestrictedSegmentations.Roles.forEach((roleBasedRestrictedSegmentation) => {
        if (currentUserAssignedRoleIds.has(roleBasedRestrictedSegmentation.RoleId)) {
          (roleBasedRestrictedSegmentation.Segmentations || []).forEach((restrictedSegmentation) => {
            this._RoleRestrictedSegmentations.push({
              Id: restrictedSegmentation.Id,
              Name: restrictedSegmentation.Name,
              NotViewable: restrictedSegmentation.NotViewable,
              NotEditable: restrictedSegmentation.NotEditable,
            })
          })
        }
      })
    }
  }
}
