<template>
  <div id="MerchToolBar" class="flex h-18 border-b bg-gray-50 w-full overflow-x-auto overflow-y-hidden flex-row">
    <div
      v-for="btn in getToolbarButtons" :key="btn.key"
      class="flex flex-col items-center justify-center mx-2 my-0 cursor-pointer hover:text-primary-500"
    >
      <tx-dropdown
        v-if="btn.subToolbarButtons && btn.subToolbarButtons.length" :disabled="btn.disabled"
        :items="btn.subToolbarButtons" value-prop="key" display-prop="label" icon-prop="faicon"
        @select="onButtonClicked"
      >
        <template #button>
          <div :class="{ 'opacity-50 cursor-not-allowed': btn.disabled, 'active:text-primary-500 hover:text-primary-500': !btn.disabled }" class="flex flex-col items-center justify-center mx-3 my-0 cursor-pointer button w-17 text-dark ">
            <font-awesome-icon class="w-6 h-6 custom-thin-icon" :icon="btn.faicon" />
            <div class="text-xs mt-1" v-text="btn.label" />
          </div>
        </template>
      </tx-dropdown>
      <div v-else :class="{ 'opacity-50 cursor-not-allowed': btn.disabled, 'active:text-primary-500 hover:text-primary-500': !btn.disabled }" class="flex flex-col items-center justify-center mx-3 my-0 cursor-pointer button w-17 text-dark max-w-[45px] text-center" @click="onButtonClicked(btn)">
        <font-awesome-icon class="w-6 h-6 custom-thin-icon" :icon="btn.faicon" />
        <div class="text-xs mt-1 align-middle" v-text="btn.label" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import TxDropdown from '@/shared/components/TxDropdown.vue'
import type MerchSlide from '@/modules/merch/services/merchSlide'

const props = defineProps<{
  activeSlide?: MerchSlide
  allowSave?: boolean
  allowUndo?: boolean
  allowRedo?: boolean
  activeTab?: string
  favoritesVisible?: boolean
  merchLoading?: boolean
}>()

const emit = defineEmits<{
  (e: 'buttonClick', btn: IToolbarButton<MerchToolbarAction>): void
}>()

const { t } = useI18n()

const getToolbarButtons = computed(() => {
  const toolbarButtons: IToolbarButton<MerchToolbarAction>[] = [
    { key: 'save', disabled: !props.allowSave, faicon: 'fa-light fa-floppy-disk', label: t('merch.toolbars.actions.save'), visible: true },
    { key: 'undo', disabled: !props.activeSlide || props.activeTab === 'sharedSlides' || !props.allowUndo, faicon: 'fa-light fa-rotate-left', label: t('merch.toolbars.actions.undo'), visible: true },
    { key: 'redo', disabled: !props.activeSlide || props.activeTab === 'sharedSlides' || !props.allowRedo, faicon: 'fa-light fa-rotate-right', label: t('merch.toolbars.actions.redo'), visible: true },
    { key: 'paste', disabled: !props.activeSlide || props.activeTab === 'sharedSlides', faicon: 'fa-light fa-paste', label: t('merch.toolbars.actions.paste'), visible: true },
    { key: 'insert', disabled: !props.activeSlide || props.activeTab === 'sharedSlides', faicon: 'fa-light fa-square-plus', label: t('merch.toolbars.actions.insert'), visible: true, subToolbarButtons: [
      { key: 'article', disabled: false, faicon: 'fa-light fa-square-plus', label: t('general.articles'), visible: true },
      { key: 'text', disabled: false, faicon: 'fa-light fa-text', label: t('merch.toolbars.actions.text'), visible: true },
      { key: 'rect', disabled: false, faicon: 'fa-light fa-rectangle', label: t('merch.toolbars.actions.box'), visible: true },
      { key: 'triangle', disabled: false, faicon: 'fa-light fa-triangle', label: t('merch.toolbars.actions.triangle'), visible: true },
      { key: 'circle', disabled: false, faicon: 'fa-light fa-circle', label: t('merch.toolbars.actions.circle'), visible: true },
      { key: 'line', disabled: false, faicon: 'fa-light fa-horizontal-rule', label: t('merch.toolbars.actions.line'), visible: true },
      { key: 'image', disabled: false, faicon: 'fa-light fa-image', label: t('merch.toolbars.actions.image'), visible: true },
    ] },
    { key: 'find', disabled: props.activeTab === 'sharedSlides' || props.merchLoading, faicon: 'fa-light fa-search', label: t('merch.toolbars.actions.find'), visible: true },
    { key: 'share', disabled: props.activeTab === 'sharedSlides' || props.merchLoading, faicon: 'fa-light fa-share', label: t('merch.toolbars.actions.share'), visible: true },
    { key: 'manageTemplate', disabled: !props.activeSlide || props.activeTab === 'sharedSlides', faicon: 'fa-light fa-layer-group', label: t('merch.toolbars.actions.manageTemplate'), visible: true },
    { key: 'modify', disabled: props.activeTab === 'sharedSlides' || props.merchLoading, faicon: 'fa-light fa-edit', label: t('merch.toolbars.actions.modify'), visible: true },
    { key: 'showFavorites', disabled: !props.activeSlide || props.activeTab === 'sharedSlides', faicon: 'fa-light fa-star', label: props.favoritesVisible ? t('merch.toolbars.actions.hideFavorites') : t('merch.toolbars.actions.showFavorites'), visible: true },
    { key: 'generate', disabled: props.activeTab === 'sharedSlides' || props.merchLoading, faicon: 'fa-light fa-clapperboard-play', label: t('merch.toolbars.actions.generate'), visible: true },

  ]
  return toolbarButtons
})

function onButtonClicked(button: IToolbarButton<MerchToolbarAction>) {
  if (button.disabled) {
    return
  }
  emit('buttonClick', button)
}
</script>
