import type { ContainerItemDetails, ContainerListItemModel } from './model/containerModel'
import Net from '@/services/net'

export function getMyContainers(catalogCode?: number, containerType?: string) {
  return Net.t1.get<ContainerListItemModel[]>('/mydatacontainers', { params: { catalogcode: catalogCode, containertype: containerType } })
}

export function getContainer(containerId: number) {
  return Net.t1.get<ContainerItemDetails>(`/mydatacontainers/${containerId}`)
}

export function createContainer(userId: number, containerName: string, containerValue: string, catalogCode?: number, containerType?: string) {
  return Net.t1.post<ContainerItemDetails>(`/users/${userId}/datacontainers`, { CatalogCode: catalogCode, ContainerName: containerName, ContainerValue: containerValue, ContainerType: containerType })
}

export function updateContainer(userId: number, containerId: number, containerName: string, containerValue: string, catalogCode?: number) {
  return Net.t1.put<ContainerItemDetails>(`/users/${userId}/datacontainers/${containerId}`, { CatalogCode: catalogCode, ContainerName: containerName, ContainerValue: containerValue })
}
