import type { CreateObjectRelationModel, ObjectRelationListModel, ObjectRelationModel, RelationTypeModel } from './model/objectRelationModel'
import Net from '@/services/net'

export function getRelationTypes(catalogCode: number) {
  return Net.t1.get<RelationTypeModel[]>(`/catalogs/${catalogCode}/relationtypes`)
}

export function getObjectRelations(catalogCode: number, objectId: number, entityId: number, isRequestArticle: ZeroOrOneType = 0) {
  return Net.t1.get<ObjectRelationListModel[]>(`/catalogs/${catalogCode}/objects/${objectId}/relations?entityid=${entityId}&isrequestarticle=${isRequestArticle}`)
}

export function deleteObjectRelation(catalogCode: number, id: number) {
  return Net.t1.delete(`/catalogs/${catalogCode}/relations/${id}`)
}

export function createObjectRelation(catalogCode: number, objectId: number, requestObject: CreateObjectRelationModel) {
  return Net.t1.post<ObjectRelationModel>(`/catalogs/${catalogCode}/relations/${objectId}`, requestObject)
}
