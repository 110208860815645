<template>
  <div class="block">
    <div
      v-for="(tab, index) in availableTabs" :key="tab.key" class="cursor-pointer py-1.5 px-3 text-center border text-md font-md inline-block" :class="{
        'rounded-l-full': index === 0,
        'rounded-r-full': index === availableTabs.length - 1,
        'border-primary-500 text-primary-500': tab.key === modelValue,
        'text-default': tab.key !== modelValue,
        'border-l-0': tab.key !== modelValue && index !== 0,
        'border-r-0': index + 1 < availableTabs.length && modelValue === availableTabs[index + 1].key,
      }" @click="onClick(tab)" v-text="t(tab.label)"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const props = defineProps<{
  tabs: ITabItem[]
  modelValue?: string | null
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', val: string): void
}>()

const { t } = useI18n()

const availableTabs = computed(() => props.tabs.filter(t => t.visible))

function onClick(tab: IKeyLabel) {
  emit('update:modelValue', tab.key)
}
</script>
