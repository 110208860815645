<template>
  <div v-show="visible" class="flex flex-col w-full h-full">
    <!-- SUB HEADER -->
    <div class="flex-shrink-0 text-center">
      <!-- NOTES -->
      <div class="text-sm text-gray-600" :class="{ 'mb-8': headerNotes.length }">
        {{ headerNotes }}
      </div>
    </div>
    <!-- BODY -->
    <div class="flex items-center justify-center flex-grow mx-24 my-3 overflow-x-hidden overflow-y-auto">
      <div
        v-for="action in actions" :key="action.id"
        class="h-[195px] w-[150px] mr-7 flex flex-col items-center box-border relative overflow-hidden transition-shadow duration-200 ease-in-out bg-stone-50 border border-solid rounded-[4px] cursor-pointer group shadow-card hover:shadow-lg" :class="{ '!border-primary-500': selectedAction != null && selectedAction.id === action.id }"
        @click="makeSelection(action)"
      >
        <icon-button class="m-auto" :faicon="action.icon" :width="64" :height="64" :icon-size="64" type="static" />
        <div class="pb-6 text-sm" v-text="action.displayLabel" />
      </div>
    </div>

    <!-- FOOTER -->
    <div class="flex flex-row justify-end flex-shrink-0 mx-24 mb-16 flex-nowrap">
      <tx-button type="cancel" :text="t('general.cancel')" @click="onCancel" />
      <tx-button class="ml-5" type="confirm" :disabled="selectedAction == null" :text="t('general.next')" @click="nextStep" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import IconButton from '@/shared/components/IconButton.vue'
import TxButton from '@/shared/components/TxButton.vue'
import useErrorMessage from '@/shared/composables/errorMessage'
import { appConstants } from '@/models/constants'

interface ITypeSelectionAction { // interface for actions for TypeSelection.vue file
  id: number
  name: string
  displayLabel: string
  icon: string
  visible: boolean
}

defineProps<{
  modelValue: number
  visible: boolean
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'next'): void
  (e: 'update:modelValue', modelValue: number | null): void
}>()

const { t } = useI18n()
const { errorMessage } = useErrorMessage()

// DATA
const headerNotes = ref('')
const selectedAction = ref<ITypeSelectionAction | null>(null)
// DATA - END

// COMPUTED
const actions = computed(() => {
  return [
    {
      id: appConstants.addArticleTypes.newModel,
      name: 'newModel',
      displayLabel: t('modelCreate.title.newModel'),
      icon: 'fa-light fa-plus',
      visible: true,
    },
  ]
})
// COMPUTED - END

// METHODS
function onCancel() {
  emit('cancel')
}

function makeSelection(action: ITypeSelectionAction) {
  if (selectedAction.value == null || selectedAction.value.id !== action.id) {
    selectedAction.value = action
  }
  else {
    selectedAction.value = null
  }
  emit('update:modelValue', selectedAction.value ? selectedAction.value.id : null)
}

async function nextStep() {
  if (selectedAction.value == null) {
    errorMessage.value = t('validations.formInvalid')
    return
  }
  emit('next')
}
// METHODS - END
</script>
