import type { SlideDetailsModel, SlideModel } from './model/merchModel'
import type SharedFolder from '@/models/sharedFolder'
import Net from '@/services/net'

export function getMySlides(catalogCode) {
  return Net.t1.get<SlideModel[]>(`/catalogs/${catalogCode}/mymerchslides?slideType=my`)
}

export function getSlideDetails(catalogCode, slideId) {
  return Net.t1.get<SlideDetailsModel>(`/catalogs/${catalogCode}/merchSlides/${slideId}`)
}

export function createUpdateMerchSlide(catalogCode, requestObject) {
  return Net.t1.put<SlideModel>(`/catalogs/${catalogCode}/merchslides`, requestObject)
}

export function updateSlidesSortOrders(catalogCode, requestObject) {
  return Net.t1.put(`/catalogs/${catalogCode}/merchslides/sortorder`, requestObject)
}

export function getSharedSlides(catalogCode) {
  return Net.t1.get<SlideModel[]>(`/catalogs/${catalogCode}/mymerchslides?slideType=shared`)
}

export function getSharedFolders(catalogCode) {
  return Net.t1.get<SharedFolder[]>(`/catalogs/${catalogCode}/sharingfolders?entity=merch`)
}

export function shareSlide(catalogCode, requestObject) {
  return Net.t1.put<string>(`/catalogs/${catalogCode}/merchslides/share`, requestObject)
}
export function deleteSlide(catalogCode, requestObject) {
  return Net.t1.put<string>(`/catalogs/${catalogCode}/merchslides/status`, requestObject)
}
