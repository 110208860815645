<template>
  <div class="w-full grow flex flex-col h-full overflow-hidden">
    <tx-data-table
      :data="data" :columns="columns" :loading="loadingData" :item-actions="itemActions" :list-actions="listActions" :visible-columns="visibleColumns" :show-status-filter="true"
      :title="t('general.articles')" :model-path="modelPath" :total-column-width="totalColumnWidth" :sort-column="{ UpdatedDate: false }"
      @selection-changed="onSelectionChanged" @refresh="doRefresh" @row-click="showDetails"
    />
    <!-- List Action Drawer -->
    <tx-drawer v-model="isDrawerVisible" right width="700px">
      <active-or-deactive-article
        v-if="selectedListAction && isActiveOrDeActiveAction" :articles="selectedArticles" :type="selectedListAction.id === 'activate' ? 'activate' : 'deactivate'"
        @cancel="closeDrawer" @updated="onArticlesUpdated"
      />
      <lock-or-unlock-article
        v-else-if="selectedListAction && isLockOrUnlockAction" :articles="selectedArticles"
        :type="selectedListAction.id === 'lock' ? 'lock' : 'unlock'"
        @cancel="closeDrawer" @updated="onArticlesUpdated"
      />
      <tx-data-importer v-else-if="importModel && selectedListAction" ref="refDataImporter" :model="importModel" :action="selectedListAction" :data="data" @import="onImport" @cancel="closeDrawer" />
    </tx-drawer>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es'
import type { IImportModel, IRequestPrices, IRequestSizes } from '../../type'
import useUserProfileSettings from '../../composables/userProfileSettings'
import ArticleModel from './article'
import TxDataTable from '@/shared/components/txDataTable'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import type Article from '@/models/article'
import type { ITxDataTableColumn, ITxDataTableItemAction, ITxDataTableListAction, ITxDataTableVisibleColumn } from '@/shared/components/txDataTable/TxDataTable.types'
import TxDrawer from '@/shared/components/TxDrawer.vue'
import TxDataImporter from '@/shared/components/txDataImporter/TxDataImporter.vue'
import ActiveOrDeactiveArticle from '@/modules/articleDetails/components/ActiveOrDeactiveArticle.vue'
import LockOrUnlockArticle from '@/modules/articleDetails/components/LockOrUnlockArticle.vue'
import { useNotificationStore } from '@/store/notification'
import Job from '@/models/job'
import { articlesBgJob, importByLicenseeBgJob, setPricesByLicenseeModelNumberBgJob, setSizesByLicenseeModelNumberBgJob, updateViaImportBgJob, updateViaImportModelBgJob } from '@/api/t1/article'
import utils from '@/services/utils'
import type { MasterSizeScaleModel, SizeScaleModel } from '@/api/t1/model/sizeModel'
import { getMasterSizeScales, getSellerAvailableSizeScales } from '@/api/t1/size'
import useEventsBus from '@/shared/composables/eventBus'
import { privileges } from '@/models/constants'
import { useConfirmDialog } from '@/shared/composables/confirmDialog'
import type MyArticle from '@/models/myArticle'
import router from '@/router'

const { t } = useI18n()
const userStore = useUserStore()
const notificationStore = useNotificationStore()
const confirmDialog = useConfirmDialog()
const { onAppEvent, offAppEvent } = useEventsBus()
const { applyPersistedListViewLayout } = useUserProfileSettings()

const columns = ref<ITxDataTableColumn[]>([])
const data = ref<Article[]>([])
const loadingData = ref(true)
const refDataImporter = ref<InstanceType<typeof TxDataImporter>>()
const selectedListAction = ref<ITxDataTableListAction>()
const importArticlesBgFailed = ref('') // TODO SHOW error message
const isDrawerVisible = ref(false)
const visibleColumns = ref<ITxDataTableVisibleColumn[]>([])
const modelPath = ref('')
const totalColumnWidth = ref(1200)

const selectedArticles = ref<MyArticle[]>([])
const selectedItems = ref<MyArticle[]>([])
const articleModel = shallowRef<ArticleModel>()
const importModel = ref<IImportModel>()

enum listActionType {
  delete = 'delete',
  activate = 'activate',
  import = 'import',
  updateViaImport = 'updateViaImport',
  updateViaImportModel = 'updateViaImportModel',
  importByLicensee = 'importByLicensee',
  setSizesByLicenseeModelNumber = 'setSizesByLicenseeModelNumber',
  setPricesByLicenseeModelNumber = 'setPricesByLicenseeModelNumber',
  view = 'view',
  lock = 'lock',
  unlock = 'unlock',
}

const itemActions = computed(() => ([{
  id: listActionType.view,
  enabled: item => item.Status === 1,
  visible: userStore.userProfile.isValidPrivilege(privileges.admin.nav.articleDetails),
  icon: 'fa-light fa-search',
  label: t('general.viewRecord'),
  onClick: (item) => {
    showDetails(item)
  },
}, {
  id: listActionType.delete,
  enabled: true,
  visible: item => userStore.activeCatalog?.DataSourceTypeId === 1 && userStore.userProfile.isValidPrivilege(privileges.article.updateStatus) && item.Status > 0,
  icon: 'fa-light fa-trash-can',
  label: t('general.delete'),
  onClick: (item) => {
    selectedArticles.value = [item]
    openDrawer(listActionType.delete)
  },
}, {
  id: listActionType.activate,
  enabled: true,
  visible: item => userStore.activeCatalog?.DataSourceTypeId === 1 && userStore.userProfile.isValidPrivilege(privileges.article.updateStatus) && item.Status === 0,
  icon: 'fa-light fa-rotate-right',
  label: t('activeOrDeactiveArticle.activate'),
  onClick: async (item) => {
    selectedArticles.value = [item]
    openDrawer(listActionType.activate)
  },
}, {
  id: listActionType.lock,
  label: t('lockOrUnlockArticle.lock'),
  icon: 'fa-light fa-lock',
  visible: userStore.activeCatalog?.ArticleLocking === 1 && userStore.userProfile.isValidPrivilege(privileges.article.lockArticles),
  enabled: item => item.Status === 1 && item.Locked === 0,
  onClick: (item) => {
    selectedArticles.value = [item]
    openDrawer(listActionType.lock)
  },
}, {
  id: listActionType.unlock,
  label: t('lockOrUnlockArticle.unlock'),
  icon: 'fa-light fa-lock-open',
  visible: userStore.activeCatalog?.ArticleLocking === 1 && userStore.userProfile.isValidPrivilege(privileges.article.unlockArticles),
  enabled: item => item.Status === 1 && item.Locked === 1,
  onClick: (item) => {
    selectedArticles.value = [item]
    openDrawer(listActionType.unlock)
  },
}]) as Array<ITxDataTableItemAction>)

const listActions = computed(() => {
  const actions: ITxDataTableListAction[] = [{
    id: listActionType.delete,
    label: t('general.delete'),
    icon: 'fa-light fa-trash-can',
    visible: userStore.activeCatalog?.DataSourceTypeId === 1 && userStore.userProfile.isValidPrivilege(privileges.article.updateStatus),
    enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.Status > 0),
    onClick: () => {
      selectedArticles.value = selectedItems.value
      openDrawer(listActionType.delete)
    },
  }, {
    id: listActionType.activate,
    label: t('activeOrDeactiveArticle.activate'),
    icon: 'fa-light fa-rotate-right',
    visible: userStore.activeCatalog?.DataSourceTypeId === 1 && userStore.userProfile.isValidPrivilege(privileges.article.updateStatus),
    enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.Status === 0),
    onClick: () => {
      selectedArticles.value = selectedItems.value
      openDrawer(listActionType.activate)
    },
  }, {
    id: listActionType.lock,
    label: t('lockOrUnlockArticle.lock'),
    icon: 'fa-light fa-lock',
    visible: userStore.activeCatalog?.ArticleLocking === 1 && userStore.userProfile.isValidPrivilege(privileges.article.lockArticles),
    enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.Locked === 0),
    onClick: () => {
      selectedArticles.value = selectedItems.value
      openDrawer(listActionType.lock)
    },
  }, {
    id: listActionType.unlock,
    label: t('lockOrUnlockArticle.unlock'),
    icon: 'fa-light fa-lock-open',
    visible: userStore.activeCatalog?.ArticleLocking === 1 && userStore.userProfile.isValidPrivilege(privileges.article.unlockArticles),
    enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.Locked === 1),
    onClick: () => {
      selectedArticles.value = selectedItems.value
      openDrawer(listActionType.unlock)
    },
  }, {
    id: listActionType.import,
    label: t('admin.articles.actions.importArticles'),
    icon: 'fa-light fa-file-import',
    visible: userStore.userProfile.isValidPrivilege(privileges.admin.articles.listActions.UI_ImportArticles),
    enabled: true,
    onClick: () => openDrawer(listActionType.import),
  }, {
    id: listActionType.updateViaImport,
    label: t('admin.articles.actions.updateViaImport'),
    icon: 'fa-light fa-file-pen',
    visible: userStore.userProfile.isValidPrivilege(privileges.admin.articles.listActions.UpdateOnlyCatalogBulkArticle),
    enabled: true,
    onClick: () => openDrawer(listActionType.updateViaImport),
  }, {
    id: listActionType.updateViaImportModel,
    label: t('admin.articles.actions.updateViaImportModel'),
    icon: 'fa-light fa-file-pen',
    visible: userStore.userProfile.isValidPrivilege(privileges.admin.articles.listActions.UpdateBulkAttributesByModelNumbers),
    enabled: true,
    onClick: () => openDrawer(listActionType.updateViaImportModel),
  }]
  // if ArticleAttribute and ModelAttribute is not assigned then disable the importByLicensee related actions
  const articleAttribute = userStore.activeCatalog?.ArticleAttribute
  const modelAttribute = userStore.activeCatalog?.ModelAttribute

  if (userStore.activeCatalog && userStore.activeCatalog.DataSourceTypeId === 1) {
    actions.push({
      id: listActionType.importByLicensee,
      label: t('admin.articles.actions.importByLicensee'),
      icon: 'fa-light fa-file-arrow-up',
      visible: userStore.userProfile.isValidPrivilege(privileges.admin.articles.listActions.ImportArticlesByLicenseeInfo),
      enabled: utils.isDefined(modelAttribute) && modelAttribute.toString().trim() !== '' && utils.isDefined(articleAttribute) && articleAttribute.toString().trim() !== '',
      onClick: () => openDrawer(listActionType.importByLicensee),
    })
  }
  if (userStore.activeCatalog && userStore.activeCatalog.DataSourceTypeId === 1) {
    actions.push({
      id: listActionType.setSizesByLicenseeModelNumber,
      label: t('admin.articles.actions.setSizesByLicenseeModelNumber'),
      icon: 'fa-light fa-wave-sine',
      visible: userStore.userProfile.isValidPrivilege(privileges.admin.articles.listActions.UpdateSizeScaleByLicensee),
      enabled: utils.isDefined(modelAttribute) && modelAttribute.toString().trim() !== '',
      onClick: () => openDrawer(listActionType.setSizesByLicenseeModelNumber),
    })
  }
  if (userStore.activeCatalog && userStore.activeCatalog.IsPriceByStyleApply && userStore.activeCatalog.DataSourceTypeId === 1) {
    actions.push({
      id: listActionType.setPricesByLicenseeModelNumber,
      label: t('admin.articles.actions.setPricesByLicenseeModelNumber'),
      icon: 'fa-light fa-money-check-dollar-pen',
      visible: userStore.userProfile.isValidPrivilege(privileges.admin.articles.listActions.UpdatePricesByLicensee),
      enabled: utils.isDefined(modelAttribute) && modelAttribute.toString().trim() !== '',
      onClick: () => openDrawer(listActionType.setPricesByLicenseeModelNumber),
    })
  }

  return actions
})

const isActiveOrDeActiveAction = computed(() => selectedListAction.value && (selectedListAction.value.id === listActionType.activate || selectedListAction.value.id === listActionType.delete))
const isLockOrUnlockAction = computed(() => selectedListAction.value && (selectedListAction.value.id === listActionType.lock || selectedListAction.value.id === listActionType.unlock))

onMounted(async () => {
  articleModel.value = new ArticleModel(t)
  columns.value = articleModel.value.getDataTableColumns()
  visibleColumns.value = articleModel.value.getDefaultVisibleColumnList()
  modelPath.value = articleModel.value.modelPath
  // retrieve persisted default colums for model if existed and apply that on visible columns
  // Note: This should be part of all module implementations.
  const { persistedColumns, totalTableColumnWidth } = applyPersistedListViewLayout(true, modelPath.value, columns.value, visibleColumns.value)
  if (persistedColumns) {
    visibleColumns.value = persistedColumns
  }
  if (totalTableColumnWidth) {
    totalColumnWidth.value = totalTableColumnWidth
  }
  await doRefresh()
  onAppEvent('catalogDataUpdated', doRefresh)
})

onUnmounted(() => {
  offAppEvent('catalogDataUpdated', doRefresh)
})

function onSelectionChanged(items: any[]) {
  selectedItems.value = items
}

async function doRefresh() {
  loadingData.value = true
  selectedItems.value = []
  data.value = []
  await userStore.doLoadData(['Articles'], [], false)
  data.value = await appConfig.DB!.getAdminArticles(userStore.activeCatalog!.CatalogCode)
  loadingData.value = false
}

function openDrawer(action: listActionType) {
  selectedListAction.value = listActions.value.find(listAction => listAction.id === action)
  importModel.value = undefined
  if (action === listActionType.activate || action === listActionType.delete) {
    isDrawerVisible.value = true
  }
  else if (articleModel.value) {
    importModel.value = articleModel.value.getImportData(action)
    isDrawerVisible.value = true
  }
}

function onImport(data: any[]) {
  if (selectedListAction.value) {
    switch (selectedListAction.value.id) {
      case listActionType.import:
        submitImportedData(data)
        break
      case listActionType.updateViaImport:
        submitUpdateViaImportData(data)
        break
      case listActionType.updateViaImportModel:
        submitUpdateViaImportModelData(data)
        break
      case listActionType.importByLicensee:
        submitImportByLicenseeData(data)
        break
      case listActionType.setSizesByLicenseeModelNumber:
        submitSetSizesByLicenseeModelNumberData(data)
        break
      case listActionType.setPricesByLicenseeModelNumber:
        submitSetPricesByLicenseeModelNumberData(data)
        break
    }
  }
}

function closeDrawer() {
  isDrawerVisible.value = false
}

async function showDetails(article: Article) {
  if (userStore.activeCatalog?.DataSourceTypeId === 3 && utils.isDefined(article.IsAccessible) && article.IsAccessible === 1) {
    const title = t('admin.articles.dialog.unAssortedArticleDetailsMessage.title')
    const message = t('admin.articles.dialog.unAssortedArticleDetailsMessage.body')
    await confirmDialog(title, message, [])
  }
  else if (userStore.userProfile.isValidPrivilege(privileges.admin.nav.articleDetails)) {
    router.push({ name: 'ArticleDetails', params: { articleId: article.Id } })
  }
}

function onArticlesUpdated(articles: MyArticle[] | Article[]) {
  let message = t('general.updatedSuccessfully')
  if (selectedListAction.value) {
    if (selectedListAction.value.id === listActionType.activate) {
      message = t('activeOrDeactiveArticle.activateSuccessfully', articles.length)
    }
    else if (selectedListAction.value.id === listActionType.delete) {
      message = t('activeOrDeactiveArticle.deactivateSuccessfully', articles.length)
    }
    else if (selectedListAction.value.id === listActionType.lock) {
      message = t('lockOrUnlockArticle.lockSuccessfully', articles.length)
    }
    else if (selectedListAction.value.id === listActionType.unlock) {
      message = t('lockOrUnlockArticle.unlockSuccessfully', articles.length)
    }
    notificationStore.addNotification({ message, type: 'Success' })
    doRefresh()
    closeDrawer()
  }
}

async function submitImportedData(validData: any[]) {
  const isApplyRetailWindowPrivilegeIsAssigned = userStore.userProfile.isValidPrivilege(privileges.admin.articles.UpdateArticleShipmentWindowRange)
  const isRestrictedRetailWindowOnRole = (utils.isDefined(userStore.activeCatalog?._RoleRestrictedRetailWindow) && !isEmpty(userStore.activeCatalog?._RoleRestrictedRetailWindow) && userStore.activeCatalog?._RoleRestrictedRetailWindow.NotEditable)
  validData.forEach((article) => {
    // Send IsShippingWindowUpdatable as 1 when "DummyArticleApplyRetailWindow" privilege is assigned and IsShippingWindowApply is 1
    // if retail window is restricted on role send IsShippingWindowUpdatable as 0
    article.IsShippingWindowUpdatable = (isApplyRetailWindowPrivilegeIsAssigned && userStore.activeCatalog?.IsShippingWindowApply === 1 && !isRestrictedRetailWindowOnRole) ? 1 : 0
    if (utils.isDefined(article.RetailExitMonthId) && article.RetailExitMonthId !== '') {
      article.ShipmentWindowRangeId = article.RetailExitMonthId
      delete article.RetailIntroMonthId
      delete article.RetailExitMonthId
    }
    if (article.ArticleNumber === '') {
      delete article.ArticleNumber
    }
    if (article.ModelNumber === '') {
      delete article.ModelNumber
    }
  })

  importArticlesBgFailed.value = ''
  await articlesBgJob(userStore.activeCatalog!.CatalogCode, validData).then(async (res) => {
    if (userStore.activeCatalog && res.data) {
      const job = new Job({
        Id: res.data.Id,
        CatalogCode: userStore.activeCatalog.CatalogCode,
        Type: 'Articles',
        Status: res.data.Status,
        UserId: userStore.userProfile.Id,
        CreatedDate: new Date(),
      })
      await appConfig.DB!.jobs.put(job)
      const message = t('admin.articles.messages.jobSuccess')
      notificationStore.addNotification({ message, type: 'Success' })
    }
  })
    .catch((e) => {
      console.error(e)
      importArticlesBgFailed.value = t('general.unexpectedError')
    })
    .finally()
}

async function submitUpdateViaImportData(validData: any[]) {
  const isApplyRetailWindowPrivilegeIsAssigned = userStore.userProfile.isValidPrivilege(privileges.admin.articles.UpdateArticleShipmentWindowRange)
  const isRestrictedRetailWindowOnRole = (utils.isDefined(userStore.activeCatalog?._RoleRestrictedRetailWindow) && !isEmpty(userStore.activeCatalog?._RoleRestrictedRetailWindow) && userStore.activeCatalog?._RoleRestrictedRetailWindow.NotEditable)
  validData.forEach((article) => {
    // Send IsShippingWindowUpdatable as 1 when "DummyArticleApplyRetailWindow" privilege is assigned and IsShippingWindowApply is 1
    // if retail window is restricted on role send IsShippingWindowUpdatable as 0
    article.IsShippingWindowUpdatable = (isApplyRetailWindowPrivilegeIsAssigned && userStore.activeCatalog?.IsShippingWindowApply === 1 && !isRestrictedRetailWindowOnRole) ? 1 : 0
    if (utils.isDefined(article.RetailExitMonthId) && article.RetailExitMonthId !== '') {
      article.ShipmentWindowRangeId = article.RetailExitMonthId
      delete article.RetailIntroMonthId
      delete article.RetailExitMonthId
    }
  })

  importArticlesBgFailed.value = ''
  await updateViaImportBgJob(userStore.activeCatalog!.CatalogCode, validData).then(async (res) => {
    if (userStore.activeCatalog && res.data) {
      const job = new Job({
        Id: res.data.Id,
        CatalogCode: userStore.activeCatalog.CatalogCode,
        Type: 'updateViaImport',
        Status: res.data.Status,
        UserId: userStore.userProfile.Id,
        CreatedDate: new Date(),
      })
      await appConfig.DB!.jobs.put(job)
      const message = t('admin.articles.messages.updateViaImportJobSuccess')
      notificationStore.addNotification({ message, type: 'Success' })
    }
  })
    .catch((e) => {
      console.error(e)
      importArticlesBgFailed.value = t('general.unexpectedError')
    })
    .finally()
}

async function submitUpdateViaImportModelData(validData: any[]) {
  importArticlesBgFailed.value = ''
  await updateViaImportModelBgJob(userStore.activeCatalog!.CatalogCode, validData).then(async (res) => {
    if (userStore.activeCatalog && res.data) {
      const job = new Job({
        Id: res.data.Id,
        CatalogCode: userStore.activeCatalog.CatalogCode,
        Type: 'updateViaImportModel',
        Status: res.data.Status,
        UserId: userStore.userProfile.Id,
        CreatedDate: new Date(),
      })
      await appConfig.DB!.jobs.put(job)
      const message = t('admin.articles.messages.updateViaImportModelJobSuccess')
      notificationStore.addNotification({ message, type: 'Success' })
    }
  })
    .catch((e) => {
      console.error(e)
      importArticlesBgFailed.value = t('general.unexpectedError')
    })
    .finally()
}

async function submitImportByLicenseeData(validData: any[]) {
  const isApplyRetailWindowPrivilegeIsAssigned = userStore.userProfile.isValidPrivilege(privileges.admin.articles.UpdateArticleShipmentWindowRange)
  const isRestrictedRetailWindowOnRole = (utils.isDefined(userStore.activeCatalog?._RoleRestrictedRetailWindow) && !isEmpty(userStore.activeCatalog?._RoleRestrictedRetailWindow) && userStore.activeCatalog?._RoleRestrictedRetailWindow.NotEditable)
  validData.forEach((article) => {
    // Send IsShippingWindowUpdatable as 1 when "DummyArticleApplyRetailWindow" privilege is assigned and IsShippingWindowApply is 1
    // if retail window is restricted on role send IsShippingWindowUpdatable as 0
    article.IsShippingWindowUpdatable = (isApplyRetailWindowPrivilegeIsAssigned && userStore.activeCatalog?.IsShippingWindowApply === 1 && !isRestrictedRetailWindowOnRole) ? 1 : 0
    if (utils.isDefined(article.RetailExitMonthId) && article.RetailExitMonthId !== '') {
      article.ShipmentWindowRangeId = article.RetailExitMonthId
      delete article.RetailIntroMonthId
      delete article.RetailExitMonthId
    }
  })
  importArticlesBgFailed.value = ''
  await importByLicenseeBgJob(userStore.activeCatalog!.CatalogCode, validData).then(async (res) => {
    if (userStore.activeCatalog && res.data) {
      const job = new Job({
        Id: res.data.Id,
        CatalogCode: userStore.activeCatalog.CatalogCode,
        Type: 'importByLicensee',
        Status: res.data.Status,
        UserId: userStore.userProfile.Id,
        CreatedDate: new Date(),
      })
      await appConfig.DB!.jobs.put(job)
      const message = t('admin.articles.messages.importByLicenseeJobSuccess')
      notificationStore.addNotification({ message, type: 'Success' })
    }
  })
    .catch((e) => {
      console.error(e)
      importArticlesBgFailed.value = t('general.unexpectedError')
    })
    .finally()
}

async function submitSetSizesByLicenseeModelNumberData(validData: any[]) {
  try {
    // Get master size scales
    const masterSizeScales: MasterSizeScaleModel[] = await getMasterSizeScales(userStore.activeCatalog!.AccountId)
    const masterSizeScaleMap = masterSizeScales.reduce((map, scale) => {
      map[scale.SizeScale] = scale.Id
      return map
    }, {})

    // Get seller available size scales
    const sizeScales: SizeScaleModel[] = await getSellerAvailableSizeScales(userStore.activeCatalog!.AccountId)
    const requestbody: IRequestSizes = validData.reduce((body, item) => {
      const masterSizeScaleId = masterSizeScaleMap[item.MasterSizeScale]
      const sizeScale = sizeScales.find(ss => ss.SizeScale === item.SizeScale && ss.MasterSizeScaleId === masterSizeScaleId)

      if (sizeScale) {
        body.push({
          SizeScaleId: sizeScale.Id,
          LicenseeModelNumber: item.LicenseeModelNumber,
        })
      }
      else {
        console.warn(`Size scale not found for MasterSizeScaleName: ${item.MasterSizeScale} and SizeScale: ${item.SizeScale}`)
      }
      return body
    }, [])

    importArticlesBgFailed.value = ''
    const res = await setSizesByLicenseeModelNumberBgJob(userStore.activeCatalog!.CatalogCode, requestbody)
    if (userStore.activeCatalog && res.data) {
      const job = new Job({
        Id: res.data.Id,
        CatalogCode: userStore.activeCatalog.CatalogCode,
        Type: 'setSizesByLicenseeModelNumber',
        Status: res.data.Status,
        UserId: userStore.userProfile.Id,
        CreatedDate: new Date(),
      })
      await appConfig.DB!.jobs.put(job)
      const message = t('admin.articles.messages.setSizesByLicenseeModelNumberJobSuccess')
      notificationStore.addNotification({ message, type: 'Success' })
    }
  }
  catch (e) {
    console.error(e)
    importArticlesBgFailed.value = t('general.unexpectedError')
  }
}

async function submitSetPricesByLicenseeModelNumberData(validData: any[]) {
  // TODO, remove ChangeReason and SkipChangeApproval with price locking
  const requestObj = {
    ArticlePrices: [] as IRequestPrices[],
    ChangeReason: '',
    SkipChangeApproval: 1,
  }

  // Transform data into required format
  const transformedData: IRequestPrices[] = []

  validData.forEach((item) => {
  // Initialize newElement with the expected structure
    const newElement: IRequestPrices = {
      LicenseeModelNumber: item.LicenseeModelNumber,
      Prices: [],
    }

    importModel.value?.fields.forEach((price) => {
      // Skip if there is no price for the price-group, since price-groups are non-mandatory and may not be sent from Excel
      if (utils.isDefined(item[price.SystemName]) && price.SystemName !== 'LicenseeModelNumber') {
        newElement.Prices.push({ PriceGroupId: price.SystemName, Price: item[price.SystemName] })
      }
    })

    // Skip article entry if there is no price group sent
    if (newElement.Prices.length) {
      transformedData.push(newElement)
    }
  })

  requestObj.ArticlePrices = transformedData

  // if there is no priceGroup editable ArticlePrices will be empty array
  if (requestObj.ArticlePrices.length) {
    importArticlesBgFailed.value = ''
    await setPricesByLicenseeModelNumberBgJob(userStore.activeCatalog!.CatalogCode, requestObj).then(async (res) => {
      if (userStore.activeCatalog && res.data) {
        const job = new Job({
          Id: res.data.Id,
          CatalogCode: userStore.activeCatalog.CatalogCode,
          Type: 'setPricesByLicenseeModelNumber',
          Status: res.data.Status,
          UserId: userStore.userProfile.Id,
          CreatedDate: new Date(),
        })
        await appConfig.DB!.jobs.put(job)
        const message = t('admin.articles.messages.setPricesByLicenseeModelNumberJobSuccess')
        notificationStore.addNotification({ message, type: 'Success' })
      }
    })
      .catch((e) => {
        console.error(e)
        importArticlesBgFailed.value = t('general.unexpectedError')
      })
      .finally()
  }
  else {
    const title = t('admin.articles.dialog.noEditablePriceGroups.title')
    const message = t('admin.articles.dialog.noEditablePriceGroups.body')
    await confirmDialog(title, message, [])
  }
}
</script>
