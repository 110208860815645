<template>
  <div
    v-show="isVisible && menuOptions.length" ref="menuRef" class="fixed z-context-menu py-2 bg-white shadow-md min-w-[150px]"
    :style="{ top: `${pos.top}px`, left: `${pos.left}px` }"
  >
    <ul class="list-none">
      <li
        v-for="option in menuOptions" :key="option.key" class="flex items-center px-4 py-2 transition duration-100 delay-0 cursor-pointer hover:bg-[#ecf5ff] hover:text-[#66b1ff] text-neutral-800 [&>button]:text-neutral-800 [&>button]:hover:text-[#66b1ff] [&>button]:transition [&>button]:duration-100 [&>button]:delay-0"
        :class="{ '!text-neutral-400 [&>button]:!text-neutral-400 pointer-events-none': option.disabled }" @click="doClick(option)"
      >
        <tx-button v-if="option.icon && option.icon.length" class="mr-1" type="staticIcon" :faicon="`fa-light ${option.icon}`" icon-size="16px" height="22px" width="22px" />
        <span>{{ option.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'
import { isFunction } from 'lodash-es'
import { onClickOutside } from '@vueuse/core'
import { useEventListener } from '../composables/event'
import TxButton from '@/shared/components/TxButton.vue'

interface IProps {
  closable?: boolean
  clickable?: boolean
  color?: string
  options?: IContextMenuItem[]
}
const props = withDefaults(defineProps<IProps>(), { closable: false, clickable: false, color: '#dbdcdc', options: () => [] as IContextMenuItem[] })
const emit = defineEmits<{
  (e: 'click', option: IContextMenuItem, targetItem?: any): void
  (e: 'close'): void
}>()

useEventListener(document, 'click', onPageClick)

const pos = reactive({
  top: 0,
  left: 0,
})
const isVisible = ref(false)
const menuRef = ref()
const targetItem = ref()

// COMPUTED
const menuOptions = computed(() => props.options.filter(option => option.visible))
// COMPUTED - END

function doClose() {
  isVisible.value = false
  emit('close')
}

function openMenu(event: IContextMenuCoords, evtItem) {
  targetItem.value = evtItem
  let menuLeft = 0
  let menuTop = 0
  const menuWidth = menuRef.value.offsetWidth
  const menuHeight = 45 * menuOptions.value.length

  if ((menuWidth + event.pageX) >= window.innerWidth) {
    menuLeft = window.innerWidth - menuWidth - 1
  }
  else {
    menuLeft = event.pageX - 1
  }
  if ((menuHeight + event.pageY) >= window.innerHeight) {
    menuTop = window.innerHeight - menuHeight - 1
  }
  else {
    menuTop = event.pageY - 1
  }
  pos.left = menuLeft
  pos.top = menuTop

  isVisible.value = true
}

function doClick(option: IContextMenuItem) {
  if (option.disabled) {
    return
  }
  isVisible.value = false

  if (option.onClick && isFunction(option.onClick)) {
    option.onClick(option)
  }
  emit('click', option, targetItem)
}

function onPageClick(e) {
  if (!menuRef.value.contains(e.target)) {
    doClose()
  }
}

onMounted(() => {
  onClickOutside(menuRef.value, doClose)
})

defineExpose({
  openMenu,
  doClose,
})
</script>
