import WbFrame, { FrameSize, frameSizes } from '../frame'
import WbTextBox from '../textBox'
import WbArticleImage from '../articleImage'
import WbArticleDetails from '../articleDetails'
import type { IWhiteboardTemplate, IWhiteboardTemplateFileMapping, IWhiteboardTemplateOption } from './IWhiteboardTemplate'
import type CatalogDetails from '@/models/catalogDetails'
import type CatalogPriceGroup from '@/models/catalogPriceGroup'
import { whiteboardConstants } from '@/models/constants'
import type MyArticle from '@/models/myArticle'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import appConfig from '@/services/appConfig'

class SketchBoard implements IWhiteboardTemplate {
  id: number
  name: string

  constructor() {
    this.id = whiteboardConstants.frameTemplatesId.sketchBoard
    this.name = 'Sketch Board'
  }

  getOptions(catalog: CatalogDetails, myAttributes: Record<string, IMyAttribute>): IWhiteboardTemplateOption[] {
    const attributes: IMyAttribute[] = []
    for (const key in myAttributes) {
      attributes.push(myAttributes[key])
    }

    const options: IWhiteboardTemplateOption[] = []

    options.push({
      name: 'displayAttributes',
      label: 'generateFrameDialog.steps.options.attributesToDisplay',
      type: 'list',
      default: ['ArticleNumber', 'Material_Code_FA_CODE_Levis', 'Merch_Fabric_Type_Levis', 'ColrwayName_Levis', 'ArticleName'],
      required: false,
      clearable: true,
      filterable: true,
      multiple: true,
      data: attributes,
      valueProp: 'SystemName',
      displayProp: 'DisplayName',
    })

    return options
  }

  getFileMapping(): IWhiteboardTemplateFileMapping[] {
    const mapping: IWhiteboardTemplateFileMapping[] = []

    mapping.push({
      name: 'articleNumber',
      column: 'ArticleNumber',
      type: 'articleNumber',
      label: 'generateFrameDialog.steps.mapping.articleNumber',
      required: true,
      autoMap: ['article', 'articlenumber', 'article number', 'artnbr', 'style color code'],
    })

    mapping.push({
      name: 'frameTitle',
      column: 'Frame Title',
      label: 'generateFrameDialog.steps.mapping.groupBy',
      type: 'list',
      required: false,
      autoMap: [],
      multiple: true,
      multipleLimit: 3,
    })

    return mapping
  }

  async generate(catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, articlesData: MyArticle[], options: Record<string, any>, myAttributes: Record<string, IMyAttribute>, myUsername: string, startPosition: IPoint, excelData?: Record<string, Record<string, string[]>>, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup, isExporting?: boolean): Promise<IWbObject[]> {
    const data: { [framesRowTitle: string]: { [frameTitle: string]: { [lifeCycleTitle: string]: { [modelName: string]: MyArticle[] } } } } = {}

    if (utils.isDefined(excelData) || isExporting) {
      // Not implemented
      return []
    }

    // Build Articles Data
    await articlesData.forEach(async (article) => {
      const framesRowTitle = `${utils.ifBlank(article.Class_Product_Hierarchy_Levis, 'No Hierachy')} ${utils.ifBlank(article.Product_Price_Positioning_Levis, 'T?')}`.toUpperCase()
      const frameTitle = `${utils.ifBlank(article.Product_Price_Positioning_Levis, 'T?')} - ${utils.ifBlank(article.Delivery_Selected_Levis, 'D?')} - ${utils.ifBlank(article.Class_Product_Hierarchy_Levis, 'No Product Hierachy')}`.toUpperCase()
      const lifeCycleTitle = utils.ifBlank(article.Product_Lifecycle_Group_Levis, 'No Lifecycle').toUpperCase()
      const modelName = article.ModelName.toUpperCase()

      if (data.hasOwnProperty(framesRowTitle)) {
        if (data[framesRowTitle].hasOwnProperty(frameTitle)) {
          if (data[framesRowTitle][frameTitle].hasOwnProperty(lifeCycleTitle)) {
            if (data[framesRowTitle][frameTitle][lifeCycleTitle].hasOwnProperty(modelName)) {
              data[framesRowTitle][frameTitle][lifeCycleTitle][modelName].push(article)
            }
            else {
              data[framesRowTitle][frameTitle][lifeCycleTitle][modelName] = [article]
            }
          }
          else {
            data[framesRowTitle][frameTitle][lifeCycleTitle] = { [modelName]: [article] }
          }
        }
        else {
          data[framesRowTitle][frameTitle] = { [lifeCycleTitle]: { [modelName]: [article] } }
        }
      }
      else {
        data[framesRowTitle] = { [frameTitle]: { [lifeCycleTitle]: { [modelName]: [article] } } }
      }
      data[framesRowTitle][frameTitle][lifeCycleTitle][modelName].sort((a, b) => a.ArticleLifecycle.localeCompare(b.ArticleNumber))
    })

    // Build Template
    const frameWidth = frameSizes.wide.width
    const frameHeight = frameSizes.wide.height
    const frameHorizontalSpacing = 50
    const frameVerticalSpacing = 100
    const objects: IWbObject[] = []
    let frameLeft = startPosition.x
    let frameTop = startPosition.y
    let ttlSlides = 0

    const frameRows = Object.keys(data).sort((a, b) => a.localeCompare(b))
    for (const framesRowTitle of frameRows) {
      const txt = new WbTextBox(framesRowTitle, { left: frameLeft, top: frameTop, width: frameHeight, height: 50, fontSize: 48, fontFamily: 'Arial', textAlign: 'center' })
      txt.rotate(270)
      txt.set('left', frameLeft - 50)
      txt.set('top', frameTop + frameHeight)
      objects.push(txt)
      frameLeft += 50
      const frameTitles = Object.keys(data[framesRowTitle]).sort((a, b) => a.localeCompare(b))
      for (const frameTitle of frameTitles) {
        const children: string[] = []
        let rowTop = frameTop + 30
        const ttlLifeCycles = Object.keys(data[framesRowTitle][frameTitle]).length
        const lifeCycleTitles = Object.keys(data[framesRowTitle][frameTitle]).sort((a, b) => a.localeCompare(b))
        for (const lifeCycleTitle of lifeCycleTitles) {
          const lifcycleObj = new WbTextBox(lifeCycleTitle, { left: frameLeft + 10, top: rowTop, width: frameWidth - 20, height: 20, fontSize: 18, fontFamily: 'Arial', backgroundColor: '#fff9b1' })
          objects.push(lifcycleObj)
          children.push(lifcycleObj.id)
          const modelLabelWidth = frameWidth / Object.keys(data[framesRowTitle][frameTitle][lifeCycleTitle]).length
          let rowLeft = frameLeft
          const modelNames = Object.keys(data[framesRowTitle][frameTitle][lifeCycleTitle]).sort((a, b) => a.localeCompare(b))
          for (const modelName of modelNames) {
            const txtObj = new WbTextBox(modelName, { left: rowLeft, top: rowTop + 25, width: modelLabelWidth, height: 20, fontSize: 10, fontFamily: 'Arial', textAlign: 'center' })
            objects.push(txtObj)
            children.push(txtObj.id)
            const articles = data[framesRowTitle][frameTitle][lifeCycleTitle][modelName]
            articles.sort((a, b) => a.ArticleLifecycle.localeCompare(b.ArticleNumber))

            const imgSize = Math.min(50, Math.round(modelLabelWidth * 2 / articles.length))
            const imgScale = imgSize * 0.2
            const imgPadding = 10
            const totalItems = articles.length
            const itemsInFirstRow = Math.ceil(totalItems / 2)
            const itemsInSecondRow = totalItems - itemsInFirstRow
            const labelHeight = 50
            const totalWidthFirstRow = itemsInFirstRow * imgSize
            const totalWidthSecondRow = itemsInSecondRow * imgSize

            for (let index = 0; index < articles.length; index++) {
              const article = articles[index]
              const top = rowTop + 50 + (imgSize + labelHeight) * Math.floor(index / itemsInFirstRow)
              const left = rowLeft + (modelLabelWidth - (index >= itemsInFirstRow ? totalWidthSecondRow : totalWidthFirstRow)) / 2 + (index % itemsInFirstRow) * (imgSize + imgPadding)
              const img = await this.createImage(top, left, article, isExporting, imgScale)
              objects.push(img)
              children.push(img.id)
              const articleDetailsOpt = {
                left,
                top: top + imgSize,
                width: imgSize,
                attributes: options.displayAttributes,
                showLabels: false,
                fontFamily: 'Arial',
                fontSize: 4,
                textAlign: 'center',
                lock: false,
                fill: '#000000',
                backgroundColor: article.StateName.toLowerCase() === 'placeholder' ? '#6cd8fa' : 'transparent',
              }
              const artDetails = await WbArticleDetails.loadArticleDetails(article, myAttributes, articleDetailsOpt, !isExporting)
              objects.push(artDetails)
              children.push(artDetails.id)
            }
            rowLeft += modelLabelWidth
          }
          rowTop += frameHeight / ttlLifeCycles
        }

        objects.push(new WbFrame('wide', { children, name: frameTitle, left: frameLeft, top: frameTop, sortOrder: ttlSlides }))

        frameLeft += frameWidth + frameHorizontalSpacing
        ttlSlides++
      }
      frameLeft = startPosition.x
      frameTop += frameHeight + frameVerticalSpacing
    }

    return objects
  }

  async getKeys(frameTitle: Array<any>, article: MyArticle, catalog: CatalogDetails, indexedLinkedCatalogDetails: Record<string, CatalogDetails>, myAttributes: Record<string, IMyAttribute>, myUsername: string, retailPg?: CatalogPriceGroup, wholesalePg?: CatalogPriceGroup, outletPg?: CatalogPriceGroup): Promise<any[]> {
    let keys: any[] = []
    if (myAttributes) {
      for (let index = 0; index < frameTitle.length; index++) {
        const key: any[] = []
        const property = frameTitle[index]
        const emptyValuePlaceHolder = '[Blank]'
        const valueAtPath = article[property]
        if (utils.isDefined(valueAtPath)) {
          if (myAttributes[property] && (myAttributes[property].AttributeType === AttributeType.Date || myAttributes[property].AttributeType === AttributeType.DateOption || myAttributes[property].AttributeType === AttributeType.DateTime)) {
            key.push(valueAtPath.toString().trim() !== '' ? utils.formatDate(valueAtPath) : emptyValuePlaceHolder)
          }
          else if (property.toLowerCase() === 'shipmentstartdate' || property.toLowerCase() === 'shipmentenddate') {
            key.push(valueAtPath.toString().trim() !== '' ? utils.formatDateUTC(valueAtPath) : emptyValuePlaceHolder)
          }
          else if (property.toLowerCase() === '_retailprice') {
            key.push(utils.isDefined(valueAtPath) ? utils.formatPrice(retailPg, Number(valueAtPath), catalog?.Config.ShowPriceThousandsSeparated) : emptyValuePlaceHolder)
          }
          else if (property.toLowerCase() === '_wholesaleprice') {
            key.push(utils.isDefined(valueAtPath) ? utils.formatPrice(wholesalePg, Number(valueAtPath), catalog?.Config.ShowPriceThousandsSeparated) : emptyValuePlaceHolder)
          }
          else if (property.toLowerCase() === '_outletprice') {
            key.push(utils.isDefined(valueAtPath) ? utils.formatPrice(outletPg, Number(valueAtPath), catalog?.Config.ShowPriceThousandsSeparated) : emptyValuePlaceHolder)
          }
          else if (property.toLowerCase() === 'colorid') {
            key.push(utils.isDefined(valueAtPath) ? article.ColorName ? article.ColorName : valueAtPath : emptyValuePlaceHolder)
          }
          else if (myAttributes[property] && myAttributes[property].AttributeType === AttributeType.MultiValue) {
            if (Array.isArray(valueAtPath)) {
              valueAtPath.forEach((value) => {
                key.push(value)
              })
            }
            else {
              key.push(valueAtPath.toString().trim() !== '' ? valueAtPath : emptyValuePlaceHolder)
            }
          }
          else if (property.toLowerCase() === '_favoritetags') {
            article._FavoriteTags.forEach((tagObject) => {
              key.push(tagObject.Tag)
            })
          }
          else if (property.toLowerCase() === '_crds') {
            article._DeliveryDates.forEach((deliveryDate) => {
              if (deliveryDate.Status === 1) {
                // key.push(utils.formatDate(new Date(deliveryDate.CustomerRequiredDate))) //todo where i will find the date
              }
            })
          }
          else if (property.toLowerCase() === '_firstdeliverydate') {
            const date = article.getFirstDeliveryDate(catalog)
            key.push(date && date.toString().trim() !== '' ? utils.formatDate(date) : emptyValuePlaceHolder)
          }
          else if (myAttributes[property] && (myAttributes[property].AttributeType === AttributeType.LinkedCatalogArticleNumber)) {
            const val = await utils.getAttributeTypeSpecificValue(myAttributes[property], article)
            key.push(val.toString() !== '' ? val : emptyValuePlaceHolder)
            // todo need to support ArticleNumber
          }
          else if (myAttributes[property] && (myAttributes[property].AttributeType === AttributeType.ArticleNumber)) {
            const articles = await appConfig.DB!.getMyArticlesById(catalog, indexedLinkedCatalogDetails, myAttributes, myUsername, Number(valueAtPath), retailPg, wholesalePg, outletPg)
            if (articles.length !== 0) {
              key.push(articles[0].ArticleNumber)
            }
            else {
              key.push(emptyValuePlaceHolder)
            }
          }
          else {
            key.push(valueAtPath.toString().trim() !== '' ? valueAtPath : emptyValuePlaceHolder)
          }
        }
        else {
          key.push(emptyValuePlaceHolder)
        }
        if (index > 0) {
          const combined: any[] = []
          for (let i = 0; i < keys.length; i++) {
            for (let l = 0; l < key.length; l++) {
              combined.push(`${keys[i]} > ${key[l]}`)
            }
          }
          keys = combined
        }
        else {
          keys.push(...key)
        }
      }
    }
    return keys
  }

  async createImage(top, left, article, isExporting, imageScaleFactor) {
    const scaleValue = { scale: imageScaleFactor }
    if (isExporting) {
      return await WbArticleImage.getJsonObject(article, 500, 500, scaleValue, { top, left, catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: article._IsRequestArticle })
    }
    else {
      return await WbArticleImage.loadArticleImage(article, 500, 500, { top, left, catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: article._IsRequestArticle }).then((newImg) => {
        newImg.setProp('scale', scaleValue)
        return newImg
      })
    }
  }
}

export default new SketchBoard()
