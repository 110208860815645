<template>
  <div v-bind="$attrs" class="flex flex-col items-start shadow-card rounded-md bg-card text-default w-56 cursor-pointer p-4 m-4 hover:shadow-xl transition-shadow ease-in-out duration-200">
    <div class="rounded-full bg-primary-50 text-on-primary-700 w-12 h-12 flex items-center justify-center font-medium leading-none text-center">
      {{ season[0] }}<br>{{ season[1] }}
    </div>
    <div class="mt-5 text-lg font-medium leading-5 line-clamp-1 overflow-hidden" v-text="catalog.AccountName" />
    <div class="mt text-secondary line-clamp-2 h-[42px]" v-text="catalog.CatalogName" />
    <div class="w-12 h-1 mt-6 border-t-2" />
    <div class="mt-4 text-md text-secondary">
      <use-time-ago v-slot="{ timeAgo }" :time="new Date(catalog.CreatedDate)">
        {{ t('general.created') }}: {{ timeAgo }}
      </use-time-ago>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { UseTimeAgo } from '@vueuse/components'

const props = defineProps<{
  catalog: ICatalog
}>()

const season = computed(() => [props.catalog.Season.substring(0, 2), props.catalog.Season.substring(2).trim()])
const { t } = useI18n()
</script>
