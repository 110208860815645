import { computed } from 'vue'
import utils from '@/services/utils'
import { appConstants } from '@/models/constants'
import { useUserStore } from '@/store/userData'

export default function useFilterableAttributes() {
  const userStore = useUserStore()

  const filterableAttributes = computed(() => {
    const filterableAttributeNames = new Set()
    const res: IMyAttribute[] = []
    for (const attributeSystemName in appConstants.staticAttributes) {
      const staticAttribute = appConstants.staticAttributes[attributeSystemName]
      if (staticAttribute.AllowFiltering && !filterableAttributeNames.has(staticAttribute.SystemName)) {
        res.push(staticAttribute)
        filterableAttributeNames.add(staticAttribute.SystemName)
      }
    }
    const filterableAttributes: IMyAttribute[] = []
    if (userStore.activeCatalog && userStore.activeCatalog.Config.FilterableAttributes.length && utils.isDefined(userStore.myAttributes)) {
      for (const filterableAttribute of userStore.activeCatalog.Config.FilterableAttributes) {
        if (userStore.myAttributes[filterableAttribute] && !filterableAttributeNames.has(filterableAttribute)) {
          filterableAttributes.push(userStore.myAttributes[filterableAttribute])
          filterableAttributeNames.add(filterableAttribute)
        }
      }
    }
    if (userStore.activeCatalog && userStore.myAttributes && userStore.activeCatalog.RequestsEnabled > 0
      && userStore.activeCatalog.RequestAttributeList && userStore.activeCatalog.RequestAttributeList.length) {
      userStore.activeCatalog.RequestAttributeList.forEach((requestAttribute) => {
        if (requestAttribute.Status > 0 && utils.isDefined(userStore.myAttributes![requestAttribute.AttributeSystemName])
          && !filterableAttributeNames.has(requestAttribute.AttributeSystemName)) {
          filterableAttributes.push(userStore.myAttributes![requestAttribute.AttributeSystemName]!)
          filterableAttributeNames.add(requestAttribute.AttributeSystemName)
        }
      })
    }
    if (filterableAttributes.length) {
      res.push(...filterableAttributes.sort((a, b) => a.DisplayName.toLowerCase() > b.DisplayName.toLowerCase() ? 1 : a.DisplayName.toLowerCase() < b.DisplayName.toLowerCase() ? -1 : 0))
    }
    return res
  })

  const filterableAttributesByAttributeSystemName = computed(() => filterableAttributes.value.reduce((acc, cur) => {
    acc[cur.SystemName] = cur
    return acc
  }, {} as Record<string, IMyAttribute>))

  return {
    filterableAttributes,
    filterableAttributesByAttributeSystemName,
  }
}
