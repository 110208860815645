import type { Instance, Placement } from '@popperjs/core'
import { createPopper } from '@popperjs/core'
import { onClickOutside } from '@vueuse/core'
import type { Ref } from 'vue'
import { onMounted, onUnmounted } from 'vue'

export function usePopper(popperEl: HTMLElement, referenceEl: HTMLElement, showOn: 'always' | 'click' | 'hover', placement?: Placement) {
  const showEvents: string[] = []
  const hideEvents: string[] = []
  let popper: Instance

  onMounted(() => {
    if (!(popperEl instanceof HTMLElement)) {
      console.error('usePopper: not a valid popper element')
      return
    }

    if (!(referenceEl instanceof HTMLElement)) {
      console.error('usePopper: not a valid reference element')
      return
    }

    popper = createPopper(referenceEl, popperEl, {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [20, 5],
          },
        },
      ],
    })

    switch (showOn) {
      case 'always':
        show()
        break
      case 'click':
        showEvents.push('click')
        break
      case 'hover':
        showEvents.push('mouseenter', 'focus')
        hideEvents.push('mouseleave', 'blur')
        break
      default:
        break
    }

    showEvents.forEach((event) => {
      referenceEl.addEventListener(event, show)
    })

    hideEvents.forEach((event) => {
      referenceEl.addEventListener(event, hide)
    })
  })

  onUnmounted(() => {
    showEvents.forEach((event) => {
      referenceEl.removeEventListener(event, show)
    })

    hideEvents.forEach((event) => {
      referenceEl.removeEventListener(event, hide)
    })

    popper?.destroy()
  })

  function show() {
    popperEl.style.display = 'block'
  }
  function hide() {
    popperEl.style.display = 'none'
  }

  if (showOn === 'click') {
    onClickOutside(popperEl, () => {
      hide()
    })
  }
}

export function useDynamicPopper(popperEl: Ref<HTMLElement | null | undefined>, placement?: Placement, offset?: number[]) {
  let popper: Instance | null = null
  // const virtualEl: VirtualElement = { getBoundingClientRect: () => new DOMRect() }

  const modifiers: any[] = []
  if (offset) {
    modifiers.push({ name: 'offset', options: { offset } })
  }
  modifiers.push({ name: 'hide' })

  onMounted(() => {
    if (!(popperEl.value instanceof HTMLElement)) {
      console.error('usePopper: not a valid popper element')
    }
  })

  onUnmounted(() => {
    popper?.destroy()
  })

  const show = (el: HTMLElement) => {
    if (popper) {
      popper.destroy()
      popper = null
    }

    if (popperEl.value) {
      popper = createPopper(el, popperEl.value, {
        placement,
        modifiers,
      })

      popper.state.elements.reference = el
      popper.update()

      if (popperEl.value) {
        popperEl.value.style.display = 'block'
      }
    }
  }

  const hide = () => {
    if (popper) {
      popper.destroy()
      popper = null
    }
    if (popperEl.value) {
      popperEl.value.style.display = 'none'
    }
  }

  return { show, hide }
}
