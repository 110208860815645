import { isFunction } from 'lodash-es'
import type { Directive } from 'vue'

export default {
  created(el, binding, _vnode) {
    let isVisible = false

    if (!isFunction(binding.value?.fct) && !isFunction(binding.value)) {
      console.warn('onVisible expects a function as a parameter')
      return
    }

    const ob = new IntersectionObserver((entries, _observer) => {
      entries.forEach((e) => {
        if (e.target === el) {
          const elementVisible = e.isIntersecting

          if (elementVisible !== isVisible) {
            isVisible = elementVisible
            const fct = binding.value.fct || binding.value
            const once = binding.value.once
            fct(isVisible)
            if (once) { ob.unobserve(el) }
          }
        }
      })
    }, { root: binding.value?.target, threshold: 0 })

    ob.observe(el);

    (el as any).ob = ob
  },
  unmounted(el, _binding, _vnode) {
    const target = el as any
    if (target.ob) {
      target.ob.disconnect()
    }
  },
} as Directive<HTMLElement>
