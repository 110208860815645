import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import { pick } from 'lodash-es'
import { merchConstants } from '@/models/constants'

interface IMbTextBoxOptions extends fabric.ITextboxOptions {
  id?: string
  locked?: boolean
  isOriginTopLeft?: boolean
  templateObject?: boolean
  link?: string
}

const editableProps: Record<string, IMbObjectProp> = {
  fill: { name: 'text color', type: 'textColor' },
  backgroundColor: { name: 'backgroundColor color', type: 'color' },
  font: { name: 'font', type: 'font' },
  fontSize: { name: 'font size', type: 'fontSize' },
  textAlign: { name: 'align', type: 'textAlign' },
  fontStyle: { name: 'font style', type: 'fontStyle' },
  // fontWeight: { name: 'font style', type: 'fontWeight' },
  locked: { name: 'lock', type: 'lock' },
  // link: { name: 'link', type: 'text' },
  // { prop: 'link' , editable: true, type: 'text' },
  // 'addComment': { name: 'add comment', type: 'addComment' },
}

export default class MbTextBox extends fabric.Textbox implements IMbObject {
  public id: string
  public type = merchConstants.objectTypes.textBox
  public locked: boolean
  public isOriginTopLeft: boolean
  public templateObject: boolean
  public link: string

  public editableProps: Record<string, IMbObjectProp> = editableProps

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    copy: { action: 'copy', label: 'Copy Selection', faicon: 'fa-light fa-copy', showInSubMenu: true },
    align: { action: 'align', label: 'Align', faicon: 'fa-light fa-align-justify', showInSubMenu: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  constructor(text: string, options?: IMbTextBoxOptions) {
    // TODO : if default font and default settings implemnted need to update the default font here
    super(text, options)
    // for link no need to change the font as per the default settings
    this.fill = options?.fill ? options.fill : '#000000'
    this.fontFamily = options?.fontFamily ? options?.fontFamily : 'Times New Roman'
    this.fontSize = options?.fontSize ? options?.fontSize : 15
    this.id = options?.id || guid()
    this.selectable = true
    this.editable = true

    this.locked = options?.locked || false
    this.templateObject = options?.templateObject != null ? options?.templateObject : false
    this.link = options?.link || ''
    this.setLock(this.locked)
    this.originX = 'left'
    this.originY = 'top'
    if ((options?.hasOwnProperty('top') || options?.hasOwnProperty('left')) && !options?.isOriginTopLeft) {
      this.migrateObjectTopLeft(options)
    }
    this.isOriginTopLeft = true
    this.stateProperties = this.stateProperties?.concat(['locked'])
  }

  // migrate or update the top left of objects that had originX and originY set to center to make the originX to left and originY to top
  migrateObjectTopLeft(options) {
    // temporary fix only for slide ua templates with angle -90 TODO: find the center coordinate and use the above equation
    if (options.hasOwnProperty('angle') && options.angle === -90) {
      if (options.hasOwnProperty('top')) {
        options.top = options.top + (options.width / 2)
        this.set('top', options.top)
      }
      if (options.hasOwnProperty('left')) {
        options.left = options.left - (options.height / 4)
        this.set('left', options.left)
      }
    }
    else {
      if (options.hasOwnProperty('top')) {
        options.top = options.top - (options.height / 2)
        this.set('top', options.top)
      }
      if (options.hasOwnProperty('left')) {
        options.left = options.left - (options.width / 2)
        this.set('left', options.left)
      }
    }
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'fill':
        this.set('fill', value.fill)
        break
      case 'backgroundColor':
        this.set('backgroundColor', value.backgroundColor)
        break
      case 'font':
        this.set('fontFamily', value.font)
        break
      case 'fontSize':
        this.set('fontSize', value.fontSize)
        break
      case 'textAlign':
        this.set('textAlign', value.textAlign)
        break
      case 'bold':
        this.set('fontWeight', value.bold ? 'bold' : '')
        break
      case 'italic':
        this.set('fontStyle', value.italic ? 'italic' : '')
        break
      case 'text':
        this.set('text', value.text)
        break
      case 'locked':
        this.set('locked', value.locked)
        this.setLock(value.locked)
        break
      default:
        console.warn('Attempting to set unsupported MbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'fill':
        result.fill = this.fill
        break
      case 'font':
        result.font = this.fontFamily
        break
      case 'fontSize':
        result.fontSize = this.fontSize
        break
      case 'textAlign':
        result.textAlign = this.textAlign
        break
      case 'bold':
        result.bold = !!this.fontWeight
        break
      case 'italic':
        result.italic = !!this.fontStyle
        break
      case 'text':
        result.text = this.text
        break
      case 'locked':
        result.lock = this.locked
        break
      case 'backgroundColor':
        result.backgroundColor = this.backgroundColor
        break
      default:
        console.warn('Attempting to get unsupported MbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override onKeyDown(e: Event): void {
    e.stopPropagation()
  }

  override toObject() {
    const propsToPluck = [
      'id',
      'type',
      'top',
      'left',
      'width',
      'height',
      'scaleX',
      'scaleY',
      'angle',
      'flipX',
      'flipY',
      'text',
      'fill',
      'backgroundColor',
      'fontFamily',
      'fontSize',
      'fontStyle',
      'fontWeight',
      'textAlign',
      'locked',
      'templateObject',
      'isOriginTopLeft',
      'link',
    ]
    return pick(this, propsToPluck)
  }

  static fromObject(object: fabric.Object, callback?: Function) {
    return fabric.Object._fromObject(merchConstants.objectTypes.textBox, object, callback, 'text') as MbTextBox
  }

  static getEditableProps() {
    return editableProps
  }
}

const f: any = fabric
f.MbTextBox = MbTextBox
