<template>
  <div class="container flex flex-col w-full h-full px-2 pt-4">
    <div class="header" />
    <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    <div class="relative flex-1 w-full mb-2 overflow-y-auto body">
      <loader v-if="loading" />
      <div v-else class="w-full">
        <table class="w-full text-center">
          <thead class="border-b bg-default">
            <tr>
              <th v-if="showForecastFor" scope="col" class="py-2 text-sm font-semibold" v-text="t('updateArticleForecast.forecastFor')" />
              <th scope="col" class="py-2 text-sm font-semibold" v-text="t('updateArticleForecast.SQ')" />
              <th scope="col" class="py-2 text-sm font-semibold" v-text="t('updateArticleForecast.BQ')" />
            </tr>
          </thead>
          <tbody>
            <template v-if="isForecastAvailable">
              <tr v-for="(forecast, key) in forecasts" :key="key" class="bg-white border-b">
                <td v-if="showForecastFor" class="py-2 text-sm font-light whitespace-nowrap" v-text="forecast.ForecastFor" />
                <td class="py-2 text-sm font-light whitespace-nowrap">
                  <tx-input v-if="!readonly && userStore.userProfile.AccountDetails.AccountTypeId !== 3" v-model="forecast.SQ.editValue" type="number" />
                  <span v-else v-text="forecast.SQ.value" />
                </td>
                <td class="py-2 text-sm font-light whitespace-nowrap">
                  <tx-input v-if="!readonly && userStore.userProfile.AccountDetails.AccountTypeId === 3" v-model="forecast.BQ.editValue" type="number" />
                  <span v-else v-text="forecast.BQ.value" />
                </td>
              </tr>
            </template>
            <tr v-else class="bg-white border-b">
              <td :colspan="showForecastFor ? 3 : 2" v-text="t('general.emptyList')" />
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- FOOTER -->
    <tx-form-footer
      v-if="!readonly" class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="t('general.update')" :primary-loading="updating" :primary-disabled="!isForecastAvailable" :secondary-disabled="updating"
      @primary-click="onUpdate" @secondary-click="onCancel"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onMounted, ref, watch } from 'vue'
import Loader from '@/shared/components/Loader.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import type MyArticle from '@/models/myArticle'
import type { UpdateArticleForecastQuantityModel } from '@/api/t1/model/articleModel'
import { getArticleForecasts, updateArticleForecastQuantity } from '@/api/t1/article'
import { privileges } from '@/models/constants'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'

interface IProps {
  article: MyArticle
  readonly?: boolean
}

const props = withDefaults(defineProps<IProps>(), { readonly: false })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[]): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()

const loading = ref(false)
const updating = ref(false)
const showForecastFor = ref(false)

interface IForecast {
  ForecastFor: string
  SQ: { value: string | number, editValue: number }
  BQ: { value: string | number, editValue: number }
  CustomerId: number
  LocationId: number | null
}
const forecasts = ref<Record<string, IForecast>>({})

const isForecastAvailable = computed(() => Object.keys(forecasts.value).length > 0)

function loadForecasts() {
  if (utils.isDefined(props.article) && userStore.userProfile.isValidPrivilege(privileges.article.getForecasts)) {
    const customerId = userStore.currentCustomer ? userStore.currentCustomer.CustomerId : -1
    loading.value = true
    getArticleForecasts(props.article.CatalogCode, customerId, props.article.Id)
      .then((res) => {
        showForecastFor.value = (customerId === -1)
        res.data.sort((a, b) => {
          const aCustomerNameLower = utils.isDefined(a.CustomerName) ? a.CustomerName.toLowerCase() : ''
          const bCustomerNameLower = utils.isDefined(b.CustomerName) ? b.CustomerName.toLowerCase() : ''
          const customerNameCompare = aCustomerNameLower.localeCompare(bCustomerNameLower)
          if (customerNameCompare === 0) {
            const aLocationNameLower = utils.isDefined(a.LocationName) ? a.LocationName.toLowerCase() : ''
            const bLocationNameLower = utils.isDefined(b.LocationName) ? b.LocationName.toLowerCase() : ''
            return aLocationNameLower.localeCompare(bLocationNameLower)
          }
          return customerNameCompare
        }).forEach((forecast) => {
          if (utils.isDefined(forecast.LocationId) && !showForecastFor.value) {
            showForecastFor.value = true
          }
          let forecastForValue = ''
          if (customerId === -1) {
            forecastForValue = forecast.CustomerName + (utils.isDefined(forecast.LocationId) ? ` - ${forecast.LocationName}` : '')
          }
          else {
            forecastForValue = utils.isDefined(forecast.LocationId) ? `${forecast.LocationName}` : ''
          }
          if (!utils.isDefined(forecasts.value[forecastForValue])) {
            forecasts.value[forecastForValue] = {
              ForecastFor: forecastForValue,
              SQ: { value: '-', editValue: 0 },
              BQ: { value: '-', editValue: 0 },
              CustomerId: forecast.CustomerId,
              LocationId: forecast.LocationId,
            } as IForecast
          }
          if (forecast.TypeId === 2 && forecast.Quantity) {
            forecasts.value[forecastForValue].SQ.value = forecast.Quantity
            forecasts.value[forecastForValue].SQ.editValue = forecast.Quantity
          }
          else if (forecast.TypeId === 3) {
            forecasts.value[forecastForValue].BQ.value = forecast.Quantity
            forecasts.value[forecastForValue].BQ.editValue = forecast.Quantity
          }
        })
      })
      .catch(e => console.error(e))
      .finally(() => loading.value = false)
  }
}

function onCancel() {
  emit('cancel')
}

function onUpdate() {
  if (userStore.userProfile && isForecastAvailable.value) {
    updating.value = true
    errorMessage.value = ''
    const requestObj: UpdateArticleForecastQuantityModel[] = []
    let invalidQuantity = false
    for (const key in forecasts.value) {
      const forecast = forecasts.value[key]
      const forecastCell = userStore.userProfile.AccountDetails.AccountTypeId !== 3 ? forecast.SQ : forecast.BQ
      if (utils.isDefined(forecastCell.editValue) && forecastCell.editValue < 0) {
        invalidQuantity = true
        break
      }
      if (forecastCell.value?.toString() !== forecastCell.editValue?.toString()) {
        requestObj.push({
          CustomerId: forecast.CustomerId,
          LocationId: forecast.LocationId,
          Quantity: forecastCell.editValue,
        })
      }
    }

    if (invalidQuantity) {
      errorMessage.value = t('updateArticleForecast.forecastHasInvalidQuantity')
      updating.value = false
    }
    else {
      updateArticleForecastQuantity(props.article.CatalogCode, props.article.Id, requestObj)
        .then((res) => {
          if (res.status === 200) {
            emit('updated', [props.article])
          }
          else {
            errorMessage.value = t('general.unableToUpdate')
          }
        })
        .catch((e) => {
          console.error(e)
          errorMessage.value = t('general.unexpectedError')
        })
        .finally(() => updating.value = false)
    }
  }
}

function reset() {
  loading.value = false
  updating.value = false
  errorMessage.value = ''
  showForecastFor.value = false
  forecasts.value = {}
  loadForecasts()
}

watch(() => props.article, reset)

onMounted(() => reset())
</script>
