import type { CreateUpdateFavoriteModel, FavoriteItemModel, UpdateFavoritesStatusModel } from './model/favoriteModel'
import Net from '@/services/net'

export function createUpdateFavorite(catalogCode: number, requestObject: CreateUpdateFavoriteModel) {
  return Net.t1.put<FavoriteItemModel>(`/catalogs/${catalogCode}/favorites/createupdate`, requestObject)
}

export function updateFavoritesStatus(catalogCode: number, requestObject: UpdateFavoritesStatusModel[]) {
  return Net.t1.put(`/catalogs/${catalogCode}/favorites/status`, requestObject)
}
