<template>
  <div class="flex flex-col overflow-hidden">
    <div class="flex-grow overflow-hidden">
      <double-splitter-content id="merch">
        <template #leftAside>
          <div class="flex flex-col w-full overflow-hidden">
            <div class="h-16 min-h-[64px] text-center border-b bg-gray-50 flex flex-col items-center">
              <tx-tabs v-model="activeTab" :tabs="tabs" class="m-auto" />
            </div>
            <my-slide-list v-show="activeTab === 'mySlides'" :active-tab="activeTab" :merch="merch" :show-generate-dialog="showGenerateDialog" :show-generate-dialog-via-browse="showGenerateDialogViaBrowse" @slide-tree="setSlideTree" @node-click="onSlideClick" @share-slides="shareSlideAction" @clear-active-slide="clearActiveSlide" />
            <shared-slide-list v-show="activeTab === 'sharedSlides'" :active-tab="activeTab" :merch="merch" @node-click="onSharedSlideClick" @refresh-tree="onSharedSlideTreeRefresh" @clear-shared-active-slide="clearActiveSlide" @duplicate-slides="duplicateSharedSlides" />
          </div>
        </template>
        <div class="flex flex-col h-full relative">
          <input v-show="false" ref="fileInputRef" type="file" accept="image/png, image/jpg, image/jpeg" @change="onFileChange">
          <merch-toolbar id="toolbar" class="toolbar" :merch-loading="merchLoading" :active-slide="activeSlide" :active-tab="activeTab" :allow-save="isDirty" :allow-undo="allowUndo" :allow-redo="allowRedo" :favorites-visible="showFavorites" @button-click="onToolbarButtonClick" />
          <div v-show="activeSlide" ref="refContent" v-resize="onResizeContainer" class="relative flex-1" :style="{ overflow: zoomedToFit ? 'hidden' : 'auto' }">
            <!-- content -->
            <div ref="refCanvasContainerMerch" class="flex-1 p-2 m-auto mb-[30px] outline-none">
              <!-- canvas-container -->
              <div v-show="showZoomPanel" class="absolute right-6 flex flex-row z-10 bg-white border-2 w-[200px] mt-4 p-2">
                <span class="p-2 w-[100px]"> {{ zoomPercentage.toFixed(0) }}% </span>
                <span class="p-1 align-middle cursor-pointer fill-black" @click="zoomOutCanvas">
                  <font-awesome-icon class="w-4 h-4 m-1 cursor-pointer hover:text-primary-500" icon="fa-light fa-minus" />
                </span>
                <span class="p-1 align-middle cursor-pointer fill-black" @click="zoomInCanvas">
                  <font-awesome-icon class="w-4 h-4 m-1 cursor-pointer hover:text-primary-500" icon="fa-light fa-plus" />
                </span>
                <div class="p-2 cursor-pointer border-2 rounded-lg text-primary-500" @click="resetZoom" v-text="t('general.reset')" />
              </div>
              <div class="m-1">
                <!-- canvas class -->
                <canvas id="can" class="shadow-xl" />
              </div>
              <object-properties
                :merch="merch" @show-align-dialog="showAlignDialog = true" @update-articles="openUpdateDrawer($event, ActionBarActionType.updateArticles)"
                @update-article-segmentation="openUpdateDrawer($event, ActionBarActionType.updateArticleSegmentation)" @update-retail-window="openUpdateDrawer($event, ActionBarActionType.updateRetailWindow)"
                @edit-favorites="openUpdateDrawer($event, ActionBarActionType.editFav)"
              />
              <!-- Article Details -->
              <tx-drawer
                v-model="articleDetailsOpen" :width="enableNewStyleDetails ? '95%' : '1024px'" right
                @closed="closeArticleDetails"
              >
                <model-details
                  v-if="enableNewStyleDetails && clickedArticle" :article="clickedArticle" @on-edit-fav="onArticleDetailsEditFav" @change-article-selection="onChangeArticle"
                  @updated="onArticleUpdated" @change="onArticleClick" @close="closeArticleDetails"
                />
                <article-details
                  v-else-if="clickedArticle" :article="clickedArticle"
                  @updated="onArticleUpdated" @change="onArticleClick"
                />
              </tx-drawer>
              <tx-drawer v-model="isUpdateArticleDrawerVisible" width="700px" right @closed="closeUpdateDrawer">
                <update-article
                  v-if="updateActionType === ActionBarActionType.updateArticles" :articles="selectedArticlesForUpdate"
                  :is-model="false" @cancel="closeUpdateDrawer" @updated="onArticlesUpdated"
                />
                <update-article-segmentation
                  v-else-if="updateActionType === ActionBarActionType.updateArticleSegmentation"
                  :articles="selectedArticlesForUpdate" :is-model="false" @cancel="closeUpdateDrawer" @updated="onArticlesUpdated"
                />
                <update-article-retail-window
                  v-else-if="updateActionType === ActionBarActionType.updateRetailWindow"
                  :articles="selectedArticlesForUpdate" :is-model="false"
                  @cancel="closeUpdateDrawer" @updated="onArticlesUpdated"
                />
              </tx-drawer>
            </div>
          </div>
        </div>
        <template #rightAside>
          <div class="flex flex-col w-full overflow-hidden">
            <div class="overflow-hidden flex-grow pointer-events-auto">
              <articles-finder :browse-by-model="false" :allow-selection-article="false" :columns="2" :visible="true" :click-to-select-article="false" @article-click="addNewArticle" />
            </div>
          </div>
        </template>
      </double-splitter-content>
    </div>
    <tx-dialog v-model="addArticlesDialogVisible" :title="t('merch.addArticles')" show-ok-cancel :loading="loadingInsertArticles" @ok="onAddArticlesDialog">
      <tx-input v-model.trim="addArticlesModelValue" type="textarea" :label="t('merch.enterArticleNumber')" clearable />
    </tx-dialog>
    <tx-dialog v-model="showAlignDialog" :title="t('merch.toolbars.actions.align')" width="300px">
      <div>
        <div class="flex flex-wrap flex-row">
          <div class="w-[45px] text-center m-2.5" @click="align('alignLeft')">
            <font-awesome-icon class="w-6 h-6" icon="fa-light fa-align-left" />
            <span v-text="t('merch.toolbars.actions.alignLeft')" />
          </div>
          <div class="w-[45px] text-center m-2.5" @click="align('alignCenter')">
            <font-awesome-icon class="w-6 h-6" icon="fa-light fa-align-center" />
            <span v-text="t('merch.toolbars.actions.alignCenter')" />
          </div>
          <div class="w-[45px] text-center m-2.5" @click="align('alignRight')">
            <font-awesome-icon class="w-6 h-6" icon="fa-light fa-align-right" />
            <span v-text="t('merch.toolbars.actions.alignRight')" />
          </div>
          <div class="w-[45px] text-center m-2.5" @click="align('alignTop')">
            <font-awesome-icon class="w-6 h-6" icon="fa-light fa-objects-align-top" />
            <span v-text="t('merch.toolbars.actions.alignTop')" />
          </div>
          <div class="w-[45px] text-center m-2.5" @click="align('alignMiddle')">
            <font-awesome-icon class="w-6 h-6" icon="fa-light fa-reflect-horizontal" />
            <span v-text="t('merch.toolbars.actions.alignMiddle')" />
          </div>
          <div class="w-[45px] text-center m-2.5" @click="align('alignBottom')">
            <font-awesome-icon class="w-6 h-6" icon="fa-light fa-objects-align-bottom" />
            <span v-text="t('merch.toolbars.actions.alignBottom')" />
          </div>
        </div>
        <div class="mt-[5px] border-t-[#f3f3f3] border-t border-solid pl-[15px]">
          <div class="flex w-[200px] m-2.5" @click="align('distributeH')">
            <font-awesome-icon class="w-6 h-6" icon="fa-light fa-distribute-spacing-horizontal" />
            <span class="leading-6 pl-[5px]" v-text="t('merch.toolbars.actions.distributeH')" />
          </div>
          <div class="flex w-[200px] m-2.5" @click="align('distributeV')">
            <font-awesome-icon class="w-6 h-6" icon="fa-light fa-distribute-spacing-vertical" />
            <span class="leading-6 pl-[5px]" v-text="t('merch.toolbars.actions.distributeV')" />
          </div>
        </div>
        <div class="mt-[5px] border-t-[#f3f3f3] border-t border-solid pl-[15px]">
          <div class="text-[11px] font-semibold text-[#686868] pl-2.5 pt-2.5 pb-0" v-text="t('merch.toolbars.actions.acrossSlide')" />
          <div class="flex w-[200px] m-2.5" @click="align('distributeHaS')">
            <font-awesome-icon class="w-6 h-6" icon="fa-light fa-square-sliders" />
            <span class="leading-6 pl-[5px]" v-text="t('merch.toolbars.actions.distributeH')" />
          </div>
          <div class="flex w-[200px] m-2.5" @click="align('distributeVaS')">
            <font-awesome-icon class="w-6 h-6" icon="fa-light fa-square-sliders-vertical" />
            <span class="leading-6 pl-[5px]" v-text="t('merch.toolbars.actions.distributeV')" />
          </div>
        </div>
      </div>
    </tx-dialog>
    <tx-dialog v-model="dirtyCanvasDialogVisible" :title="t('merch.dialog.dirtyCanvas.title')" show-ok-cancel-discard confirm-text="general.save" @ok="onMerchLeave('ok')" @cancel="onMerchLeave('cancel')" @discard="onMerchLeave('discard')">
      <div class="" v-text="t('merch.dialog.dirtyCanvas.body')" />
    </tx-dialog>
    <tx-dialog
      v-if="showDuplicateSlideDialog" v-model="showDuplicateSlideDialog" :show-ok-cancel="true" :title="t('merch.dialog.duplicateSlide.title')" confirm-text="general.ok" width="450px" height="250px"
      :loading="showDuplicateSlideLoading" :ok-state="folderForDuplicateSlide !== undefined ? 'enabled' : 'disabled'" @ok="duplicateSlides" @cancel="showDuplicateSlideDialog = false"
    >
      <parent-folder-editor v-model="slideTree[0].key" :slide-tree="slideTree" :merch="merch" @update="updateFolderForDuplicateSlide" />
    </tx-dialog>
    <favorites-editor-dialog ref="favoritesMerchDlg" />
    <!-- warning for sharing slides -->
    <tx-dialog
      v-model="ShowShareSlidesWarning" :title="t('general.alert')"
      show-ok-cancel @click="ShowShareSlidesWarning = false" @ok="shareSlides"
    >
      <div class="text-xl" v-text="t('merch.dialog.sharedSlides.shareWarning')" />
    </tx-dialog>
    <!-- Share Slide Dialog -->
    <share-slides-dialog ref="refShareSlideDialog" :selected-node-id="slideIdSelectedForSharing" :merch="merch" />
    <!-- modify Slides Dialog -->
    <modify-slides-dialog ref="refModifySlidesDialog" :merch="merch" />
    <!-- Find & Replace Dialog -->
    <find-and-replace-dialog ref="refFindAndReplaceDialog" :merch="merch" @find-node-click="onSlideClickFindText" @find-next-searched-text-on-slide="findNextSearchedTextOnSlide" @replace-all="replaceAll" />
    <status-bar />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, ref, shallowRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import ArticleDetails from '../articleDetails/ArticleDetails.vue'
import UpdateArticleSegmentation from '../articleDetails/components/UpdateArticleSegmentation.vue'
import ObjectProperties from './components/ObjectProperties.vue'
import MerchToolbar from './components/MerchToolbar.vue'
import MbArticleImage from './services/articleImage'
import useWindowEvents from './composables/windowEvents'
import { findParentPath, searchNodeInTree } from '@/modules/merch/utils'
import ShareSlidesDialog from '@/modules/merch/components/ShareSlidesDialog.vue'
import ParentFolderEditor from '@/modules/merch/components/ParentFolderEditor.vue'
import DoubleSplitterContent from '@/shared/components/DoubleSplitterContent.vue'
import ArticlesFinder from '@/shared/components/ArticlesFinder.vue'
import FindAndReplaceDialog from '@/modules/merch/components/FindAndReplaceDialog.vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxInput from '@/shared/components/TxInput.vue'
import Merch from '@/modules/merch/services/merch'
import { useUserStore } from '@/store/userData'
import type MerchSlide from '@/modules/merch/services/merchSlide'
import TxTabs from '@/shared/components/TxTabs.vue'
import MySlideList from '@/modules/merch/components/MySlideList.vue'
import SharedSlideList from '@/modules/merch/components/SharedSlideList.vue'
import StatusBar from '@/shared/components/StatusBar.vue'
import { useNotificationStore } from '@/store/notification'
import utils, { CancelToken } from '@/services/utils'
import { imageConstants, merchConstants } from '@/models/constants'
import type MyArticle from '@/models/myArticle'
import useArticleDetails from '@/shared/composables/articleDetails'
import appConfig from '@/services/appConfig'
import TxDrawer from '@/shared/components/TxDrawer.vue'
import ModelDetails from '@/modules/articleDetails/ModelDetails.vue'
import * as sBar from '@/store/status'
import UpdateArticle from '@/modules/articleDetails/components/UpdateArticle.vue'
import type Article from '@/models/article'
import UpdateArticleRetailWindow from '@/shared/components/UpdateArticleRetailWindow.vue'
import FavoritesEditorDialog from '@/shared/components/favorites/FavoritesEditorDialog.vue'
import ModifySlidesDialog from '@/modules/merch/components/ModifySlidesDialog.vue'
import { useBrowseStore } from '@/store/browse'

type MerchSlidesTabs = 'mySlides' | 'sharedSlides'

enum ActionBarActionType {
  updateArticles = 'updateArticles',
  updateArticleSegmentation = 'updateArticleSegmentation',
  updateRetailWindow = 'updateRetailWindow',
  editFav = 'editFav',
}

const userStore = useUserStore()
const notificationStore = useNotificationStore()
const browseStore = useBrowseStore()
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { articleDetailsOpen, clickedArticle, onArticleUpdated, onArticleClick, openArticleDetails, onChangeArticle, closeArticleDetails } = useArticleDetails('Merch')

const merch = shallowRef<Merch>()
const fileInputRef = ref<HTMLInputElement | null>()
const addArticlesDialogVisible = ref(false)
const showAlignDialog = ref(false)
const addArticlesModelValue = ref('')
const refCanvasContainerMerch = ref<HTMLElement>()
const activeSlide = ref<MerchSlide>()
const isDirty = ref(false)
const abortedRouteName = ref('')
const refContent = ref<HTMLElement>()
const zoomedToFit = ref(true)
const showZoomPanel = ref(false)
const zoomPercentage = ref(100)
const zoomTimer = ref(1)
const isLoading = ref(false)
const dirtyCanvasDialogVisible = ref(false)
const isUpdateArticleDrawerVisible = ref<boolean>(false)
const updateActionType = ref<ActionBarActionType>()
const selectedArticlesForUpdate = ref<MyArticle[]>([])
let requireZoomTimer: number = 0
const activeTab = ref<MerchSlidesTabs>('mySlides')
const favoritesMerchDlg = ref<InstanceType<typeof FavoritesEditorDialog>>()
const loadingInsertArticles = ref(false)
const showGenerateDialog = ref(false)
const showDuplicateSlideDialog = ref(false)
const showDuplicateSlideLoading = ref(false)
const slidesToDuplicate = ref<ITreeNode[]>([])
const slideTree = shallowRef<ITreeNode[]>([])
const sharedSlidesLoaded = ref(false)
const slideIdSelectedForSharing = ref('')
const refShareSlideDialog = ref<InstanceType<typeof ShareSlidesDialog>>()
const refModifySlidesDialog = ref<InstanceType<typeof ModifySlidesDialog>>()
const ShowShareSlidesWarning = ref(false)
const previousActiveTab = ref<MerchSlidesTabs>('mySlides')
const merchLoading = ref(false)
const showGenerateDialogViaBrowse = ref(false)
const tabs: ITabItem<MerchSlidesTabs>[] = [
  { key: 'mySlides', label: 'merch.mySlides', visible: true },
  { key: 'sharedSlides', label: 'merch.sharedSlides', visible: true },
]
const folderForDuplicateSlide = ref<ITreeNode | undefined>(undefined)
const refFindAndReplaceDialog = ref<InstanceType<typeof FindAndReplaceDialog>>()

useWindowEvents(merch, activeSlide)

watch(() => zoomTimer.value, (value) => {
  // wait for 3 seconds, after that zoom panel disappears
  if (value === 3) {
    clearInterval(requireZoomTimer)
    requireZoomTimer = 0
    showZoomPanel.value = false
  }
})
watch(() => [route.params, route.query.t], gotoRouteSelection)

const enableNewStyleDetails = computed(() => userStore.activeCatalog?.Config.EnableNewStyleDetails || false)

const allowUndo = computed(() => {
  return merch.value && activeSlide.value && merch.value.history.value.length > merch.value.historyPointer.value
})

const allowRedo = computed(() => {
  return merch.value && activeSlide.value && merch.value.historyPointer.value > 0
})

const showFavorites = computed(() => {
  return merch.value && activeSlide.value && merch.value.showFavorites.value
})

onBeforeRouteLeave((to, from, next) => {
  if (isDirty.value) {
    next(false)
    dirtyCanvasDialogVisible.value = true
    abortedRouteName.value = to.name?.toString() || ''
  }
  else {
    next()
  }
})

onMounted(async () => {
  merchLoading.value = true
  showGenerateDialogViaBrowse.value = false
  sBar.setItems([
    { id: 'ttlSlides', label: t('status.totalSlides'), value: '0', icon: '' },
  ])
  if (refCanvasContainerMerch.value) {
    merch.value = new Merch('can')
    await merch.value.loadMySlides()
    initMerchEvents()
    merch.value.canvas.on('mouse:dblclick', handleDoubleClick)
    gotoRouteSelection()
    // slides generated from browse module
    if (utils.isDefined(browseStore.dataObjectForMerch) && ((utils.isDefined(browseStore.dataObjectForMerch.articles) && browseStore.dataObjectForMerch.articles.length) || browseStore.dataObjectForMerch.isBrowseByAttribute)) {
      merchLoading.value = false
      showGenerateDialogViaBrowse.value = true
    }
  }
  merchLoading.value = false
})

onUnmounted(() => {
  if (merch.value) {
    merch.value.off('slide-active', onSlideActive)
    merch.value.canvas.off('mouse:dblclick', handleDoubleClick)
    if (merch.value.canvas) {
      merch.value.canvas.off()
      merch.value.canvas.clear()
    }
  }
  merch.value = undefined
})
watch(() => activeTab.value, (value) => {
  // wait for 3 seconds, after that zoom panel disappears
  if (merch.value && value === 'sharedSlides' && !sharedSlidesLoaded.value) {
    merch.value.loadSharedSlides()
    sharedSlidesLoaded.value = true
  }
  if (previousActiveTab.value !== value && merch.value) {
    if (previousActiveTab.value === 'mySlides' && isDirty.value && activeSlide.value && activeSlide.value.isDirty) {
      merch.value.cacheActiveSlide()
    }
    clearActiveSlide()
  }
  previousActiveTab.value = value
})
function initMerchEvents() {
  if (merch.value) {
    merch.value.on('zoom', handleCanvasZoomEvent)
    merch.value.on('slide-active', onSlideActive)
    //   this.merch.events.on('slideObjects:deleted', this.onSlideObjectDeleted)
    merch.value.on('merch-dirty', onDirtyChanged)
  }
}
function onSlideActive(data) {
  activeSlide.value = data.target
  nextTick(() => {
    if (zoomedToFit.value) {
      resetZoom()
    }
  })
}
function onDirtyChanged(val) {
  isDirty.value = val.value
}
function setSlideTree(tree) {
  slideTree.value = tree
}
function onMerchLeave(btn: string) {
  if (merch.value && userStore.activeCatalog) {
    if (btn === 'ok') {
      isLoading.value = true
      merch.value.save(userStore.activeCatalog.CatalogCode, userStore.currentCustomer ? userStore.currentCustomer.CustomerId : null).then((response) => {
        if (response.failedSlides.length !== 0 || response.deletedSlides.length !== 0) {
          notificationStore.addNotification({ message: t('merch.errors.saveFailed'), type: 'Alert' })
        }
        if (response.deletedSlides.length === 0 && response.failedSlides.length === 0) {
          notificationStore.addNotification({ message: t('merch.messages.slidesSaved'), type: 'Success' })
        }
      }).catch((error) => {
        notificationStore.addNotification({ message: t('merch.errors.saveFailed'), type: 'Alert', actions: ['Support', 'ShowDetails'], details: utils.getErrorMessage(error) })
      }).then(() => {
        if (utils.isDefined(abortedRouteName.value) && abortedRouteName.value!) {
          router.push({ name: abortedRouteName.value })
        }
        isLoading.value = false
      })
    }
    else if (btn === 'discard') {
      isDirty.value = false
      if (utils.isDefined(abortedRouteName.value) && abortedRouteName.value!) {
        router.push({ name: abortedRouteName.value })
      }
    }
    else if (btn === 'cancel') {
      dirtyCanvasDialogVisible.value = false
    }
  }
}
async function onSlideClick(slideId: string | number) {
  if (merch.value && userStore.activeCatalog && (activeSlide.value || activeSlide.value !== slideId)) {
    // this.setMerchLoading(true)
    // this.removeMask()
    await merch.value.setActiveSlide(slideId)
      .catch((e) => {
        console.error(e)
        notificationStore.addNotification({ message: t('merch.errors.slideContentInvalid'), type: 'Alert' })
        activeSlide.value = undefined
      })
      .then(() => {
        //   //set hotspot visibility on the slide change
        //   this.merch.hotspotObjectsSelectable = false
        //   this.merch.hotspotsOnCanvas()
        //   this.setMerchLoading(false)
      })
  }
}
function updateFolderForDuplicateSlide(folder, parentNode: ITreeNode | undefined) {
  folderForDuplicateSlide.value = parentNode
}
function clearActiveSlide() {
  if (merch.value) {
    merch.value.clearActiveSlide()
    activeSlide.value = undefined
  }
}
async function onSharedSlideClick(slideId: string | number, refreshSlide = false) {
  if (merch.value && userStore.activeCatalog && (!activeSlide.value || activeSlide.value.SlideId !== slideId || refreshSlide)) {
    // this.setMerchLoading(true)
    // this.removeMask()
    await merch.value.setSharedActiveSlide(slideId)
      .catch((e) => {
        console.error(e)
        notificationStore.addNotification({ message: t('merch.errors.slideContentInvalid'), type: 'Alert' })
        activeSlide.value = undefined
      })
      .then(() => {
        //   //set hotspot visibility on the slide change
        //   this.merch.hotspotObjectsSelectable = false
        //   this.merch.hotspotsOnCanvas()
        //   this.setMerchLoading(false)
      })
  }
}
function onSharedSlideTreeRefresh() {
  if (merch.value) {
    merch.value.loadSharedSlides()
  }
}
function shareSlideAction(nodeId: string) {
  slideIdSelectedForSharing.value = nodeId
  ShowShareSlidesWarning.value = true
}
function shareSlides() {
  ShowShareSlidesWarning.value = false
  refShareSlideDialog.value?.open()
}
async function duplicateSlides() {
  showDuplicateSlideLoading.value = true
  // iterate over all slides and copy to new folder
  if (merch.value && slidesToDuplicate.value.length !== 0 && folderForDuplicateSlide.value) {
    const slideIds = slidesToDuplicate.value.map(node => node.key) as string[]
    let duplicatedSlidesCount = slideIds.length
    const slidesData = await merch.value.getSlidesData(slideIds, true)
    slidesToDuplicate.value.forEach(async (slideNode) => {
      await utils.delay(200)
      if (slidesData && slidesData[slideNode.key] && folderForDuplicateSlide.value) {
        const merchSlide = slidesData[slideNode.key]
        if (typeof merchSlide === 'object') {
          const folderStructure = findParentPath(folderForDuplicateSlide.value)
          const folderStructureForDuplicatedSlide = findParentPath(slideNode, true)
          let newFolderId = folderStructure.id
          let newFolderName = folderStructure.name
          const duplicatedSlideFolderNamePath = folderStructureForDuplicatedSlide.name.split('|')
          const duplicatedSlideFolderIdPath = folderStructureForDuplicatedSlide.id.split('|')
          const currentDestinationFolderNode = searchNodeInTree(slideTree.value, folderForDuplicateSlide.value.key, 'key', true)
          let currentNodeChildren = currentDestinationFolderNode !== undefined ? currentDestinationFolderNode.children : []
          let verify = true
          for (let i = 0; i < duplicatedSlideFolderNamePath.length; i++) {
            const node = !verify ? undefined : currentNodeChildren.find(node => node.label === duplicatedSlideFolderNamePath[i])
            if (node === undefined) {
              newFolderName += merchConstants.folderPathSeparator + duplicatedSlideFolderNamePath[i]
              newFolderId += merchConstants.folderPathSeparator + duplicatedSlideFolderIdPath[i]
              verify = false
            }
            else {
              if (verify) {
                newFolderName += merchConstants.folderPathSeparator + node.label
                newFolderId += merchConstants.folderPathSeparator + node.key
                currentNodeChildren = node.children
              }
            }
          }

          await merch.value!.addSlide(newFolderId, newFolderName, folderForDuplicateSlide.value.sortOrder, merchSlide.SlideName, merchSlide?.objects || [], merchSlide.SlideSize, merchSlide.ImageSize, merchSlide.SortOrder)
        }
        else {
          duplicatedSlidesCount--
          console.error(slidesData[slideNode.key])
          notificationStore.addNotification({ message: t('merch.errors.slideContentInvalid'), type: 'Alert' })
        }
      }
    })
    if (duplicatedSlidesCount === slideIds.length) {
      notificationStore.addNotification({ message: t('merch.messages.allSlidesDuplicated'), type: 'Success' })
    }
    else if (duplicatedSlidesCount === 0) {
      notificationStore.addNotification({ message: t('merch.errors.duplicateFailed'), type: 'Alert' })
    }
    else {
      notificationStore.addNotification({ message: t('merch.messages.someSlidesDuplicated'), type: 'Success' })
    }
  }
  showDuplicateSlideLoading.value = false
  showDuplicateSlideDialog.value = false
}
function align(action: string) {
  if (!merch.value) { return }
  showAlignDialog.value = false
  merch.value.alignSelected(action)
}
function onToolbarButtonClick(btn: IToolbarButton<MerchToolbarAction>) {
  if (!merch.value) { return }
  if (btn.key === 'save') {
    if (isDirty.value && userStore.activeCatalog) {
      isLoading.value = true
      merch.value.save(userStore.activeCatalog.CatalogCode, userStore.currentCustomer ? userStore.currentCustomer.CustomerId : null).then((response) => {
        if (response.failedSlides.length !== 0 || response.deletedSlides.length !== 0) {
          notificationStore.addNotification({ message: t('merch.errors.saveFailed'), type: 'Alert' })
        }
        if (response.deletedSlides.length === 0 && response.failedSlides.length === 0) {
          notificationStore.addNotification({ message: t('merch.messages.slidesSaved'), type: 'Success' })
        }
      }).catch((error) => {
        notificationStore.addNotification({ message: t('merch.messages.saveFailed'), type: 'Alert', actions: ['Support', 'ShowDetails'], details: utils.getErrorMessage(error) })
      }).then(() => {
        isLoading.value = false
      })
    }
  }
  else if (btn.key === 'article') {
    addArticlesModelValue.value = ''
    addArticlesDialogVisible.value = true
  }
  else if (btn.key === 'image') {
    fileInputRef.value?.click()
  }
  else if (btn.key === 'generate') {
    showGenerateDialog.value = !showGenerateDialog.value
  }
  else if (btn.key === 'undo') {
    merch.value.undo()
  }
  else if (btn.key === 'redo') {
    merch.value.redo()
  }
  else if (btn.key === 'paste') {
    merch.value.paste()
  }
  else if (btn.key === 'share') {
    ShowShareSlidesWarning.value = true
  }
  else if (btn.key === 'manageTemplate') {
    merch.value.makeTemplateObjectsSelectable(!merch.value.templateObjectsSelectable)
  }
  else if (btn.key === 'showFavorites') {
    merch.value.showFavoritesOnSlide()
  }
  else if (btn.key === 'modify') {
    refModifySlidesDialog.value?.open()
  }
  else if (btn.key === 'find') {
    refFindAndReplaceDialog.value?.open()
  }
  else {
    merch.value.insertObjects(btn.key)
  }
}
async function onSlideClickFindText(selectedNode: ITreeNode, findWhat: string) {
  if (merch.value && userStore.activeCatalog && (!activeSlide.value || activeSlide.value.SlideId !== selectedNode.key)) {
    await merch.value.setActiveSlide(selectedNode.key)
      .catch((e) => {
        console.error(e)
        notificationStore.addNotification({ message: t('merch.errors.slideContentInvalid'), type: 'Alert' })
        activeSlide.value = undefined
      })
      .then(() => {
        merch.value?.highlightCanvasObjectsSearchText(findWhat)
        //  //set hotspot visibility on the slide change
        //   this.merch.hotspotObjectsSelectable = false
        //   this.merch.hotspotsOnCanvas()
        //   this.setMerchLoading(false)
      })
  }
  else {
    merch.value?.highlightCanvasObjectsSearchText(findWhat)
  }
}
async function findNextSearchedTextOnSlide(slideId: string, findWhat: string, replaceWith: string, isReplaceText: boolean = false) {
  if (merch.value && userStore.activeCatalog && (!activeSlide.value || activeSlide.value.SlideId !== slideId)) {
    await merch.value.setActiveSlide(slideId)
      .catch((e) => {
        console.error(e)
        notificationStore.addNotification({ message: t('merch.errors.slideContentInvalid'), type: 'Alert' })
        activeSlide.value = undefined
      })
      .then(() => {
        if (isReplaceText) {
          merch.value?.replaceText(findWhat, replaceWith, slideId.toString()).then(() => {
            notificationStore.addNotification({ message: t('merch.dialog.findAndReplace.replacedText'), type: 'Success' })
          })
        }
        else {
          merch.value?.selectCanvasObjectsSearchText(findWhat)
        }
      })
  }
  else {
    if (isReplaceText) {
      merch.value?.replaceText(findWhat, replaceWith, slideId.toString()).then(() => {
        notificationStore.addNotification({ message: t('merch.dialog.findAndReplace.replacedText'), type: 'Success' })
      })
    }
    else {
      merch.value?.selectCanvasObjectsSearchText(findWhat)
    }
  }
}
function replaceAll(selectedNodes: MerchSlide[], findWhat: string, replaceWith?: string) {
  merch.value?.replaceTextInSelectedSlides(selectedNodes, findWhat, replaceWith)
  if (utils.isDefined(selectedNodes.find(slide => activeSlide.value && (slide.SlideId === activeSlide.value.SlideId)))) {
    merch.value?.reRenderCurrentSlides().then(() => {
      notificationStore.addNotification({ message: t('merch.dialog.findAndReplace.replacedAll'), type: 'Success' })
    })
      .catch((error) => {
        console.log(new Error(`Unable to load slide objects\n ${error}`))
      })
  }
  else {
    notificationStore.addNotification({ message: t('merch.dialog.findAndReplace.replacedAll'), type: 'Success' })
  }
}
async function onAddArticlesDialog() {
  loadingInsertArticles.value = true
  if (utils.isValidStringValue(addArticlesModelValue.value) && userStore.activeCatalog && merch.value && userStore.activeCatalog) {
    const articleNumbers = addArticlesModelValue.value.split('\n').map(val => val.trim())
    if (articleNumbers.length) {
      const indexedRequestArticleNumberToArticle = await generateRequestArticleIndexes()
      const catalogArticleNumbers: string[] = []
      const requestArticles: Article[] = []
      let catalogArticles: MyArticle[] = []
      const requestArticlesNumbers: string[] = []
      const invalidArticleAndRequestNumbers: string[] = []
      let validCatalogArticleNumbers: any[] = []
      articleNumbers.forEach((articleNumber) => {
        if (indexedRequestArticleNumberToArticle[articleNumber]) {
          if (!requestArticlesNumbers.includes(articleNumber)) {
            requestArticlesNumbers.push(articleNumber)
            requestArticles.push(indexedRequestArticleNumberToArticle[articleNumber])
          }
        }
        else {
          catalogArticleNumbers.push(articleNumber)
        }
      })

      if (catalogArticleNumbers.length !== 0) {
        catalogArticles = await appConfig.DB!.getMyArticlesByArticleNumbers(userStore.activeCatalog, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, catalogArticleNumbers, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, userStore.currentCustomer, userStore.currentCustomerSegmentations)
        if (catalogArticles.length) {
          validCatalogArticleNumbers = catalogArticles.map(article => article.ArticleNumber)
        }
      }
      articleNumbers.forEach((articleNumber) => {
        if (!indexedRequestArticleNumberToArticle[articleNumber] && !validCatalogArticleNumbers.includes(articleNumber)) {
          invalidArticleAndRequestNumbers.push(articleNumber)
        }
      })
      if (invalidArticleAndRequestNumbers.length) { // show toast message
        notificationStore.addNotification(({ message: t('merch.errors.invalidArticlesAndRequests', { invalidArticles: invalidArticleAndRequestNumbers.join(',') }), type: 'Alert' }))
      }
      if (requestArticles.length || catalogArticles.length) {
        await merch.value!.addArticleImageToCanvas(catalogArticles, requestArticles)
      }
    }
  }
  loadingInsertArticles.value = false
  addArticlesDialogVisible.value = false
}
function onArticleDetailsEditFav(article: MyArticle) {
  favoritesMerchDlg.value?.showDialog([article])
}
async function generateRequestArticleIndexes() {
  const indexedRequestArticleNumberToId: Record<string, Article > = {}
  if (userStore.activeCatalog) {
    const requests = await appConfig.DB!.requests.where({ CatalogCode: userStore.activeCatalog.CatalogCode, Status: 1, IsCreateArticleRequest: 1 }).toArray()
    for (const request of requests) {
      if (request.Content) {
        const requestArticle = await utils.getRequestArticle(userStore.activeCatalog!, request, appConfig.DB)
        indexedRequestArticleNumberToId[requestArticle.ArticleNumber] = requestArticle
      }
    }
  }
  return indexedRequestArticleNumberToId
}
function onFileChange() {
  if (!fileInputRef.value || !fileInputRef.value.files || !fileInputRef.value.files[0] || !merch.value) { return }

  const file = fileInputRef.value.files[0]
  if (imageConstants.validImageFormats.has(file.type) && file.size <= imageConstants.maxImageSize) {
    const reader = new FileReader()
    reader.onload = function (e) {
      const image = new Image()
      image.src = (e.target!.result as string)
      image.onload = function () {
        merch.value!.insertObjects('image', image)
      }
    }
    reader.readAsDataURL(file)
    fileInputRef.value.value = ''
  }
}
async function addNewArticle(article: MyArticle) {
  if (article && merch.value) {
    if (article.CatalogCode === userStore.activeCatalog?.CatalogCode) {
      await merch.value.addArticleImageToCanvas([article])
    }
    else {
      notificationStore.addNotification({ message: t('merch.messages.notAllowedToLinkedCatalogArticle'), type: 'Alert' })
    }
  }
}
function handleDoubleClick(event) {
  const obj = merch.value?.canvas.getActiveObject() as IMbObject
  let showArticleDetails = false
  let articleId = -1
  let isRequest = false
  if (obj && (obj.type === merchConstants.objectTypes.articleImage)) {
    showArticleDetails = true
    if (obj instanceof MbArticleImage) {
      articleId = obj.articleId
      isRequest = obj.isRequest
    }
  }
  else if (obj && (obj.type === merchConstants.objectTypes.group)) {
    const allObjects = event.target._objects
    const articleImages = allObjects.filter(o => o.type === 'articleImage')
    // if there is one article image, show article details
    if (articleImages && articleImages.length === 1) {
      if (articleImages[0] instanceof MbArticleImage) {
        articleId = articleImages[0].articleId
      }
      showArticleDetails = true
    }
  }
  if (userStore.activeCatalog && showArticleDetails && articleId !== -1) {
    if (isRequest) {
      appConfig.DB!.getRequestArticleById(userStore.activeCatalog, articleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
        .then((article) => {
          if (article) {
            onArticleClick(article)
          }
        })
    }
    else {
      appConfig.DB!.getMyArticlesById(userStore.activeCatalog, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, articleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
        .then(([article]) => onArticleClick(article))
    }
  }
}

async function gotoRouteSelection() {
  if (!['Merch', 'MerchArticleDetails'].includes(route.name!.toString())) { return }
  if (userStore.activeCatalog && route.params && route.params.articleId) {
    await openArticleDetails()
  }
  else if (articleDetailsOpen.value) {
    closeArticleDetails()
  }
}

// Article update Actions
async function openUpdateDrawer(objects: IMbObject[], actionType: ActionBarActionType) {
  updateActionType.value = actionType
  selectedArticlesForUpdate.value = []
  const validArticleIds: number[] = []
  objects.forEach((object) => {
    if ((object.type === merchConstants.objectTypes.articleImage || object.type === merchConstants.objectTypes.articleDetails) && object.articleId) {
      if (!validArticleIds.includes(object.articleId)) {
        validArticleIds.push(object.articleId)
      }
    }
  })
  if (userStore && userStore.activeCatalog) {
    const catalogDetails = userStore.activeCatalog
    const res = await appConfig.DB!.getMyArticles(catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, validArticleIds, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, true)
    if (res && !(res instanceof CancelToken) && res.length > 0) {
      selectedArticlesForUpdate.value = res
    }
    else {
      console.warn('Failed to load article details by Id', res)
    }
  }
  if (actionType === ActionBarActionType.editFav) {
    favoritesMerchDlg.value?.showDialog(selectedArticlesForUpdate.value)
  }
  else {
    isUpdateArticleDrawerVisible.value = true
  }
}

function closeUpdateDrawer() {
  updateActionType.value = undefined
  isUpdateArticleDrawerVisible.value = false
}
function onArticlesUpdated(articles: MyArticle[] | Article[]) {
  let message = t('general.updatedSuccessfully')
  if (updateActionType.value === ActionBarActionType.updateArticles) {
    message = t('updateArticle.updateSuccessfully', articles.length)
  }
  else if (updateActionType.value === ActionBarActionType.updateArticleSegmentation) {
    message = t('editSegmentations.updatedSuccessfully')
  }
  else if (updateActionType.value === ActionBarActionType.updateRetailWindow) {
    message = t('updateArticleRetailWindow.saveSuccessfully')
  }
  notificationStore.addNotification({ message, type: 'Success' })
  closeUpdateDrawer()
}

function duplicateSharedSlides(slideNodes: ITreeNode[]) {
  slidesToDuplicate.value = slideNodes
  showDuplicateSlideDialog.value = true
}

function onResizeContainer() {
  if (zoomedToFit.value) {
    resetZoom()
  }
}

// zoom functions
function handleCanvasZoomEvent(zoomEvent) {
  handleCanvasZoomed(zoomEvent.factor)
}
// Returns the zoom percentage that will fit the available space
function getZoomToFit() {
  if (merch.value && refContent.value) {
    const canvasW = merch.value.canvasWidth
    const canvasH = merch.value.canvasHeight
    const availableW = refContent.value?.clientWidth - 30 // 20 = Additional padding/margin of canvas
    const availableH = refContent.value?.clientHeight - 35 // 26 = Additional padding/margin of canvas
    const zoomW = availableW / canvasW
    const zoomH = availableH / canvasH
    return Math.min(zoomW, zoomH)
  }
}
function resetZoom() {
  const zoom = getZoomToFit()
  handleCanvasZoomed(zoom)
}
function zoomOutCanvas() {
  if (merch.value) {
    const zoom = merch.value?.canvas.getZoom() - 0.1
    handleCanvasZoomed(zoom, true)
  }
}
function zoomInCanvas() {
  if (merch.value) {
    const zoom = merch.value?.canvas.getZoom() + 0.1
    handleCanvasZoomed(zoom, true)
  }
}
function handleCanvasZoomed(zoom, explicitZoom = false) {
  showZoomPanel.value = true
  zoomTimer.value = 1
  zoomTimerIntervalCountdown()
  merch.value?.canvas.setZoom(zoom)
  merch.value?.canvas.setWidth(merch.value?.canvasWidth * zoom)
  merch.value?.canvas.setHeight(merch.value?.canvasHeight * zoom)
  zoomPercentage.value = Math.round(zoom * 100)
  //   this.setZoomStatusBar(this.zoomPercentage)
  const zoomUp = getZoomToFit()
  console.log('zoom', zoom, 'zoomToFit', getZoomToFit(), 'diff', Math.abs(zoom - (zoomUp || 0)))
  zoomedToFit.value = explicitZoom ? false : (Math.abs(zoom - (zoomUp || 0)) < 0.098)
}
function zoomTimerIntervalCountdown() {
  if (!requireZoomTimer) {
    requireZoomTimer = setInterval(() => {
      zoomTimer.value++
    }, 1000)
  }
}
</script>
