import { computed } from 'vue'
import { useUserStore } from '@/store/userData'

export default function useDisableModule() {
  const userStore = useUserStore()

  const lowerDisableModuleSet = computed(() => {
    const result: Set<string> = new Set()
    if (userStore.activeCatalog && userStore.activeCatalog.Config.DisableModules.length) {
      userStore.activeCatalog.Config.DisableModules.forEach(module => result.add(module.trim().toLowerCase()))
    }
    return result
  })

  const isResourcesModuleEnabled = computed(() => !lowerDisableModuleSet.value.has('resources'))
  const isOrderModuleEnabled = computed(() => !lowerDisableModuleSet.value.has('order'))
  const isForecastModuleEnabled = computed(() => !lowerDisableModuleSet.value.has('forecast'))

  return {
    isResourcesModuleEnabled,
    isOrderModuleEnabled,
    isForecastModuleEnabled,
  }
}
