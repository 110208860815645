<template>
  <div
    v-on-visible="{ fct: doVisible, once: true }"
    class="box-content relative overflow-hidden transition-shadow duration-200 ease-in-out bg-white border border-solid rounded-md cursor-pointer group shadow-card"
    :class="[{ 'border-primary-500': selected, 'hover:shadow-xl': !browseByStore.isLoadingBucketAttributesValue }, `t-${size}`]" :style="thumbnailStyle"
  >
    <template v-if="browseByStore.isLoadingBucketAttributesValue">
      <loader style="z-index: 1801;" />
      <div class="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-white opacity-50 z-dialog-overlay" />
    </template>
    <div class="h-full" @click="doClick">
      <div class="absolute top-0 left-0 z-20 easy-check" @click.stop="doSelectionChanged" />
      <div
        v-if="allowSelection && !browseByStore.isLoadingBucketAttributesValue"
        class="box-border z-10 w-3 h-3 bg-white border rounded-sm border-dark-50 group-hover:block check"
        :class="[selectionMode || selected ? 'block' : 'hidden']"
      >
        <font-awesome-icon v-show="selected" class="w-4 h-4 -ml-0.5 -mt-1 text-primary-500" icon="fa-light fa-check" />
      </div>

      <div v-if="customTemplate == null" class="flex flex-col flex-wrap h-full">
        <span class="mt-[12px] ml-7 text-neutral-500 text-xs">{{ bucket.propertyDisplayValue }}</span>
        <div class="flex flex-col flex-grow">
          <span class="mt-auto mb-auto text-lg font-semibold leading-6 text-center whitespace-pre-wrap text-neutral-900">{{ bucket.value }}</span>
          <div v-if="indexedBucketAttributesValue != null" class="mx-[10px]">
            <div v-for="(bucketAttribute, index) in Object.values(indexedBucketAttributesValue!).slice(0, 5)" :key="index" class="flex text-xs flex-nowrap">
              <span class="flex-grow-0 flex-shrink-0 basis-auto min-w-[40%] max-w-[70%] whitespace-nowrap overflow-hidden text-ellipsis text-gray-icon">{{ bucketAttribute.displayLabel }}</span>
              <span class="ml-[10px] min-w-[40%] max-w-[70%] whitespace-nowrap overflow-hidden text-ellipsis text-dark">{{ bucketAttribute.value }}</span>
            </div>
          </div>
        </div>
        <div class="justify-self-end min-h-[50px] bg-neutral-100 grid grid-cols-2 grid-rows-2 py-2 px-4">
          <span>{{ t('general.SKUs') }}</span>
          <span>{{ t('general.models') }}</span>
          <span>{{ bucket.catalogArticleIdList.length }}</span>
          <span>{{ bucket.modelsCount }}</span>
        </div>
      </div>

      <div v-else class="h-full overflow-hidden">
        <div
          v-for="(cell, index) in customTemplate" :key="index" class="inline-block custom-template" :style="{
            'width': `${cell.w}px`,
            'height': `${cell.h}px`,
            'background-color': cell.bg,
            'margin': cell.margin,
            'padding': cell.padding,
            'max-height': cell.maxHeight || cell.value === '$articleImage' ? '85px' : '70px',
            'overflow': cell.overflow || 'hidden',
            'border-radius': cell.borderRadius,
          }" :class="[cell.style, size === 3 ? 'large' : size === 2 ? 'medium' : 'small']"
        >
          <div v-if="cell.label">
            {{ cell.label }}
          </div>
          <div v-if="cell.value === '$attributeDisplayName'">
            {{ bucket.propertyDisplayValue }}
          </div>
          <div v-else-if="cell.value === '$attributeValue'">
            {{ bucket.value }}
          </div>
          <!-- following style is to be able to support old config and get same result + new config that allows resize of thumbnail and take image size from config as is -->
          <div
            v-else-if="cell.value === '$articleImage'" :data-img="imageURL" :style="{
              'height': `${Math.max(configTemplateT1SW != null ? Math.max(cell.w || 0, cell.h || 0) : getImageSize)}px`,
              'max-height': configTemplateT1SW != null ? 'none' : '85px',
            }"
          >
            <div
              v-if="!visible || imageURL === '' || errorLoadingImg"
              class="m-auto text-dark-25"
              :class="{ 'text-dark-25': !visible || imageURL === '', 'text-red-500': errorLoadingImg }"
              :style="{ width: 'auto', height: '100%', aspectRatio: 1, objectFit: 'contain' }"
              v-html="svgNoImage"
            />
            <img
              v-else :src="imageURL" class="m-auto"
              :style="{ width: 'auto', height: '100%', aspectRatio: 1, objectFit: 'contain' }"
              @error="errorLoadingImg = true"
            >
          </div>
          <div v-else-if="cell.value === '$bucketAttribute' && cell.attr && indexedBucketAttributesValue != null && indexedBucketAttributesValue.hasOwnProperty(cell.attr)">
            {{ indexedBucketAttributesValue[cell.attr] != null ? indexedBucketAttributesValue[cell.attr].value : '' }}
          </div>
          <div v-else-if="cell.value === '$sku'">
            {{ bucket.catalogArticleIdList.length }}
          </div>
          <div v-else-if="cell.value === '$styles'">
            {{ bucket.modelsCount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts' setup>
import { computed, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import Loader from '@/shared/components/Loader.vue'
import type MyArticle from '@/models/myArticle'
import appConfig from '@/services/appConfig'
import svgNoImage from '@/assets/svg/no-image.svg?raw'
import utils from '@/services/utils'
import { formattedBucketAttributeValues, getCurrentBucketKey } from '@/services/browseByFactory'
import type { ICatalogBucketAttributeParsed } from '@/models/catalogBucketAttribute'
import { useBrowseByStore } from '@/store/browseBy'
import { useUserStore } from '@/store/userData'

interface IProps {
  articles: Array<MyArticle>
  articlesIndexes: Record<string, number>
  allowSelection?: boolean
  bucket: IAttributeBucket
  isPreviewForm?: boolean
  selected?: boolean
  selectionMode?: boolean
  size?: number
  styleCreateAction?: StyleCreateActionType
  visibleBucketAttributes: Array<ICatalogBucketAttributeParsed>
  visibleCalcTypeBucketAttributes: Array<ICatalogBucketAttributeParsed>
}

const props = withDefaults(defineProps<IProps>(), {
  allowSelection: true,
  selected: false,
  size: 3,
  selectionMode: false,
})

const emit = defineEmits<{
  (e: 'click', ev: MouseEvent): void
  (e: 'update:selected', value: boolean): void
  (e: 'selectionChanged', value: boolean): void
}>()

const userStore = useUserStore()
const browseByStore = useBrowseByStore()
const { t } = useI18n()
const visible = ref(false)
const errorLoadingImg = ref(false)
const indexedBucketAttributesValue = ref<Record<string, { displayLabel: string, value: string | number, typeId: number }> | null>(null)
const indexedCalcTypeBucketAttributesValue: Record<string, { displayLabel: string, value: string | number, typeId: number }> = reactive({})

function doClick(ev: MouseEvent) {
  if (props.selectionMode) {
    doSelectionChanged()
  }
  else {
    emit('click', ev)
  }
}

function doSelectionChanged() {
  const newVal = !props.selected
  emit('update:selected', newVal)
  emit('selectionChanged', newVal)
}

function doVisible() {
  visible.value = true
}

const getImageSize = computed(() => {
  switch (props.size) {
    case 3:
      return 85
    case 2:
      return 70
    default:
      return 40
  }
})

const configTemplateT1SW = computed(() => {
  let configTemplateT1SW = userStore.activeCatalog?.Config.BrowseByAttributeThumbnailDetailsT1SW ? userStore.activeCatalog?.Config.BrowseByAttributeThumbnailDetailsT1SW._Default : null
  if (userStore.activeCatalog?.Config.BrowseByAttributeThumbnailDetailsT1SW != null) {
    configTemplateT1SW = userStore.activeCatalog?.Config.BrowseByAttributeThumbnailDetailsT1SW._Default || null
    for (const key in userStore.activeCatalog?.Config.BrowseByAttributeThumbnailDetailsT1SW) {
      const properties = key.split(',')
      if (properties.includes(props.bucket.propertySystemName)) {
        configTemplateT1SW = userStore.activeCatalog?.Config.BrowseByAttributeThumbnailDetailsT1SW[key]
        break
      }
    }
  }
  return configTemplateT1SW
})

const configTemplate = computed(() => {
  let configTemplate = userStore.activeCatalog?.Config.BrowseByAttributeThumbnailDetails._Default || undefined
  for (const key in userStore.activeCatalog?.Config.BrowseByAttributeThumbnailDetails) {
    const properties = key.split(',')
    if (properties.includes(props.bucket.propertySystemName)) {
      configTemplate = userStore.activeCatalog?.Config.BrowseByAttributeThumbnailDetails[key]
      break
    }
  }
  return configTemplate
})

const customTemplate = computed(() => {
  if (configTemplateT1SW.value != null) {
    return props.size === 3 ? configTemplateT1SW.value.large : props.size === 2 ? configTemplateT1SW.value.medium : configTemplateT1SW.value.small
  }
  return configTemplate.value
})

const thumbnailStyle = computed(() => {
  const styles = { backgroundColor: '' }
  const thumbnailColorConfig = userStore.activeCatalog!.Config.BrowseByAttributeThumbnailColor
  if (utils.isDefined(thumbnailColorConfig) && utils.isDefined(indexedBucketAttributesValue.value) && indexedBucketAttributesValue.value.hasOwnProperty(thumbnailColorConfig.attribute)
    && utils.isDefined(indexedBucketAttributesValue.value[thumbnailColorConfig.attribute].value)) {
    Object.keys(thumbnailColorConfig.conditions).forEach((key) => {
      if (indexedBucketAttributesValue.value![thumbnailColorConfig.attribute].value.toString().toLowerCase() === key.toLowerCase()) {
        styles.backgroundColor = thumbnailColorConfig.conditions[key]
      }
    })
  }
  return styles
})

const imageURL = computed(() => {
  let imgURL = ''
  if (props.bucket.thumbnailDetails) {
    const catalog = props.bucket.thumbnailDetails.catalogCode === userStore.activeCatalog?.CatalogCode ? userStore.activeCatalog : userStore.linkedCatalogDetails[props.bucket.thumbnailDetails.catalogCode]
    if (utils.isValidStringValue(catalog?.ContextKey) && utils.isValidStringValue(catalog?.DuneContext)) {
      const params = new URLSearchParams()
      params.set('Context', catalog.DuneContext)
      params.set('ContextKey', catalog.ContextKey)
      params.set('ImageSet', props.bucket.thumbnailDetails.articleNumber)
      params.set('w', '150')
      params.set('h', '150')
      params.set('trim', 'false')
      params.set('f', 'webp')
      if (userStore.activeCatalog && userStore.activeCatalog.Config.NewestImageAssetKeyList.length) {
        params.set('Key', userStore.activeCatalog.Config.NewestImageAssetKeyList.toString())
      }
      imgURL = `${appConfig.AssetsUrl}/assets/r?${params.toString()}`
    }
  }
  return imgURL
})

init()

function init() {
  initCalcTypeBucketAttributes()

  // when come back from details view if browseByStore.indexedBucketAttributesValue is not null, re assign the values
  if (browseByStore.indexedBucketAttributesValue != null) {
    initBucketAttributesValue()
  }
}

function initCalcTypeBucketAttributes() {
  props.visibleCalcTypeBucketAttributes.forEach((attribute) => {
    const bucketAttributesValue = formattedBucketAttributeValues({}, attribute, props.bucket.catalogArticleIdList, props.articles, props.articlesIndexes)
    indexedCalcTypeBucketAttributesValue[attribute.AttributeSystemName] = {
      displayLabel: attribute.AttributeDisplayName,
      value: bucketAttributesValue,
      typeId: attribute.AttributeTypeId,
    }
  })
}

function initBucketAttributesValue() {
  indexedBucketAttributesValue.value = {}
  Object.assign(indexedBucketAttributesValue.value, indexedCalcTypeBucketAttributesValue)
  const key = getCurrentBucketKey(props.bucket.rawValueLower, props.bucket.rowPropertyRawValueLower, props.bucket.columnPropertyRawValueLower, props.bucket.rowProperty ? props.bucket.rowProperty.SystemName : null, props.bucket.columnProperty ? props.bucket.columnProperty.SystemName : null)
  const additionalInfo = utils.isDefined(browseByStore.indexedBucketAttributesValue) && utils.isDefined(browseByStore.indexedBucketAttributesValue[key]) ? browseByStore.indexedBucketAttributesValue[key] : null
  const bucketAttributesKey = 'bucketAttributes'
  if (utils.isDefined(additionalInfo) && utils.isDefined(additionalInfo[bucketAttributesKey])) {
    assignBucketAttributes(additionalInfo[bucketAttributesKey])
  }
}

function assignBucketAttributes(bucketAttributes) {
  props.visibleBucketAttributes.forEach((attribute) => {
    const bucketAttributesValue = formattedBucketAttributeValues(bucketAttributes, attribute, props.bucket.catalogArticleIdList, props.articles, props.articlesIndexes)
    indexedBucketAttributesValue.value![attribute.AttributeSystemName] = {
      displayLabel: attribute.AttributeDisplayName,
      value: bucketAttributesValue,
      typeId: attribute.AttributeTypeId,
    }
  })
}

watch(() => browseByStore.isLoadingBucketAttributesValue, (isLoadingBucketAttributesValue) => {
  if (isLoadingBucketAttributesValue) {
    indexedBucketAttributesValue.value = null
  }
  else {
    initBucketAttributesValue()
  }
})
</script>

<style lang="scss" scoped>
.t-1 {
  width: 135px;
  height: 131px;

  .easy-check {
    width: 25px;
    height: 25px;
  }

  .check {
    position: absolute;
    top: 5px;
    left: 5px;
  }
}

.t-2 {
  width: 165px;
  height: 183px;

  .easy-check {
    width: 35px;
    height: 35px;
  }

  .check {
    position: absolute;
    top: 12px;
    left: 10px;
  }
}

.t-3 {
  width: 180px;
  height: 210px;

  .easy-check {
    width: 35px;
    height: 35px;
  }

  .check {
    position: absolute;
    top: 12px;
    left: 10px;
  }
}

.custom-template {
  &.large {
    max-width: 180px;
    max-height: 210px;
    font-size: 10px;
    line-height: 12px;
  }

  &.medium {
    max-width: 165px;
    max-height: 183px;
    font-size: 9px;
    line-height: 9px;
  }

  &.small {
    max-width: 135px;
    max-height: 131px;
    font-size: 8px;
    line-height: 8px;
  }

  &.highlight {
    color: #0e70b4;
  }

  &.passive {
    color: #5D5D5D;
  }

  &.bold {
    font-weight: bold;
  }

  &.semi-bold {
    font-weight: 600;
  }

  &.truncate {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.truncate-2 {
    span {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &.right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }
}
</style>
