import DataWorker from '/src/dataWorker?worker'
import { v4 as uuidv4 } from 'uuid'

class DataWorkerWrapper {
  worker: Worker | null
  private messages: { [id: string]: { resolve: Function, reject: Function, progress?: (progress: number) => void } }

  constructor() {
    this.worker = new DataWorker()
    this.messages = {}
    this.worker.onmessage = e => this.eventHandler(e)
  }

  startWorker() {
    this.worker = new DataWorker()
  }

  stopWorker() {
    if (this.worker) {
      this.worker.terminate()
      this.worker = null
    }
  }

  sendAsync(msg: WorkerMessage, progress?: (progress: number) => void) {
    if (!this.worker) {
      this.startWorker()
    }
    const p = new Promise((resolve, reject) => {
      const id = uuidv4()
      this.messages[id] = { resolve, reject, progress }
      this.worker!.postMessage({ id, action: msg.action, content: msg.content })
    })
    return p
  }

  private eventHandler(msg: MessageEvent<IWorkerMessageResponse>) {
    console.log('Received message from worker', msg)
    if (msg && msg.data.id && this.messages.hasOwnProperty(msg.data.id)) {
      if (msg.data.type === 'Result') {
        if (msg.data.success) {
          this.messages[msg.data.id].resolve(msg.data.content)
        }
        else {
          this.messages[msg.data.id].reject(msg.data.content)
        }
        delete this.messages[msg.data.id]
      }
      else {
        if (this.messages[msg.data.id].progress) {
          this.messages[msg.data.id].progress!(msg.data.progress)
        }
      }
    }
  }
}

export default new DataWorkerWrapper()
