<template>
  <div id="articlesListToolbar" class="flex h-16 border-b bg-gray-50">
    <!-- TOGGLE PAGE SPLITTER BUTTON -->
    <div class="flex flex-col pl-1 m-auto w-11">
      <font-awesome-icon
        v-show="browseByDetailsLabel.length" icon="fa-light fa-arrow-left-long" class="w-4 h-4 cursor-pointer hover:text-primary-500"
        @click="$emit('exitBrowseByDetails')"
      />
      <font-awesome-icon
        icon="fa-light fa-bars-sort" class="w-4 h-4 mt-1 cursor-pointer hover:text-primary-500"
        :class="{ 'transform scale-x-[-1]': !asideOpen }"
        @click="$emit('asideClick')"
      />
    </div>

    <!-- HEADER INFO -->
    <div class="pt-2 mr-2 overflow-x-auto overflow-y-hidden grow">
      <template v-if="getHeader.type === 'path'">
        <div class="w-full h-4 pt-1 text-xs leading-3 tracking-wide">
          {{ getHeader.first }}
        </div>
        <div class="w-full h-8 pt-1 text-lg font-semibold text-default">
          {{ getHeader.second }}
        </div>
      </template>
      <template v-else-if="getHeader.type === 'tag'">
        <div class="w-full h-3 pt-1 text-xs leading-3 tracking-wide">
          {{ getHeader.email ? t('general.sharedTag', 1) : t('general.myTags', 1) }}
        </div>
        <div class="w-full h-5 pt-2 text-lg font-semibold text-default">
          <tx-tag>{{ getHeader }}</tx-tag>
        </div>
      </template>
      <template v-else-if="getHeader.type === 'filter'">
        <div class="flex flex-row w-full h-5 pt-1 text-xs leading-3 tracking-wide">
          <div class="pr-5 text-lg leading-4 uppercase" v-text="getHeader.title" />
          <tx-button type="text" class="text-primary-500" :text="t('general.reset')" @click="doClearFilter()" />
        </div>
        <div class="flex flex-row w-full h-10 pt-2">
          <tx-tag
            v-for="filter in getHeader.filters" :key="filter.attribute" :closable="true" clickable
            @click="openFilter(filter)" @close="doClearFilter(filter)"
          >
            {{ filter.toDisplayString(myAttributes) }}
          </tx-tag>
        </div>
      </template>
    </div>

    <div class="p-1 pr-2 m-auto mr-2 w-29 place-self-center">
      <div class="flex flex-grow-0 p-1 border border-solid rounded-md cursor-default text-sky-600 border-sky-600 place-items-center">
        <div class="font-semibold text-m" v-text="season" />
      </div>
    </div>
    <div class="flex grow place-self-center">
      <div
        v-tooltip="`${t('general.browsingBy')} ${browseBy == null ? t('general.article') : browseBy.label}`"
        class="flex flex-grow-0 pr-2 border border-solid rounded-md text-sky-600 [&>button]:text-sky-600 [&>button]:cursor-default border-sky-600 place-items-center h-7 color"
      >
        <tx-button type="staticIcon" faicon="fa-light fa-binoculars" icon-size="16px" />
        <span>{{ browseBy == null ? t('general.article') : browseBy.label }}{{ browseByDetailsLabel.length ? `: ${browseByDetailsLabel}` : '' }}</span>
      </div>
    </div>

    <!-- DYNAMIC BUTTONS -->
    <template v-if="buttons.length">
      <div v-for=" button in dynamicButtons" :key="button.id" class="m-auto mr-2 w-29 pd-1">
        <div
          class="flex items-center flex-nowrap hover:cursor-pointer" :class="{ '!cursor-not-allowed': button.disabled, 'text-disabled': button.disabled }"
          @click="onButtonClick(button, $event)"
        >
          <tx-button type="icon" :faicon="`fa-light ${button.icon}`" :disabled="button.disabled" />
          <span v-show="button.label && button.label.toString().length" class="whitespace-nowrap">{{ button.label }}</span>
        </div>
      </div>
    </template>

    <!-- ADD BUTTON -->
    <div v-if="hasCreateModelWithArticlePrivilege" class="m-auto mr-2 w-29 pd-1">
      <tx-button v-tooltip="t('general.add')" type="icon" faicon="fa-light fa-plus" @click="onAddButtonClick" />
    </div>

    <!-- SELECT ALL BUTTON -->
    <div class="w-24 m-auto">
      <tx-checkbox
        id="selectAll" v-model="getSetSelectAll" :indeterminate="selectAllIndeterminate"
        :label="t('general.selectAll')"
      />
    </div>

    <!-- BROWSE BY -->
    <div v-if="browseByList.length" class="m-auto w-11">
      <tx-dropdown faicon="fa-light fa-binoculars" :tooltip="t('general.browseBy')">
        <div class="overflow-hidden rounded w-72">
          <div class="p-2 mb-2 text-sm uppercase bg-primary text-on-primary" v-text="t('general.browseBy')" />
          <div class="flex flex-row px-2 mb-2">
            <tx-select v-model="browseBy" :data="browseByList" filterable clearable :sort-list="false" :placeholder="t('general.browseBy')" value-key="key" display-prop="displayLabel" />
          </div>
        </div>
      </tx-dropdown>
    </div>

    <!-- FILTER BUTTON -->
    <div class="m-auto w-11">
      <tx-button
        id="btnFilter" v-tooltip="t('general.filter')" type="icon" faicon="fa-light fa-filter"
        @click="$emit('filter')"
      />
    </div>

    <!-- SORT BUTTON -->
    <div v-if="(getSortByAttributes?.length || 0) > 0" v-show="!isShowingBucketsListView" class="m-auto w-11">
      <tx-dropdown
        v-model="getSetSortBy" :items="getSortByAttributes" value-prop="articleProperty"
        display-prop="displayLabel" faicon="fa-light fa-sort" :tooltip="t('general.sort')" @select="onSortSelectionChange"
      />
    </div>

    <!-- GENERATE BUTTON -->
    <div v-show="!isShowingBucketsListView && isAnyDataAvailable" class="m-auto w-11">
      <tx-button
        id="generateSlide" v-tooltip="t('general.generate')" type="icon" faicon="fa-light fa-presentation-screen"
        @click="$emit('generate')"
      />
    </div>
    <!-- PIVOT BUTTON -->
    <div class="m-auto w-11">
      <tx-dropdown faicon="fa-light fa-sitemap" :tooltip="t('general.groupBy')">
        <div class="overflow-hidden rounded w-72">
          <div class="p-2 mb-2 text-sm uppercase bg-primary text-on-primary" v-text="t('general.groupBy')" />
          <div class="flex flex-row px-2 mb-2">
            <tx-select
              v-model="getSetGroupByCol" :data="getGroupByOptions('colDivider')" value-prop="articleProperty"
              display-prop="displayLabel" filterable clearable :placeholder="t('general.column')"
            >
              <template #prefix>
                <font-awesome-icon icon="fa-light fa-columns" class="w-4" />
              </template>
            </tx-select>
          </div>
          <div class="flex flex-row px-2 pb-2">
            <tx-select
              v-model="getSetGroupByRow" :data="getGroupByOptions('rowDivider')" value-prop="articleProperty"
              display-prop="displayLabel" filterable clearable :placeholder="t('general.row')"
            >
              <template #prefix>
                <font-awesome-icon icon="fa-light fa-rows" class="w-4" />
              </template>
            </tx-select>
          </div>
        </div>
      </tx-dropdown>
    </div>

    <!-- THUMBNAIL SIZE BUTTON -->
    <div v-show="!isShowingBucketsListView || userStore.activeCatalog?.Config.BrowseByAttributeThumbnailDetailsT1SW != null" class="m-auto w-11">
      <tx-button
        id="btnThumbSize" v-tooltip="{ text: t('general.thumbnailSize'), theme: { offset: ['right'] } }" type="icon" faicon="fa-light fa-gallery-thumbnails"
        @click="doSwitchThumbSize"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, toRef } from 'vue'
import { isFunction } from 'lodash-es'
import useBrowseBy from './browseBy'
import TxDropdown from '@/shared/components/TxDropdown.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxCheckbox from '@/shared/components/TxCheckbox.vue'
import TxTag from '@/shared/components/TxTag.vue'
import type { FilterCriteria } from '@/models/filterCriteria'
import { useUserStore } from '@/store/userData'
import type { ArticlesListToolbarActions } from '@/models/constants'
import { appConstants, privileges } from '@/models/constants'
import { useBrowseByStore } from '@/store/browseBy'
import utils from '@/services/utils'

interface IProps {
  asideOpen: boolean
  buttons: Array<IToolbarActionButton<ArticlesListToolbarActions>>
  destination: IToolbarHeader
  enableBrowseBy: boolean
  browseByDetailsLabel: string
  moduleName: string
  selectAll: boolean
  selectAllIndeterminate: boolean
  isAnyDataAvailable: boolean
}
const props = withDefaults(defineProps<IProps>(), {
  buttons: () => ([]),
  enableBrowseBy: false,
  browseByDetailsLabel: '',
  isAnyDataAvailable: false,
})

const emit = defineEmits<{
  (e: 'addClick'): void
  (e: 'asideClick'): void
  (e: 'exitBrowseByDetails'): void
  (e: 'clearAllFilters', filter?: FilterCriteria): void
  (e: 'clearFilter', filter?: FilterCriteria): void
  (e: 'filter', filter?: FilterCriteria): void
  (e: 'selectAllChange', value: boolean): void
  (e: 'update:modelValue', value: boolean): void
  (e: 'update:selectAll', value: boolean): void
  (e: 'openCustomSort', value: boolean): void
  (e: 'generate'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const browseByStore = useBrowseByStore()
const { browseBy, browseByList, isShowingBucketsListView } = useBrowseBy(toRef(props, 'enableBrowseBy'))
const myAttributes = userStore.myAttributes
const season = userStore.activeCatalog?.Season

const dynamicButtons = computed(() => props.buttons.filter(button => button.visible))

function doSwitchThumbSize() {
  if (!isShowingBucketsListView.value) {
    const newSize = userStore.thumbnailSize <= 1 ? 3 : userStore.thumbnailSize - 1
    userStore.setThumbnailSize(newSize)
  }
  else {
    const newSize = browseByStore.thumbnailSize <= 1 ? 3 : browseByStore.thumbnailSize - 1
    browseByStore.thumbnailSize = newSize
  }
}

// function doSort(prop: string) {
//   userStore.setSortBy(prop.length === 0 ? undefined : prop)
// }

// function onSelectAllChange(val: boolean) {
//   emit('selectAllChange', val)
// }

function onSortSelectionChange(sortObject) {
  if (sortObject.articleProperty === '_customSort') {
    emit('openCustomSort', true)
  }
}

const getHeader = computed(() => {
  switch (props.destination.type) {
    case 'path':
      if (props.destination.value.length === 0) {
        return { type: 'path', first: '', second: '' }
      }
      return {
        type: 'path',
        first: props.destination.value.length > 1 ? props.destination.value.slice(0, -1).join(' / ') : props.destination.value.length === 1 ? userStore.activeCatalog?.CatalogName : props.moduleName,
        second: props.destination.value.length > 0 ? props.destination.value[props.destination.value.length - 1] : userStore.activeCatalog?.CatalogName,
      }
    case 'tag':
      return {
        type: 'tag',
        ...props.destination.value,
      }
    case 'filter':
      return {
        type: 'filter',
        title: props.destination.name || t('filter.title'),
        filters: props.destination.value,
      }
    default:
      return { type: '', first: '', second: '' }
  }
})

const getSetSortBy = computed({
  get: () => userStore.sortBy || '', // set default if no sortBy applied
  set: val => userStore.setSortBy(val?.length === 0 ? undefined : val),
})

const hasCreateModelWithArticlePrivilege = computed(() => {
  let hasCreateModelWithArticlePrivilege = false
  if (userStore.activeCatalog && userStore.activeCatalog!.DataSourceTypeId === appConstants.catalogTypes.master && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountId === userStore.activeCatalog?.AccountId)) {
    hasCreateModelWithArticlePrivilege = userStore.userProfile.isValidPrivilege(privileges.article.createModelWithArticles)
  }
  return hasCreateModelWithArticlePrivilege
})

function getGroupByOptions(dividerCriteria: string) {
  if (userStore.activeCatalog) {
    const res = [] as Array<{ displayLabel: string, articleProperty: string, direction?: string }>
    Object.keys(userStore.activeCatalog.Config.GroupByAttributes).forEach((itm) => {
      if (itm === '_Segmentation') {
        itm = '_Segmentations'
      }
      if (dividerCriteria === 'rowDivider') {
        if (userStore.myAttributes && userStore.myAttributes.hasOwnProperty(itm) && userStore.groupByCol !== itm) {
          res.push({ displayLabel: userStore.myAttributes[itm].DisplayName, articleProperty: itm })
        }
        else {
          console.warn(`Misconfigured GroupByAttributes: Property ${itm} not found`)
        }
      }
      else {
        if (userStore.myAttributes && userStore.myAttributes.hasOwnProperty(itm) && userStore.groupByRow !== itm) {
          res.push({ displayLabel: userStore.myAttributes[itm].DisplayName, articleProperty: itm })
        }
        else {
          console.warn(`Misconfigured GroupByAttributes: Property ${itm} not found`)
        }
      }
    })
    return res
  }
  else {
    return []
  }
}

const getSetGroupByCol = computed({
  get: () => userStore.groupByCol,
  set: val => userStore.setGroupBy(val, userStore.groupByRow),
})

const getSetGroupByRow = computed({
  get: () => userStore.groupByRow,
  set: val => userStore.setGroupBy(userStore.groupByCol, val),
})

// const getThumbnailSize = computed(() => userStore.thumbnailSize)

const getSortByAttributes = computed(() => {
  const res = [] as Array<{ displayLabel: string, articleProperty: string, direction?: string }>
  userStore.activeCatalog?.Config.ArticlesSortProperties.forEach((itm) => {
    if (userStore.myAttributes && userStore.myAttributes.hasOwnProperty(itm.articleProperty)) {
      const sortOption = { displayLabel: userStore.myAttributes[itm.articleProperty].DisplayName, articleProperty: itm.articleProperty, direction: itm.sortDirection }
      utils.insertSorted(sortOption, res, (a, b) => utils.comparer(a, b, ['displayLabel']))
    }
    else {
      console.warn(`Misconfigured ArticlesSortProperties: Property ${itm.articleProperty} not found`)
    }
  })
  if (res.length) {
    res.unshift({ displayLabel: t('general.default'), articleProperty: '', direction: undefined })
    res.push({ displayLabel: t('general.customSort'), articleProperty: '_customSort', direction: undefined })
  }
  return res
})

const getSetSelectAll = computed({
  get: () => props.selectAll,
  set: (val) => {
    emit('update:selectAll', val)
    emit('selectAllChange', val)
  },
})

function doClearFilter(filter?: FilterCriteria) {
  if (!filter) {
    emit('clearAllFilters', filter)
  }
  else {
    emit('clearFilter', filter)
  }
}

function openFilter(filter?: FilterCriteria) {
  if (filter) {
    emit('filter', filter)
  }
}

function onButtonClick(button: IToolbarActionButton<ArticlesListToolbarActions>, event) {
  if (!button.disabled && button.hasOwnProperty('onClick') && isFunction(button.onClick)) {
    button.onClick(event)
  }
}

function onAddButtonClick() {
  emit('addClick')
}
</script>

<style lang="scss" scoped>
.articles-list-toolbar {
  height: 68px;
  width: 100%;
  display: grid;
  grid-template-columns: 45px auto 100px 200px 45px 45px 65px;

  .drawer {
    padding-top: 25px;
    padding-left: 3px;
  }

  .breadcrumbs {
    padding-top: 10px;

    .header1 {
      width: 100%;
      height: 11px;
      line-height: 11px;
      color: #171717;
      font-size: 9px;
      letter-spacing: 1px;
      padding-top: 3px;
    }

    .header2 {
      padding-top: 7px;
      width: 100%;
      height: 21px;
      line-height: 21px;
      color: #4e4e4e;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .selectall {
    padding-top: 30px;

    .dx-checkbox {
      width: 100%;
      font-size: 13px;
    }
  }

  .search {
    padding-top: 20px;
    margin-right: 30px;
  }

  .filter {
    padding-top: 25px;
  }

  .group {
    padding-top: 25px;
  }

  .thumb-size {
    padding-top: 25px;
  }
}
</style>
