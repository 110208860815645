import type { CreateResourceWithArticlesOptionalModel, ResourceModel, ResourceTypeModel } from './model/resourceModel'
import Net from '@/services/net'

export function getResourceTypes() {
  return Net.t1.get<ResourceTypeModel[]>(`/resourceTypes`)
}

export function assignArticlesToResource(catalogCode: number, resourceId: number, articleIds: number[]) {
  return Net.t1.put(`/catalogs/${catalogCode}/resources/${resourceId}/assignarticles`, { ArticleIds: articleIds })
}

export function createResourceWithArticlesOptional(catalogCode: number, requestObject: CreateResourceWithArticlesOptionalModel) {
  return Net.t1.post<ResourceModel>(`/catalogs/${catalogCode}/resources`, requestObject)
}
