import { fabric } from 'fabric'
import type { IPresenceUser } from '../composables/presence'
import { whiteboardConstants } from '@/models/constants'

export default class WbCursor extends fabric.Object {
  public type = whiteboardConstants.objectTypes.cursor
  public user: IPresenceUser
  public connectable: boolean

  constructor(user: IPresenceUser, opt?: fabric.IObjectOptions) {
    super(opt)
    this.user = user
    this.connectable = false
    this.selectable = false

    this.on('added', () => {
      const newZoom = this.canvas?.getZoom() || 1
      this.set('scaleX', 1 / newZoom)
      this.set('scaleY', 1 / newZoom)
    })

    this.on('zoom', () => {
      const newZoom = this.canvas?.getZoom() || 1
      this.set('scaleX', 1 / newZoom)
      this.set('scaleY', 1 / newZoom)
    })
  }

  setPosition(point: IPoint) {
    this.animate('left', point.x, { onChange: this.canvas?.renderAll.bind(this.canvas) })
    this.animate('top', point.y, { onChange: this.canvas?.renderAll.bind(this.canvas) })
  }

  override _render(ctx: CanvasRenderingContext2D): void {
    ctx.font = '14px Helvetica'
    ctx.fillStyle = this.user.Color
    const measure = ctx.measureText(this.user.FirstName)

    ctx.beginPath()
    ctx.moveTo(0, 0)
    ctx.lineTo(20, 10)
    ctx.lineTo(16, 0)
    ctx.lineTo(20, -10)
    ctx.fill()

    const w = measure.width + 10
    const x = 25
    const y = -12
    const h = 25
    const r = 4
    ctx.beginPath()
    ctx.moveTo(x + r, y)
    ctx.arcTo(x + w, y, x + w, y + h, r)
    ctx.arcTo(x + w, y + h, x, y + h, r)
    ctx.arcTo(x, y + h, x, y, r)
    ctx.arcTo(x, y, x + w, y, r)
    ctx.closePath()
    ctx.fill()

    ctx.fillStyle = 'white'
    ctx.fillText(this.user.FirstName, 30, h / 2 - 8)
  }
}

const f: any = fabric
f.WbCursor = WbCursor
