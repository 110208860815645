<template>
  <div class="flex flex-col w-full h-full">
    <!-- SUB HEADER -->
    <div class="flex-shrink-0 text-center">
      <!-- SUB TITLE -->
      <div class="text-base text-gray-600 mb-7">
        {{ t('modelCreate.subTitle.previewPage') }}
      </div>
      <!-- NOTES -->
      <div class="text-sm text-gray-600" :class="{ 'mb-8': headerNotes.length }">
        {{ headerNotes }}
      </div>
    </div>

    <!-- BODY -->
    <div v-if="showProgress" class="h-3/4 px-10 mt-4 mb-2">
      <tx-progress v-model="progress" :title="progressTitle" />
    </div>
    <div v-if="!showProgress" class="h-3/4 flex flex-row flex-wrap flex-grow p-2 mt-10  overflow-auto">
      <template v-for="article in props.articles" :key="article.CatalogArticleId">
        <article-thumbnail class="float-left p-2 mr-10 thumbnail" :article="article" :allowselection="false" :selected="false" :is-preview-form="true" />
      </template>
    </div>

    <!-- FOOTER -->
    <div v-if="!showProgress" class="flex flex-row justify-end flex-shrink-0 mx-24 mb-16 flex-nowrap">
      <tx-button class="ml-5" type="confirm" :text="t('general.next')" @click="onNext" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ArticleThumbnail from '@/shared/components/ArticleThumbnail.vue'
import type MyArticle from '@/models/myArticle'
import TxButton from '@/shared/components/TxButton.vue'
import TxProgress from '@/shared/components/TxProgress.vue'

const props = defineProps<{
  articles: MyArticle[]
  modelCopies?: number
  modelCopiesCreated?: number
  showProgress?: boolean
}>()

const emit = defineEmits<{
  (e: 'next'): void
}>()

const { t } = useI18n()
const headerNotes = ref('')
const progress = computed(() => Math.round(((props.modelCopiesCreated ? props.modelCopiesCreated : 1) / (props.modelCopies ? props.modelCopies : 1)) * 100))
const progressTitle = computed(() => t('modelCreate.createModelProgressTitle') + ((props.modelCopies ? props.modelCopies : 1) ? ` (${(props.modelCopiesCreated ? props.modelCopiesCreated : 1)} / ${(props.modelCopies ? props.modelCopies : 1)})` : ''))

function onNext() {
  emit('next')
}
</script>
