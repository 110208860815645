export default class CatalogTreeDef {
  CatalogCode: number
  Id: number
  Name: string
  IsDefault: number
  Status: number
  Attributes: string[]

  constructor(catalogCode: number, catalogTreeDef: any) {
    this.CatalogCode = catalogCode
    this.Id = catalogTreeDef.Id
    this.Name = catalogTreeDef.Name
    this.IsDefault = catalogTreeDef.IsDefault
    this.Status = catalogTreeDef.Status
    this.Attributes = catalogTreeDef.Attributes.sort((a: any, b: any) => a.Level - b.Level).map((itm: any) => itm.AttributeSystemName)
  }
}
