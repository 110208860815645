<template>
  <div class="flex flex-col w-full h-full overflow-hidden">
    <!-- HEADER -->
    <div class="flex pt-2 pb-2 items-center">
      <tx-button type="icon" faicon="fa-light fa-arrow-left-long" class="mr-2 ml-2" @click="goBack" />
      <tx-tabs v-model="activeModelTab" :tabs="Object.values(detailsTabs)" />
    </div>
    <!-- TABS -->
    <div v-if="activeModelTab === 'details'" class="flex-1 overflow-y-auto border bg-white">
      <tx-data-details
        :data="data"
        :columns="columns"
        :loading="loadingData"
        :item-actions="itemActions"
        :show-static-actions="false"
        title-attribute="ArticleNumber"
      />
    </div>
    <div v-else-if="activeModelTab === 'deliveryDates'" class="flex-1 overflow-y-auto border bg-white">
      <!-- Include Delivery Dates tab -->
    </div>
    <!-- Details Action Drawer -->
    <tx-drawer v-model="isDrawerVisible" right width="700px">
      <active-or-deactive-article
        v-if="isActiveOrDeActiveAction && selectedArticle" :articles="[selectedArticle]" :type="(selectedListAction && selectedListAction.id === 'activate') ? 'activate' : 'deactivate'"
        @cancel="closeDrawer" @updated="onArticlesUpdated"
      />
    </tx-drawer>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import ArticleModel from './article'
import { useUserStore } from '@/store/userData'
import type Article from '@/models/article'
import type { ITxDataTableColumn, ITxDataTableItemAction } from '@/shared/components/txDataTable/TxDataTable.types'
import useEventsBus from '@/shared/composables/eventBus'
import TxDataDetails from '@/shared/components/txDataDetails/TxDataDetails.vue'
import { privileges } from '@/models/constants'
import TxTabs from '@/shared/components/TxTabs.vue'
import TxButton from '@/shared/components/TxButton.vue'
import router from '@/router'
import appConfig from '@/services/appConfig'
import utils from '@/services/utils'
import type MyArticle from '@/models/myArticle'
import { useNotificationStore } from '@/store/notification'
import TxDrawer from '@/shared/components/TxDrawer.vue'
import ActiveOrDeactiveArticle from '@/modules/articleDetails/components/ActiveOrDeactiveArticle.vue'

const { t } = useI18n()
const userStore = useUserStore()
const { onAppEvent, offAppEvent } = useEventsBus()
const route = useRoute()
const notificationStore = useNotificationStore()

const columns = ref<ITxDataTableColumn[]>([])
const data = ref<Article | null>(null)
const loadingData = ref(true)
const articleModel = shallowRef<ArticleModel>()
const articleId = computed(() => Number(route.params.articleId))
const detailsTabs = {
  details: { key: 'details', label: 'admin.articleDetails.tabs.article', visible: true },
  // deliveryDates: { key: 'deliveryDates', label: 'admin.articleDetails.tabs.deliveryDates', visible: true },
  // resources: { key: 'resources', label: 'admin.articleDetails.tabs.resources', visible: true },
  // images: { key: 'images', label: 'admin.articleDetails.tabs.images', visible: true },
  // sizes: { key: 'sizes', label: 'admin.articleDetails.tabs.sizes', visible: true },
  // prices: { key: 'prices', label: 'admin.articleDetails.tabs.prices', visible: true },
  // segmentation: { key: 'segmentation', label: 'admin.articleDetails.tabs.segmentation', visible: true },
  // history: { key: 'history', label: 'admin.articleDetails.tabs.history', visible: true },
  // related: { key: 'related', label: 'admin.articleDetails.tabs.related', visible: true },
}
const activeModelTab = ref(detailsTabs.details.key)
const isDrawerVisible = ref(false)
const selectedListAction = ref<ITxDataTableItemAction>()
const selectedArticle = ref<MyArticle>()

enum listActionType {
  delete = 'delete',
  activate = 'activate',
}

const isActiveOrDeActiveAction = computed(() => selectedListAction.value && (selectedListAction.value.id === listActionType.activate || selectedListAction.value.id === listActionType.delete))

const itemActions = computed(() => ([{
  id: listActionType.delete,
  enabled: true,
  visible: data.value && userStore.activeCatalog?.DataSourceTypeId === 1 && userStore.userProfile.isValidPrivilege(privileges.article.updateStatus) && data.value.Status > 0,
  icon: 'fa-light fa-trash-can',
  label: t('general.delete'),
  onClick: (item) => {
    selectedArticle.value = item
    openDrawer(listActionType.delete)
  },
}, {
  id: listActionType.activate,
  enabled: true,
  visible: data.value && userStore.activeCatalog?.DataSourceTypeId === 1 && userStore.userProfile.isValidPrivilege(privileges.article.updateStatus) && data.value.Status === 0,
  icon: 'fa-light fa-rotate-right',
  label: t('activeOrDeactiveArticle.activate'),
  onClick: async (item) => {
    selectedArticle.value = item
    openDrawer(listActionType.activate)
  },
}]) as Array<ITxDataTableItemAction>)

function goBack() {
  router.push({ name: 'AdminArticles' })
}

function openDrawer(action: listActionType) {
  selectedListAction.value = itemActions.value.find(itemAction => itemAction.id === action)
  if (action === listActionType.activate || action === listActionType.delete) {
    isDrawerVisible.value = true
  }
}

function closeDrawer() {
  isDrawerVisible.value = false
}

async function doRefresh() {
  loadingData.value = true
  data.value = null
  // data.value = ''
  const articleObject: Article | undefined = await appConfig.DB!.getArticle(userStore.activeCatalog!, articleId.value)
  if (utils.isDefined(articleObject)) {
    data.value = articleObject
    loadingData.value = false
  }
  else {
    loadingData.value = false
  }
}

// api calls
function onArticlesUpdated(articles: MyArticle[] | Article[]) {
  let message = t('general.updatedSuccessfully')
  if (selectedListAction.value) {
    if (selectedListAction.value.id === listActionType.activate) {
      message = t('activeOrDeactiveArticle.activateSuccessfully', articles.length)
    }
    else if (selectedListAction.value.id === listActionType.delete) {
      message = t('activeOrDeactiveArticle.deactivateSuccessfully', articles.length)
    }
    notificationStore.addNotification({ message, type: 'Success' })
    doRefresh()
    closeDrawer()
  }
}

onMounted(async () => {
  articleModel.value = new ArticleModel(t)
  columns.value = articleModel.value.getDataTableColumns()
  await doRefresh()
  onAppEvent('catalogDataUpdated', doRefresh)
})

onUnmounted(() => {
  offAppEvent('catalogDataUpdated', doRefresh)
})
</script>
