<template>
  <div
    v-show="modelValue" ref="refPanel" class="absolute z-[2000] flex flex-col bg-grey-light rounded-lg shadow-toolbar overflow-hidden"
    :style="styles"
  >
    <!-- Header -->
    <div class="flex w-full px-4 pt-2 border-b bg-primary" @dblclick="onDoubleClick">
      <div class="text-xl font-medium leading-6 text-center text-white cursor-move grow" @mousedown="onMouseDown">
        {{ title }}
      </div>
      <!-- Minimize Button -->
      <tx-button v-if="showMinimize" class="text-white" type="icon" :faicon="minimized ? 'fa-light fa-window-restore' : 'fa-light fa-window-minimize'" @click="toggleMinimize" />
      <!-- Maximize Button -->
      <tx-button v-if="showMaximize" class="text-white" type="icon" :faicon="maximized ? 'fa-light fa-window-restore' : 'fa-light fa-window-maximize'" @click="toggleMaximize" />
      <!-- Close Button -->
      <tx-button class="text-white" type="icon" faicon="fa-light fa-xmark" @click="doClose" />
    </div>

    <!-- Panel Content -->
    <div v-show="!minimized" class="flex-1 w-full h-full overflow-hidden">
      <slot />
    </div>

    <!-- Resize Handles -->
    <template v-if="!minimized && !maximized">
      <!-- Top -->
      <div class="absolute top-0 left-0 right-0 h-2 cursor-n-resize" @mousedown="onResizeMouseDown($event, 'top')" />
      <!-- Bottom -->
      <div class="absolute bottom-0 left-0 right-0 h-2 cursor-s-resize" @mousedown="onResizeMouseDown($event, 'bottom')" />
      <!-- Left -->
      <div class="absolute top-0 bottom-0 left-0 w-2 cursor-w-resize" @mousedown="onResizeMouseDown($event, 'left')" />
      <!-- Right -->
      <div class="absolute top-0 bottom-0 right-0 w-2 cursor-e-resize" @mousedown="onResizeMouseDown($event, 'right')" />
      <!-- Top-Left Corner -->
      <div class="absolute top-0 left-0 w-4 h-4 cursor-nw-resize" @mousedown="onResizeMouseDown($event, 'top-left')" />
      <!-- Top-Right Corner -->
      <div class="absolute top-0 right-0 w-4 h-4 cursor-ne-resize" @mousedown="onResizeMouseDown($event, 'top-right')" />
      <!-- Bottom-Left Corner -->
      <div class="absolute bottom-0 left-0 w-4 h-4 cursor-sw-resize" @mousedown="onResizeMouseDown($event, 'bottom-left')" />
      <!-- Bottom-Right Corner -->
      <div class="absolute bottom-0 right-0 w-4 h-4 cursor-se-resize" @mousedown="onResizeMouseDown($event, 'bottom-right')" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core'
import type { CSSProperties } from 'vue'
import { computed, reactive, ref, watch } from 'vue'
import TxButton from './TxButton.vue'

interface IProps {
  modelValue: boolean
  title?: string
  width?: number
  height?: number
  top?: number
  left?: number
  right?: number
  bottom?: number
  closeOnEsc?: boolean
  showMaximize?: boolean
  showMinimize?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  title: '',
  width: 400,
  height: 300,
  closeOnEsc: true,
  showMaximize: false,
  showMinimize: false,
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: boolean): void
  (e: 'resize', width: number, height: number): void
  (e: 'close'): void
}>()

let pos1 = 0
let pos2 = 0
let pos3 = 0
let pos4 = 0
const refPanel = ref<HTMLElement | null>(null)

const currentSize = reactive({
  width: props.width,
  height: props.height,
  top: props.top,
  left: props.left,
  right: props.right,
  bottom: props.bottom,
})

const originalSize = {
  width: props.width,
  height: props.height,
  top: props.top,
  left: props.left,
  right: props.right,
  bottom: props.bottom,
}

let isDragging = false
let requestId: number | null = null

const minimized = ref(false)
const maximized = ref(false)

// Compute the style dynamically based on the position props
const styles = computed(() => {
  const style: CSSProperties = {
    width: `${currentSize.width}px`,
    height: `${currentSize.height}px`,
  }

  // Handle vertical positioning
  if (currentSize.top !== undefined) {
    style.top = `${currentSize.top}px`
  }
  else if (currentSize.bottom !== undefined) {
    style.bottom = `${currentSize.bottom}px`
  }
  else {
    style.top = '20px'
  }

  // Handle horizontal positioning
  if (currentSize.left !== undefined) {
    style.left = `${currentSize.left}px`
  }
  else if (currentSize.right !== undefined) {
    style.right = `${currentSize.right}px`
  }
  else {
    style.right = '20px'
  }

  return style
})

// Dragging logic
function onMouseDown(e: MouseEvent) {
  pos3 = e.clientX
  pos4 = e.clientY
  isDragging = true

  document.addEventListener('mousemove', doDrag)
  document.addEventListener('mouseup', stopDrag)
}

function doDrag(e: MouseEvent) {
  if (!isDragging) { return }

  e.preventDefault()

  pos1 = pos3 - e.clientX
  pos2 = pos4 - e.clientY
  pos3 = e.clientX
  pos4 = e.clientY

  if (requestId === null) {
    requestId = requestAnimationFrame(() => {
      updatePanelPosition()
      requestId = null
    })
  }
}

function updatePanelPosition() {
  const newTop = refPanel.value!.offsetTop - pos2
  const newLeft = refPanel.value!.offsetLeft - pos1

  const dialogRect = refPanel.value!.getBoundingClientRect()
  const dialogWidth = dialogRect.width
  const dialogHeight = dialogRect.height
  const viewportWidth = window.innerWidth
  const viewportHeight = window.innerHeight

  const minTop = 0
  const maxTop = viewportHeight - dialogHeight
  const minLeft = 0
  const maxLeft = viewportWidth - dialogWidth

  // Constrain the panel's position within the viewport boundaries
  currentSize.top = Math.min(Math.max(newTop, minTop), maxTop)
  currentSize.left = Math.min(Math.max(newLeft, minLeft), maxLeft)
}

function stopDrag() {
  isDragging = false
  document.removeEventListener('mouseup', stopDrag)
  document.removeEventListener('mousemove', doDrag)

  if (requestId) {
    cancelAnimationFrame(requestId)
    requestId = null
  }
}

// Resize logic with boundary constraints
let resizeStartX = 0; let resizeStartY = 0
let initialWidth = 0; let initialHeight = 0
let initialTop = 0; let initialLeft = 0
let resizeDirection = ''

function onResizeMouseDown(e: MouseEvent, direction: string) {
  resizeStartX = e.clientX
  resizeStartY = e.clientY
  initialWidth = refPanel.value!.offsetWidth
  initialHeight = refPanel.value!.offsetHeight
  initialTop = refPanel.value!.offsetTop
  initialLeft = refPanel.value!.offsetLeft
  resizeDirection = direction

  document.addEventListener('mousemove', resizePanel)
  document.addEventListener('mouseup', stopResize)
}

function resizePanel(e: MouseEvent) {
  const dx = e.clientX - resizeStartX
  const dy = e.clientY - resizeStartY

  const minWidth = 200
  const minHeight = 150

  const viewportWidth = window.innerWidth
  const viewportHeight = window.innerHeight

  if (resizeDirection === 'right' || resizeDirection.includes('right')) {
    const newWidth = initialWidth + dx
    const maxWidth = viewportWidth - initialLeft
    currentSize.width = Math.max(Math.min(newWidth, maxWidth), minWidth)
  }

  if (resizeDirection === 'left' || resizeDirection.includes('left')) {
    const newWidth = initialWidth - dx
    const newLeft = initialLeft + dx
    if (newWidth >= minWidth && newLeft >= 0) {
      currentSize.width = newWidth
      refPanel.value!.style.left = `${newLeft}px`
    }
  }

  if (resizeDirection === 'bottom' || resizeDirection.includes('bottom')) {
    const newHeight = initialHeight + dy
    const maxHeight = viewportHeight - initialTop
    currentSize.height = Math.max(Math.min(newHeight, maxHeight), minHeight)
  }

  if (resizeDirection === 'top' || resizeDirection.includes('top')) {
    const newHeight = initialHeight - dy
    const newTop = initialTop + dy
    if (newHeight >= minHeight && newTop >= 0) {
      currentSize.height = newHeight
      refPanel.value!.style.top = `${newTop}px`
    }
  }

  emit('resize', currentSize.width, currentSize.height)
}

function stopResize() {
  document.removeEventListener('mousemove', resizePanel)
  document.removeEventListener('mouseup', stopResize)
}

// Minimize / Maximize

function setOriginalSize() {
  originalSize.width = currentSize.width
  originalSize.height = currentSize.height
  originalSize.top = currentSize.top
  originalSize.left = currentSize.left
  originalSize.right = currentSize.right
  originalSize.bottom = currentSize.bottom
}

function restoreSize() {
  currentSize.width = originalSize.width
  currentSize.height = originalSize.height
  currentSize.top = originalSize.top
  currentSize.left = originalSize.left
  currentSize.right = originalSize.right
  currentSize.bottom = originalSize.bottom
}

function toggleMaximize() {
  if (maximized.value) {
    restoreSize()
  }
  else {
    if (!minimized.value) {
      setOriginalSize()
    }

    currentSize.width = window.innerWidth
    currentSize.height = window.innerHeight
    currentSize.top = 0
    currentSize.left = 0
    currentSize.right = undefined
    currentSize.bottom = undefined
  }
  maximized.value = !maximized.value
  minimized.value = false
}

function toggleMinimize() {
  if (minimized.value) {
    restoreSize()
  }
  else {
    if (!maximized.value) {
      setOriginalSize()
    }
    currentSize.width = 300
    currentSize.height = 40
    currentSize.top = undefined
    currentSize.left = 90
    currentSize.right = undefined
    currentSize.bottom = 20
  }
  minimized.value = !minimized.value
  maximized.value = false
}

function onDoubleClick() {
  if (props.showMaximize) {
    toggleMaximize()
  }
}

// Close logic
if (props.closeOnEsc) {
  onKeyStroke('Escape', () => {
    if (props.modelValue) {
      doClose()
    }
  })
}

function doClose() {
  emit('update:modelValue', false)
  emit('close')
}

watch(() => props.modelValue, (val) => {
  if (val && minimized.value) {
    toggleMinimize()
  }
})
</script>
