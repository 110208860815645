import type { ComposerTranslation } from 'vue-i18n'
import type MyArticle from '@/models/myArticle'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import type { FilterCriteria } from '@/models/filterCriteria'
import type { ICatalogBucketAttributeParsed } from '@/models/catalogBucketAttribute'
import { getArticlePropertyInfo, getCustomSortValueListForComparer } from '@/services/catalogFactory'
import type CatalogDetails from '@/models/catalogDetails'
import { appConstants } from '@/models/constants'
import type { IBucketsModel, IGetBucketValuePayload } from '@/api/t1/model/buckets'
import { getBucketsValue } from '@/api/t1/buckets'

function getBrowseByList(browseByAttributeConfig: Record<string, { sortDirection?: string, customSortValueList?: Array<string | number> }>, isBrowseByModelEnabled: boolean, myAttributes: Record<string, IMyAttribute>, priceGroupsLabel: { wholesalePrice: string, retailPrice: string, outletPrice: string, orderPrice: string }, t: ComposerTranslation) {
  let browseByList = [] as Array<IBrowseBy>
  const browseByDynamicList: Array<IBrowseBy> = []
  for (const AttributeSystemName in browseByAttributeConfig) {
    if (myAttributes.hasOwnProperty(AttributeSystemName) || AttributeSystemName === '_Segmentation' || AttributeSystemName === '_Color' || AttributeSystemName === '_OrderPrice') {
      const propertyInfo = getArticlePropertyInfo(AttributeSystemName, myAttributes, priceGroupsLabel, t, 'BrowseByAttribute')
      if (propertyInfo != null) {
        const sortDirectionMultiple = !browseByAttributeConfig[AttributeSystemName].sortDirection ? 1 : (browseByAttributeConfig[AttributeSystemName].sortDirection!.toString().toLowerCase() === 'descending' ? -1 : 1)
        const customSortValueList: Array<string | number> = browseByAttributeConfig[AttributeSystemName].customSortValueList && Array.isArray(browseByAttributeConfig[AttributeSystemName].customSortValueList)
          ? browseByAttributeConfig[AttributeSystemName].customSortValueList!
          : []
        if (propertyInfo.type) {
          const record: IBrowseBy = { key: propertyInfo.SystemName, label: propertyInfo.displayLabel, type: propertyInfo.type, displayLabel: `${t('general.browseBy')} ${propertyInfo.displayLabel}`, sortDirectionMultiple, predefinedCustomSortValueListLower: getCustomSortValueListForComparer(propertyInfo.type, customSortValueList) }
          utils.insertSorted(record, browseByDynamicList, (a, b) => utils.comparer(a, b, ['label']))
        }
      }
    }
  }

  if (isBrowseByModelEnabled || browseByDynamicList.length) {
    browseByList.push({ key: 'browseByArticle', label: t('general.article'), displayLabel: t('general.browseByArticle'), sortDirectionMultiple: 1, predefinedCustomSortValueListLower: [], type: AttributeType.Nvarchar })
    if (isBrowseByModelEnabled) {
      browseByList.push({ key: 'browseByModel', label: t('general.model'), displayLabel: t('general.browseByModel'), sortDirectionMultiple: 1, predefinedCustomSortValueListLower: [], type: AttributeType.Nvarchar })
    }
  }
  browseByList = browseByList.concat(browseByDynamicList)
  return browseByList
}

function getCurrentBucketKey(bucketValueLower: string | null, rowDividerValue: string | null, columnDividerValue: string | null, rowDividerProperty: string | null | undefined, columnDividerProperty: string | null | undefined) {
  const pivotConfig: Array<string> = []
  if (utils.isDefined(rowDividerProperty)) {
    pivotConfig.push(rowDividerProperty)
  }
  if (utils.isDefined(columnDividerProperty)) {
    pivotConfig.push(columnDividerProperty)
  }
  pivotConfig.sort() // as agreed with API team whichever attribute comes first alphabetically will be set in rowDivider
  const rowDividerProp = pivotConfig.shift()
  const shouldSwitchRowAndColumn = rowDividerProp !== rowDividerProperty
  let sortedRowDividerValue: string | null = null
  let sortedColumnDividerValue: string | null = null
  if (shouldSwitchRowAndColumn) {
    sortedRowDividerValue = columnDividerValue
    sortedColumnDividerValue = rowDividerValue
  }
  else {
    sortedRowDividerValue = rowDividerValue
    sortedColumnDividerValue = columnDividerValue
  }
  return `${bucketValueLower}/${sortedRowDividerValue}/${sortedColumnDividerValue}`
}

// function getAccessibleBucketAttributes(getAttributesWithNoVisibilityRestriction: boolean, getAttributesWithNoEditabilityRestriction: boolean) {
//   // TODO: possibly do the sorting optional or in the consuming component (better to do it optional)
//   /**
//    * to resolve the issue with dependency of calc type bucket attributes where one calc type attributes with calculatedFormula dependant to other calc type bucket attributes that are based on aggregateBucketArticlesAttributeSystemName
//    * always evaluate calc type attributes that have calculated formula at the end, as agreed this will only resolve dependance issue when calc with calculatedFormula dependant to other calc based on aggregateBucketArticlesAttributeSystemName
//    * this will not resolve dependency chain
//    */
//   return userStore.activeCatalog?.BucketAttributeList.sort((a, b) => {
//     if (a.ValidationExpression == null || a.ValidationExpression == '' || !a.ValidationExpression.toString().toLowerCase().includes('calculatedformula')) {
//       return -1
//     }
//     else {
//       return 1
//     }
//   }).filter(bucketAttribute => bucketAttribute.Status && (!getAttributesWithNoVisibilityRestriction || bucketAttribute.Visible) && (!getAttributesWithNoEditabilityRestriction || bucketAttribute.Editable))
// }

function getBucketFilterCriteria(filterCriteria: Array<FilterCriteria>, activeCatalog: CatalogDetails, indexedLinkedCatalogs: Record<string, CatalogDetails>) {
  // as discussed and agreed with amardeep the generated string will not match the string generated from T1S
  let filterCriteriaParam = ''
  utils.sort(filterCriteria, ['attribute']).forEach((criteria, index) => {
    let criteriaValueForBuckets = criteria.getCriteriaValueForBuckets()
    if (criteria.attribute === '_DeliveryDates') {
      const deliveryDatesMap = appConstants.staticAttributes._DeliveryDates.FilterLookup
      const value = criteria.multipleVals?.reduce((acu, cur) => {
        if (cur != null && deliveryDatesMap.has(cur)) {
          utils.insertSorted(deliveryDatesMap.get(cur).toString().trim().toLowerCase(), acu)
        }
        return acu
      }, []).join(',')
      criteriaValueForBuckets = `${criteria.getCriteriaNameForBuckets()}${criteria.exclude ? '!=' : '='}${value}`
    }
    else if (criteria.attribute === '_Segmentations') {
      const value = criteria.multipleVals?.reduce((acu, cur) => {
        if (cur != null && activeCatalog._IndexedCatalogSegmentation.hasOwnProperty(cur)) {
          utils.insertSorted(activeCatalog._IndexedCatalogSegmentation[cur].Name.toString().trim().toLowerCase(), acu)
        }
        return acu
      }, []).join(',')
      criteriaValueForBuckets = `${criteria.getCriteriaNameForBuckets()}${criteria.exclude ? '!=' : '='}${value}`
    }
    else if (criteria.attribute === '_Seasons') {
      const value = criteria.multipleVals?.reduce((acu, cur) => {
        if (indexedLinkedCatalogs.hasOwnProperty(cur)) {
          utils.insertSorted(`${indexedLinkedCatalogs[cur].Season}${indexedLinkedCatalogs[cur].CatalogCode}`.toString().trim().toLowerCase(), acu)
        }
        else if (cur === activeCatalog.CatalogCode) {
          utils.insertSorted(`${activeCatalog.Season}${activeCatalog.CatalogCode}`.toString().trim().toLowerCase(), acu)
        }
        return acu
      }, []).join(',')
      criteriaValueForBuckets = `${criteria.getCriteriaNameForBuckets()}${criteria.exclude ? '!=' : '='}${value}`
    }
    if (utils.isDefined(criteriaValueForBuckets)) {
      filterCriteriaParam += criteriaValueForBuckets
      if (index !== filterCriteria.length - 1) {
        filterCriteriaParam += '-'
      }
    }
  })
  return filterCriteriaParam.length ? filterCriteriaParam : 'null'
}

function formattedBucketAttributeValues(bucketAttributeValues: Record<string, any>, parsedBucketAttribute: ICatalogBucketAttributeParsed, bucketArticleIdList: Array<string>, articles?: Array<MyArticle>, articlesIndexes?: Record<string, number>) {
  const currentAttributeValue = utils.isDefined(bucketAttributeValues[parsedBucketAttribute.AttributeSystemName]) ? bucketAttributeValues[parsedBucketAttribute.AttributeSystemName] : ''
  const attributeTypeId = parsedBucketAttribute.AttributeTypeId
  if (attributeTypeId === AttributeType.Date || attributeTypeId === AttributeType.DateOption) {
    return utils.formatDate(currentAttributeValue)
  }
  else if (attributeTypeId === AttributeType.DateTime) {
    return utils.formatDateTime(currentAttributeValue)
  }
  else if (attributeTypeId === AttributeType.MultiValue) {
    return currentAttributeValue
      ? currentAttributeValue.split(/\r?\n/).reduce((acu, cur) => {
        if (cur != null && cur.toString().trim() !== '') {
          acu.push(cur)
        }
        return acu
      }, []).join(',')
      : ''
  }
  else if (attributeTypeId === AttributeType.Calc) {
    if (utils.isDefined(parsedBucketAttribute.evaluateFunction)) {
      let evaluatedValue = -1
      try {
        evaluatedValue = parsedBucketAttribute.evaluateFunction(bucketAttributeValues)
      }
      catch (error) {
        // as discussed with Andre formula should take care of unexpected scenarios in case of any exception catch the error and log it on console
        console.warn(`exception in bucket attribute '${parsedBucketAttribute.AttributeSystemName}' evaluate function`, error)
      }
      bucketAttributeValues[parsedBucketAttribute.AttributeSystemName] = evaluatedValue
      return evaluatedValue
    }
    else if (parsedBucketAttribute.hasOwnProperty('aggregateBucketArticlesAttributeSystemName') && utils.isDefined(parsedBucketAttribute.aggregateBucketArticlesAttributeSystemName)) { // aggregate bucket article's attribute value
      let aggregateValue = 0
      if (parsedBucketAttribute.aggregateBucketArticlesAttributeSystemName === '$articleCount') {
        if (parsedBucketAttribute.includeInactiveArticles) {
          aggregateValue = bucketArticleIdList.length
        }
        else {
          if (articles && articlesIndexes) {
            aggregateValue = bucketArticleIdList.reduce((acu, articleId) => {
              const articleIndex = articlesIndexes[articleId]
              if (articleIndex >= 0 && articleIndex < articles.length) {
                const article = articles[articleIndex]
                if (article && article.Status === 1) {
                  acu++
                }
              }
              return acu
            }, 0)
          }
        }
      }
      else {
        if (articles && articlesIndexes) {
          bucketArticleIdList.forEach((articleId) => {
            const articleIndex = articlesIndexes[articleId]
            if (articleIndex >= 0 && articleIndex < articles.length) {
              const article = articles[articleIndex]
              if (article && (parsedBucketAttribute.includeInactiveArticles || article.Status === 1) && article.hasOwnProperty(parsedBucketAttribute.aggregateBucketArticlesAttributeSystemName!) && utils.isNumber(article[parsedBucketAttribute.aggregateBucketArticlesAttributeSystemName!])) {
                aggregateValue += Number(article[parsedBucketAttribute.aggregateBucketArticlesAttributeSystemName!])
              }
            }
          })
        }
      }
      // add calculated values to source
      bucketAttributeValues[parsedBucketAttribute.AttributeSystemName] = aggregateValue
      return aggregateValue
    }
  }
  else {
    return currentAttributeValue
  }
}
function getBucketQueryObject(filterCriteria: Array<FilterCriteria>, catalogDetails: CatalogDetails, linkedCatalogDetails: Record<number, CatalogDetails>, getGroupByColumAttribute: string | undefined, getGroupByRowAttribute: string | undefined, browseByAttribute: string) {
  const requestObject = {} as IGetBucketValuePayload
  requestObject.BrowseByAttribute = browseByAttribute
  requestObject.FilterCriteria = getBucketFilterCriteria(filterCriteria, catalogDetails, linkedCatalogDetails)

  const pivotConfig: Array<string> = []
  if (utils.isDefined(getGroupByColumAttribute)) {
    utils.insertSorted(getGroupByColumAttribute, pivotConfig)
  }
  if (utils.isDefined(getGroupByRowAttribute)) {
    utils.insertSorted(getGroupByRowAttribute, pivotConfig)
  }
  const rowDivider = pivotConfig.shift()
  const columnDivider = pivotConfig.shift()
  if (utils.isDefined(rowDivider)) {
    requestObject.GroupByRow = rowDivider
  }
  else {
    requestObject.GroupByRow = null
  }
  if (utils.isDefined(columnDivider)) {
    requestObject.GroupByColumn = columnDivider
  }
  else {
    requestObject.GroupByColumn = null
  }
  return requestObject
}
async function getIndexedBucketValues(catalogCode: number, filterCriteria: Array<FilterCriteria>, catalogDetails: CatalogDetails, linkedCatalogDetails: Record<number, CatalogDetails>, getGroupByColumAttribute: string | undefined, getGroupByRowAttribute: string | undefined, browseByAttribute: string) {
  let bucketsAttributesValue: Array<IBucketsModel> | null = null
  let result: Record<string, object> | null = null
  try {
    bucketsAttributesValue = await getBucketsValue(catalogCode, getBucketQueryObject(filterCriteria, catalogDetails, linkedCatalogDetails, getGroupByColumAttribute, getGroupByRowAttribute, browseByAttribute))
    result = {}
  }
  catch (error) {
    console.warn('unable to load bucket attributes value\n', error)
  }

  if (bucketsAttributesValue != null) {
    bucketsAttributesValue.forEach((bucketAttributeValue) => {
      // these values is based on what has been saved from T1SW and as agreed whatever we send API will return the same value of type string, for null they will return null
      const key = `${bucketAttributeValue.Value}/${bucketAttributeValue.GroupByRowValue}/${bucketAttributeValue.GroupByColumnValue}`
      try {
        result![key] = JSON.parse(bucketAttributeValue.BucketAttributeValues)
      }
      catch (error) {
        console.warn('Unable to parse BucketAttributeValues', error)
        result![key] = {}
      }
    })
  }
  return result
}
export {
  formattedBucketAttributeValues,
  // getAccessibleBucketAttributes,
  getBrowseByList,
  getBucketFilterCriteria,
  getCurrentBucketKey,
  getBucketQueryObject,
  getIndexedBucketValues,
}
