<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <!-- HEADER -->
    <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="headerTitle" :show-header="showHeader" />

    <!-- BODY -->
    <div class="flex-auto px-10 mt-4 alerts">
      <tx-alert show :type="type === 'lock' ? 'warning' : 'info'" :text="confirmationMessage" />
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    </div>

    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="confirmButtonText" :primary-disabled="loading" :secondary-disabled="loading"
      @primary-click="onUpdate" @secondary-click="onCancel"
    />
  </div>
</template>

<script setup lang='ts'>
import { useI18n } from 'vue-i18n'
import { computed, onMounted, ref, watch } from 'vue'
import type MyArticle from '@/models/myArticle'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import appConfig from '@/services/appConfig'
import useErrorMessage from '@/shared/composables/errorMessage'
import type { UpdateArticlesLockModel } from '@/api/t1/model/articleModel'
import { updateArticlesLock } from '@/api/t1/article'
import { useUserStore } from '@/store/userData'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'

interface IProps {
  type?: 'lock' | 'unlock'
  showHeader?: boolean
  articles: MyArticle[]
  isModel?: boolean
}
const props = withDefaults(defineProps<IProps>(), { type: 'lock', showHeader: true, isModel: false })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[]): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()

const loading = ref(false)

const headerTitle = computed(() => props.type === 'lock' ? t('lockOrUnlockArticle.lock') : t('lockOrUnlockArticle.unlock'))

const confirmationMessage = computed(() => props.type === 'lock'
  ? t('lockOrUnlockArticle.lockArticleConfirmation', { articles: props.isModel ? props.articles.map(a => a.ModelNumber).join(', ') : props.articles.map(a => a.ArticleNumber).join(', ') })
  : t('lockOrUnlockArticle.unlockArticleConfirmation', { articles: props.isModel ? props.articles.map(a => a.ModelNumber).join(', ') : props.articles.map(a => a.ArticleNumber).join(', ') }),
)

const confirmButtonText = computed(() => props.type === 'lock' ? t('lockOrUnlockArticle.lock') : t('lockOrUnlockArticle.unlock'))

function onCancel() {
  emit('cancel')
}

async function onUpdate() {
  if (userStore.activeCatalog && props.articles.length > 0) {
    loading.value = true
    const lock = props.type === 'lock' ? 1 : 0

    const requestObj: UpdateArticlesLockModel[] = []
    // when browse by model if a model is deactivated then deactivate all the active article of that model
    if (props.isModel) {
      for (const article of props.articles) {
        const modelArticles = await appConfig.DB?.articles
          .where({ CatalogCode: userStore.activeCatalog?.CatalogCode, ModelNumber: article.ModelNumber })
          .toArray()

        if (modelArticles) {
          modelArticles.forEach((modelArticle) => {
            requestObj.push({
              Id: modelArticle.Id,
              Lock: lock,
            })
          })
        }
      }
    }
    else {
      props.articles.forEach((article) => {
        requestObj.push({
          Id: article.Id,
          Lock: lock,
        })
      })
    }
    updateArticlesLock(userStore.activeCatalog.CatalogCode, props.type === 'lock', requestObj)
      .then(async () => {
        await userStore.doLoadData(['Articles'])
        emit('updated', props.articles)
      })
      .catch((e) => {
        console.error(e)
        errorMessage.value = t('general.unexpectedError')
      })
      .finally(() => loading.value = false)
  }
}

function reset() {
  loading.value = false
  errorMessage.value = ''
}

watch(() => props, reset)

onMounted(() => {
  reset()
})
</script>
