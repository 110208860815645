import { assignIn } from 'lodash-es'
import { v4 as guid } from 'uuid'
import { fabric } from 'fabric'
import { whiteboardConstants } from '@/models/constants'

interface IWbImageOptions extends fabric.IImageOptions {
  id?: string
  lock?: boolean
  preventUnlock?: boolean
}

export default class WbImage extends fabric.Image implements IWbObject {
  public id: string
  public type = whiteboardConstants.objectTypes.image
  public lock: boolean
  public preventUnlock: boolean
  public connectable: boolean
  public editableProps: Record<string, IWbObjectProp> = {
    scale: { name: 'size', type: 'scale' },
    lock: { name: 'Lock', type: 'lock' },
  }

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  constructor(e: HTMLImageElement, opt?: IWbImageOptions) {
    super(e, opt)
    this.id = opt?.id || guid()
    this.lock = opt?.lock || false
    this.connectable = true
    this.preventUnlock = opt?.preventUnlock || false

    this.setLock(this.lock)

    this.stateProperties = this.stateProperties?.concat(['lock'])
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'scale':
        if (value.scale <= 100 && this.width && this.height) {
          const scaleX = value.scaleX ? value.scaleX / 100 : value.scale / 100
          const scaleY = value.scaleY ? value.scaleY / 100 : value.scale / 100

          this.set('scaleX', scaleX)
          this.set('scaleY', scaleY)
        }
        break
      case 'lock':
        this.set('lock', value.lock)
        this.setLock(value.lock)
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'scale':
        result.scale = Math.round((this.scaleX || 1) * 100)
        break
      case 'lock':
        result.lock = this.lock
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject(propertiesToInclude?: string[]) {
    const props = propertiesToInclude || []
    props.push('id', 'lock', 'preventUnlock')
    return super.toObject(props)
  }

  static fromObject(object: WbImage, callback?: Function) {
    // eslint-disable-next-line ts/ban-ts-comment
    // @ts-expect-error
    this.loadFromUrl(object.src, object).then((v) => { callback && callback(v) })
  }

  static loadFromUrl(url: string, opt?: IWbImageOptions) {
    return new Promise<WbImage>((resolve) => {
      fabric.util.loadImage(url, (oImg) => {
        const img = new Image()
        img.onload = () => {
          const options = assignIn({ top: 10 + img.height / 2, left: 10 + img.width / 2 }, opt)
          resolve(new WbImage(oImg, options))
        }
        img.src = url
      })
    })
  }

  static getJsonObject(url, scaleValue, opt?: IWbImageOptions) {
    const o = opt
    if (o && scaleValue.scale <= 100) {
      const scale = scaleValue.scale / 100
      o.scaleX = scaleValue.scaleX ? scaleValue.scaleX / 100 : scale
      o.scaleY = scale
    }
    const img = new Image()
    const obj = new WbImage(img, o).toObject()
    obj.src = url
    return Promise.resolve(obj)
  }
}

const f: any = fabric
f.WbImage = WbImage
