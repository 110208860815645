export default class SimpleFavoriteTag {
  Id: number
  Tag: string
  Color: string

  constructor(Id: number, Tag: string, Color: string) {
    this.Id = Id
    this.Tag = Tag
    this.Color = Color
  }
}
