<template>
  <nav-status>
    <div class="flex flex-col h-full overflow-hidden">
      <div class="flex flex-row items-center px-8 py-10 border-b bg-card">
        <div class="flex-grow">
          <div class="mb-2 text-4xl font-extrabold leading-none tracking-tight" v-text="t('catalogSelection.title')" />
          <div class="ml-0.5 font-medium text-secondary" v-text="t('catalogSelection.nCatalogs', getFilteredCatalogs.length)" />
        </div>
        <div class="flex items-center space-x-3">
          <tx-input
            ref="refFilter" v-model="filter" type="text" clearable faicon="fa-light fa-magnifying-glass"
            :placeholder="t('catalogSelection.filter')" autofocus :rounded="true"
          />
          <tx-button type="confirm" width="100px" height="36px" :text="t('general.refresh')" @click="doRefresh" />
        </div>
        <div />
        <loader v-if="loadingCatalog" />
      </div>
      <div class="px-4 py-8 space-y-6 overflow-y-auto">
        <div v-if="loading" class="skeleton">
          <title-skeleton />
          <catalog-thumbnail-skeleton v-for="index in 30" :key="index" />
        </div>
        <div v-for="group in getGroups" v-else :key="group.title" class="flex flex-col">
          <div :id="`year -${group.title}`" class="flex">
            <div class="flex-grow pl-4 m-auto font-medium" v-text="group.title" />
          </div>
          <div class="flex flex-row flex-wrap">
            <catalog-thumbnail
              v-for="cat in group.catalogs" :key="cat" :catalog="getCatalogIndexes[cat]"
              @click="doGotoCatalog(getCatalogIndexes[cat].CatalogCode)"
            />
          </div>
        </div>
        <!-- PrivacyPolicyDialog -->

        <!-- Catalog Registration Dialog -->
      </div>
      <tx-drawer v-model="customerSelectionDrawer" right width="780px" @closed="onCustomerSelectionClosed">
        <div class="mx-40 mt-16">
          <!-- TODO:Implement loading indicatory for this panel -->
          <div class="mb-10 text-2xl font-semibold text-center text-dark-75" v-text="t('catalogSelection.selectCustomerTitle')" />
          <template v-if="loadingCustomers">
            <div class="w-full h-6">
              <loader />
            </div>
          </template>
          <template v-else>
            <div class="text-base mb-11" v-text="t('catalogSelection.selectCustomerDetails')" />
            <div class="mt-12">
              <tx-select
                v-model="customerSelectionForm.selectedCustomer" :label="t('catalogSelection.customerDropdown')" required filterable
                :placeholder="t('general.startTyping')" :data="getLinkedCustomers" display-prop="Name"
                secondary-display-prop="CustomerNumber" value-prop="CustomerId" @change="onCustomerChange"
              />

              <tx-switch
                v-show="getCanChangePriceGroup" v-model="customerSelectionForm.useCustomerPGs" type="inline" class="mt-6 text-base text-dark"
                :label="t('catalogSelection.useCustomerPriceGroup')" :disabled="customerSelectionForm.selectedCustomer === -1" @change="changeUserCustomerPG"
              />

              <tx-select
                v-show="getCanChangePriceGroup" v-model="customerSelectionForm.wholesalePG" class="mt-6" required filterable
                :label="priceGroupsLabel.wholesalePrice" :disabled="customerSelectionForm.useCustomerPGs"
                :data="getCatalogPriceGroups" value-prop="Id" display-prop="Name" secondary-display-prop="CurrencyCode"
              />

              <tx-select
                v-show="getCanChangePriceGroup" v-model="customerSelectionForm.retailPG" class="mt-6" required filterable
                :label="priceGroupsLabel.retailPrice" :disabled="customerSelectionForm.useCustomerPGs"
                :data="getCatalogPriceGroups" value-prop="Id" display-prop="Name" secondary-display-prop="CurrencyCode"
              />

              <tx-select
                v-show="getCanChangePriceGroup" v-model="customerSelectionForm.outletPG" class="mt-6" required filterable
                :label="priceGroupsLabel.outletPrice" :disabled="customerSelectionForm.useCustomerPGs"
                :data="getCatalogPriceGroups" value-prop="Id" display-prop="Name" secondary-display-prop="CurrencyCode"
              />
            </div>
            <div class="flex flex-row items-end justify-between w-full grow mt-11">
              <tx-button type="cancel" width="170px" height="40px" :text="t('general.close')" @click="doCloseCustomerSelection" />
              <tx-button
                type="confirm" width="170px" height="40px" :disabled="!getCustomerSelectionFormValid"
                :text="t('catalogSelection.openCatalog')" @click="doOpenCatalog"
              />
            </div>
          </template>
        </div>
      </tx-drawer>
    </div>
  </nav-status>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { onKeyStroke } from '@vueuse/core'
import { isArray } from 'lodash-es'
import CatalogThumbnail from '@/modules/catalogsList/components/CatalogThumbnail.vue'
import CatalogThumbnailSkeleton from '@/modules/catalogsList/components/CatalogThumbnailSkeleton.vue'
import TitleSkeleton from '@/shared/components/TitleSkeleton.vue'
import TxSwitch from '@/shared/components/TxSwitch.vue'
import TxInput from '@/shared/components/TxInput.vue'
import NavStatus from '@/layouts/NavStatus.vue'
import TxButton from '@/shared/components/TxButton.vue'
import CatalogDetails from '@/models/catalogDetails'
import type LinkedCustomer from '@/models/linkedCustomer'
import usePriceGroupsLabel from '@/shared/composables/priceGroupsLabel'
import TxDrawer from '@/shared/components/TxDrawer.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import Loader from '@/shared/components/Loader.vue'
import utils from '@/services/utils'
import appConfig from '@/services/appConfig'
import { useUserStore } from '@/store/userData'
import { createContainer, getContainer, getMyContainers } from '@/api/t1/container'
import { catalogsConstants } from '@/models/constants'
import type { ContainerItemDetails } from '@/api/t1/model/containerModel'

const userStore = useUserStore()
const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const loading = ref(false)
const catalogs = ref<ICatalog[]>([])
const customerSelectionDrawer = ref(false)
const loadingCustomers = ref(false)
const catalogDetails = ref<CatalogDetails>()
const catalogLinkedCustomers = ref<LinkedCustomer[]>()
const loadingCatalog = ref(false)
const customerSelectionForm = reactive({
  selectedCustomer: -1,
  catalog: null,
  retailPG: null as number | null,
  wholesalePG: null as number | null,
  outletPG: null as number | null,
  useCustomerPGs: false,
})
const filter = ref('')
const refFilter = ref<InstanceType<typeof TxSelect> | null>(null)
const recentCatalogsContainer = ref<ContainerItemDetails>()

onKeyStroke('/', (e) => {
  if ((e.target as HTMLElement)?.nodeName.toLowerCase() !== 'input') {
    e.preventDefault()
    refFilter.value?.focus()
  }
}, { target: document })

const { priceGroupsLabel } = usePriceGroupsLabel(catalogDetails)

async function doRefresh() {
  loading.value = true
  catalogs.value = await appConfig.DB!.getCatalogsList('Year', true)
  // in T1 Studio we show only live catalogs
  // return only live catalogs
  catalogs.value = catalogs.value.filter(c => c.IsLive === 1)
  loading.value = false
}

function doGotoCatalog(catalogCode: number) {
  router.push({ name: 'OpenCatalog', params: { catalogCode } })
}

async function doOpenCustomerSelection() {
  if (route.params.catalogCode && !Number.isNaN(Number(route.params.catalogCode))) {
    loadingCustomers.value = true
    const cc = Number(route.params.catalogCode)
    try {
      loadingCatalog.value = true
      utils.setTitle(t('routes.OpenCatalog'))
      catalogDetails.value = new CatalogDetails(await appConfig.DB!.getCatalogDetails(cc), userStore.userProfile.Roles)
      catalogLinkedCustomers.value = await appConfig.DB!.getLinkedCustomers(catalogDetails.value, true)
      loadingCatalog.value = false
      // check if the SkipCustomerSelection configuration added then set the config values
      if (catalogDetails.value && catalogDetails.value.Config.SkipCustomerSelection
        && utils.isDefined(catalogDetails.value.Config.SkipCustomerSelection.customerNumber)
        && utils.isDefined(catalogDetails.value.Config.SkipCustomerSelection.wholesalePriceGroupId)
        && utils.isDefined(catalogDetails.value.Config.SkipCustomerSelection.retailPriceGroupId)
        && utils.isDefined(catalogDetails.value.Config.SkipCustomerSelection.outletPriceGroupId)
        && !(userStore.userProfile.AccountDetails.AccountTypeId === 2 && userStore.userProfile.RestrictFullRange === 1 && catalogDetails.value.Config.SkipCustomerSelection.customerNumber === -1)) {
        customerSelectionDrawer.value = false
        customerSelectionForm.selectedCustomer = catalogDetails.value.Config.SkipCustomerSelection.customerNumber
        customerSelectionForm.retailPG = catalogDetails.value.Config.SkipCustomerSelection.retailPriceGroupId
        customerSelectionForm.wholesalePG = catalogDetails.value.Config.SkipCustomerSelection.wholesalePriceGroupId
        customerSelectionForm.outletPG = catalogDetails.value.Config.SkipCustomerSelection.outletPriceGroupId
        loadingCustomers.value = false
        doOpenCatalog()
      }
      else {
        customerSelectionDrawer.value = true
      }
    }
    catch (error) {
      console.error('Unable to load linked customers and/or Catalog details', error)
    }
    loadingCustomers.value = false
  }
}

function onCustomerChange(e: any) {
  if (e === -1) {
    customerSelectionForm.useCustomerPGs = false
  }
  if (customerSelectionForm.useCustomerPGs) {
    changeUserCustomerPG()
  }
  else {
    customerSelectionForm.wholesalePG = null
    customerSelectionForm.retailPG = null
    customerSelectionForm.outletPG = null
  }
}

function doCloseCustomerSelection() {
  utils.setTitle(t('routes.CatalogsList'))
  customerSelectionDrawer.value = false
}

function onCustomerSelectionClosed() {
  if (route.name !== 'CatalogsList') {
    router.push({ name: 'CatalogsList' })
  }
}

function doOpenCatalog() {
  if (customerSelectionForm.selectedCustomer != null) {
    router.push({
      name: 'Catalog',
      params: {
        catalogCode: route.params.catalogCode,
        customerId: customerSelectionForm.selectedCustomer,
        retailPG: customerSelectionForm.retailPG,
        wholesalePG: customerSelectionForm.wholesalePG,
        outletPG: customerSelectionForm.outletPG,
      },
    })
  }
}

const getCatalogIndexes = computed(() => {
  const res = {} as { [key: string]: ICatalog }
  if (catalogs.value) {
    catalogs.value.forEach((itm) => { res[itm.Id] = itm })
  }
  return res
})

const getFilteredCatalogs = computed(() => {
  if (!catalogs.value) { return [] }
  if (!filter.value || filter.value.length === 0) { return catalogs.value }
  const f = filter.value.toLowerCase()
  return catalogs.value.filter(itm => itm.CatalogName.toLowerCase().includes(f) || itm.AccountName.toLowerCase().includes(f) || itm.Season.toLowerCase().includes(f))
})

const getFilteredRecentCatalogs = computed(() => {
  if (!recentCatalogsContainer.value) { return [] }
  const recent = utils.tryParse(recentCatalogsContainer.value.Value)
  if (!recent || !isArray(recent)) { return [] }
  return getFilteredCatalogs.value.filter(itm => recent.includes(itm.CatalogCode))
})

const getGroups = computed(() => {
  const res: ICatalogGroup[] = []
  if (getFilteredRecentCatalogs.value.length > 0) {
    res.push({ title: t('catalogSelection.recent'), catalogs: getFilteredRecentCatalogs.value.map(itm => itm.Id) })
  }

  if (getFilteredCatalogs.value.length === 0) { return res }

  let g = { title: getFilteredCatalogs.value[0].Year, catalogs: [] as number[] }
  getFilteredCatalogs.value.forEach((cat) => {
    if (cat.Year !== g.title) {
      res.push(g)
      g = { title: cat.Year, catalogs: [] }
    }
    g.catalogs.push(cat.Id)
  })
  res.push(g)

  return res
})

const getCatalogPriceGroups = computed(() => {
  if (catalogDetails.value && catalogDetails.value.CatalogPriceGroupList) {
    return catalogDetails.value.CatalogPriceGroupList
  }
  else {
    return []
  }
})

const getLinkedCustomers = computed(() => {
  const res: any[] = []
  // Full Range is only for admin and sellers without RestrictFullRange
  if (userStore.userProfile.AccountDetails.AccountTypeId === 1
    || (userStore.userProfile.AccountDetails.AccountTypeId === 2 && userStore.userProfile.RestrictFullRange !== 1)) {
    res.push({ CustomerNumber: '', Name: t('general.fullRange'), CustomerId: -1 })
  }

  if (catalogLinkedCustomers.value) {
    const catalogLinkedCustomersRes = res.concat(catalogLinkedCustomers.value)
    return catalogLinkedCustomersRes
  }

  return res
})

const getCanChangePriceGroup = computed(() => {
  // TODO: Also check if the logged in user is a buyer
  return !customerSelectionForm.selectedCustomer || customerSelectionForm.selectedCustomer === -1 || customerSelectionForm.selectedCustomer > 0
})

const getCustomerSelectionFormValid = computed(() => {
  return customerSelectionForm.selectedCustomer != null
    && (customerSelectionForm.useCustomerPGs || (customerSelectionForm.retailPG !== null && customerSelectionForm.outletPG !== null && customerSelectionForm.wholesalePG !== null))
})

function changeUserCustomerPG() {
  if (catalogLinkedCustomers.value && customerSelectionForm.useCustomerPGs) {
    const selectedCustomer = catalogLinkedCustomers.value.find(
      linkedCustomer => linkedCustomer.CustomerId === customerSelectionForm.selectedCustomer,
    )
    if (utils.isDefined(selectedCustomer)) {
      customerSelectionForm.wholesalePG = selectedCustomer.WholesalePriceGroupId
      customerSelectionForm.retailPG = selectedCustomer.RetailPriceGroupId
      customerSelectionForm.outletPG = selectedCustomer.RetailAltPriceGroupId
    }
  }
}

watch(() => route.params, (newParams) => {
  if (newParams === undefined || !route.params.catalogCode || Number.isNaN(Number(route.params.catalogCode))) {
    doCloseCustomerSelection()
  }
  else {
    doOpenCustomerSelection()
  }
})

async function doLoadRecentCatalogs() {
  // Find the container that has the list of my recent boards
  const containersList = await utils.tryAsync(getMyContainers())
  if (containersList.success) {
    const container = containersList.result.data.find(f => f.ContainerName === catalogsConstants.recentCatalogsContainerName)
    if (!container) {
      // If container doesn't exist, create it
      const res = await utils.tryAsync(createContainer(userStore.userProfile.Id, catalogsConstants.recentCatalogsContainerName, '[]'))
      if (res.success) { recentCatalogsContainer.value = res.result.data }
    }
    else {
      // If container exists, load it
      const res = await utils.tryAsync(getContainer(container.Id))
      if (res.success) { recentCatalogsContainer.value = res.result.data }
    }
  }
}

onMounted(async () => {
  doLoadRecentCatalogs()

  await doRefresh()
  utils.setTitle(t('routes.CatalogsList'))
  await router.isReady()
  await doOpenCustomerSelection()
})
</script>
