export default class CustomerSegmentation {
  CatalogCode: number
  Id: number
  Segmentations: Segmentation[]

  constructor(catalogCode: number, customerId: number, customerSegmentation: []) {
    this.CatalogCode = catalogCode
    this.Id = customerId
    this.Segmentations = customerSegmentation.map((itm: any) => new Segmentation(itm))
  }
}

export class Segmentation {
  Id: number
  SegmentationId: number
  Status: number

  constructor(seg: any) {
    this.Id = seg.Id
    this.SegmentationId = seg.SegmentationId
    this.Status = seg.Status
  }
}
