import { defineStore } from 'pinia'
import utils from '@/services/utils'

interface IInnerNotification {
  id: string
  message: string
  url: string
  details: string
  type: NotificationListItemType
  showIcon: boolean
  duration: number
  timer: number
  actions?: INotificationAction[]
}

interface INotification {
  message: string
  url?: string
  details?: string
  type: NotificationListItemType
  duration?: number
  actions?: NotificationActionType[] | INotificationAction[]
}

interface INotificationAction {
  label?: string
  type: NotificationActionType
}

export const useNotificationStore = defineStore({
  id: 'notificationsStore',
  state: () => ({
    notifications: {} as Record<string, IInnerNotification>,
  }),
  actions: {
    addNotification(notification: INotification) {
      const id = utils.randomString(8)
      const itm: IInnerNotification = {
        id,
        duration: notification.duration === undefined ? 10000 : notification.duration,
        message: notification.message,
        url: notification.url || '',
        details: notification.details || '',
        showIcon: true,
        type: notification.type,
        timer: 0,
        actions: [],
      }
      if (notification.actions) {
        notification.actions.forEach((act) => {
          if (typeof act === 'string') {
            itm.actions?.push({ type: act as NotificationActionType, label: undefined })
          }
          else {
            itm.actions?.push(act)
          }
        })
      }
      this.notifications[id] = itm
      if (this.notifications[id].duration > 0) {
        this.notifications[id].timer = window.setTimeout(() => {
          this.dismissNotification(itm)
        }, itm.duration)
      }
    },
    dismissNotification(notification: IInnerNotification) {
      if (notification.timer) {
        clearTimeout(notification.timer)
      }
      delete this.notifications[notification.id]
    },
  },
})
