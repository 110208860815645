export default class CatalogShipmentWindowRange {
  Id: number
  Status: number
  ShipmentStartDate: Date
  ShipmentEndDate: Date
  RetailIntroMonth: string
  RetailExitMonth: string
  RetailIntroMonthId: number
  RetailExitMonthId: number
  Period: string
  CriteriaAttributeValue: string

  constructor(obj: any) {
    this.Id = obj.Id
    this.Status = obj.Status
    this.ShipmentStartDate = new Date(obj.ShipmentStartDate)
    this.ShipmentEndDate = new Date(obj.ShipmentEndDate)
    this.RetailIntroMonth = obj.RetailIntroMonth
    this.RetailExitMonth = obj.RetailExitMonth
    this.RetailIntroMonthId = obj.RetailIntroMonthId
    this.RetailExitMonthId = obj.RetailExitMonthId
    this.Period = obj.Period
    this.CriteriaAttributeValue = obj.CriteriaAttributeValue
  }
}
