import type { SendMentionNotificationsModel, SharedUserGroupModel, SharedUserModel, WhiteboardDetailsModel, WhiteboardItemModel } from './model/whiteboardModel'
import utils from '@/services/utils'
import Net from '@/services/net'

export function getMyWhiteboards(catalogCode: number, whiteboardType?: 'my' | 'shared', u?: string) {
  const params = new URLSearchParams()
  if (utils.isDefined(whiteboardType)) {
    params.set('whiteboardType', whiteboardType)
  }
  if (utils.isDefined(u)) {
    params.set('u', u)
  }
  return Net.t1.get<WhiteboardItemModel[]>(`/catalogs/${catalogCode}/mywhiteboards?${params.toString()}`)
}

export function getWhiteboardDetails(catalogCode: number, whiteboardId: number) {
  return Net.t1.get<WhiteboardDetailsModel>(`/catalogs/${catalogCode}/whiteboards/${whiteboardId}`)
}

export function createWhiteboard(catalogCode: number, name: string, fluidRelayId: string) {
  return Net.t1.post<WhiteboardItemModel>(`/catalogs/${catalogCode}/whiteboards`, { Name: name, FluidRelayId: fluidRelayId, SharedUsers: [], SharedUsersGroups: [] })
}

export function shareWhiteboard(catalogCode: number, whiteboardId: number, sharedUsers: SharedUserModel[] = [], sharedUserGroups: SharedUserGroupModel[] = []) {
  return Net.t1.put(`/catalogs/${catalogCode}/whiteboards/${whiteboardId}/share`, { SharedUsers: sharedUsers, SharedUsersGroups: sharedUserGroups })
}

export function updateWhiteboardDetails(catalogCode: number, whiteboardId: number, payload: Record<string, any>) {
  return Net.t1.put<WhiteboardDetailsModel>(`/catalogs/${catalogCode}/whiteboards/${whiteboardId}`, payload)
}

export function sendMentionNotifications(catalogCode: number, whiteboardId: number, payload: SendMentionNotificationsModel) {
  return Net.t1.post(`/catalogs/${catalogCode}/whiteboards/${whiteboardId}/tags/notifications`, payload)
}
