import type { IField } from '../admin/type'
import type { ITxDataTableColumn, ITxDataTableVisibleColumn } from '@/shared/components/txDataTable/TxDataTable.types'
import { AttributeType } from '@/models/catalogAttribute'
import { useUserStore } from '@/store/userData'

export default class RequestsToEditModel {
  modelPath: string
  fields: IField[]

  constructor(t) {
    this.modelPath = 'catalogs.requestsTable'
    // NOTE: Always use the clone of fields never change the property directly on field
    this.fields = this.getFields(t)
  }

  private getFields(t) {
    const userStore = useUserStore()
    // const { getArticlesLookup } = useMasterDataLookup()
    const fields: IField[] = [
      { SystemName: 'Id', DisplayName: t('requestsTable.fields.id'), Visible: true, AttributeType: AttributeType.Int } as IField,
      { SystemName: 'ObjectId', DisplayName: t('requestsTable.fields.objectId'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: '_SourceArticleNumber', DisplayName: t('requestsTable.fields.sourceArticleNumber'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: '_RequestSourceModelNumber', DisplayName: t('requestsTable.fields.sourceModelNumber'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'StateName', DisplayName: t('requestsTable.fields.stateName'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: '_impactedAttribute', DisplayName: t('requestsTable.fields.impactedAttribute'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: '_initialValue', DisplayName: t('requestsTable.fields.currentValue'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: '_requestedValue', DisplayName: t('requestsTable.fields.requestedValue'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'RequestVersion', DisplayName: t('requestsTable.fields.requestVersion'), Visible: true, AttributeType: AttributeType.Int } as IField,
      { SystemName: 'Status', DisplayName: t('requestsTable.fields.status'), Visible: true, AttributeType: AttributeType.Status } as IField,
      { SystemName: 'State', DisplayName: t('requestsTable.fields.requestState'), Visible: true, AttributeType: AttributeType.Int } as IField,
      { SystemName: 'Reason', DisplayName: t('requestsTable.fields.reason'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'Comment', DisplayName: t('requestsTable.fields.comment'), Visible: true, showInEdit: false, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'RequestType', DisplayName: t('requestsTable.fields.requestType'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'CreatedByUserName', DisplayName: t('requestsTable.fields.createdByUserName'), Visible: true, AttributeType: AttributeType.Int } as IField,
      { SystemName: 'UpdatedByUserName', DisplayName: t('requestsTable.fields.updatedByUserName'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'CreatedDate', DisplayName: t('requestsTable.fields.createdDate'), Visible: true, AttributeType: AttributeType.Date } as IField,
      { SystemName: 'UpdatedDate', DisplayName: t('requestsTable.fields.updatedDate'), Visible: true, AttributeType: AttributeType.Date } as IField,
    ]
    if (userStore.activeCatalog && userStore.activeCatalog.RequestAttributeList.length) {
      const requestAttributes = userStore.activeCatalog.RequestAttributeList.filter(a => a.Status > 0)
      requestAttributes.forEach((attribute) => {
        const newAtt: IField = {
          SystemName: attribute.AttributeSystemName,
          DisplayName: attribute.AttributeDisplayName,
          AttributeType: attribute.AttributeTypeId,
          Visible: true,
          Creatable: false,
          Editable: false,
          IsRequired: false,
          IsStatic: false,
          IsRequest: true,
          IsPersonal: false,
        }
        fields.push(newAtt)
      })
    }
    return fields
  }

  getDataTableColumns() {
    const dataTableFields: ITxDataTableColumn[] = this.fields.filter(field => field.Visible)
      .map(o => ({
        property: o.SystemName,
        title: o.DisplayName,
        filterLookup: o.FilterLookup,
        type: o.AttributeType,
        isRequest: o.IsRequest,
        lookupDisplayField: o.lookupDisplayField,
        lookupSource: o.lookupSource,
        lookupIdField: o.lookupIdField,
      }))
    return dataTableFields
  }

  getDefaultVisibleColumnList() {
    // Default column list
    const defaultColumns = ['Id', 'SourceArticleId', '_impactedAttribute', '_initialValue', '_requestedValue', 'State', 'RequestType', 'Status', 'UpdatedByUserName', 'UpdatedDate']

    const defaultFieldObjects: ITxDataTableVisibleColumn[] = this.fields.filter(field =>
      defaultColumns.includes(field.SystemName) && field.Visible && !field.IsPersonal,
    ).map(o => ({
      property: o.SystemName,
      order: 1,
      width: 0.175,
    }))

    return defaultFieldObjects
  }
}
