<template>
  <div class="catalog-thumbnail" :class="!isBucketListView ? `t-${size}` : `tb-${size}`">
    <div class="image" />
    <div class="name" :style="{ width: `${getNameSize}px` }" />
    <div class="footer" :style="{ width: `${getFooterSize}px` }" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface IProps {
  size: number
  isBucketListView?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  isBucketListView: false,
})

const getNameSize = computed(() => {
  let max = 0
  switch (props.size) {
    case 1:
      max = 86
      break

    case 2:
      max = 116
      break

    default:
      max = 141
      break
  }
  return Math.floor((Math.random() * (max - 50)) + 50)
})

const getFooterSize = computed(() => {
  let max = 0
  switch (props.size) {
    case 1:
      max = 86
      break

    case 2:
      max = 116
      break

    default:
      max = 141
      break
  }
  return Math.floor((Math.random() * (max - 50)) + 50)
})
</script>

<style lang="scss" scoped>
@mixin background-gradient {
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  border-radius: 5px;
}

.t-1 {
  width: 95px;
  height: 126px;

  .image {
    width: 60px;
    height: 60px;
  }

  .name {
    margin: 10px 5px 0;
    height: 15px;
  }

  .footer {
    margin: 5px 5px 0;
    height: 10px;
  }
}

.t-2 {
  width: 125px;
  height: 178px;

  .image {
    width: 105px;
    height: 105px;
  }

  .name {
    margin: 10px 10px 0;
    height: 10px;
  }

  .footer {
    margin: 5px 10px 0;
    height: 10px;
  }
}

.t-3 {
  width: 150px;
  height: 205px;

  .image {
    width: 105px;
    height: 105px;
  }

  .name {
    margin: 15px 10px 0;
    height: 20px;
  }

  .footer {
    margin: 10px 10px 0;
    height: 15px;
  }
}

.tb-1 {
  width: 135px;
  height: 131px;

  .image {
    width: 100px;
    height: 100px;
  }

  .name {
    margin: 10px 5px 0;
    height: 15px;
  }

  .footer {
    margin: 5px 5px 0;
    height: 10px;
  }
}

.tb-2 {
  width: 165px;
  height: 183px;

  .image {
    width: 120px;
    height: 120px;
  }

  .name {
    margin: 10px 10px 0;
    height: 10px;
  }

  .footer {
    margin: 5px 10px 0;
    height: 10px;
  }
}

.tb-3 {
  width: 180px;
  height: 210px;

  .image {
    width: 130px;
    height: 130px;
  }

  .name {
    margin: 15px 10px 0;
    height: 20px;
  }

  .footer {
    margin: 10px 10px 0;
    height: 15px;
  }
}

.catalog-thumbnail {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
  display: inline-block;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;

  .image {
    margin: 15px auto;
    @include background-gradient;
    animation: shine-lines 1.6s infinite linear;
  }

  .name {
    // display: table-cell;
    @include background-gradient;
    animation: shine-lines 1.6s infinite linear;
  }

  .footer {
    @include background-gradient;
    animation: shine-lines 1.6s infinite linear;
  }

  @keyframes shine-lines {
    0% {
      background-position: -100px;
    }

    40%,
    100% {
      background-position: 140px;
    }
  }
}
</style>
