<template>
  <div class="flags-band w-full flex flex-row flex-wrap overflow-hidden">
    <div
      v-for="flag in getVisibleFlags" v-show="!hideIfOff || flag.isOn" :key="flag.name"
      :class="mini ? 'flag-mini' : 'flag'"
      :style="{ backgroundColor: flag.back, color: flag.front, borderColor: flag.front }"
    >
      {{ flag.initials }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type MyArticle from '@/models/myArticle'

interface IProps {
  article: MyArticle
  flags: IFlagsBandFlag[]
  mini?: boolean
  max?: number
  hideIfOff?: boolean
}

const props = withDefaults(defineProps<IProps>(), { mini: false, max: -1, hideIfOff: false })
const getVisibleFlags = computed(() => {
  const res = props.flags.map((f) => {
    const isOn = props.article[f.name] && props.article[f.name]?.toString().trim() !== '' && f.value && f.value.toString().trim() !== '' && props.article[f.name]?.toString().toLowerCase() === f.value.toLowerCase()
    // if (this.max > 0 && res.length >= this.max) return
    return { ...f, isOn, back: 'white', front: isOn ? f.color : '#c2c2c2' }
  })
  return res.length > props.max ? res.slice(0, props.max) : res
})
</script>

<style lang="scss" scoped>
.flags-band {
  .flag {
    margin-left: 5px;
    border-radius: 2px;
    border: 1px solid #c2c2c2;
    color: white;
    width: 40px;
    height: 15px;
    text-align: center;
    font-size: 8px;
    font-weight: 600;
    line-height: 14px;

    &:first-child {
      margin-left: 0;
    }
  }

  .flag-mini {
    box-sizing: border-box;
    margin-right: 2px;
    font-size: 6px;
    font-weight: 600;
    line-height: 8px;
    border: 1px solid #8a8a8a;
    border-radius: 2px;
    width: 20px;
    height: 10px;
    text-align: center;
  }
}
</style>
