class AuthLoginType {
  loginType: number
  protocol: number
  requirePasswordReset: boolean
  firstName: string
  envStatus: number

  /**
   * Auth LoginType Response
   *
   * @param {object} obj Auth LoginType Response
   * @param {number} obj.loginType Login Type
   * @param {number} obj.protocol Protocol
   * @param {boolean} obj.requirePasswordReset True if user needs to reset password before logging in
   * @param {string} obj.firstName User's First Name
   * @param {number} obj.envStatus Environment's status
   */
  constructor({ loginType, protocol, requirePasswordReset, firstName, envStatus }: { loginType: number, protocol: number, requirePasswordReset: boolean, firstName: string, envStatus: number }) {
    this.loginType = loginType
    this.protocol = protocol
    this.requirePasswordReset = requirePasswordReset
    this.firstName = firstName
    this.envStatus = envStatus
  }
}

class AuthUser {
  id: number
  firstName: string
  lastName: string
  username: string
  email: string
  // phone: string
  // city: string
  // countryCode: string
  // department: string
  // designation: string
  // description: string
  status: boolean
  emailConfirmed: boolean
  loginType: number
  masterUser: number
  userType: number
  // createdOn: Date
  // updatedOn: Date
  // lastLogin: Date
  // allowedClients: string[]
  // allowedEnvironments: string[]

  get initials(): string {
    return this.firstName.substring(0, 1) + this.lastName.substring(0, 1)
  }

  constructor(authUserObj: any) {
    this.id = authUserObj.id
    this.firstName = authUserObj.firstName
    this.lastName = authUserObj.lastName
    this.username = authUserObj.username
    this.email = authUserObj.email
    this.status = authUserObj.status
    this.emailConfirmed = authUserObj.emailConfirmed
    this.loginType = authUserObj.loginType
    this.masterUser = authUserObj.masterUser
    this.userType = authUserObj.userType
  }
}

export { AuthLoginType, AuthUser }
