import type ColorPalletColor from './colorPalletColor'

export default class ColorPallet {
  CatalogCode: number
  Id: number
  Name: string
  ColorCriteria: Record<string, any>
  Status: number
  Colors: ColorPalletColor[]
  ColorIds: number[]

  constructor(CatalogCode: number, colorPallet: any) {
    this.CatalogCode = CatalogCode
    this.Id = colorPallet.Id
    this.Name = colorPallet.Name
    this.Status = colorPallet.Status
    this.Colors = colorPallet.Colors
    this.ColorIds = colorPallet.Colors.filter(c => c.Status > 0).map(c => c.ColorId)
    try {
      this.ColorCriteria = colorPallet.ColorCriteria ? JSON.parse(colorPallet.ColorCriteria) : {}
    }
    catch (error) {
      console.error(`Invalid Color Criteria for color pallet ${colorPallet.Code}: ${colorPallet.Name} -> ${colorPallet.ColorCriteria}`, error)
      this.ColorCriteria = {}
    }
  }
}
