<template>
  <tx-dialog
    v-model="visible" :title="t('saveImageDialog.title')" width="450px" :loading="loading"
    show-ok-cancel :ok-state="loading ? 'loading' : selectedImageTypes.length === 0 ? 'disabled' : 'enabled'"
    @click="doCancel()" @ok="doSave()"
  >
    <div class="w-full h-full">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <div class="w-full h-full">
        <div class="w-full">
          <tx-select
            v-model="selectedImageTypes" :label="t('saveImageDialog.selectImageTypes')" required clearable multiple-values
            :data="availableImageTypes" value-prop="key" display-prop="label"
          />
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import type MyArticle from '@/models/myArticle'
import DuneAsset from '@/models/duneAsset'
import { getArticlesAssets } from '@/api/t1/article'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import type Article from '@/models/article'

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'save'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()

const visible = ref(false)
const loading = ref(false)
const assetsMap = ref<Record<string, DuneAsset[]>>({})
const imageTypes = ref<Map<string, string>>(new Map())
const selectedImageTypes = ref<string[]>([])
const fileNamesForSave = new Map<string, number>()

const availableImageTypes = computed<IKeyLabel[]>(() => Array.from(imageTypes.value, (item) => { return { key: item[0], label: item[1] } }))

async function showDialog(articles: MyArticle[] | Article[]) {
  reset()
  loading.value = true
  const res = await getArticlesAssets(userStore.activeCatalog!.DuneContext, userStore.activeCatalog!.ContextKey, articles.map(s => s.ArticleNumber))
  if (res.data && res.data.length) {
    const assets = res.data
    if (assets && assets.length > 0) {
      assets.forEach((asset) => {
        const key = asset.Key.toLowerCase()
        if (!imageTypes.value.has(key)) {
          imageTypes.value.set(key, asset.Key)
          selectedImageTypes.value.push(key)
        }
        if (!assetsMap.value[key]) {
          assetsMap.value[key] = [asset]
        }
        else {
          assetsMap.value[key].push(asset)
        }
      })
    }
  }
  loading.value = false
}

function doCancel() {
  visible.value = false
  emit('cancel')
}

function getFileNameToSave(asset: DuneAsset) {
  let fileName = asset.FileName
  if (userStore.activeCatalog && utils.isValidStringValue(userStore.activeCatalog.Config.SaveImagesFileName)) {
    const evalFunction = `function(data){ return ${userStore.activeCatalog.Config.SaveImagesFileName}}`
    // eslint-disable-next-line no-new-func
    const tempFileName = Function(`'use strict';return (${evalFunction})`)()(asset)
    const count = fileNamesForSave.get(fileName) || 0
    fileNamesForSave.set(fileName, count + 1)
    fileName = count !== 0 ? `${tempFileName}(${count})` : tempFileName
  }
  else {
    const name = fileName.split('.').slice(0, -1).join('.')
    fileName = `${name}(${asset.Key})`
  }
  return fileName
}

async function doSave() {
  errorMessage.value = ''
  if (selectedImageTypes.value.length && userStore.activeCatalog) {
    loading.value = true
    fileNamesForSave.clear()
    const format = userStore.activeCatalog.Config.DownloadArticleImageFormat
    const promises: Promise<any>[] = []
    const selectedImageTypesSet = new Set(selectedImageTypes.value)
    selectedImageTypes.value.forEach((key) => {
      if (assetsMap.value[key]) {
        const assets = assetsMap.value[key]
        assets?.forEach((asset) => {
          const key = asset.Key.toLowerCase()
          if (selectedImageTypesSet.has(key)) {
            const promise = axios({
              url: `${appConfig.AssetsUrl}/assets/content/${asset.StorageFile}?ContextKey=${encodeURIComponent(userStore.activeCatalog!.ContextKey)}&f=${format}`,
              method: 'GET',
              responseType: 'blob', // important
            }).then((response) => {
              const duneAsset = new DuneAsset(userStore.activeCatalog!.DuneContext, asset)
              const fileName = `${getFileNameToSave(duneAsset)}.${format}`

              const link = document.createElement('a')
              link.href = URL.createObjectURL(response.data)
              link.setAttribute('download', fileName)
              link.click()
            })
            promises.push(promise)
          }
        })
      }
    })

    Promise.all(promises)
      .then(() => {
        loading.value = false
        visible.value = false
        emit('save')
      })
      .catch((e) => {
        console.error(e)
        errorMessage.value = t('general.unexpectedError')
      })
  }
  else {
    errorMessage.value = t('validations.formInvalid')
  }
}

function reset() {
  loading.value = false
  visible.value = true
  imageTypes.value = new Map()
  selectedImageTypes.value = []
  assetsMap.value = {}
}

defineExpose({
  showDialog,
})
</script>
