<template>
  <div class="overflow-hidden text-default w-full min-h-screen flex flex-auto">
    <div class="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-5">
      <div class="flex md:w-full md:max-w-6xl sm:rounded-2xl sm:shadow overflow-hidden sm:bg-card">
        <div class="w-full sm:w-auto py-8 px-4 sm:p-12 md:p-14">
          <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <slot />
          </div>
        </div>
        <div class="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-28 overflow-hidden bg-gray-800 dark:border-l">
          <svg
            viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice"
            xmlns="http://www.w3.org/2000/svg" class="absolute inset-0 pointer-events-none "
          >
            <g fill="none" stroke="currentColor" stroke-width="100" class="text-gray-700 opacity-25 ">
              <circle r="234" cx="196" cy="23" class="" />
              <circle r="234" cx="790" cy="491" class="" />
            </g>
          </svg>
          <svg viewBox="0 0 220 192" width="220" height="192" fill="none" class="absolute -top-16 -right-16 text-gray-700">
            <defs>
              <pattern
                id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                patternUnits="userSpaceOnUse" class=""
              >
                <rect x="0" y="0" width="4" height="4" fill="currentColor" class="" />
              </pattern>
            </defs>
            <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" class="" />
          </svg>
          <div class="z-10 relative w-full max-w-2xl">
            <div class="text-7xl font-bold leading-none text-gray-100 ">
              <div>{{ t('login.welcomeTitle1') }}</div>
              <div>{{ t('login.welcomeTitle2') }}</div>
            </div>
            <div class="mt-6 text-lg tracking-tight leading-6 text-gray-400 flex-grow">
              Plan intelligently,
              merchandise efficiently and sell confidently. Let’s build something great together!
            </div>
            <div class="flex-grow-0 h-8 pt-18 flex flex-row">
              <div class="text-gray-400 text-md mt-1 pr-2 flex-grow text-right">
                Powered by
              </div>
              <div class="m-auto text-gray-400" v-html="svgTrasix" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import svgTrasix from '@/assets/svg/trasix.svg?raw'

const { t } = useI18n()
</script>
