<template>
  <div class="w-full h-full relative m-auto">
    <!-- Loading -->
    <loader v-if="isLoading" :width="Math.min(size / 5, 50)" :height="Math.min(size / 5, 50)" />
    <div
      v-else-if="!isLoading && (!imgSrc || imgSrc === '')" class="object-contain w-full h-full text-dark-25"
      v-html="svgNoImage"
    />
    <div v-else-if="errorLoadingImg" class="object-contain w-full h-full text-red-500" v-html="svgNoImage" />
    <div class="w-full h-full">
      <model-viewer
        v-if="asset?.Is3DModel" class="object-contain w-full h-full" camera-controls auto-rotate :src="imgSrc"
        @load="Loaded" @error="LoadingError"
      />
      <img
        v-else v-show="isLoaded" class="object-contain w-full h-full" :src="imgSrc" @load="Loaded"
        @error="LoadingError"
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import svgNoImage from '@/assets/svg/no-image.svg?raw'
import Loader from '@/shared/components/Loader.vue'
import type DuneAsset from '@/models/duneAsset'
import appConfig from '@/services/appConfig'
import utils from '@/services/utils'

interface IProps {
  asset?: DuneAsset
  articleNumber?: string
  context?: string
  contextKey?: string
  size?: 0 | 50 | 60 | 95 | 105 | 120 | 400 | 500
  format?: 'webp' | 'png' | 'glb' | 'gltf'
}
const props = withDefaults(defineProps<IProps>(), { size: 95, format: 'webp' })

const isLoaded = ref(false)
const isLoading = ref(false)
const imgSrc = ref('')
const errorLoadingImg = ref(false)

// const styles = computed(() => {
//   return {
//     width: props.size > 0 ? `${props.size}px` : '100%',
//     height: props.size > 0 ? `${props.size}px` : '100%',
//   }
// })

function Loaded() {
  isLoaded.value = true
  isLoading.value = false
}

function LoadingError() {
  isLoading.value = false
  errorLoadingImg.value = true
}

function LoadImage() {
  isLoaded.value = false
  isLoading.value = true
  errorLoadingImg.value = false
  let url = ''
  const params = new URLSearchParams()
  if (utils.isValidStringValue(props.context)) {
    params.set('Context', props.context!)
  }
  if (utils.isValidStringValue(props.contextKey)) {
    params.set('ContextKey', props.contextKey!)
  }
  if (props.size > 0) {
    params.set('w', props.size.toString())
    params.set('h', props.size.toString())
  }
  params.set('f', props.format)
  if (utils.isDefined(props.asset) && utils.isValidStringValue(props.contextKey)) {
    url = `${appConfig.AssetsUrl}/assets/content/${props.asset.StorageFile}?${params.toString()}`
  }
  else if (utils.isDefined(props.articleNumber) && utils.isValidStringValue(props.context) && utils.isValidStringValue(props.contextKey)) {
    params.set('ImageSet', props.articleNumber!)
    url = `${appConfig.AssetsUrl}/assets/r?${params.toString()}`
  }
  else {
    return
  }
  if (imgSrc.value !== url) {
    imgSrc.value = url
  }
  else {
    Loaded()
  }
}

// first time load
LoadImage()

watch(() => props.asset, LoadImage)
</script>
