<template>
  <div class="mx-auto mt-16 text-2xl">
    {{ t('general.hi') }} {{ firstName }}
  </div>

  <div class="w-full mt-2 text-base">
    <span>{{ t('login.resetPasswordHeader') }}, {{ t('general.please') }}</span>
    <tx-button class="text-base" type="text" :disabled="loading" @click="onForgotPassword">
      &nbsp;{{ t('general.clickHere').toLowerCase() }}&nbsp;
    </tx-button>
    <span>{{ t('login.resetPasswordSubHeader') }}</span>
  </div>

  <div class="w-full h-56 mt-4 text-base">
    {{ t('login.resetPasswordBody', { count }) }}
  </div>

  <div class="w-full">
    <tx-button type="cancel" width="180px" height="40px" :disabled="loading" :text="t('general.cancel')" @click="onCancel" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import TxButton from '@/shared/components/TxButton.vue'

interface IProps {
  loading?: boolean
  firstName?: string
  userName?: string
}
const props = withDefaults(defineProps<IProps>(), { loading: false, firstName: '', userName: '' })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'forgotPassword', userName: string): void
}>()

const { t } = useI18n()

const count = ref(20)

const interval = setInterval(() => {
  if (count.value > 0) {
    count.value--
  }
  else {
    onForgotPassword()
  }
}, 1000)

function onCancel() {
  clearInterval(interval)
  emit('cancel')
}

function onForgotPassword() {
  clearInterval(interval)
  emit('forgotPassword', props.userName)
}
</script>
