<template>
  <tx-dialog v-model="dialogVisible" :title="t('dataTable.advanceFilter.filterBy', { displayLabel: headerDisplyLabel })" width="500px">
    <div class="w-full h-full">
      <div class="w-full my-2">
        <tx-input v-model="formModel.filterValues" type="textarea" style="height: 200px;" :disabled="formModel.filterBlank" />
      </div>
      <div class="flex w-full gap-5 pb-10">
        <tx-switch v-model="formModel.filterBlank" class="w-auto h-5" :label="t('dataTable.advanceFilter.filterBlank')" />
        <tx-switch v-model="formModel.excludeMode" class="w-auto h-5" :label="t('dataTable.advanceFilter.filterExclude')" />
      </div>
    </div>

    <template #footer>
      <div class="flex items-center justify-center px-6 py-4 space-x-3 sm:justify-end bg-gray-50">
        <tx-button type="confirm" :text="t('general.save')" width="100px" @click="doOk()" />
        <tx-button type="cancel" :text="t('general.cancel')" width="100px" @click="doCancel()" />
      </div>
    </template>
  </tx-dialog>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import TxDialog from '../TxDialog.vue'
import type { ITxDataTableVisibleColumn } from './TxDataTable.types'
import TxButton from '@/shared/components/TxButton.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxSwitch from '@/shared/components/TxSwitch.vue'
import { useUserStore } from '@/store/userData'
import type { FilterCriteria } from '@/models/filterCriteria'

const props = defineProps<{
  filterCriteria?: Record<string, FilterCriteria>
}>()

const emits = defineEmits<{
  (e: 'ok', columnFilterObject: Record<string, IFormItem>): void
  (e: 'cancel'): void
}>()

defineExpose({ show })

interface IFormItem {
  filterValues: string | ''
  filterBlank: boolean | false
  excludeMode: boolean | false
}

const formModel = reactive<IFormItem>({
  filterValues: '',
  filterBlank: false,
  excludeMode: false,
})

const { t } = useI18n()
const userStore = useUserStore()
const dialogVisible = ref(false)
let attributeSystemName: string = ''
let headerDisplyLabel: string = ''
const column = ref<ITxDataTableVisibleColumn>()

async function show(col: ITxDataTableVisibleColumn) {
  column.value = col
  attributeSystemName = column.value.property
  if (userStore.myAttributes && userStore.myAttributes.hasOwnProperty(attributeSystemName)) {
    headerDisplyLabel = userStore.myAttributes[attributeSystemName].DisplayName
  }
  // prefill formModel if advanced filter is applied
  const columnFilterValues = props.filterCriteria?.[attributeSystemName]
  if (columnFilterValues) {
    formModel.filterValues = columnFilterValues.multipleVals ? columnFilterValues.multipleVals.join('\n') : ''
    formModel.filterBlank = columnFilterValues.filterBlank ? columnFilterValues.filterBlank : false
    formModel.excludeMode = columnFilterValues.exclude
  }
  else {
    // clear formModel
    formModel.filterValues = ''
    formModel.filterBlank = false
    formModel.excludeMode = false
  }
  dialogVisible.value = true
}

function doOk() {
  dialogVisible.value = false
  const filterCriteriaObject = {}
  filterCriteriaObject[attributeSystemName] = formModel
  emits('ok', filterCriteriaObject)
}

function doCancel() {
  dialogVisible.value = false
  emits('cancel')
}
</script>
