import { assignIn, pick } from 'lodash-es'
import { v4 as guid } from 'uuid'
import { fabric } from 'fabric'
import { merchConstants } from '@/models/constants'

interface IMbImageOptions extends fabric.IImageOptions {
  id?: string
  locked?: boolean
  templateObject: boolean
}
const editableProps: Record<string, IMbObjectProp> = {
  scale: { name: 'size', type: 'scale' },
  locked: { name: 'lock', type: 'lock' },
}
export default class MbImage extends fabric.Image implements IMbObject {
  public id: string
  public type = merchConstants.objectTypes.image
  public locked: boolean
  public templateObject?: boolean
  public editableProps: Record<string, IMbObjectProp> = editableProps

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    copy: { action: 'copy', label: 'Copy Selection', faicon: 'fa-light fa-copy', showInSubMenu: true },
    align: { action: 'align', label: 'Align', faicon: 'fa-light fa-align-justify', showInSubMenu: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  constructor(e: HTMLImageElement, opt?: IMbImageOptions) {
    super(e, opt)
    this.id = opt?.id || guid()
    this.locked = opt?.locked || false
    this.originX = 'center'
    this.originY = 'center'
    this.templateObject = opt?.templateObject || false
    this.setLock(this.locked)

    this.stateProperties = this.stateProperties?.concat(['locked'])
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'scale':
        if (value.scale <= 100 && this.width && this.height) {
          const scale = value.scale / 100
          this.set('scaleX', scale)
          this.set('scaleY', scale)
        }
        break
      case 'locked':
        this.set('locked', value.locked)
        this.setLock(value.locked)
        break
      default:
        console.warn('Attempting to set unsupported MbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'scale':
        result.scale = Math.round((this.scaleX || 1) * 100)
        break
      case 'lock':
        result.lock = this.locked
        break
      default:
        console.warn('Attempting to get unsupported MbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject() {
    const propsToPluck = [
      'id',
      'type',
      'top',
      'left',
      'width',
      'height',
      'angle',
      'scaleX',
      'scaleY',
      'flipX',
      'flipY',
      'locked',
      'templateObject',
      'baseScaleFactorY',
      'baseScaleFactorX',
      'mask',
    ]
    return { ...pick(this, propsToPluck), data: this.getSrc() }
  }

  static fromObject(object: MbImage, callback?: Function) {
    // eslint-disable-next-line ts/ban-ts-comment
    // @ts-expect-error
    this.loadFromUrl(object.src, object).then((v) => { callback && callback(v) })
  }

  static loadFromUrl(url: string, opt?: IMbImageOptions) {
    return new Promise<MbImage>((resolve) => {
      fabric.util.loadImage(url, (oImg) => {
        const imgSrc = oImg.getAttribute('src')
        const img = new Image()
        img.onload = () => {
          const options = assignIn({ top: 10 + img.height / 2, left: 10 + img.width / 2 }, opt)
          resolve(new MbImage(oImg, options))
        }
        img.src = imgSrc || url
      })
    })
  }

  //   static getJsonObject(url, scaleValue, opt?: IMbImageOptions) {
  //     const o = opt
  //     if (o && scaleValue.scale <= 100) {
  //       const scale = scaleValue.scale / 100
  //       o.scaleX = scale
  //       o.scaleY = scale
  //     }
  //     const img = new Image()
  //     const obj = new MbImage(img, o).toObject()
  //     obj.src = url
  //     return Promise.resolve(obj)
  //   }
  static getEditableProps() {
    return editableProps
  }
}

const f: any = fabric
f.MbImage = MbImage
