import type { IPdfTemplate, IPdfTemplateFileMapping, IPdfTemplateOption } from './IPdfTemplate'
import type CatalogDetails from '@/models/catalogDetails'
import utils from '@/services/utils'
import { exportConstants } from '@/models/constants'
import type MyArticle from '@/models/myArticle'
import appConfig from '@/services/appConfig'
import type CatalogPriceGroup from '@/models/catalogPriceGroup'
import type { EllipseObjectPdf, ImageObjectPdf, LineObjectPdf, PDFJsonSchema, RectObjectPdf, TextObjectPdf, pdfPagObject } from '@/api/fileServer/model/generateModel'

/* eslint-disable style/no-mixed-spaces-and-tabs */
/* eslint-disable style/no-tabs */
class UaStandard implements IPdfTemplate {
  id = exportConstants.pdfTemplatesId.uaStandard
  name = 'Under Armour - Standard'
  includeMarkdownDate = false
  includeHardLaunchDate = false
  includeShipmentStartDate = true
  includeShipmentEndData = true
  remainingHeight = 0
  headerHeight = 28
  pageHeight = 595
  pageWidth = 842
  arialFont = 'Arial'
  arialBoldFont = 'Arial-Bold'
  helveticaFont = 'Helvetica'
  positionOfPreviousObject = 0 // position of previous text
  titleFontSize = 10
  addLine = false
  logoWidth = 28
  modelInfoWidth = 303 // (36*5 +30)
  // this.font = arialNormal.name
  maxImagePerRow = 5
  imgWidth = 102
  imgHeight = 102
  currentPositionForImage = this.headerHeight
  currentTopForImages = this.headerHeight
  maxHeight = 0
  addRow = true
  verticalOffset = 0.5
  currentModelHeight = 0
  leftMargin = 5
  imageHeightIncreased = 0
  positionOfPreviousModel = 0
  minimumRequiredHeightForImages = 0
  spacingBetweenRows = 14
  row = 0
  modelNumberProp = {
    fontSize: 8,
    fontType: 'normal',
    width: this.modelInfoWidth - 14, // check,
    height: 9,
  }

  platformProp = {
    fontSize: 8,
    fontType: 'normal',
    width: this.modelInfoWidth - 14, // check,
    height: 0,
  }

  articleNumberProp = {
    fontSize: 10,
    fontType: 'bold',
    marginTop: 8,
    width: this.modelInfoWidth - 14,
    height: 0,
  }

  priceAndFabricProp = {
    fontSize: 9,
    fontType: 'normal',
    width: this.modelInfoWidth - 14,
    height: 0,
  }

  featuresAndBenefitProp = {
    fontSize: 7,
    fontType: 'normal',
    width: this.modelInfoWidth - 14,
    marginTop: 14,
    height: 0,
  }

  colorwayAndShippingProp = {
    fontSizeHeading: 7,
    fontTypeHeading: 'bold',
    // font: 'sans-serif',
    fontTypeData: this.arialFont,
    fontSizeData: 6,
    width: this.modelInfoWidth - 14, // check
    verticalSpacing: 14,
    paddingBetweenColumns: 14,
    cellWidthForCw: 14,
    cellWidthForShipping: 38,
    cellWidthForMarkdownDate: 0,
    cellWidthForHardLaunchDate: 0,
    highlightWidth: this.modelInfoWidth - 14,
    rowHeight: 8.5,
    requiredAnotherLine: false,
    marginTop: 20,
    headerHeight: 0,
    dataHeight: 0,
    colorwayWidth: 0,
    rectExpanded: 0,
    rowHighlight: 1,
    highlight: 0,
  }

  articleImageProp = {
    imgHorSpacing: 1.1,
    imgVerSpacing: 1.7,
    left: 0,
    top: 0,
  }

  articleImageInfoProp = {
    fontSize: 6,
    fontType: 'normal',
    left: 0,
    top: 0,
  }

  getOptions(): IPdfTemplateOption[] {
    const options: IPdfTemplateOption[] = []
    options.push({
      name: 'isOneModelPerPage',
      label: 'exportPDFTemplate.options.oneModelPerPage',
      type: 'bool',
      default: false,
      required: false,
    })
    options.push({
      name: 'sortByExcelSequence',
      label: 'exportPDFTemplate.options.sortByExcelSequence',
      type: 'bool',
      default: false,
      required: false,
    })
    options.push({
      name: 'includeInactiveArticles',
      label: 'exportPDFTemplate.options.includeInactiveArticles',
      type: 'bool',
      default: false,
      required: false,
    })

    // if(utils.isValidStringValue(catalog.Config.ArIndicatorAttribute) && utils.isDefined(myAttributes[catalog.Config.ArIndicatorAttribute])) {
    //   options.push({
    //     name: 'addARIndicator',
    //     label: 'generateFrameDialog.steps.options.addARIndicator',
    //     type: 'bool',
    //     default: true,
    //     required: false,
    //   })
    // }

    // if(utils.isValidStringValue(catalog.Config.GhlIndicatorAttribute) && utils.isDefined(myAttributes[catalog.Config.GhlIndicatorAttribute])) {
    //   options.push({
    //     name: 'addGHLIndicator',
    //     label: 'generateFrameDialog.steps.options.addGHLIndicator',
    //     type: 'bool',
    //     default: true,
    //     required: false,
    //   })
    // }

    // if(utils.isValidStringValue(catalog.Config.BAndTIndicatorAttribute) && utils.isDefined(myAttributes[catalog.Config.BAndTIndicatorAttribute])) {
    //   options.push({
    //     name: 'addBAndTIndicator',
    //     label: 'generateFrameDialog.steps.options.addBAndTIndicator',
    //     type: 'bool',
    //     default: true,
    //     required: false,
    //   })
    // }
    return options
  }

  getFileMapping(): IPdfTemplateFileMapping[] {
    const mapping: IPdfTemplateFileMapping[] = []
    mapping.push({
      name: 'articleNumber',
      column: 'ArticleNumber',
      type: 'articleNumber',
      label: 'generateFrameDialog.steps.mapping.articleNumber',
      required: true,
      autoMap: ['article', 'articlenumber', 'article number', 'artnbr', 'style color code'],
    })
    return mapping
  }

  async generate(catalog: CatalogDetails, articlesData: MyArticle[], options: Record<string, any>, retailPg?: CatalogPriceGroup): Promise<PDFJsonSchema> {
    let pdfJson: PDFJsonSchema = { props: { size: 'A4' }, pages: [] }
    this.minimumRequiredHeightForImages = this.calculateMinimumRequiredHeightForImages()
    const headerGroups: Map<string, groupItems> = this.generateGroups(articlesData, options.sortByExcelSequence || false, catalog, retailPg)
    // TODO: check for the font
    for (const headerGroup of headerGroups.values()) {
      this.row = 0
      // let newPage : pdfPagObject = {props:{layout: 'landscape', size: 'A4'}}
      // newPage.objects = []
      pdfJson = this.onNewPage(headerGroup, options, catalog, pdfJson)
      // pdfJson.pages.push(newPage)
    }
    return Promise.resolve(pdfJson)
  }

  onNewPage(headerGroup, options, catalog: CatalogDetails, pdfJson: PDFJsonSchema) {
    let pageObjects: (TextObjectPdf | RectObjectPdf | ImageObjectPdf | LineObjectPdf | EllipseObjectPdf)[] = this.createPage(headerGroup.headerInfo.title.value, headerGroup.headerInfo.subtitle.value)
    const modelNumbers = Array.from(headerGroup.modelGroups.keys())
    for (let index = 0; index < modelNumbers.length; index++) {
		  const modelNumber = modelNumbers[index]
		  const model = headerGroup.modelGroups.get(modelNumber)

		  this.colorwayAndShippingProp.highlight = 1
		  this.positionOfPreviousModel = this.positionOfPreviousObject
		  if ((this.remainingHeight < this.minimumRequiredHeightForImages + this.spacingBetweenRows) || (options.isOneModelPerPage && index !== 0)) {
        const newPage: pdfPagObject = { props: { layout: 'landscape', size: 'A4', margin: 0 } }
        newPage.objects = pageObjects
        pdfJson.pages.push(newPage)
        pageObjects = []
        pageObjects.push(...this.createPage(headerGroup.headerInfo.title.value, headerGroup.headerInfo.subtitle.value))
        this.positionOfPreviousModel = this.positionOfPreviousObject
		  }
		  this.addRow = this.calculateModelInfoHeight(model, modelNumber)
		  if (!this.addRow) {
        const newPage: pdfPagObject = { props: { layout: 'landscape', size: 'A4', margin: 0 } }
        newPage.objects = pageObjects
        pdfJson.pages.push(newPage)
        pageObjects = []
        pageObjects.push(...this.createPage(headerGroup.headerInfo.title.value, headerGroup.headerInfo.subtitle.value))
        this.positionOfPreviousModel = this.positionOfPreviousObject
		  }
		  if (this.addLine) {
        this.addNewRow()
		  }
		  this.addLine = true
		  pageObjects.push(...this.addModelInfoAndTableHeader(model, modelNumber, true))
		  this.imageHeightIncreased = 0
		  // in case of excel sort all articles in each model by Article Number
		  if (options.isSortByExcelSequence) {
        utils.sort(model.articles, ['ArticleNumber'])
		  }

		  for (let index = 0; index < model.articles.length; index++) {
        const article = model.articles[index]
        const imageInRowIndex = index % this.maxImagePerRow
        if (imageInRowIndex === 0 && index !== 0) {
          // check for enough space for new row
          if (this.remainingHeight - this.imageHeightIncreased > this.minimumRequiredHeightForImages) {
            this.currentPositionForImage = this.currentTopForImages
          }
          else {
            // if not enough space add row on new page
            const newPage: pdfPagObject = { props: { layout: 'landscape', size: 'A4', margin: 0 } }
            newPage.objects = pageObjects
            pdfJson.pages.push(newPage)
            pageObjects = []
            pageObjects.push(...this.createPage(headerGroup.headerInfo.title.value, headerGroup.headerInfo.subtitle.value))
            pageObjects.push(...this.addModelInfoAndTableHeader(model, modelNumber, true))
            this.imageHeightIncreased = 0
            this.addLine = true
          }
        }
        pageObjects.push(...this.addArticleImageWithLabel(article, imageInRowIndex, index, catalog))
      }
		  this.remainingHeight = this.remainingHeight - (Math.max(this.positionOfPreviousObject - this.positionOfPreviousModel, (this.modelNumberProp.height + 8 + this.imageHeightIncreased)) + this.spacingBetweenRows)
      this.maxHeight = Math.max(this.positionOfPreviousObject, this.currentTopForImages) + this.spacingBetweenRows
		  this.positionOfPreviousObject = this.maxHeight
		  this.currentTopForImages = this.maxHeight
    }
    const newPage: pdfPagObject = { props: { layout: 'landscape', size: 'A4', margin: 0 } }
    newPage.objects = pageObjects
    pdfJson.pages.push(newPage)
    return pdfJson
  }

  addArticleImageWithLabel(article, imageInRowIndex, index, catalog) {
    // const exportDefaultImagePath = catalog!.Config.ExportDefaultImagePath

    const objects: (ImageObjectPdf | TextObjectPdf | RectObjectPdf)[] = []
    if (index === 0) {
      this.currentPositionForImage += 8 + this.modelNumberProp.height
    }
    this.articleImageProp.left = (imageInRowIndex * (this.imgWidth)) + this.modelInfoWidth
    this.articleImageProp.top = this.currentPositionForImage
    const colorCodeHeight = measureText(article.ColorCode, this.articleImageInfoProp.fontSize, this.arialFont).height

    this.articleImageInfoProp.left = this.articleImageProp.left// todo
    this.articleImageInfoProp.top = this.articleImageProp.top + this.imgHeight + this.articleImageProp.imgVerSpacing
    // let img = await ImageLoader.getImageOrFallbackData(article._mainImage, article._fallbackThumbnailImage, catalogData, appConstants.highResImageSize, updateDunesImageDBCommitMethod)
    // if(!article._isSegmented || article.Status <= 0) {
    //   //if article is not available then add the cross lines on it - this will first convert the image content to image object and then add the cross lines again
    //   img = await util.addInactiveLinesToImageContent(img, null, 'image/png')
    // }
    const params = new URLSearchParams()
    params.set('Context', catalog.DuneContext)
    params.set('ContextKey', catalog.ContextKey)
    params.set('ImageSet', article.ArticleNumber)
    params.set('w', this.imgWidth.toString())
    params.set('h', this.imgHeight.toString())
    params.set('f', 'png')
    if (catalog.Config.NewestImageAssetKeyList.length) {
      params.set('Key', catalog.Config.NewestImageAssetKeyList.toString())
    }
    const path = `${appConfig.AssetsUrl}/assets/r?${params.toString()}`
    objects.push(this.getImageObject(this.articleImageProp.left + this.articleImageProp.imgHorSpacing, this.articleImageProp.top + this.articleImageProp.imgVerSpacing, path, this.imgHeight, this.imgWidth))
    objects.push(this.getTextObject(article.ColorwayCode, this.articleImageInfoProp.left + this.articleImageProp.imgHorSpacing, this.articleImageInfoProp.top, this.arialFont, this.articleImageInfoProp.fontSize, '#757575', this.imgWidth, 0, 'center'))

    const colorwayHeight = measureText(article.Colorway, this.articleImageInfoProp.fontSize, this.arialFont).height
    objects.push(this.getTextObject(article.Colorway, this.articleImageInfoProp.left + this.articleImageProp.imgHorSpacing, this.articleImageInfoProp.top + colorCodeHeight, this.arialFont, this.articleImageInfoProp.fontSize, '#757575', this.imgWidth, 0, 'center'))
    objects.push(...this.addColorwayShippingData(article))

    // let indicatorAlias = ''
    // let indicatorWidth = 0
    // let indicatorsImagesData = []
    // if(options.addARIndicator && util.isDefined(arIndicatorKey) && util.isValidBooleanValue(article[arIndicatorKey]) ) {
    //   indicatorsImagesData.push(merchConstants.arIndicatorImageData)
    //   indicatorAlias += 'arIndicator'
    // }
    // if(options.addGHLIndicator && util.isDefined(ghlIndicatorKey) && util.isValidStringValue(article[ghlIndicatorKey])) {
    //   indicatorsImagesData.push(merchConstants.ghlIndicatorImageData)
    //   indicatorAlias += 'ghlIndicator'
    // }
    // if(options.addBAndTIndicator && util.isDefined(bAndTIndicatorKey) && util.isValidBooleanValue(article[bAndTIndicatorKey])) {
    //   indicatorsImagesData.push(merchConstants.bAndTIndicatorImageData)
    //   indicatorAlias += 'bAndTIndicator'
    // }
    // if(indicatorsImagesData.length !== 0) {
    //   indicatorWidth = indicatorsImagesData.length * 5
    //   let indicatorsImageData = await util.createIndictorsImageData(indicatorsImagesData)
    //   this.pdf.addImage(indicatorsImageData, 'PNG', this.articleImageProp.left + this.articleImageProp.imgHorSpacing + 1.5, this.articleImageProp.top + this.articleImageProp.imgVerSpacing + 1.8, indicatorWidth, 5, indicatorAlias, 'FAST')
    // }
    if (imageInRowIndex === 0) {
      const currentHeight = this.articleImageInfoProp.top + colorCodeHeight + colorwayHeight
      this.imageHeightIncreased += currentHeight - this.currentTopForImages
      this.currentTopForImages = currentHeight
    }
    return objects
  }

  addColorwayShippingData(article) {
    const objects: (ImageObjectPdf | TextObjectPdf | RectObjectPdf)[] = []
    let rectRowHeight = this.colorwayAndShippingProp.rowHeight
    const colorwayDimensions = measureText(article.colorway, this.colorwayAndShippingProp.fontSizeData, this.arialFont)
    if (colorwayDimensions.width > this.colorwayAndShippingProp.colorwayWidth) {
      this.colorwayAndShippingProp.rectExpanded = 1
      rectRowHeight = this.colorwayAndShippingProp.rowHeight * 2
    }
    else {
      rectRowHeight = this.colorwayAndShippingProp.rowHeight
    }
    const rectTop = this.positionOfPreviousObject
    if (this.colorwayAndShippingProp.highlight === 1) {
      const rectObject: RectObjectPdf = {
        type: 'rect',
        x: this.leftMargin,
        y: rectTop,
        w: this.colorwayAndShippingProp.highlightWidth,
        h: rectRowHeight,
        fill: '#dcdcdc',
      }
      objects.push(rectObject)
      this.colorwayAndShippingProp.highlight = 0
    }
    else {
      this.colorwayAndShippingProp.highlight = 1
    }
    const rowTop = rectTop + 1
    objects.push(this.getTextObject(article.ColorwayCode, this.leftMargin, rowTop, this.arialFont, this.colorwayAndShippingProp.fontSizeData, '#757575', this.colorwayAndShippingProp.cellWidthForCw))
    objects.push(this.getTextObject(article.Colorway, this.leftMargin + this.colorwayAndShippingProp.cellWidthForCw + this.colorwayAndShippingProp.paddingBetweenColumns, rowTop, this.arialFont, this.colorwayAndShippingProp.fontSizeData, '#757575', this.colorwayAndShippingProp.colorwayWidth, rectRowHeight))

    if (this.includeHardLaunchDate && article.HardLaunchDate != null) {
      if (this.includeShipmentStartDate) {
        objects.push(this.getTextObject(utils.formatDateUTC(article.ShipmentStartDate), this.colorwayAndShippingProp.highlightWidth - 2 * (this.colorwayAndShippingProp.cellWidthForShipping) - this.colorwayAndShippingProp.cellWidthForHardLaunchDate - this.colorwayAndShippingProp.cellWidthForMarkdownDate - 3 * (this.colorwayAndShippingProp.paddingBetweenColumns), rowTop, this.arialFont, this.colorwayAndShippingProp.fontSizeData, '#757575', this.colorwayAndShippingProp.cellWidthForShipping))
      }
      objects.push(this.getTextObject(utils.formatDate(article.HardLaunchDate), this.colorwayAndShippingProp.highlightWidth - this.colorwayAndShippingProp.cellWidthForMarkdownDate - this.colorwayAndShippingProp.cellWidthForShipping - this.colorwayAndShippingProp.cellWidthForHardLaunchDate - 2 * this.colorwayAndShippingProp.paddingBetweenColumns, rowTop, this.arialFont, this.colorwayAndShippingProp.fontSizeData, '#757575', this.colorwayAndShippingProp.cellWidthForHardLaunchDate))
    }
    else {
      if (this.includeShipmentStartDate) {
        objects.push(this.getTextObject(utils.formatDateUTC(article.ShipmentStartDate), this.colorwayAndShippingProp.highlightWidth - 2 * (this.colorwayAndShippingProp.cellWidthForShipping) - this.colorwayAndShippingProp.cellWidthForHardLaunchDate - this.colorwayAndShippingProp.cellWidthForMarkdownDate - 3 * (this.colorwayAndShippingProp.paddingBetweenColumns), rowTop, this.arialFont, this.colorwayAndShippingProp.fontSizeData, '#757575', this.colorwayAndShippingProp.cellWidthForShipping))
      }
    }
    if (this.includeShipmentEndData) {
      objects.push(this.getTextObject(utils.formatDateUTC(article.ShipmentEndDate), this.colorwayAndShippingProp.highlightWidth - this.colorwayAndShippingProp.cellWidthForShipping - this.colorwayAndShippingProp.cellWidthForMarkdownDate - this.colorwayAndShippingProp.paddingBetweenColumns, rowTop, this.arialFont, this.colorwayAndShippingProp.fontSizeData, '#757575', this.colorwayAndShippingProp.cellWidthForShipping))
    }
    if (this.includeMarkdownDate && article.MarkdownDate != null) {
      objects.push(this.getTextObject(article.MarkdownDate, this.colorwayAndShippingProp.highlightWidth - this.colorwayAndShippingProp.cellWidthForMarkdownDate, rowTop, this.arialFont, this.colorwayAndShippingProp.fontSizeData, '#757575', this.colorwayAndShippingProp.cellWidthForShipping))
    }
    return objects
  }

  calculateModelInfoHeight(model, modelNumberValue) {
    this.currentModelHeight = 0
    this.createObj('modelNumber', modelNumberValue)
    this.createObj('articleNumber', model.modelInfo.articleName)
    this.createObj('modelDescription', model.modelInfo.modelDescription)
    if (model.modelInfo.featuresAndBenefits !== null) {
		  this.createObj('featuresAndBenefits', model.modelInfo.featuresAndBenefits)
    }
    this.createObj('colorwayAndShipping', model)
    if (this.currentModelHeight < this.remainingHeight) {
		  return true
    }
    else {
		  return false
    }
  }

  addNewRow() {
    this.row++
    if (this.row !== 0) {
      this.currentPositionForImage = this.maxHeight
    }
  }

  addModelInfoAndTableHeader(model, modelNumber, addLine) {
    let top = 0
    const objects: (LineObjectPdf | TextObjectPdf | EllipseObjectPdf)[] = []
    if (addLine) {
      const lineObjectLeft: LineObjectPdf = {
        type: 'line',
        x1: 5,
        y1: this.positionOfPreviousObject + 5,
        x2: this.modelInfoWidth - 8,
        y2: this.positionOfPreviousObject + 5,
        color: '#808080',
      }
      objects.push(lineObjectLeft)
      const lineObjectRight: LineObjectPdf = {
        type: 'line',
        x1: this.modelInfoWidth,
        y1: this.positionOfPreviousObject + 5,
        x2: 840,
        y2: this.positionOfPreviousObject + 5,
        color: '#808080',
      }
      objects.push(lineObjectRight)
      this.positionOfPreviousObject += 8
      top = this.positionOfPreviousObject + this.verticalOffset
      const paltformTextObject: TextObjectPdf = {
        type: 'text',
        text: model.modelInfo.platform,
        font: this.arialFont,
        fontSize: this.platformProp.fontSize,
        color: '#FA0000',
        x: this.modelInfoWidth + 5,
        y: top,
      }
      objects.push(paltformTextObject)
    }
    else {
		  top = this.positionOfPreviousObject
    }
    const modelNumberTextObject: TextObjectPdf = {
      type: 'text',
      text: modelNumber,
      font: this.arialFont,
      fontSize: this.modelNumberProp.fontSize,
      color: '#757575',
      x: this.leftMargin,
      y: top,
    }
    objects.push(modelNumberTextObject)
    this.positionOfPreviousObject += this.modelNumberProp.height
    top = this.positionOfPreviousObject + this.articleNumberProp.marginTop
    const articleNameTextObject: TextObjectPdf = {
      type: 'text',
      text: model.modelInfo.articleName,
      font: this.arialBoldFont,
      fontSize: this.articleNumberProp.fontSize,
      color: '#000000',
      x: this.leftMargin,
      y: top,
    }
    objects.push(articleNameTextObject)
    this.positionOfPreviousObject += this.articleNumberProp.height + this.articleNumberProp.marginTop
    top = this.positionOfPreviousObject
    const options: Record<string, any> = {}
    options.width = this.priceAndFabricProp.width
    const modelDescriptionTextObject: TextObjectPdf = {
      type: 'text',
      text: model.modelInfo.modelDescription,
      font: this.arialFont,
      fontSize: this.priceAndFabricProp.fontSize,
      color: '#757575',
      options,
      x: this.leftMargin,
      y: top,
    }
    objects.push(modelDescriptionTextObject)
    this.positionOfPreviousObject += this.priceAndFabricProp.height
    objects.push(...this.addFeatureAndBenefits(model.modelInfo.featuresAndBenefits))
    objects.push(...this.addCwAndShippingHeader())
    return objects
  }

  addFeatureAndBenefits(featuresAndBenefits) {
    const objects: (TextObjectPdf | EllipseObjectPdf)[] = []
    if (featuresAndBenefits !== null) {
      let top = this.positionOfPreviousObject + this.featuresAndBenefitProp.marginTop
      const featuresAndBenefitArray = featuresAndBenefits.split(/\n/g)
      let addOnce = true
      featuresAndBenefitArray.forEach((feature) => {
        const circleObject: EllipseObjectPdf = {
          type: 'circle',
          x: this.leftMargin,
          y: top + 3,
          r: 1,
          color: '#979797',
        }
        objects.push(circleObject)
        objects.push(this.getTextObject(feature, 8, top, this.helveticaFont, this.featuresAndBenefitProp.fontSize, '#979797', this.featuresAndBenefitProp.width - 8, addOnce ? this.featuresAndBenefitProp.height : 0))
        const featureTextDimensions = measureText(feature, this.featuresAndBenefitProp.fontSize, this.helveticaFont)
        let featureHeight = featureTextDimensions.height
        if (featureTextDimensions.width > this.featuresAndBenefitProp.width - 8) {
          featureHeight = featureHeight * Math.ceil(featureTextDimensions.width / (this.featuresAndBenefitProp.width - 8))
        }
        top += featureHeight
        addOnce = false
      })
    }
    return objects
  }

  addCwAndShippingHeader() {
    const objects: TextObjectPdf[] = []
    const top = this.positionOfPreviousObject + this.colorwayAndShippingProp.marginTop
    objects.push(this.getTextObject('CW', this.leftMargin, top, this.arialBoldFont, this.colorwayAndShippingProp.fontSizeHeading, '#8B8B8B', this.colorwayAndShippingProp.cellWidthForCw, this.colorwayAndShippingProp.headerHeight))
    objects.push(this.getTextObject('Colorway Name', this.leftMargin + this.colorwayAndShippingProp.cellWidthForCw + this.colorwayAndShippingProp.paddingBetweenColumns, top, this.arialBoldFont, this.colorwayAndShippingProp.fontSizeHeading, '#8B8B8B', this.colorwayAndShippingProp.colorwayWidth))
    objects.push(this.getTextObject('Ship Start', this.colorwayAndShippingProp.highlightWidth - 2 * (this.colorwayAndShippingProp.cellWidthForShipping) - this.colorwayAndShippingProp.cellWidthForHardLaunchDate - this.colorwayAndShippingProp.cellWidthForMarkdownDate - 3 * (this.colorwayAndShippingProp.paddingBetweenColumns), top, this.arialBoldFont, this.colorwayAndShippingProp.fontSizeHeading, '#8B8B8B', this.colorwayAndShippingProp.cellWidthForShipping))
    if (this.includeHardLaunchDate) {
      objects.push(this.getTextObject('H. Launch Date', this.colorwayAndShippingProp.highlightWidth - this.colorwayAndShippingProp.cellWidthForShipping - this.colorwayAndShippingProp.cellWidthForHardLaunchDate - this.colorwayAndShippingProp.cellWidthForMarkdownDate - (2 * this.colorwayAndShippingProp.paddingBetweenColumns), top, this.arialBoldFont, this.colorwayAndShippingProp.fontSizeHeading, '#8B8B8B', this.colorwayAndShippingProp.cellWidthForHardLaunchDate))
    }
    objects.push(this.getTextObject('Ship End', this.colorwayAndShippingProp.highlightWidth - this.colorwayAndShippingProp.cellWidthForShipping - this.colorwayAndShippingProp.cellWidthForMarkdownDate - this.colorwayAndShippingProp.paddingBetweenColumns, top, this.arialBoldFont, this.colorwayAndShippingProp.fontSizeHeading, '#8B8B8B', this.colorwayAndShippingProp.cellWidthForShipping))
    if (this.includeMarkdownDate) {
      objects.push(this.getTextObject('Markdown Date', this.colorwayAndShippingProp.highlightWidth - this.colorwayAndShippingProp.cellWidthForMarkdownDate, top, this.arialBoldFont, this.colorwayAndShippingProp.fontSizeHeading, '#8B8B8B', this.colorwayAndShippingProp.cellWidthForMarkdownDate))
    }
    return objects
  }

  getTextObject(text, left, top, font, fontSize, color, width?, height?, align?) {
    const options: Record<string, any> = {}
    if (width) {
      options.width = width
    }
    if (align) {
      options.align = align
    }
    const textObject: TextObjectPdf = {
      type: 'text',
      text,
      font,
      fontSize,
      color,
      options,
      x: left,
      y: top,
    }
    if (height) {
      this.positionOfPreviousObject += height
    }
    return textObject
  }

  getImageObject(left, top, path?, width?, height?) {
    const options: Record<string, any> = {}
    const src = 'assets/noimg.png'
    if (width) {
      options.width = width
    }
    if (height) {
      options.height = height
    }
    const imageObject: ImageObjectPdf = {
      type: 'image',
      x: left,
      y: top,
      options,
    }
    if (path) {
      imageObject.path = path
    }
    else {
      imageObject.src = src
    }
    return imageObject
  }

  createObj(key, data) {
    switch (key) {
		  case 'modelNumber':
        this.modelNumberProp.height = measureText(data, this.modelNumberProp.fontSize, this.arialFont).height
        this.currentModelHeight += this.modelNumberProp.height
        break
		  case 'articleNumber': {
        const articleNumberTextDimensions = measureText(data, this.articleNumberProp.fontSize, this.arialBoldFont)
        this.articleNumberProp.height = articleNumberTextDimensions.height
        this.currentModelHeight += this.articleNumberProp.height + this.articleNumberProp.marginTop
        break
      }
      case 'modelDescription': {
        const modelDescriptionTextDimensions = measureText(data, this.priceAndFabricProp.fontSize, this.arialFont)// , this.priceAndFabricProp.width)
        this.currentModelHeight += modelDescriptionTextDimensions.height // todo find the number of lines
			  this.priceAndFabricProp.height = modelDescriptionTextDimensions.height
        break
      }
		  case 'featuresAndBenefits': {
        const featuresAndBenefitArray = data.split(/\n/g)
        let featuresHeight = 0
        featuresAndBenefitArray.forEach((feature) => {
          const featureTextDimensions = measureText(feature, this.featuresAndBenefitProp.fontSize, this.helveticaFont)
          let featureHeight = featureTextDimensions.height
          if (featureTextDimensions.width > this.featuresAndBenefitProp.width - 8) {
            featureHeight = featureHeight * Math.ceil(featureTextDimensions.width / (this.featuresAndBenefitProp.width - 8))
          }
          featuresHeight += featureHeight
        })
        this.featuresAndBenefitProp.height = featuresHeight + this.featuresAndBenefitProp.marginTop
        this.currentModelHeight += featuresHeight + this.featuresAndBenefitProp.marginTop
        break
      }
		  case 'colorwayAndShipping': {
        const headerHeight = measureText('Shipment', this.colorwayAndShippingProp.fontSizeHeading, this.arialBoldFont).height + this.colorwayAndShippingProp.marginTop
        this.colorwayAndShippingProp.headerHeight = headerHeight
        let dataHeight = 0
        data.articles.forEach((article, index) => {
          if (index > 4) {
            this.colorwayAndShippingProp.requiredAnotherLine = true
          }
          else {
            const colorwayDimensions = measureText(article.colorway, this.colorwayAndShippingProp.fontSizeData, this.arialFont)
            if (colorwayDimensions.width > this.colorwayAndShippingProp.colorwayWidth) {
              dataHeight += (this.colorwayAndShippingProp.rowHeight) * 2
            }
            else {
              dataHeight += this.colorwayAndShippingProp.rowHeight
            }
          }
        })
        this.currentModelHeight += headerHeight + dataHeight
        break
		  }
    }
  }

  createPage(title, subtitle) {
    this.remainingHeight = this.pageHeight - this.headerHeight
    this.positionOfPreviousObject = 0
    this.currentPositionForImage = this.headerHeight
    this.currentTopForImages = this.headerHeight
    this.addLine = false
    this.colorwayAndShippingProp.highlight = 1
    return this.getHeaderObjects(title, subtitle)
  }

  getHeaderObjects(title, subtitle) {
    this.positionOfPreviousObject += this.headerHeight
    return [{
      type: 'rect',
      x: 0,
      y: 0,
      w: this.pageWidth,
      h: 28,
      fill: '#000000',
    } as RectObjectPdf, {
      type: 'text',
      text: title,
      font: this.arialFont,
      color: '#aaaaaa',
      fontSize: this.titleFontSize,
      x: 5,
      y: 5,
    } as TextObjectPdf, {
      type: 'text',
      text: subtitle,
      font: this.arialBoldFont,
      color: '#aaaaaa',
      fontSize: this.titleFontSize,
      x: 5,
      y: 16,
    } as TextObjectPdf, {
      type: 'image',
      x: this.pageWidth - this.logoWidth,
      y: 0,
      src: 'assets/ualogo.png',
      options: {
        width: this.logoWidth,
        height: this.headerHeight,
      },
    } as ImageObjectPdf]
  }

  calculateMinimumRequiredHeightForImages() {
    const imageHeight = this.articleImageProp.imgVerSpacing + this.imgHeight + 8 + this.modelNumberProp.height
    const labelHeight = measureText('dummyData', this.articleImageInfoProp.fontSize, this.arialFont).height
    return imageHeight + (3 * labelHeight) + 2 * this.verticalOffset
  }

  generateGroups(articles, isSortByExcelSequence, catalog, retailPg?: CatalogPriceGroup) {
    const groups = new Map<string, groupItems>()
    if (!isSortByExcelSequence) {
		  utils.sort(articles, ['EndUse', 'Gender', 'UADivision', 'SortOrder', 'ArticleNumber'])
    }
    // const arIndicatorKey = catalogData.config.values[def.configEntries.ArIndicatorAttribute]
    // const ghlIndicatorKey = catalogData.config.values[def.configEntries.GhlIndicatorAttribute]
    // const bAndTIndicatorKey = catalogData.config.values[def.configEntries.BAndTIndicatorAttribute]

    // use in case of when sortByExcelSequence is true only
    let previousSep = ''

    // check if article have property 'MarkdownDate'
    let numberOfColumns = 0
    if (articles[0].hasOwnProperty('MarkdownDate')) {
		  this.includeMarkdownDate = true
		 	this.colorwayAndShippingProp.cellWidthForMarkdownDate = 54
		  if (articles[0].hasOwnProperty('HardLaunchDate')) {
        this.includeHardLaunchDate = true
        this.colorwayAndShippingProp.paddingBetweenColumns = 5.5
        this.colorwayAndShippingProp.cellWidthForHardLaunchDate = 54
        numberOfColumns = 6
		  }
      else {
        this.includeHardLaunchDate = false
        this.colorwayAndShippingProp.paddingBetweenColumns = 8.5
        this.colorwayAndShippingProp.cellWidthForHardLaunchDate = 0
        numberOfColumns = 5
		  }
    }
    else {
      this.includeMarkdownDate = false
      this.colorwayAndShippingProp.cellWidthForMarkdownDate = 0
      if (articles[0].hasOwnProperty('HardLaunchDate')) {
        this.includeHardLaunchDate = true
        this.colorwayAndShippingProp.cellWidthForHardLaunchDate = 54
        this.colorwayAndShippingProp.paddingBetweenColumns = 8.5
        numberOfColumns = 5
      }
      else {
        this.includeHardLaunchDate = false
        this.colorwayAndShippingProp.cellWidthForHardLaunchDate = 0
        this.colorwayAndShippingProp.paddingBetweenColumns = 14
        numberOfColumns = 4
      }
    }
    this.colorwayAndShippingProp.colorwayWidth = this.colorwayAndShippingProp.highlightWidth - (this.colorwayAndShippingProp.cellWidthForCw + (2 * this.colorwayAndShippingProp.cellWidthForShipping)
    + this.colorwayAndShippingProp.cellWidthForMarkdownDate + (numberOfColumns * this.colorwayAndShippingProp.paddingBetweenColumns) + this.colorwayAndShippingProp.cellWidthForHardLaunchDate)
    articles.forEach((article, articleIndex) => {
		  if (!article.ModelNumber || article.ModelNumber.trim() === '') {
        return
      }

		  const modelNumber = article.ModelNumber
		  const endUse = utils.isDefined(article.EndUse) ? article.EndUse.toString().charAt(0).toUpperCase() + article.EndUse.toString().toLowerCase().slice(1) : ''
		  const gender = utils.isDefined(article.Gender) ? article.Gender.toString().charAt(0).toUpperCase() + article.Gender.toString().toLowerCase().slice(1) : ''
		  const division = utils.isDefined(article.UADivision) ? article.UADivision.toString().charAt(0).toUpperCase() + article.UADivision.toString().toLowerCase().slice(1) : ''
		  let sep = `${endUse}//${gender}//${division}`
		  if (!article.Colorway) {
        article.Colorway = ''
		  }
		  if (!article.ColorwayCode) {
        article.ColorwayCode = ''
		  }
		  const model = {
        modelInfo: {
			  endUse,
			  gender,
			  division,
			  articleName: article.ArticleName,
			  articleId: article.Id, // use to retrieve the persisted objects
			  platform: article.Merch_Collection ? article.Merch_Collection : '',
			  modelDescription: `${article?._RetailPrice ? utils.formatPrice(retailPg, Number(article?._RetailPrice), catalog?.Config.ShowPriceThousandsSeparated) : ''} // ${article.SizeScale ? article.SizeScale : ''} // ${article.Fabric ? article.Fabric : ''}`,
			  colorwayCode: article.ColorwayCode ? article.ColorwayCode : '',
			  colorDescription: article.Colorway ? article.Colorway : '',
			  featuresAndBenefits: utils.isValidStringValue(article.FeaturesAndBenefits) ? article.FeaturesAndBenefits : null,
          //   arIndicatorKey: arIndicatorKey,
          //   ghlIndicatorKey: ghlIndicatorKey,
          //   bAndTIndicatorKey: bAndTIndicatorKey
        },
        articles: [article],
		  }

		  // check if previous row separator is same as current row sep  if sortByExcelSequence is true
		  if ((isSortByExcelSequence && articleIndex !== 0 && previousSep.includes(sep))
		    || (!isSortByExcelSequence && groups.has(sep))) {
        if (isSortByExcelSequence) {
			    sep = previousSep
        }
        if (groups && utils.isDefined(groups.get(sep)) && groups.get(sep)!.modelGroups && groups.get(sep)!.modelGroups.has(modelNumber)) {
			    groups.get(sep)!.modelGroups.get(modelNumber)!.articles.push(article)
        }
        else {
			    groups.get(sep)!.modelGroups.set(modelNumber, model)
        }
		  }
      else {
        if (isSortByExcelSequence) {
			    sep += articleIndex
        }
        const headerGroup = {
          headerInfo: {
            articleId: article.Id,
            title: { articleProperties: ['EndUse'], value: `${endUse}` },
            subtitle: { articleProperties: ['Gender', 'UADivision'], value: `${gender} - ${division}  ` },
			    },
			    modelGroups: new Map<string, modelGroupItems>(),
        }
        headerGroup.modelGroups.set(modelNumber, model)
        groups.set(sep, headerGroup)
		  }
		  // use in case of when sortByExcelSequence is true only
		  previousSep = sep
    })
    return groups
  }
}
function measureText(text, fontSize, font) {
  // Create a canvas element
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  // Set the font
  context!.font = font === 'Arial-Bold' ? `bold ${fontSize}px Arial` : `${fontSize}px Arial`
  if (font === 'Helvetica') {
    context!.font = `${fontSize}px Helvetica`
  }
  // Measure the text
  const metrics = context!.measureText(text)
  const width = metrics.width
  const height = fontSize * 1.2 // Rough estimation for height
  return { width, height }
}
interface modelGroupItems {
  articles: MyArticle[]
  modelInfo: Record<string, any>
}
interface groupItems {
  headerInfo: Record<string, any>
  modelGroups: Map<string, modelGroupItems>
}

export default new UaStandard()
