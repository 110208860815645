<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <loader v-if="loading" />
    <div v-else class="container flex flex-col w-full h-full">
      <!-- HEADER -->
      <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title=" t('articleDetails.actions.manageArticleState')" :show-header="showHeader" />

      <!-- BODY -->
      <div class="px-10 mt-4 alerts">
        <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      </div>
      <div class="w-full h-full px-10 overflow-auto gap-x-6">
        <div class="w-full">
          <tx-select
            v-model="formModel.StateId" label="Select State" :data="activeArticleStateList" value-prop="StateId" display-prop="StateName"
            :required="true" :sort-list="false" :errors="v$.StateId?.$errors" :placeholder="articlesAssignedStateName" :placeholder-style="{ color: 'black', paddingLeft: '8px' }"
            :disabled="isStateDropdownReadonly" @change="onStateChange(formModel.StateId)" @blur="v$.StateId?.$touch"
          />
          <div v-if="showRequiredAttributesErrorMessage" class="object-contain w-full h-full p-2 text-red-500">
            <div v-for="(message, index) in requiredAttributeErrorMessage" :key="index">
              {{ message }}
            </div>
          </div>
        </div>
        <div class="pt-4 mb-2 text-m">
          <span v-if="requiredAttributes.length">{{ t('manageArticleState.selectRequiredAttributes') }}</span>
        </div>
        <div v-if="requiredAttributes.length" class="grid w-full grid-cols-2 mb-5 gap-x-6">
          <div v-for="attribute in requiredAttributes" :key="attribute.SystemName" class="mb-5 last-of-type:mb-4">
            <attribute-editor
              v-model="formModel[attribute.SystemName]"
              :articles="articles"
              :attribute="attribute"
              :required="attribute.IsRequired"
              :show-checkbox="false"
              :form="formModel"
              :errors="v$[attribute.SystemName]?.$errors"
              :disabled="attribute.ReadOnly"
              :should-consider-obsolete-model-value="attribute.AttributeType === AttributeType.MasterSizeScale || attribute.AttributeType === AttributeType.SizeScale"
              :should-allow-reset-existing-value="attribute.SystemName !== 'MasterSizeScaleId'"
              :show-obsolete-master-sizescale-warning="isMasterSizeScaleObsolete"
              @blur="v$[attribute.SystemName]?.$touch"
              @reset-size-scale="resetSizeScaleAndSizes"
              @change="onRequiredAttributesChanged(attribute.SystemName, $event)"
            />
          </div>
        </div>
      </div>
      <!-- FOOTER -->
      <tx-form-footer
        class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
        :primary-text="t('general.update')" :primary-disabled="loading || v$.$invalid || showRequiredAttributesErrorMessage" :secondary-disabled="loading"
        @primary-click="onUpdate" @secondary-click="onCancel"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { clone, isArray, uniqBy } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, reactive, ref } from 'vue'
import useArticleLocalDataUpdater from '../composables/articleLocalDataUpdater'
import TxSelect from '@/shared/components/TxSelect.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import Loader from '@/shared/components/Loader.vue'
import type MyArticle from '@/models/myArticle'
import type Article from '@/models/article'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import type { ArticleStateModel } from '@/api/t1/model/articleModel'
import { updateArticle, updateArticlesState } from '@/api/t1/article'
import { updateModelSizeScale } from '@/api/t1/size'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { appConstants } from '@/models/constants'
import { AttributeType } from '@/models/catalogAttribute'
import { useUserStore } from '@/store/userData'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import appConfig from '@/services/appConfig'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'

interface IProps {
  showHeader?: boolean
  articles: MyArticle[]
  isModel?: boolean
  confirmRequestId?: number | null
  requestConfirmStateId?: number | null
}

interface IFormItem {
  StateId: number | null
  SizeScaleId: number | null
  MasterSizeScaleId: number | null
  Sizes: string
}

const props = withDefaults(defineProps<IProps>(), { showHeader: true, isModel: false })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[] | Article[]): void
  (e: 'refreshRequestDetails'): void
}>()

const userStore = useUserStore()

const { t } = useI18n()
const { errorMessage, hasError } = useErrorMessage()
const { refreshLocalArticlesData } = useArticleLocalDataUpdater()
const { prefillAttributeValue, validateAndRemoveInvalidAttributeValues, skipRestrictingPropertyUpdateBasedOnArticleState, getArticlesMaxStateDetails } = useArticleFormHelper()

const loading = ref<boolean>(false)
const requiredAttributes = ref<IMyAttribute[]>([])
const isSizeScaleRequired = ref<boolean>(false)
const showRequiredAttributesErrorMessage = ref<boolean>(false)
let requiredAttributeErrorMessage: string[] = []
let isRequiredAttributesChanged: boolean = false
let isSizeScaleChanged: boolean = false
const articles = ref<MyArticle[] | Article[]>([])
const activeArticleStateList = ref<Record<string, any>[]>([])
let articlesAssignedStateName: string = ''
const isMasterSizeScaleObsolete = ref(false)

const formModel = reactive<IFormItem>({
  StateId: null,
  SizeScaleId: null,
  MasterSizeScaleId: null,
  Sizes: '',
})

const rules = computed(() => {
  const result: Record<string, any> = {}
  requiredAttributes.value.forEach((attribute) => {
    result[attribute.SystemName] = {}
    result[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
  })
  result.StateId = {}
  result.StateId.required = helpers.withMessage(t('validations.required', { property: 'State Name' }), required)
  return result
})

const v$ = useVuelidate(rules, formModel)

const isStateDropdownReadonly = computed(() => !!props.requestConfirmStateId)

async function onUpdate() {
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
    return
  }
  // if article state required attributes are updated then call the article update API for each article and
  // update sizescale then call the update state API.
  if (userStore.activeCatalog) {
    const catalog = userStore.activeCatalog
    // when there is required attributes and the values changed then only we need to call the update article API
    // and when size scale is required then we will call assign sizescale API
    loading.value = true
    if (articles.value.length === 1 && (isRequiredAttributesChanged || isSizeScaleChanged)) {
      const validModelId: number[] = []
      let isArticleUpdated = !isRequiredAttributesChanged
      let isSizeScaleUpdated = !(isSizeScaleRequired.value && isSizeScaleChanged)
      const article = articles.value[0]
      if (isRequiredAttributesChanged) {
        const requestObj: Record<string, any> = {}
        requiredAttributes.value.forEach((attribute) => {
          if (formModel.hasOwnProperty(attribute.SystemName) && formModel[attribute.SystemName] !== articles.value[0][attribute.SystemName]) {
            const attributeValue = utils.getArticleAttributeTypeSpecificValue(attribute, formModel[attribute.SystemName])
            article[attribute.SystemName] = attributeValue
            requestObj[attribute.SystemName] = attributeValue
          }
        })
        requestObj.ArticleNumber = article.ArticleNumber
        requestObj.ArticleName = article.ArticleName
        requestObj.ModelNumber = article.ModelNumber
        await updateArticle(catalog.CatalogCode, article.Id, requestObj, true)
          .then(() => {
            isArticleUpdated = true
            validModelId.push(article.ModelId)
          })
          .catch((error: any) => {
            errorMessage.value = t('general.unexpectedError')
            if (utils.isDefined(error.response) && utils.isDefined(error.response.data) && Array.isArray(error.response.data) && error.response.data.length) {
              errorMessage.value = error.response.data.map(e => e.ErrorMessage).join(', ')
            }
          })
      }
      // if size scale is required on article state and user has entred value then assign the sizescale for the selected article
      if (isSizeScaleChanged && isArticleUpdated && isSizeScaleRequired.value && formModel.SizeScaleId !== null) {
        if (userStore.activeCatalog?.CatalogCode) {
          await updateModelSizeScale(userStore.activeCatalog!.CatalogCode, article.ModelId, { SizeScaleId: formModel.SizeScaleId })
            .then(() => {
              isSizeScaleUpdated = true
              if (!validModelId.includes(article.ModelId)) {
                validModelId.push(article.ModelId)
              }
            })
            .catch(() => {
              errorMessage.value = t('general.unexpectedError')
            })
        }
      }
      if (isArticleUpdated && isSizeScaleUpdated) {
        updateArticlesState(catalog.CatalogCode, articles.value, formModel.StateId, props.confirmRequestId)
          .then(async () => {
            if (validModelId.length === 1) {
              await refreshLocalArticlesData(false, validModelId[0])
            }
            else {
              await userStore.doLoadData(['Articles'])
            }
            emit('updated', articles.value)
            if (utils.isDefined(props.confirmRequestId)) {
              emit('refreshRequestDetails')
            }
          }).catch((e) => {
            console.error('Unable to update article state', e)
            errorMessage.value = t('general.unexpectedError')
            if (utils.isDefined(e.response) && utils.isDefined(e.response.data) && Array.isArray(e.response.data) && e.response.data.length) {
              errorMessage.value = e.response.data.map(e => e.ErrorMessage).join(', ')
            }
          }).finally(() => {
            loading.value = false
          })
      }
      else {
        errorMessage.value = t('general.unexpectedError')
        loading.value = false
      }
    }
    else {
      // if the articles have all the required attributes then call the update article state API
      updateArticlesState(catalog.CatalogCode, articles.value, formModel.StateId, props.confirmRequestId)
        .then(async () => {
          if (articles.value.length === 1) {
            await refreshLocalArticlesData(false, articles.value[0].ModelId, articles.value[0].Id)
          }
          else {
            const uniqArticlesByModelId = uniqBy(articles.value, 'ModelId')
            if (uniqArticlesByModelId.length === 1) {
              await refreshLocalArticlesData(false, uniqArticlesByModelId[0].ModelId)
            }
            else {
              await userStore.doLoadData(['Articles'])
            }
          }
          emit('updated', articles.value)
          if (utils.isDefined(props.confirmRequestId)) {
            emit('refreshRequestDetails')
          }
        }).catch((e) => {
          console.error(e)
          errorMessage.value = t('general.unexpectedError')
          if (utils.isDefined(e.response) && utils.isDefined(e.response.data) && Array.isArray(e.response.data) && e.response.data.length) {
            errorMessage.value = e.response.data.map(e => e.ErrorMessage).join(', ')
          }
        })
        .finally(() => {
          loading.value = false
        })
    }
  }
}

async function init() {
  if (props.isModel) {
    const queryCriterion: Array<[number, number, string]> = [[userStore.activeCatalog!.CatalogCode, 1, props.articles[0].ModelNumber]]
    for (let i = 1; i < props.articles.length; i++) {
      queryCriterion.push([+userStore.activeCatalog!.CatalogCode, 1, props.articles[i].ModelNumber])
    }
    const res = await appConfig.DB!.getArticlesByCatalogAndModelCriterion(queryCriterion)
    if (res && isArray(res) && res[0]) {
      articles.value = res
    }
  }
  else {
    articles.value = props.articles
  }
  let commonStateId = articles.value ? articles.value[0].StateId : null
  for (let i = 1; i < articles.value.length; i++) {
    if (commonStateId !== articles.value[i].StateId) {
      commonStateId = null
      break
    }
  }
  if (commonStateId) {
    const preSelectedState = userStore.articleStateList.find(articleState => articleState.StateId === commonStateId)
    if (preSelectedState) {
      formModel.StateId = preSelectedState.StateId
      articlesAssignedStateName = preSelectedState.StateName // to show the existing state name
    }
  }
  // show only allowed states which are higher than the selected articles current state
  // when multiple articles selected we consider the highest articles state or higher as allowed state
  activeArticleStateList.value = userStore.articleStateList.filter(state => state.Status)
  const maxArticleStateRank = getArticlesMaxStateDetails(props.articles, false)
  if (maxArticleStateRank) {
    const validStates = activeArticleStateList.value.filter(articleState => articleState.StateRank > maxArticleStateRank.StateRank)
    activeArticleStateList.value = validStates
  }

  if (props.requestConfirmStateId) {
    formModel.StateId = props.requestConfirmStateId
    onStateChange(formModel.StateId)
  }
}

function onStateChange(value: number | null) {
  isRequiredAttributesChanged = false
  isSizeScaleChanged = false
  isSizeScaleRequired.value = false
  requiredAttributeErrorMessage = []
  const selectedState = userStore.articleStateList.find(articleState => articleState.StateId === value)
  if (selectedState) {
    const articlesMeetStateRequirementObject = doesArticlesMeetSelectedStateRequirement(selectedState!)
    // if articles does not met with state requirement
    const articlesRequiredAttributesMap = articlesMeetStateRequirementObject.articlesRequiredAttributesMap
    // if multiple articles are selected we will show error message
    if (articles.value.length > 1) {
      if (!articlesMeetStateRequirementObject.doesArticlesMeetSelectedStateRequirement) {
        generateErrorMessageForBulkAction(articlesRequiredAttributesMap)
      }
      else {
        isSizeScaleRequired.value = false
        showRequiredAttributesErrorMessage.value = false
      }
    }
    else {
      generateRequiredAttributesForForm(articlesRequiredAttributesMap, selectedState)
    }
  }
}

function doesArticlesMeetSelectedStateRequirement(selectedState: ArticleStateModel) {
  const articlesRequiredAttributesMap: Record<string, { doesArticleMeetSelectedStateRequirement: boolean, articlesRequiredAttributesList: string[], stateLevelValidRequiredAttributesList: string[] }> = {}
  let doesArticlesMeetSelectedStateRequirement = true
  const requiredAttributes = selectedState.RequiredAttributes || []
  for (let i = 0; i < articles.value.length; i++) {
    const currentArticle = articles.value[i]
    articlesRequiredAttributesMap[currentArticle.ArticleNumber] = { doesArticleMeetSelectedStateRequirement: true, articlesRequiredAttributesList: [], stateLevelValidRequiredAttributesList: [] }
    for (let j = 0; j < requiredAttributes.length; j++) {
      const requiredAttribute = requiredAttributes[j]
      let isRequiredAttributeValid = true
      if (requiredAttribute.Criteria && requiredAttribute.Criteria.toString().trim() !== '') {
        let criteria: any = {}
        try {
          const criteriaJSON = JSON.parse(requiredAttribute.Criteria)
          const key = Object.keys(criteriaJSON)[0]
          criteria = { key, value: !Array.isArray(criteriaJSON[key]) ? [criteriaJSON[key]] : criteriaJSON[key] }
        }
        catch (error) {
          console.error(`not valid json for required attribute (${requiredAttribute.AttributeSystemName})`, error)
        }
        if (criteria.value && criteria.value.length) {
          const matchedValue = criteria.value.filter(value => value.toString().toLowerCase().trim() === currentArticle[criteria.key]?.toString().toLowerCase().trim())
          isRequiredAttributeValid = matchedValue.length > 0
        }
      }
      if (isRequiredAttributeValid) {
        // when single article we need to show all the criteria valid required attributes with sizescale and primary color
        articlesRequiredAttributesMap[currentArticle.ArticleNumber].stateLevelValidRequiredAttributesList.push(requiredAttribute.AttributeSystemName)
        if (!utils.isDefined(currentArticle[requiredAttribute.AttributeSystemName]) || !currentArticle[requiredAttribute.AttributeSystemName]?.toString().trim().length) {
          articlesRequiredAttributesMap[currentArticle.ArticleNumber].articlesRequiredAttributesList.push(requiredAttribute.AttributeSystemName)
          articlesRequiredAttributesMap[currentArticle.ArticleNumber].doesArticleMeetSelectedStateRequirement = false
          doesArticlesMeetSelectedStateRequirement = false
        }
      }
    }
    if (selectedState.IsSizeScaleRequired) {
      // when single article we need to show all the criteria valid required attributes with sizescale
      articlesRequiredAttributesMap[currentArticle.ArticleNumber].stateLevelValidRequiredAttributesList.push('SizeScaleId')
      if (!utils.isDefined(currentArticle.SizeScaleId) || currentArticle.SizeScaleId === 0) {
        articlesRequiredAttributesMap[currentArticle.ArticleNumber].articlesRequiredAttributesList.push('SizeScaleId')
        articlesRequiredAttributesMap[currentArticle.ArticleNumber].doesArticleMeetSelectedStateRequirement = false
        doesArticlesMeetSelectedStateRequirement = false
      }
    }
    if (selectedState.IsPrimaryColorRequired) {
      // when single article we need to show all the criteria valid required attributes with primary color
      articlesRequiredAttributesMap[currentArticle.ArticleNumber].stateLevelValidRequiredAttributesList.push('ColorId')
      if (!utils.isDefined(currentArticle.ColorId) || currentArticle.ColorId === 0) {
        articlesRequiredAttributesMap[currentArticle.ArticleNumber].articlesRequiredAttributesList.push('ColorId')
        articlesRequiredAttributesMap[currentArticle.ArticleNumber].doesArticleMeetSelectedStateRequirement = false
        doesArticlesMeetSelectedStateRequirement = false
      }
    }
  }
  return { articlesRequiredAttributesMap, doesArticlesMeetSelectedStateRequirement }
}

function generateErrorMessageForBulkAction(articlesRequiredAttributesMap: Record<string, { doesArticleMeetSelectedStateRequirement: boolean, articlesRequiredAttributesList: string[] }>) {
  showRequiredAttributesErrorMessage.value = true
  Object.keys(articlesRequiredAttributesMap).forEach((articleNumber) => {
    const reqAttributeDisplayNames: string[] = []
    if (!articlesRequiredAttributesMap[articleNumber].doesArticleMeetSelectedStateRequirement) {
      const articlesRequiredAttributesList = articlesRequiredAttributesMap[articleNumber]?.articlesRequiredAttributesList
      articlesRequiredAttributesList.forEach((attributeSystemName) => {
        if (attributeSystemName !== 'SizeScaleId') {
          const attribute = userStore.myAttributes![attributeSystemName]
          reqAttributeDisplayNames.push(attribute.DisplayName)
        }
        else {
          const staticAttributeDisplayName = 'Size Scale'
          reqAttributeDisplayNames.push(staticAttributeDisplayName)
        }
      })
      requiredAttributeErrorMessage.push(`${articleNumber} ${t('manageArticleState.requiredAttributesErrorMessage')} ${reqAttributeDisplayNames.join(', ')}`)
    }
  })
}

function generateRequiredAttributesForForm(articlesRequiredAttributesMap, selectedState) {
  // if single article is selected we will list all required attributes and allow update
  const requiredAttributesMap: Record<string, number> = {}
  requiredAttributes.value = []
  Object.keys(articlesRequiredAttributesMap).forEach((articleNumber) => {
    const stateLevelValidRequiredAttributesList = articlesRequiredAttributesMap[articleNumber]?.stateLevelValidRequiredAttributesList
    stateLevelValidRequiredAttributesList.forEach((attributeSystemName) => {
      if (attributeSystemName !== 'SizeScaleId') {
        const attribute = clone(userStore.myAttributes![attributeSystemName])
        if (attribute && !requiredAttributesMap.hasOwnProperty(attributeSystemName)) {
          requiredAttributesMap[attributeSystemName] = 1
          attribute.IsRequired = true
          requiredAttributes.value.push(attribute)
        }
      }
      else {
        const isSizeScaleEditable = !selectedState.IsSizeScaleEditable && !skipRestrictingPropertyUpdateBasedOnArticleState
        isSizeScaleRequired.value = true
        const masterSizeScaleField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
          SystemName: 'MasterSizeScaleId',
          DisplayName: t('general.masterSizeScale'),
          Creatable: true,
          AttributeType: AttributeType.MasterSizeScale,
          IsRequired: true,
          ReadOnly: isSizeScaleEditable,
          ExternalChangeManagementURL: !isSizeScaleEditable && selectedState.AllowChangeRequestForSizeScale && appConfig.stateLockingExternalChangeManagementURLForSizeScale && appConfig.stateLockingExternalChangeManagementURLForSizeScale !== null && appConfig.stateLockingExternalChangeManagementURLForSizeScale.toString().trim().length
            ? appConfig.stateLockingExternalChangeManagementURLForSizeScale
            : undefined,
        })
        const sizeScaleField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
          SystemName: 'SizeScaleId',
          DisplayName: t('general.sizeScale'),
          Creatable: true,
          AttributeType: AttributeType.SizeScale,
          IsRequired: true,
          ReadOnly: isSizeScaleEditable,
          ExternalChangeManagementURL: !isSizeScaleEditable && selectedState.AllowChangeRequestForSizeScale && appConfig.stateLockingExternalChangeManagementURLForSizeScale && appConfig.stateLockingExternalChangeManagementURLForSizeScale !== null && appConfig.stateLockingExternalChangeManagementURLForSizeScale.toString().trim().length
            ? appConfig.stateLockingExternalChangeManagementURLForSizeScale
            : undefined,
        })
        const sizeField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
          SystemName: 'Sizes',
          DisplayName: t('general.sizes', 2),
          Creatable: true,
          AttributeType: AttributeType.MultiValue,
          ReadOnly: true,
        })
        requiredAttributes.value.push(masterSizeScaleField)
        requiredAttributes.value.push(sizeScaleField)
        requiredAttributes.value.push(sizeField)
      }
      // if article is having value then prefill it
      const currentArticle = articles.value[0] // single article
      if (currentArticle.hasOwnProperty(attributeSystemName)) {
        const reqAttributeField = requiredAttributes.value.find(reqAttribute => reqAttribute.SystemName === attributeSystemName)
        if (reqAttributeField) {
          formModel[attributeSystemName] = prefillAttributeValue(formModel, articles.value[0][attributeSystemName], reqAttributeField)
          if (attributeSystemName === 'ColorId') { // if primary color is assigned then disable the primary color field
            reqAttributeField.ReadOnly = !!utils.isDefined(formModel[attributeSystemName])
          }
        }
        else {
          formModel[attributeSystemName] = currentArticle[attributeSystemName]
        }
        if (attributeSystemName === 'SizeScaleId') { // assign master size scale value
          formModel.MasterSizeScaleId = currentArticle.MasterSizeScaleId
          // check if MasterSizeScale obsolete
          const assignedMasterSizeScale = userStore.masterSizeScales[currentArticle.MasterSizeScaleId!]
          isMasterSizeScaleObsolete.value = !!(assignedMasterSizeScale && assignedMasterSizeScale.IsObsolete)
        }
      }
      const dataForValidation = Object.assign({}, articles.value[0], formModel)
      validateAndRemoveInvalidAttributeValues(requiredAttributes, formModel, dataForValidation)
    })
  })
}

function resetSizeScaleAndSizes() {
  formModel.SizeScaleId = null
  formModel.Sizes = ''
}

function onRequiredAttributesChanged(attributeSystemName: string, val) {
  // Check if the attribute has changed
  if (formModel[attributeSystemName] !== articles.value[0][attributeSystemName]) {
    if (attributeSystemName === 'SizeScaleId' || attributeSystemName === 'MasterSizeScaleId') {
      isSizeScaleChanged = true
    }
    else {
      isRequiredAttributesChanged = true
    }
  }
  if (attributeSystemName === 'SizeScaleId') {
    formModel.Sizes = ''
    const assignedSizeScale = userStore.sizeScales[val]
    if (assignedSizeScale != null) {
      formModel.Sizes = assignedSizeScale.Sizes
    }
  }
}

function onCancel() {
  emit('cancel')
}

onMounted(() => {
  init()
})
</script>
