<template>
  <div class="mx-auto mt-16 text-2xl text-center">
    {{ t('general.hi') }} {{ firstName }}
  </div>

  <div class="w-full text-center">
    <tx-button
      v-tooltip="t('login.changeUsername')" type="default" width="180px" height="30px" :disabled="loading"
      @click="onCancel"
    >
      <span class="text-sm">{{ userName }}</span>
    </tx-button>
  </div>

  <div class="w-full h-56 mt-10">
    <div class="w-full">
      <tx-input
        ref="refPassword" v-model="password" type="password" :label="t('general.password')" :readonly="loading" required
        @keyup.enter="doLogin"
      />

      <!-- Forgot Password -->
      <tx-button class="mt-4" type="text" :disabled="loading" @click="onForgotPassword">
        <span class="text-sm">{{ t('login.forgotPassword') }}</span>
      </tx-button>
    </div>
  </div>

  <div class="w-full text-center">
    <tx-button
      type="confirm" width="180px" height="40px" :disabled="!password" :loading="loading"
      :text="t('login.title')" @click="doLogin"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { onMounted, ref } from 'vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxButton from '@/shared/components/TxButton.vue'

interface IProps {
  loading?: boolean
  firstName?: string
  userName?: string
}
const props = withDefaults(defineProps<IProps>(), { loading: false, firstName: '', userName: '' })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'login', userName: string, password: string): void
  (e: 'forgotPassword', userName: string): void
}>()

const { t } = useI18n()

const password = ref('')
const refPassword = ref<InstanceType<typeof TxInput>>()

function onCancel() {
  emit('cancel')
}

function onForgotPassword() {
  emit('forgotPassword', props.userName)
}

function doLogin() {
  if (props.userName.length > 0 && password.value.length > 0) {
    emit('login', props.userName, password.value)
  }
}

onMounted(() => {
  refPassword.value?.focus()
})
</script>
