<template>
  <transition
    enter-from-class="opacity-0" leave-to-class="opacity-0" enter-active-class="transition-opacity"
    leave-active-class="transition-opacity"
  >
    <div v-if="showOverlay" v-show="modelValue" class="fixed inset-0 z-drawer-overlay">
      <div class="absolute inset-0 bg-black opacity-50" tabindex="0" @click="doOverlayClick" />
    </div>
  </transition>
  <transition
    :enter-from-class="right ? 'translate-x-full' : '-translate-x-full'"
    :leave-to-class="right ? 'translate-x-full' : '-translate-x-full'" enter-active-class="transition-all ease-out"
    leave-active-class="transition-all ease-out" enter-to-class="translate-x-0" leave-from-class="translate-x-0"
    @after-enter="contentVisible = true" @after-leave="contentVisible = false"
  >
    <aside
      v-show="modelValue" class="fixed top-0 h-full overflow-auto bg-white z-drawer"
      :class="right ? 'right-0' : 'left-0'" :style="{ width }"
    >
      <tx-button
        v-if="showClose" type="icon" class="absolute z-10 w-6 h-6 top-7 right-7" faicon="fa-light fa-xmark"
        @click="doCloseClick"
      />
      <slot v-if="contentVisible" />
    </aside>
  </transition>
</template>

<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core'
import { ref, watch } from 'vue'
import TxButton from '@/shared/components/TxButton.vue'

interface IProps {
  modelValue: boolean
  right?: boolean
  width?: string
  showOverlay?: boolean
  showClose?: boolean
  closeOnEsc?: boolean
  closeOnOverlayClick?: boolean
}
const props = withDefaults(defineProps<IProps>(), { right: false, width: '300px', showOverlay: true, showClose: true, closeOnEsc: true, closeOnOverlayClick: true })

const emit = defineEmits<{
  (e: 'update:modelValue', val: boolean): void
  (e: 'closed'): void
  (e: 'opened'): void
}>()

const contentVisible = ref(false)

onKeyStroke('Esc', (e) => {
  if (props.closeOnEsc && props.modelValue) {
    emit('update:modelValue', false)
    e.preventDefault()
  }
})

function doOverlayClick() {
  if (props.closeOnOverlayClick && props.modelValue) {
    emit('update:modelValue', false)
  }
}

function doCloseClick() {
  emit('update:modelValue', false)
}

watch(() => props.modelValue, () => {
  if (!props.modelValue) {
    emit('closed')
  }
  else {
    emit('opened')
  }
})
</script>

<style lang="scss">
.opacity-trans {
  transition: opacity 0.5s ease;
}
</style>
