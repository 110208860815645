<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <!-- HEADER -->
    <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="headerTitle" :show-header="showHeader" />

    <!-- BODY -->
    <div class="px-10 mt-4 alerts">
      <tx-alert show :type="type === 'deactivate' ? 'warning' : 'info'" :text="confirmationMessage" />
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    </div>
    <!-- FORM -->
    <div class="relative flex-auto mt-4 body">
      <!-- LOADER -->
      <div v-show="loadingDropReasons">
        <loader style="z-index: 1801;" />
        <div class="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-transparent opacity-50" />
      </div>
      <!-- FORM -->
      <div v-if="isDropReasonAvailable" class="grid w-full px-10 gap-x-6">
        <div v-for="attribute in visibleAttributes" :key="attribute.SystemName" class="mb-5 last-of-type:mb-4">
          <attribute-editor
            v-model="formModel[attribute.SystemName]"
            :attribute="attribute" :form="formModel"
            :required="attribute.IsRequired"
            :errors="v$[attribute.SystemName]?.$errors"
            :articles="articles"
            @blur="v$[attribute.SystemName]?.$touch"
            @change="v$[attribute.SystemName]?.$touch"
          />
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="confirmButtonText" :primary-disabled="v$.$invalid || loading || loadingDropReasons || (formModel.DropReasonId === null && isDropReasonAvailable)" :secondary-disabled="loading"
      @primary-click="onUpdate" @secondary-click="onCancel"
    />
  </div>
</template>

<script setup lang='ts'>
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import type DropReason from '@/models/dropReason'
import Loader from '@/shared/components/Loader.vue'
import type MyArticle from '@/models/myArticle'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import appConfig from '@/services/appConfig'
import useErrorMessage from '@/shared/composables/errorMessage'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import type { UpdateArticlesStatusModel } from '@/api/t1/model/articleModel'
import { appConstants } from '@/models/constants'
import { updateArticlesStatus } from '@/api/t1/article'
import { useUserStore } from '@/store/userData'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'

interface IProps {
  type?: 'activate' | 'deactivate'
  showHeader?: boolean
  articles: MyArticle[]
  isModel?: boolean
  confirmRequestId?: number | null
}
const props = withDefaults(defineProps<IProps>(), { type: 'activate', showHeader: true, isModel: false })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[]): void
  (e: 'refreshRequestDetails'): void
}>()

const { t } = useI18n()
const withI18nMessage = createI18nMessage({ t })
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()

const loading = ref(false)
const loadingDropReasons = ref(false)
const formModel = reactive<Record<string, any>>({})
const dropReasons = ref<DropReason[]>([])

const headerTitle = computed(() => props.type === 'activate' ? t('activeOrDeactiveArticle.activate') : t('activeOrDeactiveArticle.deactivate'))

const confirmationMessage = computed(() => props.type === 'activate'
  ? t('activeOrDeactiveArticle.activateArticleConfirmation', { articles: props.isModel ? props.articles.map(a => a.ModelNumber).join(', ') : props.articles.map(a => a.ArticleNumber).join(', ') })
  : t('activeOrDeactiveArticle.deactivateArticleConfirmation', { articles: props.isModel ? props.articles.map(a => a.ModelNumber).join(', ') : props.articles.map(a => a.ArticleNumber).join(', ') }),
)

const confirmButtonText = computed(() => props.type === 'activate' ? t('activeOrDeactiveArticle.activate') : t('activeOrDeactiveArticle.deactivate'))

const isDropReasonAvailable = computed(() => userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited && props.type === 'deactivate' && dropReasons.value.length > 0)

const visibleAttributes = computed(() => {
  const visibleAttributes: IMyAttribute[] = []
  if (isDropReasonAvailable.value) {
    const dropReasonField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'DropReasonId',
      DisplayName: t('activeOrDeactiveArticle.dropReason'),
      Creatable: true,
      AttributeType: AttributeType.Int,
      ReadOnly: false,
      IsRequired: true,
      DropDownData: dropReasons.value,
      DropDownValueDisplayProp: 'Reason',
      DropDownValueProp: 'Id',
    })
    visibleAttributes.push(dropReasonField)
  }
  return visibleAttributes
})

const rules = computed(() => {
  const rules: Record<string, any> = {}
  visibleAttributes.value.forEach((visibleAttribute) => {
    if (visibleAttribute.IsRequired) {
      rules[visibleAttribute.SystemName] = { required: withI18nMessage(required) }
    }
  })
  return rules
})
const v$ = useVuelidate(rules, formModel)

function onCancel() {
  emit('cancel')
}

async function onUpdate() {
  if (userStore.activeCatalog && props.articles.length > 0 && (!isDropReasonAvailable.value || (utils.isDefined(formModel.DropReasonId)))) {
    loading.value = true
    const status = props.type === 'activate' ? 1 : 0
    const selectedDropReasonId = formModel.DropReasonId

    const requestObj: UpdateArticlesStatusModel[] = []
    // when browse by model if a model is deactivated then deactivate all the active article of that model
    if (props.isModel) {
      for (const article of props.articles) {
        const modelArticles = await appConfig.DB?.articles
          .where({ CatalogCode: userStore.activeCatalog?.CatalogCode, ModelNumber: article.ModelNumber })
          .toArray()

        if (modelArticles) {
          modelArticles.forEach((modelArticle) => {
            requestObj.push({
              Id: modelArticle.Id,
              Status: status,
              DropReasonId: utils.isDefined(selectedDropReasonId) ? selectedDropReasonId : null,
            })
          })
        }
      }
    }
    else {
      props.articles.forEach((article) => {
        requestObj.push({
          Id: article.Id,
          Status: status,
          DropReasonId: utils.isDefined(selectedDropReasonId) ? selectedDropReasonId : null,
        })
      })
    }
    updateArticlesStatus(userStore.activeCatalog.CatalogCode, requestObj, props.confirmRequestId)
      .then(async () => {
        // assign the Reason
        let assignedReason = ''
        const assignedDropReason = dropReasons.value.find(reason => utils.haveEqualStringValue(reason.Id, selectedDropReasonId))
        if (utils.isDefined(assignedDropReason)) {
          assignedReason = assignedDropReason.Reason
        }
        if (props.isModel) {
          const modelArticles = await appConfig.DB?.articles.where({ CatalogCode: userStore.activeCatalog?.CatalogCode, ModelNumber: props.articles[0].ModelNumber }).toArray()
          if (modelArticles) {
            modelArticles.forEach((article) => {
              article.Status = status
              article.DropReason = assignedReason !== '' ? assignedReason : null
            })
          }
        }
        else {
          props.articles.forEach((article) => {
            article.Status = status
            article.DropReason = assignedReason !== '' ? assignedReason : null
          })
        }
        await userStore.doLoadData(['Articles'])
        emit('updated', props.articles)
        if (utils.isDefined(props.confirmRequestId)) {
          emit('refreshRequestDetails')
        }
      })
      .catch((e) => {
        console.error(e)
        errorMessage.value = t('general.unexpectedError')
      })
      .finally(() => loading.value = false)
  }
}

function reset() {
  loading.value = false
  loadingDropReasons.value = false
  errorMessage.value = ''
  formModel.DropReason = null
}

watch(() => props, reset)

onMounted(async () => {
  reset()
  if (props.type === 'deactivate') {
    try {
      loadingDropReasons.value = true
      dropReasons.value = await appConfig.DB!.getArticleDropReasons(userStore.activeCatalog!.AccountId, 1)
    }
    catch (error) {
      dropReasons.value = []
    }
    finally {
      loadingDropReasons.value = false
    }
  }
})
</script>
