import type Order from './order'
import type MyArticle from './myArticle'
import type { ArticleCrd } from './articleDeliveryDate'
import type CatalogDetails from './catalogDetails'
import utils from '@/services/utils'
import type { IOrderlineContent, IOrderlineResponse, ISellerVASModel } from '@/api/t1/model/orderModel'
import type { IOrderlineArticleDecodedStatus, IOrderlineCRD, IOrderlineDecodedStatus, IOrderlineVAS } from '@/modules/orders/Orders.types'

export default class Orderline {
  ActiveTotalQuantity: number
  Article: MyArticle
  BitwiseStatus: number | null
  ContainsValidVAS: boolean
  DecodedStatus: IOrderlineArticleDecodedStatus | null
  IndexedCRD: Record<number, IOrderlineCRD>
  IndexedVAS: Record<number, IOrderlineVAS>
  CurrentPrice: number
  InitialPrice: number
  Status: boolean
  TotalQuantity: number
  OrderedQuantity: number | null // TODO: once implemented forecast
  BuyerForecast: number | null // TODO: once implemented forecast
  BuyerForecastDiff: number | null // TODO: once implemented forecast
  SellerForecast: number | null // TODO: once implemented forecast
  SellerForecastDiff: number | null // TODO: once implemented forecast
  ShiftUpdatedDate: number | null // TODO: once implemented shift delivery date

  constructor(order: Order, article: MyArticle, isArticleSegmented: boolean = true) {
    this.Article = article
    this.Status = article.Status === 1 && isArticleSegmented
    this.BitwiseStatus = null
    this.DecodedStatus = null
    this.TotalQuantity = 0
    this.ActiveTotalQuantity = 0
    this.ContainsValidVAS = false
    this.IndexedCRD = {}
    this.IndexedVAS = {}
    // this.CurrentPrice = this.Article._Prices[order.OrderPriceGroup.Id] != null ? Number.parseFloat(this.Article._Prices[order.OrderPriceGroup.Id].Price.toFixed(2)) : 0
    this.CurrentPrice = this.Article._Prices[order.OrderPriceGroup.Id] != null && this.Article._Prices[order.OrderPriceGroup.Id].Price != null ? this.Article._Prices[order.OrderPriceGroup.Id].Price : 0
    this.InitialPrice = this.Article._Prices[order.OrderPriceGroup.Id] != null && this.Article._Prices[order.OrderPriceGroup.Id].Price != null ? this.Article._Prices[order.OrderPriceGroup.Id].Price : 0

    this.OrderedQuantity = null // TODO:: once implemented forecast
    this.BuyerForecast = null // TODO:: once implemented forecast
    this.BuyerForecastDiff = null // TODO:: once implemented forecast
    this.SellerForecast = null // TODO:: once implemented forecast
    this.SellerForecastDiff = null // TODO:: once implemented forecast
    this.ShiftUpdatedDate = null // TODO: once implemented shift delivery date
  }

  loadFromAPIResponse(order: Order, orderline: IOrderlineResponse, indexedVAS: Record<number, ISellerVASModel>, activeCatalog: CatalogDetails, isCustomerRequiredDateAllowed: boolean, customerRequiredDateValidation: { relation: string }) {
    // TODO: do index _DeliveryDates on articles
    const articleCRD = this.Article._DeliveryDates.find(crd => crd.CrdId === orderline.CrdId)
    if (!utils.isDefined(articleCRD)) {
      console.warn(`Unable to set quantity of invalid CRD Id: ${orderline.CrdId} for Article: ${this.Article.Id}`)
      return
    }

    this.InitialPrice = orderline.InitialPrice
    this.BitwiseStatus = orderline.Status
    const decodedStatus = Orderline.decodeBitwiseStatus(orderline.Status)
    this.DecodedStatus = (({ invalidArticle, invalidCatalogOrCustomerSegmentation, invalidPriceGroup, invalidArticleSeg, allocationCriteriaField, invalidSegmentation, customerUnlinked }) =>
      ({ invalidArticle, invalidCatalogOrCustomerSegmentation, invalidPriceGroup, invalidArticleSeg, allocationCriteriaField, invalidSegmentation, customerUnlinked }))(decodedStatus)
    this.Status = !(this.DecodedStatus.invalidArticle || this.DecodedStatus.invalidCatalogOrCustomerSegmentation || this.DecodedStatus.invalidPriceGroup || this.DecodedStatus.invalidArticleSeg
    || this.DecodedStatus.allocationCriteriaField || this.DecodedStatus.invalidSegmentation || this.DecodedStatus.customerUnlinked)

    // init indexed CRDs
    if (!this.IndexedCRD.hasOwnProperty(orderline.CrdId)) {
      this.IndexedCRD[orderline.CrdId] = {
        IsActive: true,
        BitwiseStatus: null,
        DecodedStatus: null,
        Status: true,
        OldCrdId: null,
        DeliveryDate: null,
        IndexedSizeCurve: {},
        IndexedSizes: {},
        TotalQuantity: 0,
        ActiveTotalQuantity: 0,
      }
    }

    this.IndexedCRD[orderline.CrdId].IsActive = !!orderline.IsActive
    this.IndexedCRD[orderline.CrdId].BitwiseStatus = orderline.Status
    this.IndexedCRD[orderline.CrdId].DecodedStatus = (({ invalidCRD, invalidArticleCRD, unAvailableCRD }) => ({ invalidCRD, invalidArticleCRD, unAvailableCRD }))(decodedStatus)
    this.IndexedCRD[orderline.CrdId].Status = !(decodedStatus.invalidCRD || decodedStatus.invalidArticleCRD || decodedStatus.unAvailableCRD)
    this.IndexedCRD[orderline.CrdId].DeliveryDate = orderline.DeliveryDate ? orderline.DeliveryDate : isCustomerRequiredDateAllowed ? this.getDeliveryDate(orderline.CrdId, articleCRD, customerRequiredDateValidation, activeCatalog) : null
    this.IndexedCRD[orderline.CrdId].OldCrdId = orderline.OldCrdId

    // init IndexedSizes
    orderline.orderLineSizeResponse.forEach((orderlineSize) => {
      // TODO: do index _Sizes on article
      if (this.Article._Sizes.findIndex(articleSize => articleSize.Id === orderlineSize.SizeId) === -1) {
        console.warn(`Unable to set quantity of invalid size: ${orderlineSize.SizeId} for Article: ${this.Article.Id}`)
        return
      }

      this.IndexedCRD[orderline.CrdId].IndexedSizes[orderlineSize.SizeId] = {
        Status: !!orderlineSize.Status,
        Quantity: orderlineSize.Quantity,
      }
      this.TotalQuantity += orderlineSize.Quantity
      this.IndexedCRD[orderline.CrdId].TotalQuantity += orderlineSize.Quantity
      order.setCRDTotalQuantity(orderline.CrdId, order.getCRDTotalQuantity(orderline.CrdId) + orderlineSize.Quantity)

      if (this.Status && this.IndexedCRD[orderline.CrdId].Status && this.IndexedCRD[orderline.CrdId].IsActive && orderlineSize.Status) {
        this.ActiveTotalQuantity += orderlineSize.Quantity
        this.IndexedCRD[orderline.CrdId].ActiveTotalQuantity += orderlineSize.Quantity
        order.setCRDTotalQuantityForActiveLine(orderline.CrdId, order.getCRDTotalQuantityForActiveLine(orderline.CrdId) + orderlineSize.Quantity)
      }
    })

    // init IndexedSizeCurve
    if (orderline.OrderlineSizeCurveResponse) {
      orderline.OrderlineSizeCurveResponse.forEach((sizeCurve) => {
        this.IndexedCRD[orderline.CrdId].IndexedSizeCurve[sizeCurve.Id] = {
          Quantity: sizeCurve.Quantity,
        }
      })
    }

    // init IndexedVAS
    orderline.orderLineVasResponse.forEach((VAS) => {
      this.IndexedVAS[VAS.VasId] = {
        Id: VAS.VasId,
        Code: VAS.VasCode,
        AdditionalInfo: VAS.AdditionalInfo != null ? VAS.AdditionalInfo : '',
        Status: !!VAS.Status,
        SellerVASStatus: !!(indexedVAS[VAS.VasId] && indexedVAS[VAS.VasId].Status),
      }
    })
    this.updateOrderlineContainVas()
  }

  loadFromAPIResponseForDraft(order: Order, orderline: IOrderlineContent, indexedVAS: Record<number, ISellerVASModel>, activeCatalog: CatalogDetails, isCustomerRequiredDateAllowed: boolean, customerRequiredDateValidation: { relation: string }, isArticleSegmented: boolean = true) {
    // TODO: do index _DeliveryDates on articles
    const articleCRD = this.Article._DeliveryDates.find(crd => crd.CrdId === orderline.CrdId)
    if (!utils.isDefined(articleCRD)) {
      console.warn(`Unable to set quantity of invalid CRD Id: ${orderline.CrdId} for Article: ${this.Article.Id}`)
      return
    }

    this.InitialPrice = orderline.InitialPrice
    this.BitwiseStatus = null // for draft orders will always be null since it will not be processed by API (even reopen orders saved as draft)
    this.DecodedStatus = null // for draft orders will always be null since it will not be processed by API (even reopen orders saved as draft)
    this.Status = this.Article.Status === 1 && isArticleSegmented

    if (!this.IndexedCRD.hasOwnProperty(orderline.CrdId)) {
      const nowInMilliseconds = Date.now()
      const isActiveCRD = articleCRD.Status && order.ActiveCatalog._IndexedCatalogCRD[orderline.CrdId].Status
      const isAvailableCRD = order.ActiveCatalog._IndexedCatalogCRD[orderline.CrdId].Availability
        && (articleCRD.AvailabilityFrom == null || nowInMilliseconds >= articleCRD.AvailabilityFrom.getTime())
        && (articleCRD.AvailabilityTo == null || nowInMilliseconds <= articleCRD.AvailabilityTo.getTime())
      this.IndexedCRD[orderline.CrdId] = {
        IsActive: true, // drop orderline will not be part of JSON save on server
        BitwiseStatus: null,
        DecodedStatus: null,
        Status: !!(isActiveCRD && isAvailableCRD),
        OldCrdId: orderline.OldCrdId,
        DeliveryDate: null,
        IndexedSizeCurve: {},
        IndexedSizes: {},
        TotalQuantity: 0,
        ActiveTotalQuantity: 0,
      }
      this.IndexedCRD[orderline.CrdId].DeliveryDate = this.findDeliveryDate(orderline.DeliveryDate, orderline.CrdId, articleCRD, isCustomerRequiredDateAllowed, customerRequiredDateValidation, activeCatalog)
    }

    // init IndexedSizes
    orderline.OrderlineSize.forEach((orderlineSize) => {
      // TODO: do index _Sizes on article
      const articleSize = this.Article._Sizes.find(articleSize => articleSize.Id === orderlineSize.SizeId)
      if (!utils.isDefined(articleSize)) {
        console.warn(`Unable to set quantity of invalid size: ${orderlineSize.SizeId} for Article: ${this.Article.Id}`)
        return
      }

      this.IndexedCRD[orderline.CrdId].IndexedSizes[orderlineSize.SizeId] = {
        Status: !!articleSize.Status,
        Quantity: orderlineSize.Quantity,
      }
      this.TotalQuantity += orderlineSize.Quantity
      this.IndexedCRD[orderline.CrdId].TotalQuantity += orderlineSize.Quantity
      order.setCRDTotalQuantity(orderline.CrdId, order.getCRDTotalQuantity(orderline.CrdId) + orderlineSize.Quantity)

      if (this.Status && this.IndexedCRD[orderline.CrdId].Status && this.IndexedCRD[orderline.CrdId].IsActive && articleSize.Status) {
        this.ActiveTotalQuantity += orderlineSize.Quantity
        this.IndexedCRD[orderline.CrdId].ActiveTotalQuantity += orderlineSize.Quantity
        order.setCRDTotalQuantityForActiveLine(orderline.CrdId, order.getCRDTotalQuantityForActiveLine(orderline.CrdId) + orderlineSize.Quantity)
      }
    })

    // init IndexedSizeCurve
    if (orderline.OrderlineSizeCurve) {
      orderline.OrderlineSizeCurve.forEach((sizeCurve) => {
        this.IndexedCRD[orderline.CrdId].IndexedSizeCurve[sizeCurve.Id] = {
          Quantity: sizeCurve.Quantity,
        }
      })
    }

    // init IndexedVAS
    orderline.OrderlineVas.forEach((VAS) => {
      this.IndexedVAS[VAS.VasId] = {
        Id: VAS.VasId,
        Code: VAS.VasCode,
        AdditionalInfo: VAS.AdditionalInfo != null ? VAS.AdditionalInfo : '',
        Status: true, // if VAS is deleted it wont be part of JSON saved on server
        SellerVASStatus: !!(indexedVAS[VAS.VasId] && indexedVAS[VAS.VasId].Status),
      }
    })
    this.updateOrderlineContainVas()
  }

  findDeliveryDate(deliveryDate: string | null, crdId: number, articleCrd: ArticleCrd, isCustomerRequiredDateAllowed: boolean, customerRequiredDateValidation: { relation: string }, activeCatalog: CatalogDetails) {
    // following 3 line of comment brought from T1S
    // As discussed with Amardeep ,this function will find the delivery date , as if order is in draft, reopened  state we need to
    // check if actual crd matching with the relation in configuration , if yes then it ill be set.
    // If orderline already have the delivery date if validator available and state is draft, reopened , we need to validate the date else just keep as it is
    if (deliveryDate) {
      if (isCustomerRequiredDateAllowed) {
        if (this.isDeliveryDateValid(deliveryDate, crdId, articleCrd, customerRequiredDateValidation, activeCatalog)) {
          return deliveryDate
        }
        else {
          return this.getDeliveryDate(crdId, articleCrd, customerRequiredDateValidation, activeCatalog)
        }
      }
      else {
        return deliveryDate
      }
    }
    else {
      if (isCustomerRequiredDateAllowed) {
        return this.getDeliveryDate(crdId, articleCrd, customerRequiredDateValidation, activeCatalog)
      }
      else {
        return null
      }
    }
  }

  isDeliveryDateValid(deliveryDate: string | null, crdId: number, articleCrd: ArticleCrd, customerRequiredDateValidation: { relation: string }, activeCatalog: CatalogDetails) {
    let isCrdValid = true
    if (this.IndexedCRD[crdId].Status) {
      if (utils.isDefined(this.IndexedCRD[crdId].DecodedStatus)) {
        const decodedStatuses = Object.values(this.IndexedCRD[crdId].DecodedStatus!)
        for (let i = 0; i < decodedStatuses.length; i++) {
          if (decodedStatuses[i]) {
            isCrdValid = false
            break
          }
        }
      }
    }
    else {
      isCrdValid = false
    }

    if (!isCrdValid) {
      return deliveryDate
    }
    else {
      const currentCrdDateObject = new Date(activeCatalog._IndexedCatalogCRD[articleCrd.CrdId].CustomerRequiredDate)
      currentCrdDateObject.setHours(0, 0, 0)
      const deliveryDateObject = new Date(deliveryDate!)
      deliveryDateObject.setHours(0, 0, 0)
      if (utils.validateCondition(deliveryDateObject.getTime(), currentCrdDateObject.getTime(), customerRequiredDateValidation.relation)) {
        return deliveryDate
      }
      else {
        return null
      }
    }
  }

  getDeliveryDate(crdId: number, articleCrd: ArticleCrd, customerRequiredDateValidation: { relation: string }, activeCatalog: CatalogDetails) {
    let isCrdValid = true
    if (this.IndexedCRD[crdId].Status) {
      if (utils.isDefined(this.IndexedCRD[crdId].DecodedStatus)) {
        const decodedStatuses = Object.values(this.IndexedCRD[crdId].DecodedStatus!)
        for (let i = 0; i < decodedStatuses.length; i++) {
          if (decodedStatuses[i]) {
            isCrdValid = false
            break
          }
        }
      }
    }
    else {
      isCrdValid = false
    }

    if (isCrdValid) {
      // as analyzed and discussed with Aanchal
      if (['===', '=='].includes(customerRequiredDateValidation.relation)) {
        return activeCatalog._IndexedCatalogCRD[articleCrd!.CrdId].CustomerRequiredDate
      }
      else {
        return null
      }
    }
    else {
      return activeCatalog._IndexedCatalogCRD[articleCrd!.CrdId].CustomerRequiredDate
    }
  }

  setDeliveryDate(crdId: number, deliveryDate: string) {
    if (!this.IndexedCRD.hasOwnProperty(crdId)) {
      this.IndexedCRD[crdId] = {
        IsActive: true,
        BitwiseStatus: null,
        DecodedStatus: null,
        Status: true,
        OldCrdId: null,
        DeliveryDate: null,
        IndexedSizeCurve: {},
        IndexedSizes: {},
        TotalQuantity: 0,
        ActiveTotalQuantity: 0,
      }
    }
    this.IndexedCRD[crdId].DeliveryDate = deliveryDate
  }

  setQuantity(currentOrder: Order, crdId: number, sizesWithQuantity: Array<{ sizeId: number, quantity: number }>) {
    // TODO: do index _DeliveryDates on articles
    const articleCRD = this.Article._DeliveryDates.find(crd => crd.CrdId === crdId)

    if (!utils.isDefined(articleCRD)) {
      console.warn(`Unable to set quantity of invalid CRD Id: ${crdId} for Article: ${this.Article.Id}`)
      return
    }

    let CRDQuantityDifference = 0
    let CRDQuantityDifferenceActive = 0
    // const currentPrice = this.Article._Prices[currentOrder.OrderPriceGroup.Id] != null ? this.Article._Prices[currentOrder.OrderPriceGroup.Id].Price : 0 // this.CurrentPrice
    const CRDTotalQuantity = currentOrder.getCRDTotalQuantity(crdId)
    const CRDActiveTotalQuantity = currentOrder.getCRDTotalQuantityForActiveLine(crdId)

    if (!this.IndexedCRD.hasOwnProperty(crdId)) {
      this.IndexedCRD[crdId] = {
        IsActive: true,
        BitwiseStatus: null,
        DecodedStatus: null,
        Status: true,
        OldCrdId: null,
        DeliveryDate: null,
        IndexedSizeCurve: {},
        IndexedSizes: {},
        TotalQuantity: 0,
        ActiveTotalQuantity: 0,
      }
    }

    const oldCRDTotalQuantityOfOrderline = this.IndexedCRD[crdId].TotalQuantity
    const oldCRDActiveTotalQuantityOfOrderline = this.IndexedCRD[crdId].ActiveTotalQuantity

    sizesWithQuantity.forEach((record) => {
      // TODO: do index _Sizes on article
      const articleSize = this.Article._Sizes.find(articleSize => articleSize.Id === record.sizeId)
      if (!utils.isDefined(articleSize)) {
        console.warn(`Unable to set quantity of invalid size: ${record.sizeId} for Article: ${this.Article.Id}`)
        return
      }
      const quantity = !Number.isNaN(Number(record.quantity)) ? Number(record.quantity) : 0
      let sizeQuantityDifference = quantity
      if (!this.IndexedCRD[crdId].IndexedSizes.hasOwnProperty(record.sizeId)) {
        this.IndexedCRD[crdId].IndexedSizes[record.sizeId] = {
          Status: !!articleSize.Status,
          Quantity: quantity,
        }
      }
      else {
        sizeQuantityDifference = quantity - this.IndexedCRD[crdId].IndexedSizes[record.sizeId].Quantity
        this.IndexedCRD[crdId].IndexedSizes[record.sizeId].Quantity = quantity
      }
      this.IndexedCRD[crdId].TotalQuantity += sizeQuantityDifference
      this.TotalQuantity += sizeQuantityDifference
      this.IndexedCRD[crdId].ActiveTotalQuantity += sizeQuantityDifference
      this.ActiveTotalQuantity += sizeQuantityDifference
    })

    CRDQuantityDifference = this.IndexedCRD[crdId].TotalQuantity - oldCRDTotalQuantityOfOrderline
    CRDQuantityDifferenceActive = this.IndexedCRD[crdId].ActiveTotalQuantity - oldCRDActiveTotalQuantityOfOrderline
    currentOrder.setCRDTotalQuantity(crdId, CRDTotalQuantity + CRDQuantityDifference)
    currentOrder.setTotalQuantity(currentOrder.getTotalQuantity() + CRDQuantityDifference)
    currentOrder.setCRDTotalQuantityForActiveLine(crdId, CRDActiveTotalQuantity + CRDQuantityDifferenceActive)
    currentOrder.setActiveTotalQuantity(currentOrder.getActiveTotalQuantity() + CRDQuantityDifferenceActive)
    currentOrder.setTotalInitialValue(currentOrder.CalculatedTotalInitialValue + (CRDQuantityDifference * this.InitialPrice))
    // currentOrder.setTotalCurrentValue(currentOrder.CalculatedTotalCurrentValue + (CRDQuantityDifferenceActive * currentPrice))
    currentOrder.setTotalCurrentValue(currentOrder.CalculatedTotalCurrentValue + (CRDQuantityDifferenceActive * this.CurrentPrice))
    currentOrder.setIsOrderlinesDirty(true)
  }

  updateOrderlineContainVas() {
    this.ContainsValidVAS = false
    if (Object.values(this.IndexedVAS).some(orderlineVAS => orderlineVAS.Status && orderlineVAS.SellerVASStatus)) {
      this.ContainsValidVAS = true
    }
  }

  static decodeBitwiseStatus(status: number): IOrderlineDecodedStatus {
    return {
      invalidArticle: (status & 2) === 2, // Article is deactivated
      invalidCRD: (status & 4) === 4, // CRD at Catalog Level is deactivate
      invalidCatalogOrCustomerSegmentation: (status & 8) === 8, // Segmentation at Catalog Level is deactivated
      invalidPriceGroup: (status & 16) === 16, // Price Group at Catalog Level is deactivated
      invalidSize: (status & 32) === 32, // Size is deactivated
      invalidArticleCRD: (status & 64) === 64, // CRD at Article Level is deactivated
      invalidArticleSeg: (status & 128) === 128, // Article Segmentation and Customer Segmentation no longer match
      unAvailableCRD: (status & 256) === 256, // Delivery date availability criteria failed
      allocationCriteriaField: (status & 512) === 512, // Allocation criteria failed
      invalidSegmentation: (status & 1024) === 1024, //
      customerUnlinked: (status & 2048) === 2048, // customer unlinked
    }
  }
}
