<template>
  <div class="relative bg-gray-200 border rounded" :style="{ width }">
    <div v-if="title.length > 0" class="p-1 text-center bg-white">
      {{ title }}
    </div>
    <div :style="{ width: `${progressWidth}%`, backgroundColor: color }" class="absolute top-0 h-4 text-center text-white rounded" :class="dynamicClass">
      <div v-if="showPercentage" class="float-right pr-1">
        {{ progressWidth }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  title?: string
  modelValue: number
  width?: string
  shimmer?: boolean
  color?: string
  showPercentage?: boolean
}
const props = withDefaults(defineProps<Props>(), { title: '', shimmer: true, width: '100%', color: '#009bffb3', showPercentage: false })
const progressWidth = computed(() => Math.min(props.modelValue, 100))
const dynamicClass = computed(() => {
  let dynamicClass = ''
  if (props.shimmer) {
    dynamicClass = 'shimmer'
  }
  if (progressWidth.value === 100) {
    dynamicClass = 'static'
  }
  return dynamicClass
})
</script>

<style lang="scss" scoped>
.shimmer {
  position: relative;
  overflow: hidden;
}
.static {
  position: relative;
  overflow: hidden;
}

.shimmer::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg,
      rgba(233, 233, 233, 1) 0,
      rgba(233, 233, 233, 0.9) 50%,
      rgba(233, 233, 233, 0.8) 100%);
  animation: shimmer 2.5s ease-out infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}
</style>
