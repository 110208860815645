<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <loader v-if="loading" />
    <div v-else class="container flex flex-col w-full h-full">
      <!-- HEADER -->
      <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="t('articleDetails.actions.editPrices')" :show-header="showHeader" />

      <!-- BODY -->
      <div class="px-10 mt-4 alerts">
        <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      </div>

      <!-- FORM -->
      <div class="h-full">
        <div class="grid w-full grid-cols-2 px-10 gap-x-6">
          <div v-for="priceField in pricesAttributes" :key="priceField.SystemName" class="mb-5 last-of-type:mb-4">
            <attribute-editor
              v-model="formModel[priceField.SystemName]"
              :articles="articles"
              :attribute="priceField"
              :required="priceField.IsRequired"
              :disabled="priceField.ReadOnly"
              :form="formModel"
              :show-external-change-management-info="priceField.ExternalChangeManagementURL != null"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- FOOTER -->
    <tx-form-footer
      v-if="!loading" class="flex flex-row justify-end flex-shrink-0 flex-nowrap" :primary-text="t('general.update')"
      :primary-disabled="isUpdateButtonDisabled" @primary-click="onUpdate" @secondary-click="onCancel"
    />
  </div>
</template>

<script setup lang="ts">
import { isArray, keyBy, uniqBy } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import type { ComputedRef } from 'vue'
import { computed, reactive, ref, watch } from 'vue'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import Loader from '@/shared/components/Loader.vue'
import type MyArticle from '@/models/myArticle'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import appConfig from '@/services/appConfig'
import useErrorMessage from '@/shared/composables/errorMessage'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import { updateArticlePrices } from '@/api/t1/article'
import type { ArticlePriceModel, UpdateArticlePricesModel } from '@/api/t1/model/articleModel'
import ArticlePrice from '@/models/articlePrice'
import { appConstants } from '@/models/constants'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import { useUserStore } from '@/store/userData'
import useEventsBus from '@/shared/composables/eventBus'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'

interface IProps {
  showHeader?: boolean
  articles: MyArticle[]
}
const props = withDefaults(defineProps<IProps>(), { showHeader: true })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[]): void
}>()

const { t } = useI18n()
const { errorMessage, hasError } = useErrorMessage()
const userStore = useUserStore()
const { skipRestrictingPropertyUpdateBasedOnArticleState, getArticlesMaxStateRank, getArticlesLocked } = useArticleFormHelper()
const { emitter } = useEventsBus()

const loading = ref<boolean>(false)
const formModel = reactive<Record<number, any>>({})
const isAnyArticleLocked = ref(false)

const pricesAttributes: ComputedRef<Array<IMyAttribute>> = computed(() => {
  const pricesAttributes: Array<IMyAttribute> = []
  const maxArticleState = getArticlesMaxStateRank(props.articles, !!userStore.activeCatalog!.IsPriceByStyleApply)
  userStore.activeCatalog!.CatalogPriceGroupList.forEach((priceGroup) => {
    if (priceGroup.Status) {
      const priceGroupStateRank = priceGroup.StateRank || 0
      const lockBasedOnArticlesState = !skipRestrictingPropertyUpdateBasedOnArticleState && priceGroup.StateRank != null && maxArticleState >= priceGroupStateRank
      const priceField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
        AttributeType: AttributeType.Decimal,
        DisplayName: `${priceGroup.Description} (${priceGroup.CurrencyCode})`,
        SystemName: priceGroup.Id,
        ReadOnly: !priceGroup.IsEditable || lockBasedOnArticlesState || isAnyArticleLocked.value,
        ExternalChangeManagementURL: lockBasedOnArticlesState && priceGroup.AllowChangeRequestForPriceGroups && appConfig.stateLockingExternalChangeManagementURLForPrices != null && appConfig.stateLockingExternalChangeManagementURLForPrices.toString().trim().length
          ? appConfig.stateLockingExternalChangeManagementURLForPrices
          : undefined,
      })
      pricesAttributes.push(priceField)
    }
  })
  return pricesAttributes
})

const isUpdateButtonDisabled = computed(() => {
  return pricesAttributes.value.every(priceAttribute => priceAttribute.ReadOnly) || (isAnyArticleLocked.value)
})

async function onInit() {
  loading.value = true
  if (!props.articles.length) {
    loading.value = false
    return
  }
  isAnyArticleLocked.value = await getArticlesLocked(props.articles, !!userStore.activeCatalog!.IsPriceByStyleApply)
  const commonPrices: Record<number, number> = {}
  const priceGroupMapById = pricesAttributes.value.map(pricesAttributes => Number.parseInt(pricesAttributes.SystemName))
  for (const priceGroupId in props.articles[0]._Prices) {
    commonPrices[priceGroupId] = props.articles[0]._Prices[priceGroupId].Price
  }

  for (let i = 1; i < props.articles.length; i++) {
    if (!priceGroupMapById.length) { // to avid unnecessary loops
      break
    }
    const article = props.articles[i]
    for (const priceGroupId in article._Prices) {
      const price = article._Prices[priceGroupId]
      if (!priceGroupMapById.length) { // to avid unnecessary loops
        break
      }
      if (commonPrices[priceGroupId] !== article._Prices[priceGroupId].Price) {
        commonPrices[priceGroupId] = 0

        const index = priceGroupMapById.indexOf(price.PriceGroupId)
        if (index !== -1) {
          priceGroupMapById.splice(index, 1)
        }
      }
    }
  }

  pricesAttributes.value.forEach((priceAttribute) => {
    formModel[priceAttribute.SystemName] = utils.isDefined(commonPrices[priceAttribute.SystemName]) ? commonPrices[priceAttribute.SystemName] : 0
  })
  loading.value = false
}
function onCancel() {
  emit('cancel')
}

async function onUpdate() {
  loading.value = true

  const articleIdField = userStore.activeCatalog?.IsPriceByStyleApply ? 'ModelNumber' : 'Id'
  const articleToUpdate = userStore.activeCatalog?.IsPriceByStyleApply ? uniqBy(props.articles, 'ModelNumber') : props.articles
  const priceGroupById: Record<number, IMyAttribute> = keyBy(pricesAttributes.value, 'SystemName')
  const promises: Promise<any>[] = []
  const responseArticleMap: Record<number, ArticlePriceModel[]> = {}

  for (let i = 0; i < articleToUpdate.length; i++) {
    const article = articleToUpdate[i]
    const payload: UpdateArticlePricesModel = {
      Prices: [],
    }
    for (const priceGroupId in formModel) {
      if (priceGroupById[priceGroupId]) {
        if (!priceGroupById[priceGroupId].ReadOnly) {
          payload.Prices.push({ PriceGroupId: Number(priceGroupId), Price: Number.parseFloat(formModel[priceGroupId]) })
        }
      }
    }
    promises.push(updateArticlePrices(userStore.activeCatalog!.CatalogCode, article[articleIdField], payload, userStore.activeCatalog!.IsPriceByStyleApply)
      .then((resp) => {
        if (resp.data && isArray(resp.data) && resp.data.length > 0) {
          resp.data.forEach((itm) => {
            if (!responseArticleMap.hasOwnProperty(itm.ArticleId)) {
              responseArticleMap[itm.ArticleId] = []
            }
            responseArticleMap[itm.ArticleId].push(itm)
          })
        }
      }))
  }

  Promise.all(promises)
    .then(async () => {
      const articlePriceData: ArticlePrice[] = []
      for (const articleId in responseArticleMap) {
        const articlePrice = { Id: Number(articleId), Prices: responseArticleMap[articleId] }
        articlePriceData.push(new ArticlePrice(userStore.activeCatalog!.CatalogCode, articlePrice))
      }
      await appConfig.DB!.bulkUpdateArticles(userStore.activeCatalog!.CatalogCode, 'prices', articlePriceData, true)
      emitter('catalogDataUpdated', { source: 'Price' })
      emit('updated', props.articles)
    }).catch((e) => {
      console.error(e)
      errorMessage.value = t('general.unexpectedError')
    })
    .finally(() => {
      loading.value = false
    })
}

onInit()
watch(() => props.articles, async () => {
  await onInit()
})
</script>
