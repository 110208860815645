import type { IFilterCriteria } from './filterCriteria'

export default class UserPreferences {
  userId: number
  targetSummary: { filterCriteria: IFilterCriteria[] }

  constructor(userPreferences: UserPreferences) {
    this.userId = userPreferences.userId
    this.targetSummary = userPreferences.targetSummary
  }
}
