<template>
  <div class="flex flex-col w-full h-full px-2 pt-10">
    <!-- HEADER -->
    <div class="flex-shrink-0 mb-4 text-xl font-semibold text-center header">
      {{ t('catalog.actionBar.updateBucketAttributes') }}
    </div>

    <!-- BODY -->
    <div class="relative flex flex-col flex-grow">
      <template v-if="loading">
        <loader style="z-index: 1801;" />
        <div class="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-white opacity-50 z-dialog-overlay" />
      </template>
      <!-- FORM -->
      <div class="flex-grow my-3 overflow-x-hidden overflow-y-auto">
        <div class="flex flex-row flex-wrap">
          <div
            v-for="(attribute, index) in visibleBucketAttributes" :key="attribute.SystemName" class="px-5 mb-7 basis-1/2"
            :class="visibleBucketAttributes.length % 2 === 0 ? 'basis-1/2' : index !== visibleBucketAttributes.length - 1 ? 'basis-1/2' : 'basis-full'"
          >
            <attribute-editor
              v-model="formModel[attribute.SystemName]"
              :form="formModel"
              :articles="[]"
              :attribute="attribute"
              :show-external-change-management-info="false"
              :show-update-seasonless-attribute-warning="false"
              :tooltip-placement="index % 4 === 0 ? 'bottom' : 'left'"
            />
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <div class="flex flex-row justify-end flex-shrink-0 mx-10 mb-10 flex-nowrap">
        <tx-button type="cancel" :text="t('general.cancel')" @click="onCancel" />
        <tx-button class="ml-5" type="confirm" :disabled="!isFormValid" :text="t('general.update')" @click="update" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onUnmounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import Loader from '@/shared/components/Loader.vue'
import TxButton from '@/shared/components/TxButton.vue'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import type { FilterCriteria } from '@/models/filterCriteria'
import { getBucketFilterCriteria, getCurrentBucketKey } from '@/services/browseByFactory'
import type { ICreateBucketsModel } from '@/api/t1/model/buckets'
import { updateBucketsValue } from '@/api/t1/buckets'
import { useBrowseByStore } from '@/store/browseBy'
import { useNotificationStore } from '@/store/notification'
import { useUserStore } from '@/store/userData'

const props = defineProps<{
  targetedBuckets: Array<IAttributeBucket>
  filterCriteria: Array<FilterCriteria>
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updateCompleted'): void
}>()

const { t } = useI18n()
const notificationStore = useNotificationStore()
const userStore = useUserStore()
const browseByStore = useBrowseByStore()
const loading = ref<boolean>(false)
const formModel = reactive<Record<number, any>>({})
let timeoutTracker = 0

const visibleBucketAttributes = computed(() =>
  userStore.activeCatalog!.BucketAttributeList.filter(
    BucketAttribute =>
      BucketAttribute.Status && BucketAttribute.Editable && BucketAttribute.AttributeTypeId !== AttributeType.Calc,
  ).map(attribute => ({
    AttributeType: attribute.AttributeTypeId,
    AttributeSource: 'self',
    Creatable: false,
    Editable: attribute.Editable,
    DisplayName: attribute.AttributeDisplayName,
    SystemName: attribute.AttributeSystemName,
    AllowFiltering: false,
    FilterLookup: new Map(),
    IsRequired: false,
    IsSeasonless: false,
    Overridable: false,
    IsStatic: false,
    IsPersonal: false,
    IsModelLevel: false,
    ValidationExpression: '',
    ValidationMessage: '',
    VettingList: attribute.AllowedValues,
    Visible: true,
    Criteria: {},
    DefaultValue: undefined,
    ExternalChangeManagementURL: undefined,
  } as IMyAttribute)),
)

const isFormValid = computed(() => {
  let isFormValid = false
  for (const key in formModel) {
    if (formModel[key] != null && formModel[key].toString().trim().length) {
      isFormValid = true
      break
    }
  }
  return isFormValid
})

init()

function init() {
  let needResetCommonValues = false
  if (props.targetedBuckets.length > 0) {
    const catalogBucketAttributesSystemNameList = userStore.activeCatalog!.BucketAttributeList.map(attribute => attribute.AttributeSystemName)
    const visibleBucketAttributesSystemNameList = visibleBucketAttributes.value.map(attribute => attribute.SystemName)
    const rowDividerProperty = userStore.groupByRow != null && userStore.groupByRow.toString().trim().length ? userStore.groupByRow : null
    const columnDividerProperty = userStore.groupByCol != null && userStore.groupByCol.toString().trim().length ? userStore.groupByCol : null
    const key = getCurrentBucketKey(props.targetedBuckets[0].rawValueLower, props.targetedBuckets[0].rowPropertyRawValueLower, props.targetedBuckets[0].columnPropertyRawValueLower, rowDividerProperty, columnDividerProperty)
    const firstRecordAdditionalInfo = utils.isDefined(browseByStore.indexedBucketAttributesValue) && utils.isDefined(browseByStore.indexedBucketAttributesValue[key]) ? browseByStore.indexedBucketAttributesValue[key] : null
    const bucketAttributesKey = 'bucketAttributes'
    if (utils.isDefined(firstRecordAdditionalInfo) && utils.isDefined(firstRecordAdditionalInfo[bucketAttributesKey])) {
      const firstRecordBucketAttributes = firstRecordAdditionalInfo[bucketAttributesKey]
      // firstRecordBucketAttributes attribute will always contains attributes that are accessible and not restricted to user
      for (const attributeSystemName in firstRecordBucketAttributes) {
        if (catalogBucketAttributesSystemNameList.includes(attributeSystemName) && visibleBucketAttributesSystemNameList.includes(attributeSystemName)) {
          formModel[attributeSystemName] = firstRecordBucketAttributes[attributeSystemName]
        }
      }
    }
    for (let i = 1; i < props.targetedBuckets.length; i++) {
      const key = getCurrentBucketKey(props.targetedBuckets[i].rawValueLower, props.targetedBuckets[i].rowPropertyRawValueLower, props.targetedBuckets[i].columnPropertyRawValueLower, rowDividerProperty, columnDividerProperty)
      const additionalInfo = utils.isDefined(browseByStore.indexedBucketAttributesValue) && utils.isDefined(browseByStore.indexedBucketAttributesValue[key]) ? browseByStore.indexedBucketAttributesValue[key] : null
      if (utils.isDefined(additionalInfo) && utils.isDefined(additionalInfo[bucketAttributesKey])) {
        const bucketAttributes = additionalInfo[bucketAttributesKey]
        for (const attributeSystemName in bucketAttributes) {
          if (formModel.hasOwnProperty(attributeSystemName)
            && ((typeof formModel[attributeSystemName] != typeof bucketAttributes[attributeSystemName])
            || (!Array.isArray(formModel[attributeSystemName]) && formModel[attributeSystemName] !== bucketAttributes[attributeSystemName])
            || (Array.isArray(formModel[attributeSystemName]) && Array.isArray(bucketAttributes[attributeSystemName])
            && ((formModel[attributeSystemName].length !== bucketAttributes[attributeSystemName].length) || formModel[attributeSystemName].some(value => !bucketAttributes[attributeSystemName].includes(value))
            )
            )
            )
          ) {
            formModel[attributeSystemName] = undefined
          }
        }
      }
      else {
        needResetCommonValues = true
        break
      }
    }
  }
  else {
    needResetCommonValues = true
  }
  if (needResetCommonValues) {
    visibleBucketAttributes.value.forEach((attribute) => {
      formModel[attribute.SystemName] = undefined
    })
  }
}

async function update() {
  loading.value = true
  const requestObjects: Array<ICreateBucketsModel> = []
  const filterParam = getBucketFilterCriteria(props.filterCriteria, userStore.activeCatalog!, userStore.linkedCatalogDetails)
  const pivotConfig: Array<string> = []
  if (utils.isDefined(userStore.groupByRow) && userStore.groupByRow.toString().trim().length) {
    pivotConfig.push(userStore.groupByRow)
  }
  if (utils.isDefined(userStore.groupByCol) && userStore.groupByCol.toString().trim().length) {
    pivotConfig.push(userStore.groupByCol)
  }
  pivotConfig.sort() // as agreed with API team whichever attribute comes first alphabetically will be set in rowDivider
  const rowDivider = pivotConfig.shift()
  const columnDivider = pivotConfig.shift()
  const shouldReverseValues = rowDivider !== userStore.groupByRow

  const additionalInfo = { bucketAttributes: formModel }
  let content = ''
  try {
    content = JSON.stringify(additionalInfo)
  }
  catch (error) {
    content = JSON.stringify({})
  }
  props.targetedBuckets.forEach((targetedBucket) => {
    let groupByRowValue: string | null = null
    let groupByColumnValue: string | null = null
    if (shouldReverseValues) {
      groupByRowValue = targetedBucket.columnPropertyRawValueLower
      groupByColumnValue = targetedBucket.rowPropertyRawValueLower
    }
    else {
      groupByRowValue = targetedBucket.rowPropertyRawValueLower
      groupByColumnValue = targetedBucket.columnPropertyRawValueLower
    }
    const requestObject = {
      FilterCriteria: filterParam,
      BrowseByAttribute: targetedBucket.propertySystemName,
      Value: targetedBucket.rawValueLower,
      GroupByRow: utils.isDefined(rowDivider) ? rowDivider : null,
      GroupByColumn: utils.isDefined(columnDivider) ? columnDivider : null,
      GroupByRowValue: !utils.isDefined(rowDivider) ? null : groupByRowValue,
      GroupByColumnValue: !utils.isDefined(columnDivider) ? null : groupByColumnValue,
      BucketAttributeValues: content,
    }
    requestObjects.push(requestObject as never)
  })

  try {
    await updateBucketsValue(userStore.activeCatalog!.CatalogCode, requestObjects)
    notificationStore.addNotification({ message: t('general.messages.updateSuccessfully'), type: 'Success' })
    // reload updated data locally
    await new Promise((resolve) => {
      browseByStore.isLoadingBucketAttributesValue = true
      timeoutTracker = setTimeout(() => {
        if (!utils.isDefined(browseByStore.indexedBucketAttributesValue)) {
          browseByStore.indexedBucketAttributesValue = {}
        }
        requestObjects.forEach((requestObject) => {
          const key = `${requestObject.Value}/${requestObject.GroupByRowValue}/${requestObject.GroupByColumnValue}`
          try {
            browseByStore.indexedBucketAttributesValue![key] = JSON.parse(requestObject.BucketAttributeValues)
          }
          catch (error) {
            console.error(error)
          }
        })
        browseByStore.isLoadingBucketAttributesValue = false
        resolve(true)
      }, 300)
    })
  }
  catch (error) {
    console.warn(error)
    notificationStore.addNotification({ message: t('general.messages.updateFailed'), type: 'Alert' })
  }
  finally {
    loading.value = false
    emit('updateCompleted')
  }
}

function onCancel() {
  emit('cancel')
}

onUnmounted(() => {
  clearTimeout(timeoutTracker)
})
</script>
