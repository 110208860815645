<template>
  <div class="relative flex flex-col grow overflow-hidden">
    <loader v-if="loading" />
    <div v-if="!loading" class="border-b bg-gray-50 shrink grow-0 flex flex-row">
      <tx-input
        v-model="filter" rounded faicon="fa-light fa-magnifying-glass" clearable :placeholder="t('general.typeToFilter')"
        class="my-4 mx-2"
      />
      <tx-actions-button
        :items="sharedSlidesListActions"
        class="my-auto mr-1"
      />
    </div>
    <div class="overflow-y-auto">
      <tx-tree v-if="!loading" ref="refSharedSlideTree" class="tree" :context-menu="contextMenu" show-checkbox="onHoverOrOtherChecked" :filter="filter" :allow-toggle-on-node-click="true" :data="slideTree" @node-click="onTreeItemClick" @action-click="onTreeActionClick" @check-change="OnNodeCheckedChange" @context-menu-node="setContextMenuNode" @context-menu-item-click="contextMenuItemClick" />
    </div>
    <!-- edit slide -->
    <edit-slide-dialog ref="refEditSlideSharedDialog" :merch="props.merch" :slide-tree="slideTree" :allow-editing="false" />
  </div>
</template>

<script lang="ts" setup>
import type { Ref } from 'vue'
import { computed, nextTick, onMounted, onUnmounted, ref, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { searchNodeInTree } from '../utils'
import EditSlideDialog from './EditSlideDialog.vue'
import TxTree from '@/shared/components/TxTree.vue'
import { merchConstants } from '@/models/constants'
import utils from '@/services/utils'
import type Merch from '@/modules/merch/services/merch'
import Loader from '@/shared/components/Loader.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxActionsButton from '@/shared/components/TxActionsButton.vue'
import * as sBar from '@/store/status'
import { useUserStore } from '@/store/userData'

const props = defineProps<{
  merch: Merch | undefined
  activeTab: string

}>()
const emit = defineEmits<{
  (e: 'nodeClick', node: string | number, refreshSlide: boolean): void
  (e: 'refreshTree'): void
  (e: 'duplicateSlides', slideNodes: ITreeNode[]): void
  (e: 'clearSharedActiveSlide'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()

const refSharedSlideTree = ref<InstanceType<typeof TxTree>>()
const refEditSlideSharedDialog = ref<InstanceType<typeof EditSlideDialog>>()
const slideTree = ref<ITreeNode[]>([])
const loading = ref(false)
const filter = ref('')
// const editMode = ref(false)
const checkedNodes = ref<ITreeNode[]>([])
const refreshData = ref(false)
const currentTreeNodeData = ref<ITreeNode | null>(null)
const contextNode = ref<ITreeNode | null>(null)
watchEffect(() => {
  if (props.merch) {
    attach(props.merch)
  }
})
watch(() => props.activeTab, (value) => {
  if (value === 'sharedSlides') {
    if (props.merch?.sharedSlides.value && Object.keys(props.merch?.sharedSlides.value).length) {
      buildTree(slideTree, props.merch?.sharedSlides ? Object.values(props.merch?.sharedSlides.value) : [])
    }
  }
})
const contextMenu = computed(() => {
  let menu: IContextMenuItem[] = []
  if (utils.isDefined(contextNode.value) && !contextNode.value.isOwner && !contextNode.value.isFolder) {
    menu = [{
      key: 'duplicate',
      label: t('merch.duplicate'),
      icon: 'fa-copy',
      disabled: false,
      visible: true,
    }, {
      key: 'slideProperties',
      label: t('merch.slideProperties'),
      icon: 'fa-search',
      disabled: false,
      visible: true,
    }]
  }
  return menu
})

const sharedSlidesListActions = computed(() => {
  return [
    { key: 'refresh', disabled: false, visible: true, label: t('merch.refresh'), icon: 'fa-light fa-refresh', onClick: refreshTree },
    { key: 'duplicateBulkSlides', disabled: checkedNodes.value.length === 0, visible: true, label: t('merch.duplicate'), icon: 'fa-light fa-copy', onClick: duplicateBulkSlides },
  ] as ITxActionItem[]
})

onUnmounted(() => {
  if (props.merch) {
    detach(props.merch)
  }
})

function attach(obj: Merch) {
  obj.on('sharedSlides-loaded', onSlidesLoaded)
}

function detach(obj: Merch) {
  obj.off('sharedSlides-loaded', onSlidesLoaded)
}
async function buildTree(treeData: Ref<ITreeNode[]>, slidesData) {
  slideTree.value = []
  addSharedFolderNodes(slidesData, treeData.value)
  if (userStore && userStore.userProfile && userStore.activeCatalog) {
    const userId = userStore.userProfile.Id
    const customerId = userStore.currentCustomer ? userStore.currentCustomer.CustomerId : null
    const catalogCode = userStore.activeCatalog.CatalogCode
    slidesData.forEach((slideData) => {
      if ((!(slideData.CreatedBy === userId && slideData.CustomerId === customerId && slideData.CatalogCode === catalogCode)) && ((slideData.SharedUsers.findIndex(user => user.Id === userId)) !== -1 || slideData.SharedUsersGroups.length)) {
        const ownerTargetNode = createOwnerAndGetTargetNode(slideData, treeData.value)
        const target = createFolderTrailAndGetTargetNode(slideData, ownerTargetNode)
        if (!Array.isArray(target)) {
          const node: ITreeNode = {
            key: slideData.SlideId,
            labelKey: slideData.SlideName.toLowerCase(),
            label: `${slideData.SlideName} (${slideData.SlideSize})`,
            faicon: 'fa-light fa-frame',
            sortOrder: slideData.SortOrder || 0,
            checked: false,
            expanded: false,
            isFolder: false,
            path: [],
            children: [],
            sharedUsers: slideData.SharedUsers,
            sharedUsersGroups: slideData.SharedUsersGroups,
            badgeValue: slideData.unavailableArticleCount,
            actions: ['View', 'Duplicate'], // ['Edit', 'Duplicate', 'Delete'],
          }
          node.parent = target
          utils.insertSorted(node, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
        }
      }
    })
  }
  loading.value = false
  // If after refresh building the tree, need to select the old selected slide if exists
  nextTick(() => {
  // check if selected node is still present in the tree
    if (utils.isDefined(currentTreeNodeData.value) && currentTreeNodeData.value && !currentTreeNodeData.value.isOwner && !currentTreeNodeData.value.isFolder) {
      if (refreshData.value && props.merch?.sharedSlides && props.merch?.sharedSlides.value && props.merch?.sharedSlides.value[currentTreeNodeData.value.key]) {
        const node = searchNodeInTree(treeData.value, currentTreeNodeData.value.key.toString(), 'key', false)
        if (utils.isDefined(node)) {
          expandTree(node)
          refSharedSlideTree.value?.setCurrentKey(node.key)
          onTreeItemClick(node, true)
        }
      }
      else {
        currentTreeNodeData.value = null
        emit('clearSharedActiveSlide')
      }
    }
    refreshData.value = false
  })
}
function expandTree(node: ITreeNode) {
  let currentNode = node.parent
  while (utils.isDefined(currentNode)) {
    currentNode.expanded = true
    currentNode = currentNode.parent // Move to the parent node
  }
}
function addSharedFolderNodes(slidesData, target: ITreeNode[]) {
  if (props.merch?.sharedFolders && props.merch?.sharedFolders.value && Object.keys(props.merch?.sharedFolders.value).length) {
    const sharedFolderNode: ITreeNode = {
      key: '_sharedFolder',
      labelKey: '-1',
      label: 'Shared Folders',
      faicon: 'fa-light fa-folder',
      sortOrder: 0,
      checked: false,
      expanded: false,
      isFolder: true,
      isOwner: false,
      path: [],
      children: [],
      sharedUsers: [],
      sharedUsersGroups: [],
      badgeValue: undefined,
      actions: [],
    }
    utils.insertSorted(sharedFolderNode, target, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
    // eslint-disable-next-line ts/ban-ts-comment
    // @ts-expect-error
    target = sharedFolderNode
    slidesData.forEach((slideData) => {
      slideData.SharedFolders.forEach((sharedFolder) => {
        // get folderId and folder name path
        const pathData = { folderId: [], folderName: [] }
        getSharedFolderPath(sharedFolder, props.merch?.sharedFolders.value, pathData)
        const targetFolder = createFolderTrailAndGetTargetNode(slideData, target, true, pathData)
        if (!Array.isArray(targetFolder)) {
          const node: ITreeNode = {
            key: slideData.SlideId,
            labelKey: slideData.SlideName.toLowerCase(),
            label: `${slideData.SlideName} (${slideData.SlideSize})`,
            faicon: 'fa-light fa-frame',
            sortOrder: slideData.SortOrder || 0,
            checked: false,
            expanded: false,
            isFolder: false,
            path: [],
            children: [],
            sharedUsers: slideData.SharedUsers,
            sharedUsersGroups: slideData.SharedUsersGroups,
            badgeValue: slideData.unavailableArticleCount,
            actions: ['View', 'Duplicate'], // ['Edit', 'Duplicate', 'Delete'],
          }
          node.parent = targetFolder
          utils.insertSorted(node, targetFolder.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
        }
      })
    })
  }
}
function getSharedFolderPath(sharedFolder, indexedSharedFolders, pathData) {
  const folder = indexedSharedFolders[sharedFolder.Id]
  pathData.folderId.push(folder.Id)
  pathData.folderName.push(folder.Name)
  if (utils.isDefined(folder.ParentFolderId)) {
    getSharedFolderPath(indexedSharedFolders[folder.ParentFolderId], indexedSharedFolders, pathData)
  }
  return pathData
}
function onSlidesLoaded() {
  if (props.merch?.sharedSlides && props.merch?.sharedSlides.value && Object.keys(props.merch?.sharedSlides.value).length) {
    sBar.setItemValue('ttlSlides', Object.keys(props.merch?.sharedSlides.value).length.toLocaleString())
  }
  if (props.merch?.sharedSlides.value && Object.keys(props.merch?.sharedSlides.value).length) {
    buildTree(slideTree, props.merch?.sharedSlides ? Object.values(props.merch?.sharedSlides.value) : [])
  }
  else {
    loading.value = false
  }
}
function createOwnerAndGetTargetNode(slideData, target: ITreeNode[]) {
  let ownerFolder = Array.isArray(target) ? target.find(owner => owner.key === slideData.CreatedBy) : target
  if (!ownerFolder) {
    ownerFolder = {
      key: slideData.CreatedBy,
      label: slideData.CreatedByName,
      labelKey: slideData.CreatedByName.toLowerCase(),
      faicon: 'fa-light fa-user',
      sortOrder: 0,
      checked: false,
      expanded: false,
      isFolder: false,
      isOwner: true,
      path: [],
      children: [],
      badgeValue: 0,
      actions: [], // ['Edit', 'Delete'],
    }
    utils.insertSorted(ownerFolder, target, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
  }
  // eslint-disable-next-line ts/ban-ts-comment
  // @ts-expect-error
  target = ownerFolder
  return target
}
function createFolderTrailAndGetTargetNode(slideData, target: ITreeNode[] | ITreeNode, isSharedFolder: boolean = false, pathData: Record<string, any> = {}) {
  const folderIdPathList = isSharedFolder ? pathData.folderId.reverse() : slideData.FolderId.split(merchConstants.folderPathSeparator)
  const folderNamePathList = isSharedFolder ? pathData.folderName.reverse() : slideData.FolderName.split(merchConstants.folderPathSeparator)
  for (let i = 0; i < folderIdPathList.length; i++) {
    const currentTarget = !Array.isArray(target) ? target.children : target
    let subFolder = currentTarget.find(folder => folder.key === folderIdPathList[i])
    if (!subFolder) {
      subFolder = {
        key: folderIdPathList[i],
        label: folderNamePathList[i],
        labelKey: folderNamePathList[i].toLowerCase(),
        faicon: 'fa-light fa-folder',
        sortOrder: isSharedFolder ? 0 : slideData.FolderIdSortOrder ? slideData.FolderIdSortOrder : 0,
        checked: false,
        expanded: false,
        isFolder: true,
        path: [],
        children: [],
        badgeValue: 0,
        actions: [], // ['Edit', 'Delete'],
      }
      if (!Array.isArray(target)) {
        subFolder.parent = target
        utils.insertSorted(subFolder, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
      }
      else {
        utils.insertSorted(subFolder, target, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
      }
    }
    else {
      subFolder.badgeValue += slideData.unavailableArticleCount
    }
    target = subFolder
  }
  return target
}
function onTreeItemClick(nodeData: ITreeNode, refreshSlide: boolean = false) {
  currentTreeNodeData.value = nodeData
  if (!nodeData.isFolder && !nodeData.isOwner) {
    emit('nodeClick', nodeData.key, refreshSlide)
  }
}
function refreshTree() {
  refreshData.value = true
  loading.value = true
  emit('refreshTree')
}
// function toggleEditMode() {
//   editMode.value = !editMode.value
//   if (!editMode.value) {
//     checkedNodes.value = []
//     resetCheckedNodes(slideTree.value)
//   }
// }
// function resetCheckedNodes(nodes: ITreeNode[]) {
//   if (nodes.length > 0) {
//     nodes.forEach((node) => {
//       node.checked = false
//       resetCheckedNodes(node.children)
//     })
//   }
// }
function OnNodeCheckedChange() {
  const checkedData = refSharedSlideTree.value?.getCheckedNodes(true)
  checkedNodes.value = []
  if (utils.isDefined(checkedData) && checkedData.length > 0) {
    checkedNodes.value = checkedData.filter(node => node.isFolder === false && !node.isOwner)
  }
}
function onTreeActionClick(item: ITreeNode, action: string) {
  if (action === 'Duplicate') {
    duplicateSlides([item])
  }
  else if (action === 'View') {
    refEditSlideSharedDialog.value?.showDialog(item.key, item)
  }
}
function duplicateSlides(slideNodes) {
  emit('duplicateSlides', slideNodes)
}
function setContextMenuNode(item) {
  contextNode.value = item
}
function duplicateBulkSlides() {
  if (checkedNodes.value.length !== 0) {
    duplicateSlides(checkedNodes.value)
  }
}
function contextMenuItemClick(option: IContextMenuItem, targetItem: ITreeNode) {
  if (option.key === 'duplicate') {
    duplicateSlides([targetItem])
  }
  else if (option.key === 'slideProperties' && contextNode.value) {
    refEditSlideSharedDialog.value?.showDialog(contextNode.value.key, contextNode.value)
  }
}
onMounted(() => {
  loading.value = true
})
</script>
