<template>
  <div class="w-full grow flex flex-col h-full overflow-hidden">
    <tx-data-table
      :data="data" :columns="columns" :loading="loadingData" :item-actions="itemActions" :list-actions="listActions" :visible-columns="visibleColumns"
      :title="t('general.articles')" :model-path="modelPath" :total-column-width="totalColumnWidth" :sort-column="{ UpdatedDate: false }"
      @selection-changed="onSelectionChanged" @refresh="doRefresh"
    />
    <approve-reject-request-dialog ref="showApproveRejectDialog" @approve-reject="onApproveReject" />

    <tx-drawer v-model="isStatusDrawerVisible" right width="700px">
      <active-or-deactive-article
        class="grow"
        :type="isActivate ? 'activate' : 'deactivate'" :show-header="true"
        :articles="[currentArticle!]" :confirm-request-id="confirmRequestId" @cancel="closeDrawer" @updated="onArticlesUpdated"
      />
    </tx-drawer>

    <tx-drawer v-model="isStateDrawerVisible" right width="700px">
      <manage-article-state
        class="grow" :articles="[currentArticle!]" :confirm-request-id="confirmRequestId" :request-confirm-state-id="requestConfirmValue"
        :show-header="false" @cancel="closeDrawer" @updated="onArticlesUpdated"
      />
    </tx-drawer>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import useUserProfileSettings from '../admin/composables/userProfileSettings'
import useArticleLocalDataUpdater from '../articleDetails/composables/articleLocalDataUpdater'
import ManageArticleState from '../articleDetails/components/ManageArticleState.vue'
import RequestsToEditModel from './requests'
import ActiveOrDeactiveArticle from '@/modules/articleDetails/components/ActiveOrDeactiveArticle.vue'
import { useConfirmDialog } from '@/shared/composables/confirmDialog'
import TxDataTable from '@/shared/components/txDataTable'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import type { ITxDataTableColumn, ITxDataTableItemAction, ITxDataTableListAction, ITxDataTableVisibleColumn } from '@/shared/components/txDataTable/TxDataTable.types'
import { useNotificationStore } from '@/store/notification'
import { editRequestKeys, privileges, requestConstants } from '@/models/constants'
import type RequestModel from '@/models/request'
import ApproveRejectRequestDialog from '@/shared/components/requests/ApproveRejectRequestDialog.vue'
import { updateArticle, updateArticlePrices } from '@/api/t1/article'
import { updateRequestStatuses } from '@/api/t1/request'
import type MyArticle from '@/models/myArticle'
import { AttributeType } from '@/models/catalogAttribute'
import { updateModelSizeScale } from '@/api/t1/size'
import TxDrawer from '@/shared/components/TxDrawer.vue'
import type { ArticlePriceModel, UpdateArticlePricesModel } from '@/api/t1/model/articleModel'
import ArticlePrice from '@/models/articlePrice'
import useEventsBus from '@/shared/composables/eventBus'
import utils from '@/services/utils'

const { t } = useI18n()
const userStore = useUserStore()
const notificationsStore = useNotificationStore()
const { applyPersistedListViewLayout } = useUserProfileSettings()
const confirmDialog = useConfirmDialog()
const { refreshLocalArticlesData } = useArticleLocalDataUpdater()
const { emitter } = useEventsBus()

const columns = ref<ITxDataTableColumn[]>([])
const data = ref<any[]>([])
const loadingData = ref(true)
const visibleColumns = ref<ITxDataTableVisibleColumn[]>([])
const modelPath = ref('')
const totalColumnWidth = ref(1200)

const selectedRequests = ref<RequestModel[]>([])
const selectedItems = ref<RequestModel[]>([])
const requestsToEditModel = shallowRef<RequestsToEditModel>()
const showApproveRejectDialog = ref<InstanceType<typeof ApproveRejectRequestDialog>>()
const confirmRequestId = ref<number | null>(null)
const requestConfirmValue = ref<number | null>(null)
const currentArticle = ref<MyArticle | null>(null)
const isStatusDrawerVisible = ref(false)
const isStateDrawerVisible = ref(false)
const isActivate = ref(false)

interface UpdateRequestsStatusModel {
  Id: number
  Status: ZeroOrOneType
}
enum actionType {
  delete = 'delete',
  activate = 'activate',
  approve = 'approve',
  reject = 'reject',
  confirm = 'confirm',
}

const itemActions = computed(() => ([
  {
    id: actionType.delete,
    enabled: true,
    visible: item => userStore.userProfile.isValidPrivilege(privileges.article.updateStatus) && item.Status > 0,
    icon: 'fa-light fa-trash-can',
    label: t('general.delete'),
    onClick: (item) => {
      onActivateDeactivate([item], 0)
    },
  },
  // {
  //   id: actionType.activate,
  //   enabled: true,
  //   visible: item => userStore.userProfile.isValidPrivilege(privileges.article.updateStatus) && item.Status === 0,
  //   icon: 'fa-light fa-rotate-right',
  //   label: t('activeOrDeactiveArticle.activate'),
  //   onClick: async (item) => {
  //     selectedRequests.value = [item]
  //     openDrawer(actionType.activate)
  //   },
  // },
  {
    id: actionType.approve,
    enabled: true,
    visible: item => userStore.userProfile.isValidPrivilege(privileges.request.approveRequest) && (item.State === requestConstants.requestStates.new || item.State === requestConstants.requestStates.reject),
    label: t('requests.approve'),
    icon: 'fa-light fa-thumbs-up',
    onClick: async (item) => {
      selectedRequests.value = [item]
      openApproveRejectDialog(selectedRequests.value, actionType.approve)
    },
  },
  {
    id: actionType.reject,
    enabled: true,
    visible: item => userStore.userProfile.isValidPrivilege(privileges.request.rejectRequest) && (item.State === requestConstants.requestStates.new || item.State === requestConstants.requestStates.approve),
    label: t('requests.reject'),
    icon: 'fa-light fa-thumbs-down',
    onClick: async (item) => {
      selectedRequests.value = [item]
      openApproveRejectDialog(selectedRequests.value, actionType.reject)
    },
  },
  {
    id: actionType.confirm,
    enabled: true,
    visible: item => userStore.userProfile.isValidPrivilege(privileges.request.confirmRequest) && item.State === requestConstants.requestStates.approve,
    label: t('requests.confirm'),
    icon: 'fa-light fa-paper-plane',
    onClick: async (item) => {
      onConfirmRequest(item)
    },
  },
]) as Array<ITxDataTableItemAction>)

const listActions = computed(() => {
  const actions: ITxDataTableListAction[] = [{
    id: actionType.delete,
    label: t('general.delete'),
    icon: 'fa-light fa-trash-can',
    visible: userStore.userProfile.isValidPrivilege(privileges.request.updateStatus),
    enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.Status > 0),
    onClick: () => {
      onActivateDeactivate(selectedItems.value, 0)
    },
  },
  // {
  //   id: actionType.activate,
  //   label: t('activeOrDeactiveArticle.activate'),
  //   icon: 'fa-light fa-rotate-right',
  //   visible: userStore.userProfile.isValidPrivilege(privileges.request.updateStatus),
  //   enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.Status === 0),
  //   onClick: () => {
  //     selectedRequests.value = selectedItems.value
  //     openDrawer(actionType.activate)
  //   },
  // },
  {
    id: actionType.approve,
    label: t('requests.approve'),
    icon: 'fa-light fa-thumbs-up',
    visible: userStore.userProfile.isValidPrivilege(privileges.request.approveRequest),
    enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.State === requestConstants.requestStates.new || x.State === requestConstants.requestStates.reject),
    onClick: () => {
      selectedRequests.value = selectedItems.value
      openApproveRejectDialog(selectedRequests.value, actionType.approve)
    },
  }, {
    id: actionType.reject,
    label: t('requests.reject'),
    icon: 'fa-light fa-thumbs-down',
    visible: userStore.userProfile.isValidPrivilege(privileges.request.rejectRequest),
    enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.State === requestConstants.requestStates.new || x.State === requestConstants.requestStates.approve),
    onClick: () => {
      selectedRequests.value = selectedItems.value
      openApproveRejectDialog(selectedRequests.value, actionType.reject)
    },
  }]
  return actions
})

// const isActiveOrDeActiveAction = computed(() => selectedListAction.value && (selectedListAction.value.id === actionType.activate || selectedListAction.value.id === actionType.delete))
// const isApproveRejectAction = computed(() => selectedListAction.value && (selectedListAction.value.id === actionType.approve || selectedListAction.value.id === actionType.reject))

onMounted(async () => {
  requestsToEditModel.value = new RequestsToEditModel(t)
  columns.value = requestsToEditModel.value.getDataTableColumns()
  visibleColumns.value = requestsToEditModel.value.getDefaultVisibleColumnList()
  modelPath.value = requestsToEditModel.value.modelPath
  // retrieve persisted default colums for model if existed and apply that on visible columns
  // Note: This should be part of all module implementations.
  const { persistedColumns, totalTableColumnWidth } = applyPersistedListViewLayout(true, modelPath.value, columns.value, visibleColumns.value)
  if (persistedColumns) {
    visibleColumns.value = persistedColumns
  }
  if (totalTableColumnWidth) {
    totalColumnWidth.value = totalTableColumnWidth
  }
  await doRefresh()
})

function onSelectionChanged(items: any[]) {
  selectedItems.value = items
}

async function doRefresh() {
  loadingData.value = true
  if (userStore.activeCatalog) {
    selectedItems.value = []
    data.value = []
    await userStore.doLoadData(['Requests'], [], false)
    const response = await appConfig.DB!.requests
      .where({ CatalogCode: userStore.activeCatalog.CatalogCode })
      .filter(r => !r.IsCreateArticleRequest)
      .toArray()
    data.value = await utils.getModifyArticleRequestsTranslatedData(userStore.activeCatalog, userStore.myAttributes!, response, userStore.sizeScales, userStore.articleStateList, appConfig.DB!)
  }
  loadingData.value = false
}

function openApproveRejectDialog(requests: any[], action: actionType) {
  const actionNumber = action === actionType.approve ? 1 : 2
  showApproveRejectDialog.value?.showDialog(requests, actionNumber)
}

function onApproveReject() {
  doRefresh()
}

async function onConfirmRequest(request: any) {
  if (userStore.activeCatalog && request.Content) {
    confirmRequestId.value = null
    const title = t('general.alert')
    const message = t('requests.confirmRequestMessage')
    // add comment and reason
    const confirmContentArray: any[] = []
    confirmContentArray.push({ header: `<strong>${t('requests.title.approvalRejectionReason')}:</strong>` })
    confirmContentArray.push(`${t('requestsTable.fields.reason')}: ${request.Reason}`)
    confirmContentArray.push(`${t('requestsTable.fields.comment')}: ${request.Comment}`)

    const attributesList = Object.values(userStore.myAttributes!)
    const modelLevelAttributeNames = attributesList
      .filter(attribute => attribute.IsModelLevel)
      .map(attribute => attribute.SystemName)

    await confirmDialog(title, message, confirmContentArray, async () => {
      const key = Object.keys(request.Content)[0]
      let newValue = request.Content[key]
      const attr = attributesList.filter(attribute => attribute.SystemName === key)[0]
      const articleObject: MyArticle | undefined = await appConfig.DB!.getArticle(userStore.activeCatalog!, request.SourceArticleId)
      if (articleObject) {
        currentArticle.value = articleObject
        if (attr && (!attr.IsStatic || key === 'ModelName')) {
          const updatePayload = {
            ModelId: articleObject.ModelId,
            ModelNumber: articleObject.ModelNumber,
            ArticleNumber: articleObject.ArticleNumber,
            ArticleName: articleObject.ArticleName,
          }
          if (attr.AttributeType === AttributeType.ColorPalette) {
            newValue = JSON.stringify(newValue)
          }
          updatePayload[key] = newValue
          try {
            await updateArticle(userStore.activeCatalog!.CatalogCode, articleObject.Id, updatePayload, modelLevelAttributeNames.includes(key), request.Id)
            await refreshLocalArticlesData(true, articleObject.ModelId)
            await userStore.doLoadData(['Requests'])
            doRefresh()
            notificationsStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
          }
          catch (error) {
            console.error(error)
          }
        }
        if ((attr && attr.IsStatic) || key === editRequestKeys.prices) {
          if (key === editRequestKeys.stateName) {
            requestConfirmValue.value = Number.parseInt(newValue)
            confirmRequestId.value = request.Id
            isStateDrawerVisible.value = true
          }
          else if (key === editRequestKeys.colorId) {
            const updatePayload = {
              ModelId: articleObject.ModelId,
              ModelNumber: articleObject.ModelNumber,
              ArticleNumber: articleObject.ArticleNumber,
              ArticleName: articleObject.ArticleName,
            }
            const colorId = newValue ? Object.keys(newValue)[0] : newValue
            updatePayload[key] = colorId
            try {
              await updateArticle(userStore.activeCatalog!.CatalogCode, articleObject.Id, updatePayload, modelLevelAttributeNames.includes(key), request.Id)
              await refreshLocalArticlesData(true, articleObject.ModelId, articleObject.Id, true, true)
              await userStore.doLoadData(['Requests'])
              doRefresh()
              notificationsStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
            }
            catch (error) {
              console.error(error)
            }
          }
          else if (key === editRequestKeys.status) {
            requestConfirmValue.value = Number.parseInt(newValue)
            confirmRequestId.value = request.Id
            isActivate.value = newValue === 1
            isStatusDrawerVisible.value = true
          }
          else if (key === editRequestKeys.sizeScale) {
            try {
              await updateModelSizeScale(userStore.activeCatalog!.CatalogCode, request.SourceModelId!, { SizeScaleId: newValue }, request.Id)
              await refreshLocalArticlesData(true, articleObject.ModelId)
              await userStore.doLoadData(['Requests'])
              doRefresh()
              notificationsStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
            }
            catch (error) {
              console.error(error)
            }
          }
          else if (key === editRequestKeys.prices) {
            const requestedPrices = request.Content.Prices.map(contentPrice => ({
              PriceGroupId: contentPrice.Id,
              Price: Number.parseFloat(contentPrice.Price),
            }))

            const articleIdField = userStore.activeCatalog?.IsPriceByStyleApply ? 'ModelNumber' : 'Id'
            const payload: UpdateArticlePricesModel = {
              Prices: requestedPrices,
            }

            try {
              const response = await updateArticlePrices(userStore.activeCatalog!.CatalogCode, articleObject[articleIdField], payload, userStore.activeCatalog!.IsPriceByStyleApply, request.Id)

              await refreshLocalArticlesData(true, articleObject.ModelId)
              await userStore.doLoadData(['Requests'])
              doRefresh()

              // update local articles data
              const responseArticleMap: Record<number, ArticlePriceModel[]> = {}

              if (response.data && Array.isArray(response.data)) {
                response.data.forEach((item: ArticlePriceModel) => {
                  if (!responseArticleMap.hasOwnProperty(item.ArticleId)) {
                    responseArticleMap[item.ArticleId] = []
                  }
                  responseArticleMap[item.ArticleId].push(item)
                })
              }

              const articlePriceData: ArticlePrice[] = []
              for (const articleId in responseArticleMap) {
                const articlePrice = { Id: Number(articleId), Prices: responseArticleMap[articleId] }
                articlePriceData.push(new ArticlePrice(userStore.activeCatalog!.CatalogCode, articlePrice))
              }

              await appConfig.DB!.bulkUpdateArticles(userStore.activeCatalog!.CatalogCode, 'prices', articlePriceData, true)
              emitter('catalogDataUpdated', { source: 'Price' })

              notificationsStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
            }
            catch (error) {
              console.error(error)
            }
          }
        }
      }
    })
  }
}

async function onActivateDeactivate(requests: RequestModel[], status: ZeroOrOneType) {
  if (userStore.activeCatalog) {
    const artcileNumbers: string[] = []
    const requestObj: UpdateRequestsStatusModel[] = []
    requests.forEach((request) => {
      artcileNumbers.push(request.SourceArticleNumber)
      requestObj.push({
        Id: request.Id,
        Status: status,
      })
    })
    const title = t('general.alert')
    const activateOrDeactivateAction = status === 0 ? 'delete' : 'activate'
    const message = t('activeOrDeactiveArticle.activateOrDeactivateAlert', { action: activateOrDeactivateAction, number: artcileNumbers.toString() })

    await confirmDialog(title, message, [], async () => {
      await updateRequestStatuses(userStore.activeCatalog!.CatalogCode, requestObj)
      await userStore.doLoadData(['Requests'])
      notificationsStore.addNotification({ message: t(status === 0 ? 'activeOrDeactiveArticle.deactivateRequestSuccessfully' : 'activeOrDeactiveArticle.activateRequestSuccessfully', 1), type: 'Success' })
      doRefresh()
    })
  }
}

async function onArticlesUpdated() {
  const message = t('general.updatedSuccessfully')
  notificationsStore.addNotification({ message, type: 'Success' })
  await userStore.doLoadData(['Requests'])
  doRefresh()
  closeDrawer()
}

function closeDrawer() {
  isStateDrawerVisible.value = isStateDrawerVisible.value ? false : isStateDrawerVisible.value
  isStatusDrawerVisible.value = isStatusDrawerVisible.value ? false : isStatusDrawerVisible.value
}
</script>
