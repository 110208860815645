import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import RenderHelper from './renderHelper'
import { whiteboardConstants } from '@/models/constants'

interface IWbStickyOptions extends fabric.IRectOptions {
  id?: string
  font?: string
  fontSize?: number
  textAlign?: CanvasTextAlign
  textColor?: string
  bold?: boolean
  italic?: boolean
  lock?: boolean
  preventUnlock?: boolean
}

export default class WbSticky extends fabric.Rect implements IWbObject {
  public id: string
  public type = whiteboardConstants.objectTypes.sticky
  public text = ''
  public textAlign: CanvasTextAlign = 'center'
  public font = 'Helvetica'
  public fontSize = -1 // Auto
  public textColor = '#000000'
  public bold = false
  public italic = false
  public lock: boolean
  public preventUnlock: boolean
  public connectable: boolean
  public editableProps: Record<string, IWbObjectProp> = {
    color: { name: 'color', type: 'color' },
    font: { name: 'font', type: 'font' },
    fontSize: { name: 'font size', type: 'fontSize' },
    textColor: { name: 'text color', type: 'textColor' },
    // 'textAlign': { name: 'align', type: 'textAlign' },
    fontStyle: { name: 'font style', type: 'fontStyle' },
    size: { name: 'size', type: 'size' },
    lock: { name: 'Lock', type: 'lock' },
    addDiscussion: { name: 'add comment', type: 'addDiscussion' },
  }

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  constructor(text: string, opt?: IWbStickyOptions) {
    super(opt)
    this.id = opt?.id || guid()
    this.text = text
    this.textAlign = opt?.textAlign || 'center'
    this.font = opt?.font || 'Helvetica'
    this.fontSize = opt?.fontSize || -1
    this.textColor = opt?.textColor || '#000000'
    this.bold = opt?.bold || false
    this.italic = opt?.italic || false
    this.lock = opt?.lock || false
    this.shadow = new fabric.Shadow('rgba(0,0,0,0.2) 2px 2px 10px')
    this.connectable = true
    this.preventUnlock = opt?.preventUnlock || false

    this.setLock(this.lock)

    this.stateProperties = this.stateProperties?.concat(['font', 'fontSize', 'textAlign', 'textColor', 'bold', 'italic', 'text', 'lock', 'preventUnlock'])

    this.on('scaling', () => {
      this.width! *= this.scaleX!
      this.height! *= this.scaleY!
      this.scaleX = 1
      this.scaleY = 1
      this.dirty = true
      this.setCoords()
      this.canvas?.requestRenderAll()
    })
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'color':
        this.set('fill', value.backgroundColor)
        break
      case 'font':
        this.set('font', value.font)
        break
      case 'fontSize':
        this.set('fontSize', value.fontSize)
        break
      case 'textColor':
        this.set('textColor', value.textColor)
        break
      case 'textAlign':
        this.set('textAlign', value.textAlign)
        break
      case 'bold':
        this.set('bold', value.bold)
        break
      case 'italic':
        this.set('italic', value.italic)
        break
      case 'size': {
        let size = 100
        switch (value.size) {
          case 'S':
            size = 50
            break
          case 'M':
            size = 100
            break
          case 'L':
            size = 200
            break
        }
        this.set('width', size)
        this.set('height', size)
        break
      }
      case 'text':
        this.set('text', value.text)
        break
      case 'lock':
        this.set('lock', value.lock)
        this.setLock(value.lock)
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'color':
        result.backgroundColor = this.fill
        break
      case 'font':
        result.font = this.font
        break
      case 'fontSize':
        result.fontSize = this.fontSize
        break
      case 'textColor':
        result.textColor = this.textColor
        break
      case 'textAlign':
        result.textAlign = this.textAlign
        break
      case 'bold':
        result.bold = this.bold
        break
      case 'italic':
        result.italic = this.italic
        break
      case 'size':
        result.size = this.width === 50
          ? 'S'
          : this.width === 100
            ? 'M'
            : this.width === 200
              ? 'L'
              : 'C'
        break
      case 'text':
        result.text = this.text
        break
      case 'lock':
        result.lock = this.lock
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject(propertiesToInclude?: string[]) {
    const props = propertiesToInclude || []
    props.push('id', 'font', 'fontSize', 'textAlign', 'textColor', 'bold', 'italic', 'text', 'lock', 'preventUnlock')
    return super.toObject(props)
  }

  static fromObject(object: fabric.Object, callback?: Function) {
    return fabric.Object._fromObject(whiteboardConstants.objectTypes.sticky, object, callback, 'text')
  }

  override _render(ctx: CanvasRenderingContext2D): void {
    const h = this.height || 0
    const w = this.width || 0
    const xStart = -w / 2
    const yStart = -h / 2
    // const xEnd = xStart + w
    // const yEnd = yStart + h
    // const foldPercent = 0.3
    const margin = 10

    // Draw rectangle
    super._render(ctx)

    // Draw paper fold
    // ctx.beginPath()
    // ctx.moveTo(xEnd, yEnd - yEnd * foldPercent)
    // ctx.lineTo(xEnd - xEnd * foldPercent, yEnd - yEnd * foldPercent)
    // ctx.lineTo(xEnd - xEnd * foldPercent, yEnd)
    // ctx.closePath()
    // ctx.fillStyle = 'rgba(0,0,0,0.1)'
    // ctx.fill()

    // Draw text
    // If font is auto, then loop until you find the size that fits
    ctx.fillStyle = this.textColor
    const fontStyle = `${(this.italic ? 'italic' : '')} ${(this.bold ? 'bold' : '')}`
    if (this.fontSize < 0) {
      let fontSize = 100
      while (!RenderHelper.drawTextInBox(ctx, this.font, fontSize, fontStyle, this.text, xStart + margin, yStart + margin, w - margin * 2, h - margin * 2, 'center', 'middle', true, false)) {
        fontSize--
        if (fontSize <= 0) { break }
      }
    }
    else {
      RenderHelper.drawTextInBox(ctx, this.font, this.fontSize, fontStyle, this.text, xStart + margin, yStart + margin, w - margin * 2, h - margin * 2, 'center', 'middle', false, false)
    }
  }
}

const f: any = fabric
f.WbSticky = WbSticky
