import { defineStore } from 'pinia'

export const useBrowseByStore = defineStore({
  id: 'browseByStore',
  state: () => ({
    browseBy: undefined as IBrowseBy | undefined,
    browseByDetailsMatchCurrentCatalogOnlyFromCriteria: false, // set true to avoid fetching current catalog article when go to a bucket details where either bucket, row divider or column divider is based on _Seasons
    indexedBucketAttributesValue: null as Record<string, object> | null,
    isLoadingBucketAttributesValue: false,
    isShowingBucketsListView: false,
    thumbnailSize: 3,
  }),
  getters: {
    isBrowseByModel: state => !!(state.browseBy && state.browseBy.key === 'browseByModel'),
    isBrowseByAttribute: state => state.browseBy != null && state.browseBy.key !== 'browseByArticle' && state.browseBy.key !== 'browseByModel',
  },
})
