import type Article from '@/models/article'
import type CatalogDetails from '@/models/catalogDetails'
import type CatalogShipmentWindowRange from '@/models/catalogShipmentWindowRange'
import utils from '@/services/utils'
import { appConstants } from '@/models/constants'

export default function useShipmentWindow() {
  /**
   * @description return list of available shipment window based on criteria attribute value and parent shipment date (for child catalogs)
   * @return {Array<CatalogShipmentWindowRange>} list of available shipment window based on criteria attribute and parent shipment date
   */
  function getShipmentWindows(articles: Array<Article>, activeCatalog: CatalogDetails): Array<CatalogShipmentWindowRange> {
    // this method return shipment windows based on criteria attribute value on articles, if no articles return empty array
    if (!articles.length) {
      return []
    }
    let availableRetailIntroMonths = [] as Array<CatalogShipmentWindowRange>
    // RetailWindowAttribute is mandatory field on catalog as discussed in sprint planing 05-09-2022
    const retailWindowFilterCriteriaAttributeValue = articles[0][activeCatalog.RetailWindowAttribute]
    let allArticlesHaveSameValueForRetailWindowAttribute = true
    let biggestParentArticlesShipmentStartDate = articles[0].PShipmentStartDate && utils.isValidStringValue(articles[0].PShipmentStartDate) ? new Date(articles[0].PShipmentStartDate) : undefined // PShipmentStartDate could be null and ! is not an issue here (just to stop typescript complaining)
    let smallestParentArticlesShipmentEndDate = articles[0].PShipmentEndDate && utils.isValidStringValue(articles[0].PShipmentEndDate) ? new Date(articles[0].PShipmentEndDate) : undefined// PShipmentEndDate could be null and ! is not an issue here (just to stop typescript complaining)

    for (let i = 1; i < articles.length; i++) {
      // if all the article does not have same value for RetailWindowAttribute configured in catalog as the value in first article tag the variable false and exit the loop
      if (!utils.haveEqualStringValue(articles[i][activeCatalog.RetailWindowAttribute], retailWindowFilterCriteriaAttributeValue)) {
        allArticlesHaveSameValueForRetailWindowAttribute = false
        break
      }
      // find biggest parent shipment start date and smallest parent shipment end date for inherited catalogs
      if (activeCatalog.DataSourceTypeId === appConstants.catalogTypes.inherited) {
        if (articles[i].PShipmentStartDate && utils.isValidStringValue(articles[i].PShipmentStartDate) && (!biggestParentArticlesShipmentStartDate || biggestParentArticlesShipmentStartDate < new Date(articles[i].PShipmentStartDate!))) {
          biggestParentArticlesShipmentStartDate = new Date(articles[i].PShipmentStartDate!)
        }
        if (articles[i].PShipmentEndDate && utils.isValidStringValue(articles[i].PShipmentEndDate) && (!smallestParentArticlesShipmentEndDate || smallestParentArticlesShipmentEndDate > new Date(articles[i].PShipmentEndDate!))) {
          smallestParentArticlesShipmentEndDate = new Date(articles[i].PShipmentEndDate!)
        }
      }
    }

    // all articles should have same value for RetailWindowAttribute used as filter criteria otherwise return empty list
    if (allArticlesHaveSameValueForRetailWindowAttribute) {
      // filter shipment window range based on RetailWindowAttribute criteria value and parent shipment dates in case of inherited catalog
      availableRetailIntroMonths = activeCatalog.ShipmentWindowRangeList.filter(shipmentWindow =>
        shipmentWindow.Status && utils.haveEqualStringValue(shipmentWindow.CriteriaAttributeValue, retailWindowFilterCriteriaAttributeValue)
        && (
          activeCatalog.DataSourceTypeId === appConstants.catalogTypes.master
          || ((!biggestParentArticlesShipmentStartDate || (new Date(shipmentWindow.ShipmentStartDate) >= biggestParentArticlesShipmentStartDate)) && (!smallestParentArticlesShipmentEndDate || new Date(shipmentWindow.ShipmentEndDate) <= smallestParentArticlesShipmentEndDate))),
      )
    }
    return availableRetailIntroMonths
  }

  /**
   * @description: chronologically sort list of retail intro or retail exit months
   * @param {Array<string | CatalogShipmentWindowRange>} list that needs to be sorted
   * @returns {Array<string | CatalogShipmentWindowRange>} sorted list
   */
  function getSortRetailIntroExitMonths(list: Array<string | CatalogShipmentWindowRange>, objectProperty?: string | undefined): Array<string | CatalogShipmentWindowRange> {
    return list.sort((a, b) => {
      let aLower
      let bLower
      if (utils.isDefined(objectProperty)) {
        aLower = utils.isDefined(a[objectProperty]) ? a[objectProperty].toString().toLowerCase().trim().substring(0, 3) : ''
        bLower = utils.isDefined(b[objectProperty]) ? b[objectProperty].toString().toLowerCase().trim().substring(0, 3) : ''
      }
      else {
        aLower = utils.isDefined(a) ? a.toString().toLowerCase().trim().substring(0, 3) : ''
        bLower = utils.isDefined(b) ? b.toString().toLowerCase().trim().substring(0, 3) : ''
      }
      // if a value not a predefined 3 char month then move them to the end of list
      const aIndex = appConstants.threeCharMonths.includes(aLower) ? appConstants.threeCharMonths.indexOf(aLower) : 13
      const bIndex = appConstants.threeCharMonths.includes(bLower) ? appConstants.threeCharMonths.indexOf(bLower) : 13
      return aIndex - bIndex
    })
  }

  return {
    getShipmentWindows,
    getSortRetailIntroExitMonths,
  }
}
