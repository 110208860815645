<template>
  <div
    ref="refToolbar" class="absolute z-[1000] w-14 bg-white rounded-lg shadow-toolbar pointer-events-auto"
    :style="{ left, top, right, bottom }"
  >
    <div class="p-2">
      <div
        class="pb-2 text-xs text-primary-500" :class="{ 'cursor-move': moveable }" @mousedown.prevent="onMouseDown"
        v-text="title"
      />
      <div
        v-show="!collapsed" class="flex items-center justify-center gap-1 transition-all"
        :class="{ 'flex-row': !vertical, 'flex-col': vertical }" :style="{ 'max-height': `${maxHeight}px` }"
      >
        <tx-button
          v-for="btn in visibleButtons" :key="btn.key" v-tooltip="{ text: btn.label, theme: { placement: tooltipPlacement } }" class="my-1" type="icon"
          :icon="btn.icon" :faicon="btn.faicon" :disabled="btn.disabled || !btn.visible" :active="activeButton === btn.key" @click="onBtnClick(btn)"
        />
      </div>
    </div>
    <div
      v-show="hiddenButtons && hiddenButtons.length > 0" v-tooltip="{ text: t('general.more'), theme: { placement: tooltipPlacement } }"
      class="w-full rounded-sm my-1 bg-grey text-center cursor-pointer pt-0.5" @click="onMoreClick"
    >
      <font-awesome-icon icon="fa-light fa-angle-double-right" class="w-4 h-4 m-auto text-base" />
    </div>
    <div class="w-full h-[13px] bg-grey text-center rounded-b-lg cursor-pointer pt-0.5" @click="doCollapse">
      <font-awesome-icon icon="fa-light fa-chevron-up" class="w-4 h-4 m-auto text-base" :class="{ 'rotate-180': collapsed }" />
    </div>
  </div>
  <div
    v-show="moreButtonsVisible && !collapsed" ref="refToolbar"
    class="absolute z-[1000] w-14 bg-grey-light rounded-lg shadow-toolbar pointer-events-auto ml-15"
    :style="{ left, top, right, bottom, marginTop: `${Math.max(0, (visibleButtons.length + 1 - hiddenButtons.length) * 42 + 25)}px` }"
  >
    <div class="p-2">
      <div
        class="flex items-center justify-center gap-1 transition-all"
        :class="{ 'flex-row': !vertical, 'flex-col': vertical }"
        :style="{ 'max-height': `${hiddenButtons.length * 45}px` }"
      >
        <tx-button
          v-for="btn in hiddenButtons" :key="btn.key" v-tooltip="{ text: btn.label, theme: { placement: tooltipPlacement } }" class="my-1" type="icon"
          :icon="btn.icon" :faicon="btn.faicon" :disabled="btn.disabled || !btn.visible"
          :active="activeButton === btn.key" @click="onBtnClick(btn)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import TxButton from './TxButton.vue'

interface IProps {
  left?: string
  top?: string
  right?: string
  bottom?: string
  moveable?: boolean
  vertical?: boolean
  title?: string
  buttons?: IToolbarButton<MerchToolbarAction>[]
  activeButton?: string
  tooltipPlacement?: string
  maxHeight: number
}
const props = withDefaults(defineProps<IProps>(), { moveable: false, vertical: false, buttons: () => [] as IToolbarButton<MerchToolbarAction>[], tooltipPlacement: 'right' })

const emit = defineEmits<{
  (e: 'buttonClick', btn: IToolbarButton<MerchToolbarAction>): void
}>()

const { t } = useI18n()
let pos1 = 0
let pos2 = 0
let pos3 = 0
let pos4 = 0
const refToolbar = ref<HTMLElement>()
const collapsed = ref(false)
const moreButtonsVisible = ref(false)

const visibleButtons = computed(() => {
  // Height of buttons = 40 * Buttons + 4 * (Buttons - 1) + 23 + 16 + 13
  // Available space for buttons = MaxHeight - 52 / 42
  const availableSpace = props.maxHeight - 52
  if (Math.floor(availableSpace / 42) < props.buttons.length) {
    return props.buttons.slice(0, Math.floor(availableSpace / 42) - 2)
  }
  return props.buttons
})

const hiddenButtons = computed(() => {
  const availableSpace = props.maxHeight - 52
  if (Math.floor(availableSpace / 42) < props.buttons.length) {
    return props.buttons.slice(Math.floor(availableSpace / 42) - 2)
  }
  return []
})

function onMoreClick() {
  moreButtonsVisible.value = !moreButtonsVisible.value
}

function onMouseDown(e: MouseEvent) {
  if (!props.moveable) { return }

  pos3 = e.clientX
  pos4 = e.clientY
  document.addEventListener('mouseup', stopDrag)
  document.addEventListener('mousemove', doDrag)
}

function doDrag(e: MouseEvent) {
  e.preventDefault()
  pos1 = pos3 - e.clientX
  pos2 = pos4 - e.clientY
  pos3 = e.clientX
  pos4 = e.clientY

  refToolbar.value!.style.top = `${refToolbar.value!.offsetTop - pos2}px`
  refToolbar.value!.style.left = `${refToolbar.value!.offsetLeft - pos1}px`
}

function stopDrag() {
  document.removeEventListener('mouseup', stopDrag)
  document.removeEventListener('mousemove', doDrag)
}

function onBtnClick(btn: IToolbarButton<MerchToolbarAction>) {
  moreButtonsVisible.value = false
  emit('buttonClick', btn)
}

function doCollapse() {
  collapsed.value = !collapsed.value
}
</script>
