import type { CatalogColorModel, ICatalogDetails, ICatalogShipmentWindowRange, IRolesBasedRestrictedAttributes, IRolesBasedRestrictedRetailWindows, IRolesBasedRestrictedSegmentations } from './model/catalog'
import Net from '@/services/net'

export function getCatalogDetails(catalogCode: number) {
  return Net.t1.get<ICatalogDetails>(`/catalogs/${catalogCode}?status=0`)
}

export function getCatalogShipmentWindows(catalogCode: number) {
  return Net.t1.get<ICatalogShipmentWindowRange[]>(`/catalogs/${catalogCode}/shipmentwindows`)
}

export function getCatalogColors(catalogCode: number) {
  return Net.t1.get<CatalogColorModel[]>(`/catalogs/${catalogCode}/colors`)
}

export function getRolesBasedRestrictedAttributes(catalogCode: number) {
  return Net.t1.get<IRolesBasedRestrictedAttributes>(`/catalogs/${catalogCode}/role/articleattributes`)
}

// restricted retail windows
export function getRolesBasedRestrictedRetailWindows(catalogCode: number) {
  return Net.t1.get<IRolesBasedRestrictedRetailWindows>(`/catalogs/${catalogCode}/role/reservedattributes`)
}

export function getRolesBasedRestrictedSegmentations(catalogCode: number) {
  return Net.t1.get<IRolesBasedRestrictedSegmentations>(`/catalogs/${catalogCode}/role/segmentations`)
}

export function setCatalogImportMapping(catalogCode: number, requestData: Record<string, any>) {
  return Net.t1.put(`/catalogs/${catalogCode}/importmapping`, { ImportMapping: JSON.stringify(requestData) })
}
