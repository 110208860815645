<template>
  <li class="relative overflow-hidden" :class="{ 'pl-0.5': !isFolder }" :draggable="draggable" @click.right.stop="doContext($event, item.key, item)" @dragstart="dragStart($event, item)" @dragend="dragEnd($event)" @dragover="dragOver($event)" @drop="handleDrop($event, item)">
    <div v-if="item.badgeValue && item.badgeValue !== 0" class="bg-red-500 text-white absolute right-0 h-3 leading-3 text-center rounded-2xl text-xs py-0 px-1">
      {{ item.badgeValue }}
    </div>
    <div class="treeList flex items-center border-b h-11 border-form hover:bg-hover" @mouseenter="onMouseEnter(true)" @mouseleave="onMouseEnter(false)">
      <font-awesome-icon
        v-if="isFolder"
        class="w-3 h-3 m-0.5 text-grey-icon pl-0.5" :class="{ 'cursor-pointer': isFolder }"
        :icon="item.expanded ? 'fa-light fa-square-minus' : 'fa-light fa-square-plus'"
        @click="doToggle(item)"
      />
      <input
        v-if="isCheckboxVisible" class="mx-1 cursor-pointer text-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border-gray-300"
        type="checkbox" :value="item.key" :checked="isChecked(item)" :indeterminate="isChildChecked(item)" :disabled="isCheckboxDisabled"
        @change="onCheckboxChange(item, ($event?.target as HTMLInputElement).checked)"
      >
      <font-awesome-icon
        v-if="item.faicon" class="w-4 h-4 ml-0.5 mr-1 cursor-pointer text-grey-icon"
        :icon="item.faicon"
      />
      <highlight-text
        class="flex-1 cursor-pointer text-md text-ellipsis overflow-hidden whitespace-nowrap" :class="{ 'text-blue-basic': isSelected, 'text-dark': !isSelected, 'font-semibold': item.isDirty }"
        :text="showSlideIndex && !item.isFolder && !props.item.isOwner ? `${item.slideIndex} ${item.label}` : item.label" :query="highlightText" @click="doSelectNode(item)"
      />
      <div class="flex">
        <tx-actions-button
          v-if="item.actions.length > 0"
          :class="{ hidden: !mouseOver && !actionsMenuOpened }"
          :items="getActions"
          class="my-4 mx-1"
          @state-changed="onActionMenuStateChange"
        />
        <!-- <font-awesome-icon
          v-for="action in item.actions" :key="action" class="w-4 h-4 mx-1 cursor-pointer text-grey-icon hover:text-primary-500"
          :icon="getActionIcon(action)" @click="onActionClick(item, action)"
        /> -->
      </div>
      <font-awesome-icon
        v-if="item.faicon2" class="w-4 h-4 mx-0.5 cursor-pointer text-primary-500"
        :icon="item.faicon2"
      />
    </div>
    <ul v-if="item.expanded && isFolder" class="ml-4">
      <tx-tree-item
        v-for="child in item.children" :key="child.key" class="nodes-list-item" :item="child" :selected-key="selectedKey"
        :default-is-open="defaultIsOpen" :draggable="draggable" :show-checkbox="showCheckbox" :highlight-text="highlightText" :show-slide-index="showSlideIndex" :allow-strict-check="props.allowStrictCheck" :current-selection-count="props.currentSelectionCount" :checked-nodes-limit="props.checkedNodesLimit"
        @node-click="doSelectNode" @toggle-node="doToggle" @check-change="onCheckboxChange" @action-click="onActionClick" @context="(data) => doContext(data.evt, data.key, data.item)" @drop="(event, targetNode, draggedNodeKey, dropPosition) => handleDrop(event, targetNode, draggedNodeKey.toString(), dropPosition)"
      />
    </ul>
  </li>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import TxActionsButton from './TxActionsButton.vue'
import HighlightText from './HighlightText.vue'

interface IProps {
  item: ITreeNode
  selectedKey?: number | string | null
  defaultIsOpen?: boolean
  showCheckbox?: 'never' | 'always' | 'onHoverOrOtherChecked'
  showSlideIndex?: boolean
  allowStrictCheck?: boolean
  highlightText: string
  draggable?: boolean
  checkedNodesLimit?: number
  currentSelectionCount: number
}
const props = withDefaults(defineProps<IProps>(), { defaultIsOpen: false, showCheckbox: 'never', showSlideIndex: false, allowStrictCheck: false, draggable: false })

const emit = defineEmits<{
  (e: 'nodeClick', node: ITreeNode): void
  (e: 'checkChange', node: ITreeNode, checked: boolean): void
  (e: 'actionClick', node: ITreeNode, action: TreeNodeAction): void
  (e: 'toggleNode', node: ITreeNode): void
  (e: 'context', data: { evt: MouseEvent, key: string | number, item: ITreeNode }): void
  (e: 'drop', event: MouseEvent, targetNode: ITreeNode, draggedNodeKey: string | number, dropPosition: string): void
}>()

const mouseOver = ref(false)
const actionsMenuOpened = ref(false)

const isCheckboxVisible = computed(() => {
  return props.showCheckbox === 'always' || (props.showCheckbox === 'onHoverOrOtherChecked' && (mouseOver.value || isChecked(props.item)))
})

const isCheckboxDisabled = computed(() => {
  if (!props.item.checked && props.checkedNodesLimit && props.checkedNodesLimit <= props.currentSelectionCount) {
    return true
  }
  else {
    return false
  }
})

const isFolder = computed(() => {
  return props.item.children && props.item.children.length
})

const isSelected = computed(() => {
  return props.selectedKey === props.item.key
})

function doToggle(node: ITreeNode) {
  emit('toggleNode', node)
}

function doSelectNode(node: ITreeNode) {
  emit('nodeClick', node)
}

function onMouseEnter(entered: boolean) {
  mouseOver.value = entered
}

function onActionMenuStateChange(state: boolean) {
  actionsMenuOpened.value = state
}

function onCheckboxChange(node: ITreeNode, checked: boolean) {
  if (!props.allowStrictCheck) {
    checkTreeNodes(node.children, checked, 0)
  }
  node.checked = checked && node.isFolder && !props.allowStrictCheck ? isChecked(node) : checked
  emit('checkChange', node, checked)
}

function checkTreeNodes(nodes: ITreeNode[], checked: boolean, currentSlectedNodesinFolderCount: number) {
  if (nodes.length > 0) {
    nodes.forEach((node) => {
      if (!checked || (!props.checkedNodesLimit || (props.checkedNodesLimit > (props.currentSelectionCount + currentSlectedNodesinFolderCount)))) {
        node.checked = checked && node.isFolder ? isChecked(node) : checked
        if (props.checkedNodesLimit && !node.isFolder && node.checked) {
          currentSlectedNodesinFolderCount += 1
        }
        currentSlectedNodesinFolderCount = checkTreeNodes(node.children, checked, currentSlectedNodesinFolderCount)
        emit('checkChange', node, checked)
      }
    })
  }
  return currentSlectedNodesinFolderCount
}

function isChecked(node: ITreeNode) {
  return node.checked || (node.children.length > 0 && node.children.every(c => c.checked))
}

function isChildChecked(node: ITreeNode) {
  return isChecked(node) || props.allowStrictCheck ? false : node.children.some(c => c.checked)
}

const getActions = computed(() => {
  return props.item.actions.map((action) => {
    return {
      key: getActionKey(action),
      label: action,
      icon: getActionIcon(action),
      disabled: false,
      visible: true,
      onClick: () => onActionClick(props.item, action),
    } as ITxActionItem
  })
})

// TODO: THIS COMPONENT IS TIGHTLY COUPLED WITH THE MERCH MODULE BY HAVING PREDEFINED ACTIONS! THIS IS INCORRECT AND NEEDS TO BE REFACTORED ASAP!

function getActionIcon(action: TreeNodeAction) {
  switch (action) {
    case 'Edit':
      return 'fa-light fa-edit'
    case 'Duplicate':
      return 'fa-light fa-copy'
    case 'Delete':
      return 'fa-light fa-trash-can'
    case 'View':
      return 'fa-light fa-search'
  }
  return ''
}

function getActionKey(action: TreeNodeAction) {
  switch (action) {
    case 'Edit':
      return isFolder.value ? 'editFolder' : 'editSlide'
    case 'Duplicate':
      return 'duplicate'
    case 'Delete':
      return isFolder.value ? 'deleteFolder' : 'deleteSlide'
    case 'View':
      return 'view'
  }
}

function onActionClick(node: ITreeNode, action: TreeNodeAction) {
  emit('actionClick', node, action)
}
function doContext(evt: MouseEvent, key: string | number, item: ITreeNode) {
  evt.preventDefault()
  emit('context', { evt, key, item })
}
function dragStart(event, item) {
  event.stopPropagation()
  event.dataTransfer.clearData()
  event.dataTransfer.setData('text/plain', item.key)
}
function dragEnd(event) {
  event.preventDefault()
}
function dragOver(event) {
  event.preventDefault()
}

function handleDrop(event, targetNode, draggedNodeKey = '', dropPosition = '') {
  event.preventDefault()
  event.stopPropagation()
  draggedNodeKey = event.dataTransfer.getData('text/plain')
  const targetElement = event.target
  const rect = targetElement.getBoundingClientRect()
  const offsetY = event.clientY - rect.top
  const nodeHeight = targetElement.offsetHeight
  if (offsetY < nodeHeight / 3) {
    dropPosition = 'before'
  }
  else if (offsetY > (2 * nodeHeight) / 3) {
    dropPosition = 'after'
  }
  else {
    dropPosition = 'inner'
  }
  emit('drop', event, targetNode, draggedNodeKey, dropPosition)
}
</script>

<style lang="scss" scoped>
.actions {
  white-space: nowrap;
  display: none;
  visibility: hidden;
}
</style>
