<template>
  <div class="container w-full p-2">
    <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    <div class="relative w-full overflow-y-auto">
      <loader v-if="loading" />
      <div v-else class="w-full">
        <table class="w-full text-center">
          <thead class="border-b bg-default">
            <tr>
              <th v-for="column in columns" :key="column.field" scope="col" class="py-2 text-sm font-semibold" v-text="column.displayLabel" />
            </tr>
          </thead>
          <tbody>
            <tr v-for="(stock, key) in stocks" :key="key" class="bg-white border-b">
              <td v-for="column in columns" :key="column.field" class="py-2 text-sm font-light whitespace-nowrap" v-text="stock[column.field]" />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { orderBy } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { onMounted, ref, watch } from 'vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import Loader from '@/shared/components/Loader.vue'
import type MyArticle from '@/models/myArticle'
import type { StockDefinitionModel } from '@/api/t1/model/stockModel'
import { getStockDefinitions, getStocks } from '@/api/t1/stock'
import { privileges } from '@/models/constants'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'

const props = defineProps<{
  article: MyArticle
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()

const loading = ref(false)
const columns = ref<{ field: string | number, displayLabel: string }[]>([])
const stocks = ref<Record<number, { size: string, totalStock: number, [stockDefinitions: number]: number }>>({})

let stockDefinitions: StockDefinitionModel[] = []
function loadStockDefinitions() {
  loading.value = true
  stockDefinitions = []
  columns.value = []
  if (userStore.activeCatalog && userStore.activeCatalog.IsStockApply > 0 && userStore.userProfile.isValidPrivilege(privileges.stock.getDefinitions)) {
    return getStockDefinitions(userStore.activeCatalog.CatalogCode)
      .then((res) => {
        stockDefinitions = utils.isDefined(userStore.currentCustomer)
          ? res.data.filter(s => s.Status > 0 && s.Id === userStore.currentCustomer?.StockDefinitionId)
          : res.data
        if (stockDefinitions.length) {
          columns.value.push({ field: 'size', displayLabel: t('stock.stockPerSize') })
          orderBy(stockDefinitions, s => s.StockName).forEach((stockDefinition) => {
            columns.value.push({ field: stockDefinition.Id, displayLabel: stockDefinition.StockName })
          })
          columns.value.push({ field: 'totalStock', displayLabel: t('stock.totalStocks') })
        }
      })
      .catch((e) => {
        console.error(e)
        errorMessage.value = t('general.unexpectedError')
      })
      .finally(() => loading.value = false)
  }
}

function loadStocks() {
  if (userStore.activeCatalog && userStore.activeCatalog.IsStockApply > 0 && userStore.userProfile.isValidPrivilege(privileges.stock.getList) && utils.isDefined(props.article)) {
    loading.value = true
    getStocks(userStore.activeCatalog.CatalogCode, props.article.Id, userStore.currentCustomer?.CustomerId)
      .then((res) => {
        res.data.forEach((articleStock) => {
          if (utils.isDefined(stocks.value[articleStock.SizeId])) {
            articleStock.Stocks.forEach((s) => {
              if (s.Status > 0) {
                const currentTotal = s.Actual - s.Reserved
                stocks.value[articleStock.SizeId][s.StockDefinitionId] = currentTotal
                stocks.value[articleStock.SizeId].totalStock += currentTotal
              }
            })
          }
        })
      })
      .catch((e) => {
        console.error(e)
        errorMessage.value = t('general.unexpectedError')
        // don't show table
        columns.value = []
        stocks.value = {}
      })
      .finally(() => loading.value = false)
  }
}

async function reset() {
  loading.value = false
  errorMessage.value = ''
  stocks.value = {}

  await loadStockDefinitions()
  if (utils.isDefined(props.article) && stockDefinitions.length > 0) {
    props.article._Sizes.forEach((size) => {
      if (size.Status > 0) {
        const data = { size: size.SizeName, totalStock: 0 }
        stockDefinitions.forEach((stockDefinition) => {
          data[stockDefinition.Id] = 0
        })
        stocks.value[size.Id] = data
      }
    })
  }
  loadStocks()
}

watch(() => props.article, reset)

onMounted(() => reset())
</script>
