<template>
  <div class="relative flex items-center">
    <template v-if="currentOrder.CalculatedTotalQuantity !== currentOrder.CalculatedActiveTotalQuantity">
      <div class="flex flex-col">
        <div class="flex my-[2px]">
          <div :style="{ width: `${grandTotalWidth}px` }">
            <span class="sticky left-0 pl-3 overflow-hidden whitespace-nowrap text-ellipsis">{{ t('orderlines.field.grandTotal') }}</span>
          </div>
          <div v-tooltip="thousandsFormattedCalculatedTotalQuantity" class="pl-2 overflow-hidden whitespace-nowrap text-ellipsis" :style="{ width: `${totalQuantityField?.width ?? 150}px` }">
            {{ thousandsFormattedCalculatedTotalQuantity }}
          </div>
          <div v-tooltip="thousandsFormattedAccumulatedCurrentPrice.total" class="pl-2 overflow-hidden whitespace-nowrap text-ellipsis" :style="{ width: `${totalPriceField?.width ?? 150}px` }">
            {{ thousandsFormattedAccumulatedCurrentPrice.total }}
          </div>
        </div>
        <div class="flex my-[2px]">
          <div :style="{ width: `${grandTotalWidth}px` }">
            <span class="sticky left-0 pl-3 overflow-hidden whitespace-nowrap text-ellipsis">{{ t('orderlines.field.grandTotalActive') }}</span>
          </div>
          <div v-tooltip="thousandsFormattedCalculatedActiveTotalQuantity" class="pl-2 overflow-hidden whitespace-nowrap text-ellipsis" :style="{ width: `${totalQuantityField?.width ?? 150}px` }">
            {{ thousandsFormattedCalculatedActiveTotalQuantity }}
          </div>
          <div v-tooltip="thousandsFormattedAccumulatedCurrentPrice.active" class="pl-2 overflow-hidden whitespace-nowrap text-ellipsis" :style="{ width: `${totalPriceField?.width ?? 150}px` }">
            {{ thousandsFormattedAccumulatedCurrentPrice.active }}
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div :style="{ width: `${grandTotalWidth}px` }">
        <span class="sticky left-0 pl-3 overflow-hidden whitespace-nowrap text-ellipsis">{{ t('orderlines.field.grandTotal') }}</span>
      </div>
      <div v-tooltip="thousandsFormattedCalculatedTotalQuantity" class="pl-2 overflow-hidden whitespace-nowrap text-ellipsis" :style="{ width: `${totalQuantityField?.width ?? 150}px` }">
        {{ thousandsFormattedCalculatedTotalQuantity }}
      </div>
      <div v-tooltip="thousandsFormattedAccumulatedCurrentPrice.total" class="pl-2 overflow-hidden whitespace-nowrap text-ellipsis" :style="{ width: `${totalPriceField?.width ?? 150}px` }">
        {{ thousandsFormattedAccumulatedCurrentPrice.total }}
      </div>
    </template>
  </div>
</template>

<script lang='ts' setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import type { IOrderlineVisibleColumn, IStaticColumnWidth } from '../../Orders.types'
import type Order from '@/models/order'
import utils from '@/services/utils'

const props = defineProps<{
  containsValidVas: boolean
  currentOrder: Order
  staticColumnsWidth: IStaticColumnWidth
  visibleColumns: Array<IOrderlineVisibleColumn>
}>()

const { t } = useI18n()

const totalPriceField = props.visibleColumns.find(column => column.property === 'TotalPrice')
const totalQuantityField = props.visibleColumns.find(column => column.property === 'TotalQuantity')

const accumulatedCurrentPrice = computed(() => {
  const accumulatedCurrentPrice = {
    total: 0,
    active: 0,
  }
  if (props.currentOrder.IndexedOrderlines != null) {
    Object.values(props.currentOrder.IndexedOrderlines).forEach((orderline) => {
      accumulatedCurrentPrice.total += orderline.TotalQuantity * orderline.CurrentPrice
      accumulatedCurrentPrice.active += orderline.ActiveTotalQuantity * orderline.CurrentPrice
    })
  }
  return accumulatedCurrentPrice
})

const thousandsFormattedAccumulatedCurrentPrice = computed(() => {
  return {
    total: utils.thousandsSeparator(accumulatedCurrentPrice.value.total),
    active: utils.thousandsSeparator(accumulatedCurrentPrice.value.active),
  }
})

const thousandsFormattedCalculatedTotalQuantity = computed(() => utils.thousandsSeparator(props.currentOrder.CalculatedTotalQuantity))
const thousandsFormattedCalculatedActiveTotalQuantity = computed(() => utils.thousandsSeparator(props.currentOrder.CalculatedActiveTotalQuantity))

const grandTotalWidth = computed(() => {
  let grandTotalWidth = props.visibleColumns.reduce((acu, cur) => {
    if (cur.property !== 'Quantities' && cur.property !== 'TotalQuantity' && cur.property !== 'TotalPrice') {
      acu += cur.width
    }
    return acu
  }, 0)
  grandTotalWidth += props.staticColumnsWidth.imageColumnWidth + props.staticColumnsWidth.infoIconColumnWidth
  + props.staticColumnsWidth.selectAllColumnWidth + props.staticColumnsWidth.warnIconColumnWidth
  + (props.containsValidVas ? props.staticColumnsWidth.vasColumnWidth : 0)

  return grandTotalWidth
})
</script>
