import { isEqual, isObject } from 'lodash-es'
import type { ValidationRuleWithParams } from '@vuelidate/core'
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import type { Ref } from 'vue'
import { computed } from 'vue'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import type MyArticle from '@/models/myArticle'
import { useUserStore } from '@/store/userData'
import type Article from '@/models/article'

export default function useAttributeValidator(articles: Ref<MyArticle[] | Article[]>, attributes: IMyAttribute[], form: Record<string, any>, attributeOptions: Record<string, { checked: boolean }> = {}) {
  const { t } = useI18n()
  const userStore = useUserStore()

  function updateCriteriaAttributeAllowedValues() {
    attributes.forEach((attribute) => {
      const criteriaKeys = Object.keys(attribute.Criteria)

      if (criteriaKeys.length > 0) { // is vetting list attribute with criteria applied
        let validVettingListValues: string[] = []
        let useVettingListAttributePreDefinedAllowedValues = true

        criteriaKeys.forEach((vettingListValue) => {
          let isValidVettingListValue = true
          const vettingListValueCriteria = attribute.Criteria[vettingListValue] // criteria for each possible vetting list attribute value
          const dependantAttributesSystemName = utils.isDefined(vettingListValueCriteria) && isObject(vettingListValueCriteria) ? Object.keys(vettingListValueCriteria) : [] // dependant attributes are the attributes where the current vetting list attribute's value will be depend on

          dependantAttributesSystemName.forEach((dependantAttributeSystemName) => {
            if (utils.isDefined(form[dependantAttributeSystemName])) {
              const dependantAttributeValue = form[dependantAttributeSystemName]
              if (utils.isDefined(dependantAttributeValue) && utils.isValidStringValue(dependantAttributeValue)) {
                useVettingListAttributePreDefinedAllowedValues = false
                const isDate = utils.validateDate(dependantAttributeValue)
                const dependantAttributePreDefinedLowerCaseValues = vettingListValueCriteria[dependantAttributeSystemName].map((value) => {
                  return isDate === true ? new Date(value).getTime().toString().toLowerCase() : value.toString().toLowerCase()
                })
                if (isDate === true) {
                  if (!dependantAttributePreDefinedLowerCaseValues.includes(new Date(dependantAttributeValue.toString()).getTime().toString().toLowerCase())) {
                    isValidVettingListValue = false
                  }
                }
                else {
                  if (!dependantAttributePreDefinedLowerCaseValues.includes(dependantAttributeValue.toString().toLowerCase())) {
                    isValidVettingListValue = false
                  }
                }
              }
            }
            else {
              isValidVettingListValue = false
            }
          })

          if (isValidVettingListValue) {
            validVettingListValues.push(vettingListValue)
          }
        })

        if (useVettingListAttributePreDefinedAllowedValues) {
          validVettingListValues = attribute.VettingList || []
        }

        if (!isEqual(validVettingListValues, attribute.CriteriaVettingList)) {
          attribute.CriteriaVettingList = validVettingListValues
          if (validVettingListValues.findIndex(v => utils.isDefined(form[attribute.SystemName]) && v.toLowerCase() === form[attribute.SystemName].toString().toLowerCase()) < 0) {
            form[attribute.SystemName] = null
          }
        }
      }
    })
  }

  function updateLookupAttributeValues() {
    if (userStore.indexedLookupAttributeDefinition) {
      attributes.forEach((attribute) => {
        if (attribute.AttributeType === AttributeType.LookupTable) {
          if (userStore.indexedLookupAttributeDefinition[attribute.SystemName]) {
            const sourceAttributes = userStore.indexedLookupAttributeDefinition[attribute.SystemName].SourceAttributes
            const values = userStore.indexedLookupAttributeDefinition[attribute.SystemName].Values
            let anyMatched = false
            for (let i = 0; i < values.length; i++) {
              const value = values[i]
              let matched = 0
              sourceAttributes.forEach((attribute) => {
                if (value.Source[attribute] === form[attribute]) {
                  matched++
                }
              })
              if (matched === sourceAttributes.length) {
                anyMatched = true
                if (form[attribute.SystemName] !== value.Destination[attribute.SystemName]) {
                  form[attribute.SystemName] = value.Destination[attribute.SystemName]
                  attributeOptions[attribute.SystemName].checked = true
                }
                break
              }
            }
            if (!anyMatched && utils.isValidStringValue(form[attribute.SystemName])) {
              attributeOptions[attribute.SystemName].checked = true
              form[attribute.SystemName] = null
            }
          }
        }
      })
    }
  }

  const commonArticleModel = computed(() => {
    const result: Record<string, any> = {}
    articles.value.forEach((article) => {
      Object.keys(article).forEach((property) => {
        if (!result.hasOwnProperty(property)) {
          result[property] = article[property]
        }
        // eslint-disable-next-line eqeqeq
        if (result[property] != article[property]) {
          result[property] = null
        }
      })
    })
    return result
  })

  const rules = computed(() => {
    const result: Record<string, any> = {}
    attributes.forEach((attribute) => {
      result[attribute.SystemName] = {}
      if (attribute.AttributeType !== AttributeType.Calc && (!utils.isDefined(attributeOptions[attribute.SystemName]) || attributeOptions[attribute.SystemName].checked)) {
        if (attribute.IsRequired) {
          result[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
        }
        else if (utils.isValidStringValue(attribute.ValidationExpression)) {
          let message = utils.isValidStringValue(attribute.ValidationMessage) ? attribute.ValidationMessage : t('validations.invalidValue')
          if (utils.isDefined(attribute.parsedValidationExpression) && attribute.parsedValidationExpression.length) {
            const validationRules: ValidationRuleWithParams[] = []
            attribute.parsedValidationExpression.forEach((itm) => {
              if (utils.isValidStringValue(itm.Message)) {
                message = itm.Message
              }
              const validateExpression = () => {
                let isValid = false
                if (form.hasOwnProperty(attribute.SystemName) && (form.hasOwnProperty(itm.SourceField) || commonArticleModel.value.hasOwnProperty(itm.SourceField))) {
                  let currentValue = form[attribute.SystemName]
                  let sourceFieldValue = form.hasOwnProperty(itm.SourceField) ? form[itm.SourceField] : commonArticleModel.value[itm.SourceField]
                  if (utils.isValidStringValue(currentValue) && utils.isValidStringValue(sourceFieldValue)) {
                    switch (attribute.AttributeType) {
                      case AttributeType.Date:
                      case AttributeType.DateOption:
                      case AttributeType.DateTime:
                        currentValue = new Date(currentValue).getTime()
                        sourceFieldValue = new Date(sourceFieldValue).getTime()
                        break
                      default:
                        currentValue = currentValue.toString().trim().toLowerCase()
                        sourceFieldValue = sourceFieldValue.toString().trim().toLowerCase()
                    }
                  }
                  isValid = utils.validateExpression(currentValue, sourceFieldValue, itm)
                }
                return isValid
              }
              validationRules.push(helpers.withMessage(message, validateExpression))
            })
            result[attribute.SystemName].validateExpression = validationRules
          }
          else {
            try {
              const pattern = helpers.regex(new RegExp(attribute.ValidationExpression))
              result[attribute.SystemName].pattern = helpers.withMessage(message, pattern)
            }
            catch (e) {
              console.warn(`${attribute.SystemName} validation expression is invalid`)
            }
          }
        }

        if (attribute.maxLength != null) {
          result[attribute.SystemName].maxLength = helpers.withMessage(t('validations.maxLength', { property: attribute.DisplayName, max: attribute.maxLength }), maxLength(attribute.maxLength))
        }
      }
    })
    return result
  })

  const v$ = useVuelidate(rules, form)

  return {
    updateCriteriaAttributeAllowedValues,
    updateLookupAttributeValues,
    v$,
  }
}
