<template>
  <transition name="fadepop" @after-leave="onAfterLeave">
    <div v-show="modelValue" class="fixed inset-0 flex z-dialog-overlay overflow-hidden max-h-[100vw]">
      <div v-if="showOverlay" class="fixed inset-0 bg-black opacity-50" />
      <div
        class="flex flex-col z-10 m-auto bg-white rounded-2xl opacity-100 dialog overflow-hidden shadow-dialog max-h-[90vw] relative"
        :style="{ width, height }"
      >
        <!-- LOADING MASK -->
        <template v-if="loading">
          <loader style="z-index: 1801;" />
          <div class="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-white opacity-50 z-dialog-overlay" />
        </template>

        <div class="flex w-full px-4 py-4">
          <div class="pt-2 text-xl font-medium leading-6 text-center grow text-dark-75" v-text="title" />
          <tx-button type="icon" faicon="fa-light fa-xmark" @click="doClose" />
        </div>
        <div class="flex-1 w-full h-full overflow-hidden" :class="bodyClasses ? bodyClasses : 'px-8'">
          <slot />
        </div>
        <div class="w-full mt-4">
          <slot name="footer">
            <div
              v-if="showOkCancel || showOkCancelDiscard || showOk"
              class="flex items-center justify-center px-6 py-4 space-x-3 sm:justify-end bg-gray-50"
            >
              <tx-button
                v-if="showOkCancel || showOkCancelDiscard" type="cancel" :disabled="cancelState === 'disabled'" :loading="cancelState === 'loading'"
                :text="t('general.cancel')" width="113px" height="40px" @click="doClose"
              />
              <tx-button
                v-if="showBack" type="cancel" :disabled="backState === 'disabled'" :loading="backState === 'loading'"
                :text="t(backText)" width="113px" height="40px" @click="doBack"
              />
              <tx-button
                v-if="showOkCancelDiscard" type="danger" :disabled="discardState === 'disabled'" :loading="discardState === 'loading'" :text="t(discardText)"
                width="113px" height="40px" @click="discard"
              />
              <tx-button
                v-if="showOk || showOkCancel || showOkCancelDiscard" type="confirm" :disabled="okState === 'disabled'" :loading="okState === 'loading'" :text="t(confirmText)"
                width="113px" height="40px" @click="doOk"
              />
            </div>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { onKeyStroke } from '@vueuse/core'
import TxButton from './TxButton.vue'
import Loader from '@/shared/components/Loader.vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<IProps>(), {
  showOverlay: true,
  title: '',
  width: '400px',
  okState: 'enabled',
  discardState: 'enabled',
  cancelState: 'enabled',
  confirmText: 'general.confirm',
  discardText: 'general.discard',
  loading: false,
  showOkCancelDiscard: false,
  backText: 'general.back',
  showBack: false,
  backState: 'enabled',
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: boolean): void
  (e: 'closed'): void
  (e: 'opened'): void
  (e: 'ok'): void
  (e: 'discard'): void
  (e: 'back'): void
  (e: 'cancel'): void
  (e: 'afterLeave'): void
}>()

const { t } = useI18n()

interface IProps {
  modelValue: boolean
  showOverlay?: boolean
  title?: string
  width?: string
  height?: string
  showOk?: boolean
  showOkCancel?: boolean
  showOkCancelDiscard?: boolean
  okState?: 'enabled' | 'loading' | 'disabled'
  discardState?: 'enabled' | 'loading' | 'disabled'
  cancelState?: 'enabled' | 'loading' | 'disabled'
  loading?: boolean // when loading is true user will not be able to do any action even they can not close the dialog
  confirmText?: string
  discardText?: string
  backText?: string
  showBack?: boolean
  backState?: 'enabled' | 'loading' | 'disabled'
  bodyClasses?: string
}
onKeyStroke('Escape', () => {
  if (props.modelValue && !props.loading) {
    doClose()
  }
})

function onAfterLeave() {
  emit('afterLeave')
}

function doClose() {
  if (!props.loading) {
    emit('update:modelValue', false)
    emit('cancel')
  }
}
function doOk() {
  emit('ok')
}
function discard() {
  emit('discard')
}
function doBack() {
  emit('back')
}
watch(() => props.modelValue, () => {
  if (props.modelValue) {
    emit('opened')
  }
  else {
    emit('closed')
  }
})
</script>

<style lang="scss" scoped>
.dialog {
  transition: all 0.5s ease;
}

.fadepop-enter-active,
.fadepop-leave-active {
  transition: all 0.5s ease;
}

.fadepop-enter-from,
.fadepop-leave-to {
  opacity: 0;

  .dialog {
    transform: scale(0.8);
  }
}
</style>
