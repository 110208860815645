<template>
  <div class="overflow-hidden">
    <loader v-show="isLoading" class="h-full !relative" />
    <div v-show="!isLoading" class="h-full p-2 overflow-auto">
      <template v-for="article in articles" :key="article.CatalogArticleId">
        <article-thumbnail
          v-model:selected="selectedItems[article.CatalogArticleId]"
          class="float-left p-2 mb-2 mr-2 thumbnail"
          :article="article"
          :selection-mode="selectedArticleIds.length > 0"
          @selection-changed="selectionChanged"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRefs, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import type MyArticle from '@/models/myArticle'
import Loader from '@/shared/components/Loader.vue'
import ArticleThumbnail from '@/shared/components/ArticleThumbnail.vue'

const props = defineProps<{
  createType: StyleCreateType
  articles: MyArticle[]
  contextArticle?: MyArticle
  visible: boolean
  isLoading: boolean
}>()

const emit = defineEmits<{
  (e: 'selectionChanged', selectedArticles: MyArticle[]): void
}>()

const { t } = useI18n()

// DATA
const selectedItems = ref({} as { [param: string]: boolean })
const indexedArticles = {} as { [articleId: string]: number }
// DATA - END

// COMPUTED
const selectedArticleIds = computed(() => {
  return Object.entries(selectedItems.value).reduce((acc, curr) => {
    if (curr[1]) {
      acc.push(Number(curr[0]))
    }
    return acc
  }, [] as number[])
})

const validations = computed(() => {
  const result: Record<string, any> = {}

  props.articles.forEach((article) => {
    result[article.CatalogArticleId] = {
      alLeastOne: helpers.withMessage(t('validations.atleast'), alLeastOneFormRule),
    }
  })
  return result
})
// COMPUTED-END

// WATCHERS
watch(() => toRefs(props.articles), (articles) => {
  articles.forEach((article, index) => {
    indexedArticles[article.value.CatalogArticleId] = index
    selectedItems[article.value.CatalogArticleId] = false
  })
})
// WATCHERS-END

const v$ = useVuelidate(validations, selectedItems)

// FUNCTIONS
function selectionChanged() {
  emit('selectionChanged', selectedArticleIds.value.map(selectedArticleId => props.articles[indexedArticles[selectedArticleId]]))
}

function alLeastOneFormRule() {
  let valid = false
  for (const articleId in selectedItems.value) {
    if (selectedItems.value[articleId]) {
      valid = true
      break
    }
  }
  return valid
}
// FUNCTIONS-END

defineExpose({
  v$,
})
</script>
