<template>
  <label class="flex items-center" :class="props.class" :style="style">
    <input
      ref="refCheckbox" type="checkbox" v-bind="$attrs" class="cursor-pointer" :class="{ 'bg-gray-200 rounded': disabled }"
      :checked="checked" :disabled="disabled" @click.stop="" @change="onChange"
    >
    <span v-if="label && label.length > 0" class="pl-1" :class="labelClass || 'text-base'" v-text="label" />
    <slot />
  </label>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue'
import { onMounted, ref, watch } from 'vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<IProps>(), { modelValue: false, indeterminate: false, disabled: false })

const emit = defineEmits<{
  (e: 'update:modelValue', val: TxBooleanish): void
  (e: 'change', val: TxBooleanish): void
}>()

interface IProps {
  modelValue?: TxBooleanish
  label?: string
  indeterminate?: boolean
  disabled?: boolean
  class?: string
  style?: CSSProperties
  labelClass?: string
}
const refCheckbox = ref<HTMLInputElement>()
const checked = ref(false)

onMounted(() => {
  if (props.indeterminate && refCheckbox.value) {
    refCheckbox.value.indeterminate = props.indeterminate
  }
  checked.value = !!props.modelValue
})

watch(() => props.indeterminate, () => {
  if (refCheckbox.value) {
    refCheckbox.value.indeterminate = props.indeterminate
  }
})

watch(() => props.modelValue, () => {
  checked.value = !!props.modelValue
  refCheckbox.value!.checked = !!props.modelValue
})

function onChange(event: Event) {
  const checkbox = event?.target as HTMLInputElement
  const val: TxBooleanish = typeof props.modelValue === 'boolean' ? checkbox.checked : (checkbox.checked ? 1 : 0)
  checked.value = !!val
  emit('update:modelValue', val)
  emit('change', val)
}
</script>
