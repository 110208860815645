export default class ArticleSize {
  CatalogCode: number
  Id: number // Todo: Find out if this ID is the ArticleId, if so, remove ArticleNumber
  Sizes: Size[]

  constructor(catalogCode: number, articleSize: any) {
    this.CatalogCode = catalogCode
    this.Id = articleSize.Id
    this.Sizes = articleSize.Sizes.map((itm: any) => new Size(itm))
  }
}

export class Size {
  Id: number
  SizeIndex: string
  SizeName: string
  Upc: string | null
  Status: number
  SortOrder: number

  constructor(size: any) {
    this.Id = size.Id
    this.SizeIndex = size.SizeIndex
    this.SizeName = size.SizeName
    this.Upc = size.Upc
    this.Status = size.Status
    this.SortOrder = size.SortOrder
  }
}
