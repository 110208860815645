// import messages from '@intlify/vite-plugin-vue-i18n/messages'
import { createI18n } from 'vue-i18n'
import { merge } from 'lodash-es'
import messagesEn from '@/locales/en.json'
import messageEnCustom from '@/locales/enCustom.json'

const enCustom = {}
merge(enCustom, messagesEn, messageEnCustom)

// to use i18n in ts files used the solution from https://github.com/intlify/vue-i18n-next/issues/904
const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: messagesEn,
    enCustom,
  },
})

export default i18n
