<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <!-- HEADER -->
    <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="t('assortArticles.title')" :show-header="showHeader" />

    <!-- BODY -->
    <div class="px-10 mt-4 alerts">
      <tx-alert :show="articles.length > 5" :text="t('assortArticles.backgroundJobMessage')" />
      <tx-alert show type="info" :text="t('assortArticles.assortArticleConfirmation', { articles: props.isModel ? props.articles.map(a => a.ModelNumber).join(', ') : props.articles.map(a => a.ArticleNumber).join(', ') })" />
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    </div>
    <div v-if="totalAssortArticlesRequest > 0 && !hasError" class="px-10 mt-4 mb-2">
      <tx-progress v-model="updateSizesProgress" :title="updateSizesProgressTitle" />
    </div>
    <!-- FORM -->
    <div class="relative flex-auto mt-4 body">
      <!-- LOADER -->
      <div v-show="loadingDropReasons">
        <loader style="z-index: 1801;" />
        <div class="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-transparent opacity-50" />
      </div>
      <!-- FORM -->
    </div>

    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="t('assortArticles.title')" :primary-disabled="loading || totalAssortArticlesRequest > 0" :secondary-disabled="loading"
      @primary-click="onUpdate" @secondary-click="onCancel"
    />
  </div>
</template>

<script setup lang='ts'>
import { useI18n } from 'vue-i18n'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { clone, isArray } from 'lodash-es'
import useArticleLocalDataUpdater from '../composables/articleLocalDataUpdater'
import Loader from '@/shared/components/Loader.vue'
import type MyArticle from '@/models/myArticle'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import appConfig from '@/services/appConfig'
import useErrorMessage from '@/shared/composables/errorMessage'
import { assortArticlesBgJob, createArticle, updateArticlesStatus } from '@/api/t1/article'
import { useUserStore } from '@/store/userData'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'
import type Article from '@/models/article'
import TxProgress from '@/shared/components/TxProgress.vue'
import { useNotificationStore } from '@/store/notification'
import Job from '@/models/job'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'

interface IProps {
  type?: 'activate' | 'deactivate'
  showHeader?: boolean
  articles: MyArticle[]
  isModel?: boolean
}
const props = withDefaults(defineProps<IProps>(), { type: 'activate', showHeader: true, isModel: false })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[] | Article[]): void
  (e: 'back'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()
const { refreshLocalArticlesData } = useArticleLocalDataUpdater()
const notificationStore = useNotificationStore()

const loading = ref(false)
const loadingDropReasons = ref(false)
const formModel = reactive<Record<string, any>>({})
const articles = ref<MyArticle[] | Article[]>([])
const totalAssortArticlesRequest = ref(0)
const assortedArticleCount = ref(0)

const updateSizesProgress = computed(() => Math.round((assortedArticleCount.value / totalAssortArticlesRequest.value) * 100))
const updateSizesProgressTitle = computed(() => t('assortArticles.assortArticlesProgressTitle') + (totalAssortArticlesRequest.value ? ` (${assortedArticleCount.value} / ${totalAssortArticlesRequest.value})` : ''))

function onCancel() {
  emit('cancel')
}

async function onUpdate() {
  if (userStore.activeCatalog?.CatalogCode && articles.value.length > 0) {
    if (articles.value.length <= 5) {
      totalAssortArticlesRequest.value = articles.value.length
      assortedArticleCount.value = 0
      const updatedArticles: (MyArticle | Article)[] = []
      const failedArticles: string[] = []
      // call API one by one after the API call is completed to avoid deadlock at API server side
      let processedArticleCount = 0
      const processNextArticle = async () => {
        if (processedArticleCount < articles.value.length) {
          const article = articles.value[processedArticleCount]
          try {
            // If the field "IsAccessible" is true then user the Create article API.
            if (article.IsAccessible) {
              const response = await createArticle(userStore.activeCatalog!.CatalogCode, [{ ArticleName: article.ArticleName, ArticleNumber: article.ArticleNumber, ArticleId: article.Id, ModelId: article.ModelId, ModelName: article.ModelName, ModelNumber: article.ModelNumber }], 0)
              if (response && response.data && response.data.length) {
                updatedArticles.push(...Array.isArray(response.data[0]) ? response.data[0] : [response.data[0]])
              }
              else {
                updatedArticles.push(...Array.isArray(response.data) ? response.data : [response.data])
              }
            }
            else { // If the field "IsAccessible" is false and current catalog article status is unassorted then user the API to activate article.
              await updateArticlesStatus(userStore.activeCatalog!.CatalogCode, [{ Id: article.Id, Status: 1, DropReasonId: null }])
              article.Status = 1
              updatedArticles.push(article)
            }
            assortedArticleCount.value++
          }
          catch (error) {
            console.error(error)
            failedArticles.push(article.ArticleNumber)
          }
          finally {
            processedArticleCount++
            await processNextArticle()
          }
        }
        else {
          // once article assorted updated
          if (updatedArticles.length) {
            const updatePromises: Promise<any>[] = []
            updatedArticles.forEach((article) => {
              updatePromises.push(refreshLocalArticlesData(true, article.ModelId, undefined, false))
            })
            await Promise.all(updatePromises)
            emit('updated', updatedArticles)
          }
          if (failedArticles.length) {
            errorMessage.value = t('assortArticles.failedAssortArticles', { articles: failedArticles.join(', ') })
          }
        }
      }
      processNextArticle() // recursive call
    }
    else {
      errorMessage.value = ''
      // remove calc type attributes from request
      let calculatedAttributesSystemNamesArray: string[] = []
      if (userStore.activeCatalog && userStore.myAttributes) {
        calculatedAttributesSystemNamesArray = userStore.activeCatalog.AssignedCatalogAttributes
          .map(attribute => userStore.myAttributes![attribute.AttributeSystemName])
          .filter(myAttr => utils.isDefined(myAttr) && myAttr.AttributeType === AttributeType.Calc)
          .map(myAttr => myAttr.SystemName)
      }
      const requestArticles: Article[] = []
      articles.value.forEach((article) => {
        const articleClone = clone(article)
        // loop through calculatedAttributesSystemNamesArray and delete it from article
        for (const attr of calculatedAttributesSystemNamesArray) {
          delete articleClone[attr]
        }
        articleClone.Status = 1
        requestArticles.push(articleClone)
      })
      await assortArticlesBgJob(userStore.activeCatalog!.CatalogCode, requestArticles)
        .then(async (res) => {
          if (userStore.activeCatalog && res.data) {
            const job = new Job({
              Id: res.data.Id,
              CatalogCode: userStore.activeCatalog.CatalogCode,
              Type: 'assortArticles',
              Status: res.data.Status,
              UserId: userStore.userProfile.Id,
              CreatedDate: new Date(),
            })
            await appConfig.DB!.jobs.put(job)
            const message = t('assortArticles.jobSuccess')
            notificationStore.addNotification({ message, type: 'Success' })
            onCancel() // close
          }
        })
        .catch((e) => {
          console.error(e)
          errorMessage.value = t('general.unexpectedError')
        })
        .finally()
    }
  }
}

function reset() {
  loading.value = false
  loadingDropReasons.value = false
  errorMessage.value = ''
  formModel.DropReason = null
}

watch(() => props, reset)

onMounted(async () => {
  reset()
  if (props.isModel) {
    const queryCriterion: Array<[number, number, string]> = [[userStore.activeCatalog!.CatalogCode, 1, props.articles[0].ModelNumber]]
    for (let i = 1; i < props.articles.length; i++) {
      queryCriterion.push([+userStore.activeCatalog!.CatalogCode, 1, props.articles[i].ModelNumber])
    }
    const res = await appConfig.DB!.getArticlesByCatalogAndModelCriterion(queryCriterion)
    if (res && isArray(res) && res[0]) {
      articles.value = res
    }
  }
  else {
    articles.value = props.articles
  }
})
</script>
