import type CustomerLocation from '@/models/CustomerLocation'
import type Order from '@/models/order'
import type UserProfile from '@/models/userProfile'
import { ordersConstants } from '@/models/constants'

function checkIfOrderIsEditable(order: Order, userProfile: UserProfile, indexedLocations: Record<number, CustomerLocation>, checkProcessStatus = true) {
  const result = {
    isOrderEditable: true,
    isOrderInactive: false,
    noneEditableProcessStatus: false,
    haveOrderOwnership: true,
    doesCustomerLocationExist: true,
  }
  // check for status
  if (!order.Status) {
    result.isOrderEditable = false
    result.isOrderInactive = true
  }
  // check for process status
  if (checkProcessStatus) {
    if (order.OrderProcessStatusId != null && ordersConstants.noneEditableProcessStatuses.includes(order.OrderProcessStatusId)) {
      result.isOrderEditable = false
      result.noneEditableProcessStatus = true
    }
  }
  // check for ownership
  if (order.CreatedBy !== userProfile.Id) {
    result.isOrderEditable = false
    result.haveOrderOwnership = false
  }
  // check for location status
  if (!indexedLocations.hasOwnProperty(order.LocationId) || !indexedLocations[order.LocationId].Status) {
    result.isOrderEditable = false
    result.doesCustomerLocationExist = false
  }
  return result
}

export {
  checkIfOrderIsEditable,
}
