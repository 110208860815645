<template>
  <div class="w-full">
    <div v-if="label.length > 0" class="flex">
      <label v-if="label.length > 0" class="text-xs tracking-wide uppercase label" :class="{ required }" v-text="label" />
    </div>
    <div class="relative" :class="props.class">
      <div :class="{ 'border-red-300 bg-red-50 placeholder-red-200 text-red-900': hasError }">
        <div v-if="hasIcon" class="absolute top-1.5 left-0 w-6 h-6 text-dark-grey" v-html="icon" />
        <input
          ref="refInput" type="text" v-bind="$attrs" :value="displayModelValue"
          class="text-base border rounded bg-card border-form shadow-input h-9 pr-7"
          :class="{ 'pl-7': hasIcon, 'pl-2': !hasIcon, '!bg-gray-200 rounded': disabled }" :placeholder="placeholder"
          :disabled="disabled" :required="required" @input="doInput" @focus="doFocus"
        >
        <loader v-if="loading || (dataLoading && !disabled)" :style="{ width: '25px', left: 'unset', right: '15px' }" />
        <font-awesome-icon
          v-if="clearable && !disabled && modelValue"
          class="absolute top-2.5 right-2 w-4 h-4 cursor-pointer" icon="fa-light fa-xmark" @click="doClear"
        />
      </div>
      <div v-if="hasError">
        <p v-for="error in errors" :key="error.$uid" class="text-xs italic text-red-500">
          {{ error.$message }}
        </p>
      </div>
    </div>
    <!-- Suggestions -->
    <div
      v-show="isOpen" ref="refSuggestions"
      class="max-w-lg py-1 overflow-x-hidden text-base bg-white shadow-dropdown max-h-56 overflow-y-overlay z-dropdown focus:outline-none"
    >
      <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="leading-9">
        <li
          v-for="(d) in suggestions" id="listbox-item-0" :key="hasValueProp ? d[valueProp] : d" v-tooltip="{ text: showTooltip ? d[secondaryDisplayProp] : null }" tabindex="0"
          role="option"
          class="relative flex items-center pl-2 pr-3 text-gray-900 cursor-pointer select-none h-9 hover:bg-grey-light focus:outline-none focus:bg-grey-light"
          @click="doSelect(d)"
        >
          <slot name="item" :item="d" :class="{ 'text-primary-500 font-bold': isSelected(d) }">
            <div class="pl-2 truncate grow">
              {{ hasDisplayProp ? d[displayProp] : d }}
            </div>
            <div
              v-if="hasSecondaryDisplayProp" class="truncate w-2/6 text-gray-600 pl-0.5"
              :class="{ 'text-primary-500 font-bold': isSelected(d) }" v-text="d[secondaryDisplayProp]"
            />
          </slot>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import _debounce from 'lodash-es/debounce'
import type { Instance } from '@popperjs/core'
import { createPopper } from '@popperjs/core'
import { onClickOutside } from '@vueuse/core'
import type { ErrorObject } from '@vuelidate/core'
import { computed, nextTick, onMounted, ref } from 'vue'
import Loader from './Loader.vue'
import { sameWidthModifier } from '@/services/utils'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<IProps>(), { modelValue: null, label: '', errors: () => [] as ErrorObject[], placeholder: '', clearable: false, disabled: false, required: false, debounce: 300, valueProp: '', displayProp: '', secondaryDisplayProp: '', icon: '', class: '', dataLoading: false, showTooltip: false })

const emit = defineEmits<{
  (e: 'update:modelValue', val?: string | number): void
  (e: 'change', val?: string | number, rawVal?: any): void
  (e: 'input', val?: string | number): void
}>()

interface IProps {
  modelValue?: string | number | null
  label?: string
  errors?: ErrorObject[]
  placeholder?: string
  clearable?: boolean
  disabled?: boolean
  required?: boolean
  debounce?: number
  valueProp?: string
  displayProp?: string
  secondaryDisplayProp?: string
  fetchSuggestions: Function
  icon?: string
  class?: string
  dataLoading?: boolean
  showTooltip?: boolean
}
const refInput = ref()
const displayModelValue = ref<string | number | null | undefined>(null)
let suggestionDisabled = false
const loading = ref(false)
const isOpen = ref(false)
const refSuggestions = ref()
const suggestions = ref<any[]>([])
let popper: Instance

const hasIcon = computed(() => props.icon.length > 0)

const hasError = computed(() => props.errors.length)

const hasDisplayProp = computed(() => props.displayProp.length > 0)

const hasSecondaryDisplayProp = computed(() => props.secondaryDisplayProp && props.secondaryDisplayProp.length > 0)

const hasValueProp = computed(() => props.valueProp.length > 0)

const debouncedGetData = _debounce(getData, props.debounce)

function doClear() {
  refInput.value!.value = ''
  emit('update:modelValue', '')
  emit('change', '', '')
  emit('input', '')
  displayModelValue.value = ''
  suggestions.value = []
}

function doInput(e: Event) {
  const value = (e?.target as HTMLInputElement).value
  emit('update:modelValue', value)
  emit('input', value)
  displayModelValue.value = value
  suggestionDisabled = false
  // if (!value) {
  //   suggestionDisabled = true
  //   suggestions.value = []
  //   return
  // }
  debouncedGetData(value)
}

function doSelect(d) {
  isOpen.value = false
  const value = hasValueProp.value ? d[props.valueProp] : d
  displayModelValue.value = hasDisplayProp.value ? d[props.displayProp] : d
  emit('update:modelValue', value)
  emit('change', value, d)

  nextTick(() => {
    popper.update()
  })
}

function doFocus() {
  debouncedGetData(displayModelValue.value?.toString() || '')
}

function getData(queryString: string) {
  if (suggestionDisabled) {
    return
  }
  loading.value = true
  props.fetchSuggestions(queryString, (items: any[]) => {
    loading.value = false
    if (suggestionDisabled) {
      return
    }
    suggestions.value = items
    doOpenSuggestions()
  })
}

function doCloseSuggestions() {
  isOpen.value = false
}

function doOpenSuggestions() {
  if (!props.disabled) {
    isOpen.value = true
    popper.update()
  }
}

function isSelected(val) {
  const v = hasValueProp.value ? val[props.valueProp] : val
  return props.modelValue === v
}

onMounted(() => {
  displayModelValue.value = props.modelValue

  popper = createPopper(refInput.value, refSuggestions.value, {
    placement: 'bottom-start',
    modifiers: [
      sameWidthModifier,
      {
        name: 'offset',
        options: {
          offset: [0, 1],
        },
      },
    ],
    strategy: 'fixed',
  })

  onClickOutside(refSuggestions.value, () => {
    doCloseSuggestions()
  })
})

function setDisplayModelValue(value: string) {
  displayModelValue.value = value
}

function focus() {
  refInput.value?.focus()
}

defineExpose({
  focus,
  setDisplayModelValue,
})
</script>
