import { computed, ref } from 'vue'
import utils from '@/services/utils'

export default function useErrorMessage() {
  const errorMessage = ref('')

  const hasError = computed(() => utils.isValidStringValue(errorMessage.value))

  return {
    errorMessage,
    hasError,
  }
}
