export default class CatalogPriceGroup {
  AllowChangeRequestForPriceGroups: boolean
  CatalogCode: number
  CurrencyCode: string
  Description: string
  Id: number
  IsEditable: number
  Name: string
  SortOrder: number
  StateId: number
  StateRank: number
  Status: number

  constructor({ Id, CatalogCode, Name, Description, CurrencyCode, IsEditable, SortOrder, Status, StateId, StateRank, AllowChangeRequestForPriceGroups }: CatalogPriceGroup) {
    this.AllowChangeRequestForPriceGroups = !!AllowChangeRequestForPriceGroups
    this.CatalogCode = CatalogCode
    this.CurrencyCode = CurrencyCode
    this.Description = Description
    this.Id = Id
    this.IsEditable = IsEditable
    this.Name = Name
    this.SortOrder = SortOrder
    this.Status = Status
    this.StateId = StateId
    this.StateRank = StateRank
  }
}
