<template>
  <div class="w-full h-full overflow-auto mt-4">
    <div v-if="!items.length" class="text-lg">
      <p>{{ t('general.emptyList') }}</p>
    </div>
    <tx-collapse v-else v-model="activeItems" class="px-4">
      <tx-collapse-item
        v-for="(item) in items" :key="item.Id" :title-class="`tracking-[1px] font-semibold text-lg ${(item.Status === 0 ? 'opacity-50' : '')}`"
        :title="`${item.IsCreateArticleRequest ? t(item.RequestTypeTranslator) : getEditRequestTitle(item)}: ${item.IsCreateArticleRequest ? item.Content.ArticleNumber : item.SourceArticleId && props.articleIdArticleNumberMap && props.articleIdArticleNumberMap[item.SourceArticleId] ? props.articleIdArticleNumberMap[item.SourceArticleId] : item.SourceArticleNumber}`"
        :value="item.Id"
      >
        <div class="border rounded shadow">
          <div
            class="p-2"
            :class="{
              'cursor-pointer hover:border hover:border-primary hover:rounded': item.IsCreateArticleRequest, // show pointer only if clickable
              'border border-primary rounded': item.ObjectId === selectedObjectId,
              'opacity-50': item.Status === 0,
            }"
            @click="item.IsCreateArticleRequest ? onRequestArticleSelected(item) : ''"
          >
            <div class="flex font-semibold justify-between border-b pb-2">
              <div class="flex items-center">
                <div class="mr-4">
                  <div v-tooltip="{ text: utils.formatDateTime(item.CreatedDate), theme: { placement: 'right' } }">
                    <use-time-ago v-slot="{ timeAgo }" :time="new Date(item.CreatedDate)">
                      {{ timeAgo }}
                    </use-time-ago>
                  </div>
                </div>
              </div>
              <div class="flex flex-col items-center justify-center flex-grow">
                <tx-chip :text="requestConstants.requestStatesReverse[item.State]" :type="getChipType(item)" />
              </div>
              <div class="flex flex-col justify-end items-start">
                <div class="flex items-left">
                  <div class="whitespace-nowrap" style="max-width: 500px;">
                    <div class="overflow-hidden overflow-ellipsis">
                      {{ t('requests.createdBy') }}: {{ item.CreatedByUserName }}
                    </div>
                  </div>
                </div>
                <div v-if="item.State === requestConstants.requestStates.approve || item.State === requestConstants.requestStates.reject || item.State === requestConstants.requestStates.confirm" class="flex items-start">
                  <div class="whitespace-nowrap" style="max-width: 500px;">
                    <div class="overflow-hidden overflow-ellipsis">
                      {{ t('requests.updatedBy') }}: {{ item.UpdatedByUserName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="item.Content" class="pt-2 border-b">
              <div class="font-semibold text-lg uppercase label w-full whitespace-nowrap overflow-hidden inline-block">
                {{ t('articleDetails.modelOrArticleAttributes') }}
              </div>
              <div class="grid w-full grid-cols-3 gap-x-6">
                <template v-for="(value, key) in item.Content" :key="key">
                  <div v-if="userStore.myAttributes && userStore.myAttributes[key]" class="mb-5 last-of-type:mb-4">
                    <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                      {{ userStore.myAttributes[key].DisplayName }}
                    </div>
                    <div class="pl-1 font-semibold leading-3 w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                      {{ utils.getAttributeTypeSpecificValue(userStore.myAttributes[key], value, userStore.priceGroups, userStore.activeCatalog?.Config.ShowPriceThousandsSeparated) }}
                    </div>
                  </div>
                </template>
              </div>
            </div> -->
            <div v-if="item.RequestType !== requestConstants.requestTypes.EditPrices.key && !item.IsCreateArticleRequest" class="pt-4">
              <div class="mb-5">
                <div class="pl-1 font-semibold leading-3 w-full break-words whitespace-normal inline-block">
                  {{ getSubtitle(item) }}
                </div>
              </div>
            </div>
            <div v-else-if="!item.IsCreateArticleRequest" class="pt-4">
              <div class="grid w-full grid-cols-3 gap-x-6">
                <div v-for="price in getPriceRequest(item)" :key="price.Id" class="mb-5 last-of-type:mb-4">
                  <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                    {{ price.Name }}
                  </div>
                  <div class="pl-1 font-semibold leading-3 w-full break-words whitespace-normal inline-block">
                    {{ price.InitialPrice }} -> {{ price.RequestedPrice }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-2">
              <div class="font-semibold text-lg uppercase label w-full whitespace-nowrap overflow-hidden inline-block">
                {{ t('articleDetails.requestAttributes') }}
              </div>
              <div class="grid w-full grid-cols-3 gap-x-6">
                <div v-for="attribute in getAvailableRequestAttributes(item.RequestSource)" :key="attribute.AttributeSystemName" class="mb-5 last-of-type:mb-4">
                  <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                    {{ attribute.AttributeDisplayName }}
                  </div>
                  <div class="pl-1 font-semibold leading-3 w-full break-words whitespace-normal inline-block">
                    {{ item.RequestAttributes && item.RequestAttributes[attribute.AttributeSystemName] && item.RequestAttributes[attribute.AttributeSystemName].length ? item.RequestAttributes[attribute.AttributeSystemName][0] : '-' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-2">
              <div class="font-semibold text-lg uppercase label w-full whitespace-nowrap overflow-hidden inline-block">
                {{ t('requests.title.approvalRejectionReason') }}
              </div>
              <div class="grid w-full grid-cols-3 gap-x-6">
                <div class="mb-5">
                  <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                    {{ t('requestsTable.fields.reason') }}
                  </div>
                  <div class="pl-1 font-semibold leading-3 w-full break-words whitespace-normal inline-block">
                    {{ item.Reason }}
                  </div>
                </div>
                <div class="mb-5">
                  <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                    {{ t('requestsTable.fields.comment') }}
                  </div>
                  <div class="pl-1 font-semibold leading-3 w-full break-words whitespace-normal inline-block">
                    {{ item.Comment }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex space-x-2 justify-end border-t p-1">
            <template v-if="item.CreatedBy === userStore.userProfile.Id && (item.State === requestConstants.requestStates.draft || item.State === requestConstants.requestStates.new) && hasUpdateStatusRequestPrivilege">
              <!-- <tx-button
                v-if="item.Status === 0" faicon="fa-light fa-rotate-right"
                :text="t('activeOrDeactiveArticle.activate')" @click="onActivateOrDeActivateRequest(item, 1)"
              /> -->
              <tx-button
                v-if="item.Status === 1" faicon="fa-light fa-trash-can"
                type="danger" :is-request="true" :text="t('general.delete')" @click="onActivateOrDeActivateRequest(item, 0)"
              />
            </template>
            <tx-button
              v-if="item.Status === 1 && (item.State === requestConstants.requestStates.new || item.State === requestConstants.requestStates.approve) && hasRejectRequestPrivilege"
              type="cancel" faicon="fa-light fa-thumbs-down" :is-request="true" :disabled="disableButton" :text="t('articleDetails.actions.reject')" @click="openApproveRejectDialog(item, 2)"
            />
            <tx-button
              v-if="item.Status === 1 && (item.State === requestConstants.requestStates.new || item.State === requestConstants.requestStates.reject) && hasApproveRequestPrivilege"
              faicon="fa-light fa-thumbs-up" :is-request="true" :text="t('articleDetails.actions.approve')" @click="openApproveRejectDialog(item, 1)"
            />
            <tx-button
              v-if="item.Status === 1 && item.State === requestConstants.requestStates.approve && hasConfirmRequestPrivilege && isAllowedConfirmRequests[item.Id]"
              :is-request="true" :disabled="disableButton" :text="t('articleDetails.actions.confirm')" @click="onConfirmRequest(item)"
            />
          </div>
        </div>
      </tx-collapse-item>
    </tx-collapse>
    <approve-reject-request-dialog ref="showApproveRejectDialog" @approve-reject="onApproveReject" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { UseTimeAgo } from '@vueuse/components'
import { useUserStore } from '@/store/userData'
import { useConfirmDialog } from '@/shared/composables/confirmDialog'
import { useNotificationStore } from '@/store/notification'
import TxCollapse from '@/shared/components/txCollapse/txCollapse.vue'
import TxCollapseItem from '@/shared/components/txCollapse/txCollapseItem.vue'
import TxChip from '@/shared/components/TxChip.vue'
import TxButton from '@/shared/components/TxButton.vue'
import type RequestModel from '@/models/request'
import { appConstants, editRequestKeys, privileges, requestConstants } from '@/models/constants'
import utils from '@/services/utils'
import ApproveRejectRequestDialog from '@/shared/components/requests/ApproveRejectRequestDialog.vue'
import type CatalogRequestAttribute from '@/models/catalogRequestAttribute'
import { updateRequestStatuses } from '@/api/t1/request'
import appConfig from '@/services/appConfig'
import { AttributeType } from '@/models/catalogAttribute'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import type MyArticle from '@/models/myArticle'

const props = defineProps<{
  items: RequestModel[]
  selectedRequestId?: number
  selectedObjectId?: string
  articleIdArticleNumberMap?: Record<number, string>
  disableRequestActions?: boolean
}>()

const emit = defineEmits<{
  (e: 'itemSelected', requestId: number, objectId: string): void
  (e: 'confirm', item: RequestModel): void
  (e: 'refreshRequestDetails'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const confirmDialog = useConfirmDialog()
const notificationsStore = useNotificationStore()
const { skipRestrictingPropertyUpdateBasedOnArticleState, getArticlesLocked, getArticlesMaxStateDetails, getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank, getIndexedRestrictedAttributesBasedOnArticlesStateRank } = useArticleFormHelper()

const showApproveRejectDialog = ref<InstanceType<typeof ApproveRejectRequestDialog>>()
const isAllowedConfirmRequests = ref<{ [key: string]: boolean }>({})

const activeItems = ref<Array<number>>([])
if (props.selectedRequestId) {
  activeItems.value.push(props.selectedRequestId)
}

const hasUpdateStatusRequestPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.updateStatus))
const hasRejectRequestPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.rejectRequest))
const hasApproveRequestPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.approveRequest))
const hasConfirmRequestPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.confirmRequest))
const disableButton = computed(() => !!props.disableRequestActions)

watch(() => props.selectedRequestId, (requestId) => {
  activeItems.value = []
  if (requestId) {
    activeItems.value.push(requestId)
  }
})

watch(() => props.items, async (newItems) => {
  for (const item of newItems) {
    isAllowedConfirmRequests.value[item.Id] = await isAllowedConfirm(item)
  }
}, { immediate: true })

function getAvailableRequestAttributes(requestSource: number) {
  let requestAttributes: CatalogRequestAttribute[] = []
  if (userStore.activeCatalog) {
    requestAttributes = userStore.activeCatalog.RequestAttributeList.filter(a => a.Status > 0)
    if (requestSource === requestConstants.requestSources.new || requestSource === requestConstants.requestSources.carryover) {
      if (userStore.activeCatalog.Config.RequestForm.carryoverModel?.requestAttributesForm
        && userStore.activeCatalog.Config.RequestForm.carryoverModel.requestAttributesForm.length > 0) {
        const configRequestAttributes = new Set(userStore.activeCatalog.Config.RequestForm.carryoverModel.requestAttributesForm.map(x => x.attribute))
        for (let i = requestAttributes.length - 1; i >= 0; i--) {
          if (!configRequestAttributes.has(requestAttributes[i].AttributeSystemName)) {
            requestAttributes.splice(i, 1)
          }
        }
      }
    }
    else if (requestSource === requestConstants.requestSources.similarStyle) {
      if (userStore.activeCatalog.Config.RequestForm.copyModel?.requestAttributesForm
        && userStore.activeCatalog.Config.RequestForm.copyModel.requestAttributesForm.length > 0) {
        const configRequestAttributes = new Set(userStore.activeCatalog.Config.RequestForm.copyModel.requestAttributesForm.map(x => x.attribute))
        for (let i = requestAttributes.length - 1; i >= 0; i--) {
          if (!configRequestAttributes.has(requestAttributes[i].AttributeSystemName)) {
            requestAttributes.splice(i, 1)
          }
        }
      }
    }
  }
  return requestAttributes
}

function getPriceRequest(request) {
  const contentPrices = request.Content.Prices
  const initialPrices = request.InitialContent.Prices

  if (!Array.isArray(contentPrices)) {
    return []
  }

  const requestedPrices = contentPrices.map((contentPrice) => {
    const initialPrice = initialPrices.find(initPrice => initPrice.Id === contentPrice.Id)
    return {
      Id: contentPrice.Id,
      Name: contentPrice.Name,
      InitialPrice: initialPrice?.Price || 0,
      RequestedPrice: contentPrice.Price || 0,
    }
  })

  return requestedPrices
}

// check if user is allowed confirm the request,
// should have specific privilege, attribute should be editable,
// should not be restricted at state and role level(role level attributes comes as non editable)
async function isAllowedConfirm(request: RequestModel) {
  if (request.IsCreateArticleRequest) {
    return true
  }
  let isAllowedConfirmRequest = false
  const { userProfile, activeCatalog } = userStore
  const priceGroups = userStore.activeCatalog!.CatalogPriceGroupList
  const { AccountDetails } = userProfile
  const isAccountValid = AccountDetails.AccountTypeId === 1 || AccountDetails.AccountId === activeCatalog?.AccountId
  const articleObject: MyArticle | undefined = await appConfig.DB!.getArticle(userStore.activeCatalog!, request.SourceArticleId!)
  if (articleObject) {
    const key = Object.keys(request.Content)[0]
    const maxArticleStateRankDetails = getArticlesMaxStateDetails([articleObject], (key === editRequestKeys.prices || key === editRequestKeys.sizeScale || key === editRequestKeys.modelName))
    if (request.RequestType === requestConstants.requestTypes.EditAttribute.key) {
      const attribute = userStore.myAttributes![key]
      const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.update)
      if (hasPrivilege && isAccountValid && attribute.Editable) {
        const indexedRestrictedAttributesBasedOnArticlesMaxSateRank = getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank([articleObject])
        const indexedRestrictedAttributesBasedOnArticlesStateRank = getIndexedRestrictedAttributesBasedOnArticlesStateRank([articleObject])
        if (!attribute.ReadOnly && ((attribute.IsModelLevel && !indexedRestrictedAttributesBasedOnArticlesMaxSateRank.hasOwnProperty(attribute.SystemName)) || (!attribute.IsModelLevel && !indexedRestrictedAttributesBasedOnArticlesStateRank.hasOwnProperty(attribute.SystemName)))) {
          isAllowedConfirmRequest = true
        }
      }
    }
    else {
      // static properties
      if (key === editRequestKeys.stateName) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.AssignArticleStateToArticles)
        if (hasPrivilege && isAccountValid) {
          isAllowedConfirmRequest = true
        }
      }
      else if (key === editRequestKeys.modelName) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.update)
        if (hasPrivilege && isAccountValid) {
          isAllowedConfirmRequest = true
          if (utils.isDefined(maxArticleStateRankDetails)) {
            // if user has special permission assigned to his role then skip restriction
            if (!skipRestrictingPropertyUpdateBasedOnArticleState) {
              isAllowedConfirmRequest = maxArticleStateRankDetails.IsModelNameEditable === 1
            }
          }
        }
      }
      else if (key === editRequestKeys.prices) {
        const hasPrivilege = userProfile.isValidPrivilege(privileges.article.UpdateCatalogModelPrices)
          || userProfile.isValidPrivilege(privileges.article.UpdateCatalogArticleBulkPrices)
        if (hasPrivilege && isAccountValid) {
          const isAnyArticleLocked = await getArticlesLocked([articleObject], !!activeCatalog?.IsPriceByStyleApply)

          if (!isAnyArticleLocked) {
            let allEditableAndNotRestricted = true

            for (const price of request.Content.Prices) {
              const priceGroup = priceGroups.filter(pg => pg.Id === price.Id)[0]
              if (priceGroup && maxArticleStateRankDetails) {
                const priceGroupStateRank = priceGroup.StateRank || 0
                const lockBasedOnArticlesState = !skipRestrictingPropertyUpdateBasedOnArticleState
                  && priceGroup.StateRank != null && maxArticleStateRankDetails.StateRank >= priceGroupStateRank
                if (!priceGroup.IsEditable || lockBasedOnArticlesState) {
                  allEditableAndNotRestricted = false
                  break
                }
              }
              else {
                allEditableAndNotRestricted = false
                break
              }
            }

            if (allEditableAndNotRestricted) {
              isAllowedConfirmRequest = true
            }
          }
        }
      }
      else if (key === editRequestKeys.status) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.updateStatus)
        if (hasPrivilege && isAccountValid) {
          isAllowedConfirmRequest = true
        }
      }
      else if (key === editRequestKeys.colorId) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.update)
        if (hasPrivilege && isAccountValid && userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited
          && userStore.sellerDetails?.AutomaticNumbering === 1 && (articleObject.ColorId === null || articleObject.ColorId === 0)) {
          isAllowedConfirmRequest = true
        }
      }
      else if (key === editRequestKeys.sizeScale) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.updateSizeScale)
        const lockedBasedOnArticleState = (maxArticleStateRankDetails == null || !maxArticleStateRankDetails.IsSizeScaleEditable) && !skipRestrictingPropertyUpdateBasedOnArticleState
        if (hasPrivilege && isAccountValid && userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited && userStore.sellerDetails?.IsSizeScaleByStyle && !lockedBasedOnArticleState) {
          isAllowedConfirmRequest = true
        }
      }
    }
  }
  return isAllowedConfirmRequest
}

watch(() => props.selectedRequestId, (requestId) => {
  if (requestId) {
    activeItems.value = []
    activeItems.value.push(requestId)
  }
})

function getChipType(item: RequestModel) {
  return item.State === requestConstants.requestStates.approve || item.State === requestConstants.requestStates.confirm
    ? 'success'
    : item.State === requestConstants.requestStates.reject
      ? 'error'
      : 'primary'
}

async function onActivateOrDeActivateRequest(request: RequestModel, status: ZeroOrOneType) {
  if (userStore.activeCatalog) {
    const title = t('general.alert')
    const activateOrDeactivateAction = status === 0 ? 'delete' : 'activate'
    const message = t('activeOrDeactiveArticle.activateOrDeactivateAlert', { action: activateOrDeactivateAction, number: request.Content.ArticleNumber })

    await confirmDialog(title, message, [], async () => {
      await updateRequestStatuses(userStore.activeCatalog!.CatalogCode, [{ Id: request.Id, Status: status }])
      await appConfig.DB!.requests.update([userStore.activeCatalog!.CatalogCode, request.Id], { Status: status })
      await userStore.doLoadData(['Requests'])
      notificationsStore.addNotification({ message: t(status === 0 ? 'activeOrDeactiveArticle.deactivateRequestSuccessfully' : 'activeOrDeactiveArticle.activateRequestSuccessfully', 1), type: 'Success' })
      emit('refreshRequestDetails')
    })
  }
}

function openApproveRejectDialog(request: RequestModel, actionType: number) {
  showApproveRejectDialog.value?.showDialog([request], actionType)
}

async function onConfirmRequest(request: RequestModel) {
  await userStore.doLoadData(['Requests'])
  emit('confirm', request)
}

function onRequestArticleSelected(request: RequestModel) {
  emit('itemSelected', request.Id, request.ObjectId)
}

function onApproveReject() {
  emit('refreshRequestDetails')
}

function getEditRequestTitle(item) {
  const key = Object.keys(item.Content)[0]
  if (item.RequestType === requestConstants.requestTypes.EditAttribute.key) {
    const displayName = userStore.myAttributes![key].DisplayName
    return `Edit ${displayName} Request`
  }
  else {
    if (requestConstants.requestTypes[item.RequestType]) {
      return t(`${requestConstants.requestTypes[item.RequestType].label}`)
    }
    else {
      return t(item.RequestTypeTranslator)
    }
  }
}

function getSubtitle(item: RequestModel) {
  const key = Object.keys(item.Content)[0]
  const initialValue = utils.isDefined(item.InitialContent?.[key]) && item.InitialContent?.[key] !== '' && item.InitialContent?.[key].length ? item.InitialContent?.[key] : t('general.blank')
  const newValue = item.Content[key]
  const attributesList = Object.values(userStore.myAttributes!)
  const colorPaletteSystemNames = attributesList
    .filter(attribute => attribute.AttributeType === AttributeType.ColorPalette && attribute.SystemName !== editRequestKeys.colorId)
    .map(attribute => attribute.SystemName)

  if (item.RequestType === requestConstants.requestTypes.EditAttribute.key && !colorPaletteSystemNames.includes(key)) {
    let value = newValue
    if (userStore.myAttributes![key]) {
      value = utils.getAttributeTypeSpecificValueWithValue(userStore.myAttributes![key], newValue)
    }
    return `${initialValue} -> ${value}`
  }
  else {
    if (key === editRequestKeys.stateName) {
      const state = userStore.articleStateList.find(state => state.StateId === newValue)
      if (state) {
        return `${initialValue} -> ${state.StateName}`
      }
      return `${initialValue} -> ${newValue}`
    }
    else if (key === editRequestKeys.colorId || colorPaletteSystemNames.includes(key)) {
      let parsedInitialValue = initialValue
      const values = Object.values(newValue) as string[]
      const parsedNewValue = values.length > 0 ? values[0] : newValue

      if (colorPaletteSystemNames.includes(key) && initialValue !== null) {
        const parsedInitial = utils.tryParse(initialValue.toString())
        if (parsedInitial) {
          const values = Object.values(parsedInitial) as string[]
          parsedInitialValue = values.length > 0 ? values[0] : parsedInitial
        }
      }

      return `${parsedInitialValue} -> ${parsedNewValue}`
    }
    else if (key === editRequestKeys.status) {
      const initialStatus = initialValue === 1 ? 'Active' : 'Inactive'
      const newStatus = newValue === 1 ? 'Active' : 'Inactive'
      return `${initialStatus} -> ${newStatus}`
    }
    else if (key === editRequestKeys.sizeScale) {
      const sizeScaleObj = userStore.sizeScales[newValue]
      if (sizeScaleObj) {
        return `${initialValue} -> ${sizeScaleObj.SizeScale}`
      }
      return `${initialValue} -> ${newValue}`
    }
    else {
      return `${initialValue} -> ${newValue}`
    }
  }
}
</script>
