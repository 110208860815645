import type MerchSlide from './services/merchSlide'
import type SharedFolder from '@/models/sharedFolder'
import { merchConstants } from '@/models/constants'
import utils from '@/services/utils'
import type { SlideModel } from '@/api/t1/model/merchModel'

export function buildSharedFolderTree(indexedSharedFolder: Record<number, SharedFolder>, userId) {
  let tree: ITreeNode[] = []
  const mappedFolders = {}
  // First map the nodes of the array to an object -> create a hash table.
  const sharedFolders = Object.values(indexedSharedFolder)
  for (let i = 0; i < sharedFolders.length; i++) {
    // restrict folders if the user doesnot allowed to share(AllowSharing false)
    let isAllowedSharing = false
    const currentUser = sharedFolders[i].Users.find(user => user.UserId === userId)
    if (currentUser && currentUser.AllowSharing) {
      isAllowedSharing = true
    }

    if (sharedFolders[i].Status === 1) {
      const arrElem: ITreeNode = {
        key: sharedFolders[i].Id,
        label: sharedFolders[i].Name,
        sortOrder: 0,
        checked: false,
        expanded: false,
        isFolder: true,
        path: [],
        faicon: 'fa-light fa-folder',
        children: [],
        actions: [], // ['Edit', 'Duplicate', 'Delete'],
        allowSharing: isAllowedSharing,
      }
      mappedFolders[arrElem.key] = arrElem
      mappedFolders[arrElem.key].children = []
    }
  }
  for (const key in mappedFolders) {
    const sharedFolder = indexedSharedFolder[key]
    const mappedElem = mappedFolders[key]
    // If the element is not at the root level, add it to its parent array of children.
    if (sharedFolder.ParentFolderId && sharedFolder.ParentFolderId !== 0) {
      if (mappedFolders[sharedFolder.ParentFolderId]) {
        mappedFolders[sharedFolder.ParentFolderId].children.push(mappedElem)
      }
    }
    // If the element is at the root level, add it to first level elements array.
    else {
      tree.push(mappedElem)
    }
  }
  tree = tree.filter(node => node.allowSharing)
  return tree
}

export function searchNodeInTree(tree: ITreeNode[], value: string | number, key = 'label', searchOnlyFolder = true, reverse = false) {
  for (let i = 0; i < tree.length; i++) {
    const stack = [tree[i]]
    while (stack.length) {
      const node = stack[reverse ? 'pop' : 'shift']()
      if (node && node[key] === value && (!searchOnlyFolder || node.isFolder)) { return node }
      if (node && node.children) {
        stack.push(...node.children)
      }
    }
  }
  return undefined
}

export function buildSlideTree(slides: MerchSlide[] | SlideModel[]) {
  const slideTree = []
  Object.values(slides).forEach((slideData) => {
    const target = createFolderTrailAndGetTargetNode(slideData, slideTree)
    if (!Array.isArray(target)) {
      const node: ITreeNode = {
        key: slideData.SlideId,
        label: slideData.SlideName,
        sortOrder: slideData.SortOrder,
        faicon: 'fa-light fa-frame',
        checked: false,
        expanded: false,
        isFolder: false,
        path: [],
        children: [],
        actions: [],
      }
      node.parent = target
      utils.insertSorted(node, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
    }
  })
  return slideTree
}
function createFolderTrailAndGetTargetNode(slideData: MerchSlide | SlideModel, target: ITreeNode[] | ITreeNode) {
  const folderIdPathList = slideData.FolderId.split(merchConstants.folderPathSeparator)
  const folderNamePathList = slideData.FolderName.split(merchConstants.folderPathSeparator)
  for (let i = 0; i < folderIdPathList.length; i++) {
    const currentTarget = !Array.isArray(target) ? target.children : target
    let subFolder = currentTarget.find(folder => folder.key === folderIdPathList[i])
    if (!subFolder) {
      subFolder = {
        key: folderIdPathList[i],
        label: folderNamePathList[i],
        sortOrder: slideData.FolderIdSortOrder ? slideData.FolderIdSortOrder : 0,
        checked: false,
        faicon: 'fa-light fa-folder',
        expanded: false,
        isFolder: true,
        path: [],
        children: [],
        actions: [], // ['Edit', 'Delete'],
      }
      if (!Array.isArray(target)) {
        subFolder.parent = target
        utils.insertSorted(subFolder, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'label']))
      }
      else {
        utils.insertSorted(subFolder, target, (a, b) => utils.comparer(a, b, ['sortOrder', 'label']))
      }
    }
    target = subFolder
  }
  return target
}
export function getGroupObjects(objectJson: any, groupObjects: any) {
  if (objectJson.type === merchConstants.objectTypes.group && objectJson.objects && objectJson.objects.length) {
    objectJson.objects.forEach((obj) => {
      if (obj.type !== merchConstants.objectTypes.group) {
        groupObjects.push(obj)
      }
      else {
        getGroupObjects(obj, groupObjects)
      }
    })
    return groupObjects
  }
}

// Function to find the parent path from a child node to the root
export function findParentPath(childNode: ITreeNode | undefined, generateNewId = false) {
  const path: Record<string, any> = { id: [], name: [] }
  let currentNode = childNode

  // Traverse upward from the child node to the root
  while (utils.isDefined(currentNode)) {
    if (!currentNode.isOwner && currentNode.isFolder && currentNode.labelKey !== '-1') {
      path.id.push(generateNewId ? utils.randomId() : currentNode.key)
      path.name.push(currentNode.label)
    }
    currentNode = currentNode.parent // Move to the parent node
  }
  const folderStructure = { id: '', name: '' }
  for (let i = path.id.length - 1; i >= 0; i--) {
    folderStructure.id += path.id[i] + (i !== 0 ? merchConstants.folderPathSeparator : '')
    folderStructure.name += path.name[i] + (i !== 0 ? merchConstants.folderPathSeparator : '')
  }
  return folderStructure
}

// Function to generate all paths from a tree array structure including parents
export function getAllPathsWithParents(treeArray) {
  const paths: any[] = []

  function dfs(node, path) {
    if (!node) { return }

    // Add the current node to the path
    if (node.isFolder) {
      path.push({ key: node.key, label: node.label })
      // Add the path to the list of paths
      paths.push([...path])
    }

    // Recursive DFS for each child node
    if (node.children) {
      node.children.forEach(child => dfs(child, path.slice()))// pass a copy of the path
    }

    // Backtrack: Remove the current node from the path
    path.pop()
  }

  // Start DFS from the root node with an empty path
  treeArray.forEach(node => dfs(node, []))
  const pathStrings: any[] = []
  paths.forEach((path) => {
    let key = ''
    const pathValue: string[] = []
    path.forEach((value) => {
      key = value.key
      pathValue.push(value.label)
    })
    pathStrings.push({ path: pathValue.join('|'), key })
  })
  return pathStrings
}

export function findAllChildNodes(node: ITreeNode, childNodes: ITreeNode[]) {
  if (node.children) {
    for (let i = 0; i < node.children.length; i++) {
      if (node.children[i].isFolder) {
        findAllChildNodes(node.children[i], childNodes)
      }
      else {
        childNodes.push(node.children[i])
      }
    }
  }
  return childNodes
}
