<template>
  <div class="overflow-hidden font-sans text-base subpixel-antialiased font-normal select-none bg-default text-default">
    <router-view />
  </div>
  <reload-prompt />
  <notifications-list />
  <confirm-dialog ref="confirmDialogEl" />
</template>

<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from './store/userData'
import auth from './services/auth'
import { appConstants } from './models/constants'
import ReloadPrompt from '@/shared/components/ReloadPrompt.vue'
import workerWrapper from '@/services/workerWrapper'
import NotificationsList from '@/shared/components/NotificationsList.vue'
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue'

const { t } = useI18n()
const userStore = useUserStore()
const confirmDialogEl = ref<InstanceType<typeof ConfirmDialog>>()

onBeforeMount(() => {
  const currentToken = auth.hasValidToken()
  if (currentToken) {
    userStore.setToken(currentToken)
    userStore.setProfile()
    auth.getMyUsers().then((resp) => {
      userStore.setTotalUser(resp.length)
    })
  }
})

onMounted(() => {
  // translate static attributes display names
  Object.keys(appConstants.staticAttributes)
    .forEach((key) => {
      appConstants.staticAttributes[key].DisplayName = t(`${appConstants.staticAttributes[key].DisplayName}`)
      if (appConstants.staticAttributes[key].FilterLookup && appConstants.staticAttributes[key].FilterLookup.size > 0) {
        appConstants.staticAttributes[key].FilterLookup.forEach((value, key, map) => {
          map.set(key, t(value))
        })
      }
    })

  userStore.setConfirmDialogEl(confirmDialogEl.value)
})

onBeforeUnmount(() => {
  workerWrapper.stopWorker()
})
</script>
