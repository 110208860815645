<template>
  <div :style="{ width: `${column.width}px` }" class="box-border flex h-full text-sm">
    <div v-if="column.property !== 'Quantities'" class="flex flex-col content-center justify-center w-full px-2">
      <div
        v-if="isNull(columnValue) || isUndefined(columnValue)"
        class="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-disabled"
      >
        {{ t('general.blank') }}
      </div>
      <div
        v-else-if="column.type === AttributeType.Bool || column.type === AttributeType.Status"
        class="flex flex-row max-w-[70px] min-w-[50px] py-[3px] px-2 items-center rounded-sm bg-grey-white"
      >
        <div
          class="box-border flex-none w-2 h-2 mr-2 rounded-full whitespace-nowrap"
          :style="{ backgroundColor: column.isArticleProperty && currentOrderline.Article[column.property] || (!column.isArticleProperty && currentOrderline[column.property]) ? 'green' : 'red' }"
        />
        <div class="box-border overflow-hidden overflow-ellipsis whitespace-nowrap">
          {{ columnValue }}
        </div>
      </div>
      <div v-else class="w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
        {{ columnValue }}
      </div>
    </div>
    <!-- QUANTITIES Column -->
    <div v-else class="flex flex-row flex-nowrap">
      <template v-for="catalogCrd in visibleCrdList " :key="catalogCrd.Id">
        <div
          v-if="crdStats[catalogCrd.Id].status || (orderline.IndexedCRD.hasOwnProperty(catalogCrd.Id) && orderline.IndexedCRD[catalogCrd.Id].TotalQuantity > 0)"
          class="flex flex-col items-center justify-around p-1 transition cursor-pointer w-25 flex-nowrap odd:bg-grey-light"
          :class="[!crdStats[catalogCrd.Id].status ? 'cursor-auto text-neutral-400 !bg-neutral-50' : 'hover:bg-neutral-200']"
          @click="onCrdClick(catalogCrd)"
        >
          <span v-tooltip="catalogCrd.formattedCrd" class="text-xs">{{ catalogCrd.Description }}</span>
          <span v-if="isCustomerRequiredDateValidatorAvailable && orderline.IndexedCRD.hasOwnProperty(catalogCrd.Id)" class="text-xs">{{ utils.formatDate(orderline.IndexedCRD[catalogCrd.Id].DeliveryDate) }}</span>
          <div
            v-tooltip="{ text: !crdStats[catalogCrd.Id].status ? crdStats[catalogCrd.Id].reasons.join(', ') : null }"
            class="border-b-[1px] w-3/4 text-center relative"
          >
            <span v-show="!crdStats[catalogCrd.Id].status" class="absolute font-bold text-red-500 left-3 after:content-['!']" />
            <span>{{ orderline.IndexedCRD.hasOwnProperty(catalogCrd.Id) ? orderline.IndexedCRD[catalogCrd.Id].TotalQuantity : 0 }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang='ts' setup>
import { computed, toRef } from 'vue'
import { isNull, isUndefined } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import type { IOrderlineCrd, IOrderlineVisibleColumn } from '../../Orders.types'
import Order from '@/models/order'
import type Orderline from '@/models/orderline'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import { getArticlePropertyRenderValue } from '@/services/catalogFactory'

const props = defineProps<{
  column: IOrderlineVisibleColumn
  crdStats: Record<number, { status: boolean, reasons: Array<string> }>
  currentOrder: Order
  orderline: Orderline
  visibleCrdList: Array<IOrderlineCrd>
}>()

const emit = defineEmits<{
  (e: 'crdClick', articleId: number, crdId: number)
}>()

const { t } = useI18n()

const currentOrderline = toRef(props, 'orderline')

const columnValue = computed(() => {
  let columnValue: any = null
  if (props.column.isArticleProperty) {
    const translatedValues = {
      active: t('general.active'),
      inactive: t('general.inactive'),
      yes: t('general.yes'),
      no: t('general.no'),
    }
    columnValue = getArticlePropertyRenderValue(currentOrderline.value.Article, props.column.property, props.column.type, translatedValues)
  }
  else if (props.column.property === 'CurrentPrice') {
    columnValue = `${props.currentOrder.OrderPriceGroup.CurrencyCode} ${utils.thousandsSeparator(Number.parseFloat(currentOrderline.value[props.column.property].toFixed(2)))}`
  }
  else if (props.column.property === 'TotalPrice') {
    columnValue = utils.thousandsSeparator(Number.parseFloat((currentOrderline.value.TotalQuantity * currentOrderline.value.CurrentPrice).toFixed(2)))
  }
  else if (props.column.property === 'TotalQuantity') {
    columnValue = utils.thousandsSeparator(currentOrderline.value[props.column.property])
  }
  else {
    columnValue = currentOrderline.value[props.column.property]
  }
  return columnValue
})

const isCustomerRequiredDateValidatorAvailable = computed(() => Order.isCustomerRequiredDateValidatorAvailable(props.currentOrder.ActiveCatalog))

function onCrdClick(crd: IOrderlineCrd) {
  emit('crdClick', currentOrderline.value.Article.Id, crd.Id)
}
</script>
