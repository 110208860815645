<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <div class="header" />
    <!-- BODY -->
    <div class="px-10 mt-4 alerts">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    </div>
    <!-- FORM -->
    <div class="w-full px-10 overflow-y-auto gap-x-6 body">
      <div class="w-full">
        <div class="w-full my-2">
          <tx-select
            v-model="form.TypeId" :label="t('createResourceForm.resourceType')" :data="resourceTypes"
            :errors="v$.TypeId.$errors" value-prop="Id" display-prop="Type" required @blur="v$.TypeId.$touch"
          />
        </div>
        <div class="w-full my-2">
          <tx-input
            v-model="form.ResourceName" :label="t('createResourceForm.resourceName')"
            :errors="v$.ResourceName.$errors" required @blur="v$.ResourceName.$touch"
          />
        </div>
        <div class="w-full my-2">
          <upload-attachment
            v-model="form.FileId" :label="t('createResourceForm.resourceImage')"
            :restricted-formats="restrictedFormats"
          />
        </div>
        <div class="w-full my-2">
          <upload-attachment v-model="form.IconFileId" :label="t('createResourceForm.resourceIcon')" />
        </div>
        <div class="flex w-full my-2 gap-x-2">
          <tx-input
            v-model="form.Category" :label="t('createResourceForm.category')" :errors="v$.Category.$errors"
            @blur="v$.Category.$touch"
          />
          <tx-input v-model="form.SortOrder" :label="t('createResourceForm.sortOrder')" type="number" />
          <tx-switch v-model="form.Cascading" :label="t('createResourceForm.cascading')" />
        </div>
        <div class="w-full my-2">
          <tx-input
            v-model="form.Description" :label="t('createResourceForm.description')"
            :errors="v$.Description.$errors" @blur="v$.Description.$touch"
          />
        </div>
      </div>
    </div>
    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="t('general.save')" :primary-disabled="v$.$invalid" :secondary-disabled="loading"
      :primary-loading="loading" @primary-click="onUpdate" @secondary-click="onCancel"
    />
  </div>
</template>

<script setup lang="ts">
import { unionBy } from 'lodash-es'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, maxLength, minLength, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import TxAlert from '../TxAlert.vue'
import TxSelect from '../TxSelect.vue'
import TxInput from '../TxInput.vue'
import TxSwitch from '../TxSwitch.vue'
import UploadAttachment from '../UploadAttachment.vue'
import type MyArticle from '@/models/myArticle'
import type ResourceType from '@/models/resourceType'
import type { CreateResourceWithArticlesOptionalModel } from '@/api/t1/model/resourceModel'
import { createResourceWithArticlesOptional } from '@/api/t1/resource'
import Resource from '@/models/resource'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'

const props = defineProps<{
  articles: MyArticle[]
  isModel: boolean | false
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[], type: string): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()
const withI18nMessage = createI18nMessage({ t })

const loading = ref(false)
const form = reactive<CreateResourceWithArticlesOptionalModel>({
  TypeId: 0,
  ResourceName: '',
  FileId: null,
  IconFileId: null,
  Category: null,
  SortOrder: 0,
  Cascading: 1,
  Description: null,
  ArticleIds: [],
})
const rules = computed(() => ({
  TypeId: { required: withI18nMessage(required) },
  ResourceName: { required: withI18nMessage(required), minLength: withI18nMessage(minLength(1)), maxLength: withI18nMessage(maxLength(250)) },
  Category: { maxLength: withI18nMessage(maxLength(1000)) },
  Description: { maxLength: withI18nMessage(maxLength(2000)) },
}))
const v$ = useVuelidate(rules, form)

const resourceTypes = ref<ResourceType[]>([])
const articles = ref<MyArticle[]>([])

const restrictedFormats = computed(() => {
  const resourceType = resourceTypes.value.find(r => r.Id.toString() === form.TypeId?.toString())
  return utils.isDefined(resourceType) ? resourceType.AllowedFileTypes : []
})

function onCancel() {
  emit('cancel')
}

async function onUpdate() {
  errorMessage.value = ''
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
    return
  }
  if (userStore.activeCatalog && articles.value.length > 0 && form.TypeId > 0 && form.ResourceName.length > 0) {
    const catalogCode = userStore.activeCatalog.CatalogCode
    loading.value = true
    form.Cascading = form.Cascading ? 1 : 0
    form.ArticleIds = articles.value.map(a => a.Id)
    createResourceWithArticlesOptional(catalogCode, form)
      .then(async (res) => {
        const newResource = new Resource(catalogCode, res.data)
        await appConfig.DB!.resources.add(newResource)
        articles.value.forEach((article) => {
          article._Resources = unionBy(article._Resources, [newResource], 'Id')
        })
        emit('updated', articles.value, 'create')
      })
      .catch((e) => {
        console.error(e)
        if (e.response && e.response.data) {
          errorMessage.value = e.response.data.map(x => x.ErrorMessage).join(', ')
        }
        else {
          errorMessage.value = t('general.unexpectedError')
        }
      })
      .finally(() => loading.value = false)
  }
}

async function reset() {
  loading.value = false
  errorMessage.value = ''
  form.TypeId = 0
  form.ResourceName = ''
  form.FileId = null
  form.IconFileId = null
  form.Category = null
  form.SortOrder = 0
  form.Cascading = 1
  form.Description = null
  form.ArticleIds = []
  if (props.isModel) {
    if (!userStore || (!userStore.activeCatalog)) {
      console.warn('Unable to load model details by modelNumber')
      return null
    }
    const catalogDetails = userStore.activeCatalog
    const modelNumbers = props.articles.map(s => s.ModelNumber)
    const modelArticles = await appConfig.DB!.getMyArticlesByModelNumbers(modelNumbers, catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
    if (modelArticles && modelArticles.length) {
      articles.value = modelArticles
    }
  }
  else {
    articles.value = props.articles
  }
}

watch(() => props.articles, reset)

onMounted(async () => {
  reset()
  resourceTypes.value = await appConfig.DB!.getResourceTypes()
})
</script>
