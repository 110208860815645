<template>
  <div class="w-full h-56 mt-10">
    <div class="w-full">
      <tx-input
        ref="refUserName" v-model="userName" :label="t('general.username')" :readonly="loading" required
        @keyup.enter="onNext"
      />
      <div class="inline-flex items-end justify-between w-full mt-3 ">
        <tx-checkbox :label="t('login.rememberMe')" />
      </div>
    </div>
  </div>

  <div class="w-full text-center">
    <tx-button
      type="confirm" width="180px" height="40px" :disabled="!userName" :loading="loading"
      :text="t('general.next')" @click="onNext"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { onMounted, ref } from 'vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxCheckbox from '@/shared/components/TxCheckbox.vue'

interface IProps {
  loading?: boolean
}
withDefaults(defineProps<IProps>(), { loading: false })

const emit = defineEmits<{
  (e: 'next', userName: string): void
}>()

const { t } = useI18n()

const userName = ref('')
const refUserName = ref<InstanceType<typeof TxInput>>()

function onNext() {
  if (userName.value.length > 0) {
    emit('next', userName.value)
  }
}

onMounted(() => {
  refUserName.value?.focus()
})
</script>
