import type { AxiosError, AxiosInstance } from 'axios'
import Axios from 'axios'
import type { IAxiosRetryConfig } from 'axios-retry'
import axiosRetry, { isNetworkError, isRetryableError } from 'axios-retry'
import type { AppConfig } from './appConfig'

export class Net {
  t1: AxiosInstance
  auth: AxiosInstance
  dunes: AxiosInstance
  assets: AxiosInstance
  whiteboard: AxiosInstance
  fileServer: AxiosInstance
  errorListeners: NetErrorFunction[]

  constructor() {
    this.t1 = Axios.create({ timeout: 300000 })
    this.auth = Axios.create({ timeout: 60000 })
    this.dunes = Axios.create({ timeout: 300000 })
    this.assets = Axios.create({ timeout: 300000 })
    this.whiteboard = Axios.create({ timeout: 300000 })
    this.fileServer = Axios.create({ timeout: 300000 })

    const retryConfig: IAxiosRetryConfig = {
      retries: 3,
      retryCondition: error => isNetworkError(error) || isRetryableError(error),
      onRetry: (retryCount, error, requestConfig) => { console.warn(`HTTP request failed, retrying (${retryCount})`, error, requestConfig) },
      retryDelay: () => 20000,
    }

    axiosRetry(this.t1, retryConfig)
    axiosRetry(this.auth, retryConfig)
    axiosRetry(this.dunes, retryConfig)
    axiosRetry(this.assets, retryConfig)

    this.t1.interceptors.response.use(resp => resp, err => errorHandler(this.errorListeners, 't1', err))
    this.dunes.interceptors.response.use(resp => resp, err => errorHandler(this.errorListeners, 'dunes', err))
    this.whiteboard.interceptors.response.use(resp => resp, err => errorHandler(this.errorListeners, 'whiteboard', err))
    this.fileServer.interceptors.response.use(resp => resp, err => errorHandler(this.errorListeners, 'fileServer', err))

    this.errorListeners = []
  }

  init(appConfig: AppConfig) {
    this.t1.defaults.baseURL = appConfig.T1URL
    this.auth.defaults.baseURL = appConfig.AuthUrl
    this.dunes.defaults.baseURL = appConfig.DunesUrl
    this.assets.defaults.baseURL = appConfig.AssetsUrl
    this.whiteboard.defaults.baseURL = appConfig.WhiteboardUrl
    this.fileServer.defaults.baseURL = appConfig.FileServerUrl

    if (typeof localStorage !== 'undefined') {
      const token = localStorage.getItem('tk')
      if (token) {
        this.setToken(token)
      }
    }
  }

  setToken(token: string) {
    this.t1.defaults.headers.common.Authorization = `Bearer ${token}`
    this.auth.defaults.headers.common.Authorization = `Bearer ${token}`
    this.dunes.defaults.headers.common.Authorization = `Bearer ${token}`
    this.whiteboard.defaults.headers.common.Authorization = `Bearer ${token}`
    this.fileServer.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  onError(fct: NetErrorFunction) {
    this.errorListeners.push(fct)
  }

  offError(fct: NetErrorFunction) {
    const i = this.errorListeners.indexOf(fct)
    if (i >= 0) {
      this.errorListeners.splice(i, 1)
    }
  }
}

async function errorHandler(listeners: NetErrorFunction[], source: string, err: AxiosError) {
  listeners.forEach((element) => {
    element(source, err)
  })
  return Promise.reject(err)
}

type NetErrorFunction = (source: string, error: Error | AxiosError) => void

export default new Net()
