import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import { whiteboardConstants } from '@/models/constants'

interface IWbTextBoxOptions extends fabric.ITextboxOptions {
  id?: string
  lock?: boolean
  preventUnlock?: boolean
  localOnly?: boolean
  truncate?: boolean
}

export default class WbTextBox extends fabric.Textbox implements IWbObject {
  public id: string
  public type = whiteboardConstants.objectTypes.textBox
  public lock: boolean
  public connectable: boolean
  public localOnly: boolean
  public preventUnlock: boolean
  public truncate: boolean

  public editableProps: Record<string, IWbObjectProp> = {
    backgroundColor: { name: 'background color', type: 'backgroundColor' },
    textColor: { name: 'text color', type: 'textColor' },
    font: { name: 'font', type: 'font' },
    fontSize: { name: 'font size', type: 'fontSize' },
    textAlign: { name: 'align', type: 'textAlign' },
    fontStyle: { name: 'font style', type: 'fontStyle' },
    lock: { name: 'Lock', type: 'lock' },
    addDiscussion: { name: 'add comment', type: 'addDiscussion' },
  }

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  constructor(text: string, opt?: IWbTextBoxOptions) {
    super(text, opt)
    this.id = opt?.id || guid()
    this.lock = opt?.lock || false
    this.connectable = true
    this.localOnly = opt?.localOnly || false
    this.preventUnlock = opt?.preventUnlock || false
    this.truncate = opt?.truncate || false

    this.setLock(this.lock)

    this.stateProperties = this.stateProperties?.concat(['lock'])
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'textColor':
        this.set('fill', value.textColor)
        break
      case 'font':
        this.set('fontFamily', value.font)
        break
      case 'fontSize':
        this.set('fontSize', value.fontSize)
        break
      case 'textAlign':
        this.set('textAlign', value.textAlign)
        break
      case 'bold':
        this.set('fontWeight', value.bold ? 'bold' : '')
        break
      case 'italic':
        this.set('fontStyle', value.italic ? 'italic' : '')
        break
      case 'text':
        this.set('text', value.text)
        break
      case 'lock':
        this.set('lock', value.lock)
        this.setLock(value.lock)
        break
      case 'backgroundColor':
        this.set('backgroundColor', value.backgroundColor)
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'textColor':
        result.textColor = this.fill
        break
      case 'font':
        result.font = this.fontFamily
        break
      case 'fontSize':
        result.fontSize = this.fontSize
        break
      case 'textAlign':
        result.textAlign = this.textAlign
        break
      case 'bold':
        result.bold = !!this.fontWeight
        break
      case 'italic':
        result.italic = !!this.fontStyle
        break
      case 'text':
        result.text = this.text
        break
      case 'lock':
        result.lock = this.lock
        break
      case 'backgroundColor':
        result.backgroundColor = this.backgroundColor
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override _wrapText(lines: string[], desiredWidth: number): string[][] {
    if (this.truncate) {
      const newLines = lines.map((_line, lineIndex) => {
        const offset = 0
        let textWidth = this._measureWord([..._line], lineIndex, offset)

        if (textWidth > desiredWidth && !this.isEditing) {
          const ellipsis = '…'
          const lengthReducingFactor = desiredWidth / textWidth
          let length = Math.ceil(_line.length * lengthReducingFactor)
          while (textWidth >= desiredWidth && length > 0) {
            _line = _line.substring(0, length--) + ellipsis
            textWidth = this._measureWord([..._line], lineIndex, offset)
          }
        }
        return _line
      })
      return super._wrapText(newLines, desiredWidth)
    }
    return super._wrapText(lines, desiredWidth)
  }

  override onKeyDown(e: Event): void {
    e.stopPropagation()
  }

  override toObject(propertiesToInclude?: string[]) {
    const props = propertiesToInclude || []
    props.push('id', 'lock', 'localOnly', 'preventUnlock')
    return super.toObject(props)
  }

  static fromObject(object: fabric.Object, callback?: Function) {
    return fabric.Object._fromObject(whiteboardConstants.objectTypes.textBox, object, callback, 'text') as WbTextBox
  }
}

const f: any = fabric
f.WbTextBox = WbTextBox
