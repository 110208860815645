<template>
  <div class="flex flex-col h-full overflow-hidden">
    <div class="grow flex flex-col h-full overflow-hidden">
      <tx-data-table
        :clickable-record="true"
        :columns="columns"
        :data="orders"
        :item-actions="itemActions"
        :list-actions="listActions"
        :loading="isLoading || isLoadingData"
        :initial-default-visible-column-max-count="12"
        :visible-columns="visibleColumns"
        :model-path="modelPath"
        :total-column-width="totalColumnWidth"
        :sort-column="{ UpdatedDate: false }"
        @refresh="doRefresh"
        @row-click="showOrderlines"
        @selection-changed="selectionChanged"
      >
        <template #fieldViewer="{ indexedColumns, columns, record }">
          <order-field-viewer
            v-for="column in columns" :key="column.property" :column="column" :value="record[column.property]"
            :width="`${column.width}px`" :type="indexedColumns.get(column.property)!.type"
          />
        </template>
      </tx-data-table>

      <!-- ORDER DETAILS -->
      <tx-drawer v-model="showOrderlineDrawer" :show-close="false" :close-on-overlay-click="false" :close-on-esc="false" right width="95%" @closed="onOrderlineDrawerClosed">
        <order-details
          v-if="ordersStore.currentOrder != null" :indexed-articles="indexedArticles" :indexed-locations="indexedLocations" :indexed-v-a-s="indexedVAS"
          :indexed-customers="indexedCustomers" @reopen-order="doReopenOrders" @close="closeOrderline"
        />
      </tx-drawer>

      <!-- CREATE ORDER -->
      <tx-drawer v-model="showActionDrawer" right width="700px" @closed="onActionDrawerClosed">
        <create-order
          v-if="currentAction === actionsEnum.create" :indexed-articles="indexedArticles" :indexed-customers="indexedCustomers" :indexed-locations="indexedLocations"
          @create="addOrder" @cancel="closeActionsDrawer"
        />
      </tx-drawer>

      <!-- IMPORT ORDER -->
      <tx-drawer v-model="showImportDrawer" right width="700px" @closed="onImportDrawerClosed">
        <import-order
          v-if="currentAction === actionsEnum.import" :indexed-articles="indexedArticles" :indexed-customers="indexedCustomers" :indexed-locations="indexedLocations" :orders="orders" :indexed-orders="indexedOrders" :indexed-v-a-s="indexedVAS" @cancel="closeImportDrawer"
        />
      </tx-drawer>

      <!-- SEND MULTIPLE ORDER -->
      <tx-drawer v-model="showSendMultipleOrdersDrawer" right width="700px">
        <send-multiple-orders
          v-if="showSendMultipleOrdersDrawer" :orders="selectedOrders" :indexed-articles="indexedArticles" :indexed-locations="indexedLocations" :indexed-v-a-s="indexedVAS"
          :indexed-customers="indexedCustomers" @failed="onSendMultipleOrdersFailed" @close="closeSendMultipleOrdersDrawer"
        />
      </tx-drawer>

      <!-- ALERTS DIALOG -->
      <tx-dialog v-model="showAlertDialog" :show-ok="true" :title="alertDialogTitle" confirm-text="general.ok" width="450px" height="250px" @ok="showAlertDialog = false" @closed="onAlertDialogClosed">
        <div class="whitespace-pre-line">
          {{ alertDialogMessage }}
        </div>
      </tx-dialog>

      <!-- PROMPT DIALOG -->
      <tx-dialog
        v-model="showPromptDialog" :show-ok-cancel="true" :title="promptDialogTitle" confirm-text="general.ok"
        width="450px" height="250px" :loading="promptDialogLoading" @ok="promptDialogUserConfirm" @cancel="promptDialogUserCancel"
      >
        <div class="whitespace-pre-line">
          {{ promptDialogDescription }}
        </div>
      </tx-dialog>
    </div>
    <status-bar />
  </div>
</template>

<script lang='ts' setup>
import { computed, onMounted, onUnmounted, provide, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { clone } from 'lodash-es'
import useUserProfileSettings from '../admin/composables/userProfileSettings'
import CreateOrder from './components/order/CreateOrder.vue'
import ImportOrder from './components/order/ImportOrder.vue'
import OrderFieldViewer from './components/order/OrderFieldViewer.vue'
import OrderDetails from './OrderDetails.vue'
import SendMultipleOrders from './components/order/SendMultipleOrders.vue'
import appConfig from '@/services/appConfig'
import type CustomerLocation from '@/models/CustomerLocation'
import type LinkedCustomer from '@/models/linkedCustomer'
import type MyArticle from '@/models/myArticle'
import Order from '@/models/order'
import StatusBar from '@/shared/components/StatusBar.vue'
import TxDataTable from '@/shared/components/txDataTable'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxDrawer from '@/shared/components/TxDrawer.vue'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import type { IOrderModel, ISellerVASModel, IUpdateOrderPayload } from '@/api/t1/model/orderModel'
import type { ITxDataTableColumn, ITxDataTableItemAction, ITxDataTableListAction, ITxDataTableVisibleColumn } from '@/shared/components/txDataTable/TxDataTable.types'
import { deleteOrders, fetchOrders, fetchSellerVAS, getProcessStatus, reopenOrders } from '@/api/t1/order'
import { ordersConstants } from '@/models/constants'
import { useNotificationStore } from '@/store/notification'
import { useOrdersStore } from '@/store/orders'
import { useUserStore } from '@/store/userData'
import useEventsBus from '@/shared/composables/eventBus'
import * as sBar from '@/store/status'
import type CustomerSegmentation from '@/models/customerSegmentation'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const ordersStore = useOrdersStore()
const notificationsStore = useNotificationStore()
const { onAppEvent, offAppEvent } = useEventsBus()
const { applyPersistedListViewLayout } = useUserProfileSettings()

const orders = ref<Array<Order>>([])
// const currentOrder = ref<Order | null>(null)
const isLoading = ref(false)
const isLoadingData = ref(false)
const showOrderlineDrawer = ref(false)
const showActionDrawer = ref(false)
const showImportDrawer = ref(false)
const currentAction = ref(0)

const allSelectedOrdersAreDraft = ref(false)
const allSelectedOrdersAreSubmitted = ref(false)
const allSelectedOrdersAreDraftOrReopened = ref(false)
const allSelectedOrdersEditableExcludingProcessStatus = ref(false)

const modelPath = ref('t1SWebOrders')
const totalColumnWidth = ref(1200)
const visibleColumns = ref<ITxDataTableVisibleColumn[]>([])
const columns = ref<ITxDataTableColumn[]>([])

const showAlertDialog = ref(false)
const alertDialogTitle = ref('')
const alertDialogMessage = ref('')

const showPromptDialog = ref(false)
const promptDialogLoading = ref(false)
const promptDialogTitle = ref('')
const promptDialogDescription = ref('')
let deferredPromptDialogClosureResolve: null | ((value: void | PromiseLike<void>) => void) = null
let deferredPromptDialogClosureReject: null | ((value: void | any) => void) = null
let promptAction: null | 'removeOrders' | 'sendOrders' = null

const showSendMultipleOrdersDrawer = ref(false)

const selectedOrders = ref<Array<Order>>([])
let indexedArticles: Record<number, MyArticle> = {}
let indexedCustomers: Record<number, LinkedCustomer> = {}
let indexedLocations: Record<number, CustomerLocation> = {}
let indexedVAS: Record<number, ISellerVASModel> = {}

// order process status tracking
let submittingOrderReferenceList: Array<string> = []
let trackOrderProcessStatus = false
let trackOrderProcessStatusTimeoutToken: number
const ordersProcessStatusTrackerIntervalTime = 30000

const actionsEnum = {
  create: 1,
  import: 2,
}

// indexes start from 1 due to error TypeError: Cannot create property 'xyz' on number '0'
const indexedOrders = computed(() => orders.value.reduce((acu, cur, index) => (acu[cur.OrderReference] = index + 1) && acu, {}))

const itemActions = computed(() => ([
  {
    id: 'reopenOrders',
    label: t('orders.actions.reopen'),
    icon: 'fa-light fa-rotate-right',
    visible: (order: Order) => isOrderEditable(order, false) && order.OrderProcessStatusId === ordersConstants.orderProcessStatus.submitted,
    enabled: true,
    onClick: (order: Order) => doReopenOrders([order]),
  },
]) as Array<ITxDataTableItemAction>)

const listActions = computed(() => ([
  {
    id: 'create',
    label: t('general.create'),
    icon: 'fa-light fa-plus',
    visible: true,
    enabled: true,
    onClick: showCreateOrder,
  },
  {
    id: 'send',
    label: t('orders.actions.send'),
    icon: 'fa-light fa-paper-plane',
    visible: selectedOrders.value.length > 0,
    enabled: selectedOrders.value.length <= 25 && (!userStore.activeCatalog!.IsStockApply || (userStore.currentCustomer != null && selectedOrders.value.length === 1)
    // enabled: (allSelectedOrdersEditableExcludingProcessStatus.value && allSelectedOrdersAreDraftOrReopened.value && selectedOrders.value.length <= 25) && (
    // !userStore.activeCatalog!.IsStockApply || (userStore.currentCustomer != null && selectedOrders.value.length == 1)
    ),
    onClick: () => { showSendMultipleOrdersDrawer.value = true },
  },
  {
    id: 'reopen',
    label: t('orders.actions.reopen'),
    icon: 'fa-light fa-rotate-right',
    visible: selectedOrders.value.length > 0,
    enabled: allSelectedOrdersEditableExcludingProcessStatus.value && allSelectedOrdersAreSubmitted.value,
    onClick: () => doReopenOrders(selectedOrders.value),
  },
  {
    id: 'delete',
    label: t('orders.actions.delete'),
    icon: 'fa-light fa-trash-can',
    visible: selectedOrders.value.length > 0,
    enabled: allSelectedOrdersEditableExcludingProcessStatus.value && allSelectedOrdersAreDraft.value,
    onClick: () => removeOrders(selectedOrders.value),
  },
  {
    id: 'import',
    label: t('orders.actions.import'),
    icon: 'fa-light fa-upload',
    visible: true,
    enabled: true,
    onClick: () => showImportOrder(),
  },
] as Array<ITxDataTableListAction>))

async function loadData() {
  await Promise.all([getIndexedArticles(), getIndexedCustomers(), getIndexedLocations(), getIndexedVAS()])
    .then((res) => {
      indexedArticles = res[0]
      indexedCustomers = res[1]
      indexedLocations = res[2]
      indexedVAS = res[3]
    })
    .catch(e => console.error(e))
}

async function init() {
  utils.setTitle(userStore.activeCatalog!.CatalogName, t('routes.orders'))
  isLoading.value = true
  columns.value = getColumns()
  // retrieve persisted default colums for model if existed and apply that on visible columns
  // Note: This should be part of all module implementations.
  visibleColumns.value = clone(columns.value).map(o => ({
    property: o.property,
    order: 1,
    width: 0.175,
  }))

  const { persistedColumns, totalTableColumnWidth } = applyPersistedListViewLayout(true, modelPath.value, columns.value, visibleColumns.value)
  if (persistedColumns) {
    visibleColumns.value = persistedColumns
  }
  if (totalTableColumnWidth) {
    totalColumnWidth.value = totalTableColumnWidth
  }
  await loadData()
  orders.value = await getOrders(await getIndexedCustomers())
  if (route.params && utils.isDefined(route.params.orderId)) {
    const order = getOrderByOrderId(Number(route.params.orderId))
    if (utils.isDefined(order)) {
      initCurrentOrder(order)
      doOpenOrderlinesDrawer(order)
    }
    else {
      router.push({ name: 'Orders' })
    }
  }
  isLoading.value = false
}

async function getIndexedArticles() {
  let result: Record<number, MyArticle> = {}
  try {
    const articles = await appConfig.DB?.getCatalogMyArticles(userStore.activeCatalog!, userStore.myAttributes!, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
    if (utils.isDefined(articles)) {
      result = articles.reduce((acu, cur) => (acu[cur.Id] = cur) && acu, {})
    }
  }
  catch (error) {
    result = {}
    console.warn(error)
  }
  return result
}

// linked customers will have segmentaions
async function getIndexedCustomers() {
  let linkedCustomers: Array<LinkedCustomer> = []
  if (!utils.isDefined(userStore.currentCustomer)) {
    try {
      linkedCustomers = await appConfig.DB!.getLinkedCustomers(userStore.activeCatalog!)
    }
    catch (error) {
      linkedCustomers = []
      console.warn(error)
    }
  }
  else {
    linkedCustomers = [userStore.currentCustomer]
  }
  const indexedCustomers: Record<number, LinkedCustomer> = {}
  for (const customer of linkedCustomers) {
    try {
      // Call the getCustomerSegmentations API for each customer
      const customerSegmentation: CustomerSegmentation | undefined = await appConfig.DB!.getCustomerSegmentations(userStore.activeCatalog!, customer.CustomerId, false)

      // Assign customer data and segmentations to indexedCustomers
      indexedCustomers[customer.CustomerId] = {
        ...customer,
      }
      if (customerSegmentation && customerSegmentation.Segmentations && customerSegmentation.Segmentations.length) {
        indexedCustomers[customer.CustomerId].Segmentations = customerSegmentation.Segmentations.filter(seg => seg.Status === 1)
      }
    }
    catch (error) {
      console.warn(`Failed to fetch segmentations for customer ${customer.CustomerId}:`, error)
      indexedCustomers[customer.CustomerId] = { ...customer, Segmentations: [] }
    }
  }
  return indexedCustomers
}

async function getIndexedLocations() {
  let locations: Array<CustomerLocation> = []
  try {
    if (!utils.isDefined(userStore.currentCustomer)) {
      locations = await appConfig.DB?.locations
        .where({ AccountId: userStore.activeCatalog?.AccountId })
        .filter(l => l.Status > 0).toArray() ?? []
    }
    else {
      locations = await appConfig.DB?.locations.where({ CustomerId: userStore.currentCustomer.CustomerId }).toArray() ?? []
    }
  }
  catch (error) {
    locations = []
    console.error(error)
  }
  return utils.arrayToNumberDictionary(locations, 'Id')
}

async function getIndexedVAS() {
  let sellerVAS: Array<ISellerVASModel> = []
  try {
    const sellerVASResponse = await fetchSellerVAS(userStore.activeCatalog!.AccountId)
    sellerVAS = sellerVASResponse.data
  }
  catch (error) {
    sellerVAS = []
    console.warn(error)
  }
  return utils.arrayToNumberDictionary(sellerVAS, 'Id')
}

async function doRefresh() {
  isLoadingData.value = true
  orders.value = await getOrders(indexedCustomers)
  resetProcessingOrdersReferenceList()
  isLoadingData.value = false
}

async function getOrders(indexedCustomers: Record<number, LinkedCustomer>) {
  let orders: Array<Order> = []
  try {
    const getOrdersResponse = await fetchOrders(userStore.activeCatalog!.CatalogCode, userStore.currentCustomer?.CustomerId)
    if (getOrdersResponse.data.length) {
      getOrdersResponse.data.forEach((order) => {
        // TODO: Handle Orders which are not valid
        if (utils.isDefined(indexedCustomers[order.CustomerId])) {
          orders.push(new Order(indexedCustomers[order.CustomerId], userStore.activeCatalog!, userStore.userProfile, order))
        }
      })
    }
  }
  catch (error) {
    console.error(error)
    orders = []
  }
  return orders
}

function showOrderlines(order: Order) {
  initCurrentOrder(order)
  router.push({ name: 'Orderlines', params: { orderId: order.Id } })
}

function initCurrentOrder(order: Order) {
  ordersStore.setCurrentOrder(order)
  // currentOrder.value = order
}

function selectionChanged(orders: Array<Order>) {
  selectedOrders.value = orders
}

function getColumns() {
  const columns: Array<ITxDataTableColumn> = [
    { property: 'OrderReference', title: t('orders.field.orderReference'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'Alias', title: t('orders.field.alias'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'CustomerNumber', title: t('orders.field.customerNumber'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'CustomerName', title: t('orders.field.customerName'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'LocationCode', title: t('orders.field.locationCode'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'LocationName', title: t('orders.field.locationName'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'OrderProcessStatus', title: t('orders.field.orderProcessStatus'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'TotalQuantity', title: t('orders.field.totalQuantity'), type: AttributeType.Int, filterLookup: new Map() },
    { property: 'TotalCurrentValue', title: t('orders.field.totalCurrentValue'), type: AttributeType.Decimal, filterLookup: new Map() },
    { property: 'UpdatedDate', title: t('orders.field.updatedDate'), type: AttributeType.TimeAgo, filterLookup: new Map() },
    { property: 'CreatedByEmail', title: t('orders.field.createdByEmail'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'UpdatedByEmail', title: t('orders.field.updatedByEmail'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'CatalogCode', title: t('orders.field.catalogCode'), type: AttributeType.Int, filterLookup: new Map() },
    { property: 'Comment', title: t('orders.field.comment'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'CreatedBy', title: t('orders.field.createdBy'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'CreatedDate', title: t('orders.field.createdDate'), type: AttributeType.TimeAgo, filterLookup: new Map() },
    { property: 'CurrencyCode', title: t('orders.field.currencyCode'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'CustomerId', title: t('orders.field.customerId'), type: AttributeType.Int, filterLookup: new Map() },
    { property: 'CustomerReference', title: t('orders.field.customerReference'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'GFRQuantity', title: t('orders.field.GFRQuantity'), type: AttributeType.Decimal, filterLookup: new Map() },
    { property: 'GFRValue', title: t('orders.field.GFRValue'), type: AttributeType.Decimal, filterLookup: new Map() },
    { property: 'Id', title: t('orders.field.id'), type: AttributeType.Int, filterLookup: new Map() },
    { property: 'InactiveQuantity', title: t('orders.field.inactiveQuantity'), type: AttributeType.Int, filterLookup: new Map() },
    { property: 'LocationId', title: t('orders.field.locationId'), type: AttributeType.Int, filterLookup: new Map() },
    { property: 'OrderContent', title: t('orders.field.orderContent'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'OrderProcessStatusId', title: t('orders.field.orderProcessStatusId'), type: AttributeType.Int, filterLookup: new Map() },
    { property: 'OrderType', title: t('orders.field.orderType'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'OrderTypeId', title: t('orders.field.orderTypeId'), type: AttributeType.Int, filterLookup: new Map() },
    { property: 'PriceGroupName', title: t('orders.field.priceGroupName'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'RevisionNumber', title: t('orders.field.revisionNumber'), type: AttributeType.Int, filterLookup: new Map() },
    { property: 'SellerAccountid', title: t('orders.field.sellerAccountId'), type: AttributeType.Int, filterLookup: new Map() },
    { property: 'Source', title: t('orders.field.source'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'SourceId', title: t('orders.field.sourceId'), type: AttributeType.Int, filterLookup: new Map() },
    { property: 'Status', title: t('orders.field.status'), type: AttributeType.Int, filterLookup: new Map() },
    { property: 'StockCode', title: t('orders.field.stockCode'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'StockDefinitionId', title: t('orders.field.stockDefinitionId'), type: AttributeType.Int, filterLookup: new Map() },
    { property: 'StockName', title: t('orders.field.stockName'), type: AttributeType.Nvarchar, filterLookup: new Map() },
    { property: 'TotalInitialValue', title: t('orders.field.totalInitialValue'), type: AttributeType.Decimal, filterLookup: new Map() },
    { property: 'UpdatedBy', title: t('orders.field.updatedBy'), type: AttributeType.Nvarchar, filterLookup: new Map() },
  ]
  return columns
}

function doOpenOrderlinesDrawer(order: Order) {
  showOrderlineDrawer.value = true
  utils.setTitle(userStore.activeCatalog!.CatalogName, `${t('routes.orders')} | ${order.OrderReference}`)
}

function doCloseOrderlinesDrawer() {
  showOrderlineDrawer.value = false
  utils.setTitle(userStore.activeCatalog!.CatalogName, t('routes.orders'))
}

function onOrderlineDrawerClosed() {
  if (ordersStore.currentOrder) {
    ordersStore.setCurrentOrder(null)
  }

  if (route.name !== 'Orders') {
    router.push({ name: 'Orders' })
  }
}

function getOrderByOrderId(orderId: number): Order | undefined {
  return orders.value.find(order => order.Id === orderId)
}

/**
 * @description update the order properties without reloading the get list of orders,
 * if needed we can use the response from server and replace the values received from response
 * @param {Order} order current order on which user clicked and
 * @param {IUpdateOrderPayload} updateOrderPayload properties to update
 */
function updateOrderInOrdersList(order: Order, updateOrderPayload: IUpdateOrderPayload | Partial<IOrderModel> | Partial<Order>) {
  // order is reactive and updating its property should update in the list as well unless cloned
  Order.updateOrderProperties(order, updateOrderPayload)

  // if (utils.isDefined(order) && utils.isDefined(updateOrderPayload)) {
  //   const orderIndex = orders.value.findIndex(listOrder => listOrder.Id === order.Id)
  //   if (orderIndex !== -1) {
  //     const orderInList = orders.value[orderIndex]
  //     for (const key in updateOrderPayload) {
  //       orderInList[key] = updateOrderPayload[key]
  //     }
  //   }
  // }
}

function closeOrderline() {
  // add newly added order to orders list if user saved the order
  if (ordersStore.currentOrder != null && ordersStore.currentOrder.Id != null && ordersStore.currentOrder.Id !== -1) {
    addNewlyCreatedOrderToOrdersList(ordersStore.currentOrder)
  }
  doCloseOrderlinesDrawer()
}

function isOrderEditable(order: Order, checkProcessStatus = true) {
  return order.Status && order.CreatedBy === userStore.userProfile.Id && indexedLocations.hasOwnProperty(order.LocationId) && indexedLocations[order.LocationId].Status
    && (!checkProcessStatus || order.OrderProcessStatusId == null || !ordersConstants.noneEditableProcessStatuses.includes(order.OrderProcessStatusId))
}

function showCreateOrder() {
  showActionDrawer.value = true
  currentAction.value = actionsEnum.create
}

function addOrder(newOrder: Order) {
  newOrder.IsOrderlinesDirty = true
  // orders.value.push(newOrder)
  closeActionsDrawer()
  showOrderlines(newOrder)
}

function closeSendMultipleOrdersDrawer() {
  showSendMultipleOrdersDrawer.value = false
}

function onSendMultipleOrdersFailed(error: Error) {
  closeSendMultipleOrdersDrawer()
  showAlertDialog.value = true
  if (error.message && error.message.toString().toLowerCase() === 'network error') {
    alertDialogTitle.value = t('orders.sendOrder.failedAlerts.title')
    alertDialogMessage.value = t('orders.sendOrder.failedAlerts.noInternetMessage')
  }
  else {
    alertDialogTitle.value = t('orders.sendOrder.failedAlerts.title')
    alertDialogMessage.value = t('orders.sendOrder.failedAlerts.unexpectedErrorMessage')
  }
}

async function doReopenOrders(orderList: Array<Order>) {
  if (orderList.length) {
    try {
      await reopenOrders(userStore.activeCatalog!.CatalogCode, orderList.map(order => order.Id!))
      orderList.forEach((order) => {
        order.OrderProcessStatusId = ordersConstants.orderProcessStatus.reopened
        order.OrderProcessStatus = t('general.reopened')
        order.UpdatedDate = new Date().toISOString()
      })
      notificationsStore.addNotification({ message: t('orders.reopenOrders.success'), type: 'Success' })
    }
    catch (error) {
      console.error(error)
      notificationsStore.addNotification({ message: t('orders.reopenOrders.failed'), type: 'Alert' })
    }
  }
}

function removeOrders(orderList: Array<Order>) {
  const promise = new Promise((resolve, reject) => {
    deferredPromptDialogClosureResolve = resolve
    deferredPromptDialogClosureReject = reject
  })
  if (orderList.length) {
    promptDialogTitle.value = t('orders.deleteOrders.promptDialogTitle')
    promptDialogDescription.value = t('orders.deleteOrders.promptDialogDescription')
    showPromptDialog.value = true
    promptAction = 'removeOrders'
  }

  promise
    .catch((error) => {
      console.error(error)
    })
    .finally(() => {
      resetPromptDialogValues()
    })
}

async function doRemoveOrders(orderList: Array<Order>) {
  try {
    await deleteOrders(userStore.activeCatalog!.CatalogCode, orderList.map(selectedOrder => selectedOrder.Id!))
    orderList.forEach((selectedOrder) => {
      const index = orders.value.findIndex(order => order.Id === selectedOrder.Id)
      if (index !== -1) {
        orders.value.splice(index, 1)
      }
    })
    selectedOrders.value = []
    notificationsStore.addNotification({ message: t('orders.deleteOrders.success'), type: 'Success' })
  }
  catch (error) {
    console.error(error)
    notificationsStore.addNotification({ message: t('orders.deleteOrders.failed'), type: 'Alert' })
  }
}

function showImportOrder() {
  showImportDrawer.value = true
  currentAction.value = actionsEnum.import
}

/**
 * @description: this method will check and add a synced order (order saved on server) to orders list that has been recently created and not part of order list
 * @param order
 */
function addNewlyCreatedOrderToOrdersList(order: Order) {
  if (!indexedOrders.value.hasOwnProperty(order.OrderReference)) {
    // in case of new orders add calculated values to the values displayed in orders table
    order.TotalQuantity = order.CalculatedTotalQuantity
    order.TotalCurrentValue = order.CalculatedTotalCurrentValue
    order.TotalInitialValue = order.CalculatedTotalInitialValue
    orders.value.push(order)
  }
}

function closeActionsDrawer() {
  showActionDrawer.value = false
}

function onActionDrawerClosed() {
  currentAction.value = 0
}
function onImportDrawerClosed() {
  currentAction.value = 0
}
function closeImportDrawer() {
  showImportDrawer.value = false
  if (route.name !== 'Orders') {
    router.push({ name: 'Orders' })
  }
}

async function promptDialogUserConfirm() {
  if (promptAction === 'removeOrders') {
    try {
      promptDialogLoading.value = true
      await doRemoveOrders(selectedOrders.value)
      deferredPromptDialogClosureResolve!()
    }
    finally {
      promptDialogLoading.value = false
    }
  }
}

function promptDialogUserCancel() {
  deferredPromptDialogClosureReject!(new Error('Operation canceled by user'))
}

function resetPromptDialogValues() {
  showPromptDialog.value = false
  promptDialogTitle.value = ''
  promptDialogDescription.value = ''
  promptAction = null
}

function onAlertDialogClosed() {
  alertDialogTitle.value = ''
  alertDialogMessage.value = ''
}

function resetProcessingOrdersReferenceList() {
  stopTrackingOrdersProcessStatus()
  orders.value.forEach((order) => {
    if (order.OrderProcessStatusId === ordersConstants.orderProcessStatus.submitting) {
      submittingOrderReferenceList.push(order.OrderReference)
    }
  })
  startTrackingOrdersProcessStatus()
}

function addOrderToSubmittingOrderReferenceList(orders: Array<Order>) {
  orders.forEach((order) => {
    if (!submittingOrderReferenceList.includes(order.OrderReference)) {
      submittingOrderReferenceList.push(order.OrderReference)
    }
  })
  if (!trackOrderProcessStatus) {
    startTrackingOrdersProcessStatus()
  }
}

function startTrackingOrdersProcessStatus() {
  if (submittingOrderReferenceList.length && !trackOrderProcessStatus) {
    trackOrderProcessStatus = true
    trackSubmittingOrders()
  }
}

function stopTrackingOrdersProcessStatus() {
  trackOrderProcessStatus = false
  submittingOrderReferenceList = []
}

async function trackSubmittingOrders() {
  if (trackOrderProcessStatus) {
    const startTime = new Date().getTime()
    if (submittingOrderReferenceList.length) {
      try {
        const response = await getProcessStatus(userStore.activeCatalog!.CatalogCode, submittingOrderReferenceList)
        if (!response.data.length) {
          stopTrackingOrdersProcessStatus()
          return
        }
        response.data.forEach((responseData) => {
          // if orders process status changes from submitting update it in the list
          if (indexedOrders.value.hasOwnProperty(responseData.OrderReference) && orders.value[indexedOrders.value[responseData.OrderReference] - 1].OrderProcessStatusId !== responseData.OrderProcessStatusId) {
            orders.value[indexedOrders.value[responseData.OrderReference] - 1].OrderProcessStatusId = responseData.OrderProcessStatusId
            orders.value[indexedOrders.value[responseData.OrderReference] - 1].OrderProcessStatus = responseData.OrderProcessStatus
            orders.value[indexedOrders.value[responseData.OrderReference] - 1].Id = responseData.OrderId // for the case when user creates a new order and send it directly, so the Id will not be added to order
          }
          // if order's process status is no more submitting remove it from the list
          if (responseData.OrderProcessStatusId !== ordersConstants.orderProcessStatus.submitting && submittingOrderReferenceList.includes(responseData.OrderReference)) {
            submittingOrderReferenceList.splice(submittingOrderReferenceList.indexOf(responseData.OrderReference), 1)
          }
        })

        const elapsedTime = new Date().getTime() - startTime
        if (elapsedTime > ordersProcessStatusTrackerIntervalTime) {
          clearTimeout(trackOrderProcessStatusTimeoutToken)
          trackSubmittingOrders()
        }
        else {
          clearTimeout(trackOrderProcessStatusTimeoutToken)
          trackOrderProcessStatusTimeoutToken = setTimeout(
            trackSubmittingOrders,
            ordersProcessStatusTrackerIntervalTime - elapsedTime,
          )
        }
      }
      catch (error: any) {
        if (error.message && error.message.toString().toLowerCase() !== 'network error') {
          console.warn(error)
          stopTrackingOrdersProcessStatus()
        }
      }
    }
    else {
      stopTrackingOrdersProcessStatus()
    }
  }
}

watch(() => route.params.orderId, (orderId) => {
  if (!utils.isDefined(orderId) || Number.isNaN(Number(orderId))) {
    doCloseOrderlinesDrawer()
  }
  else {
    doOpenOrderlinesDrawer(ordersStore.currentOrder!)
  }
})

watch(() => selectedOrders.value, (selectedOrders) => {
  if (selectedOrders.length) {
    allSelectedOrdersAreDraft.value = true
    allSelectedOrdersAreSubmitted.value = true
    allSelectedOrdersAreDraftOrReopened.value = true
    allSelectedOrdersEditableExcludingProcessStatus.value = true
  }
  else {
    allSelectedOrdersAreDraft.value = false
    allSelectedOrdersAreSubmitted.value = false
    allSelectedOrdersAreDraftOrReopened.value = false
    allSelectedOrdersEditableExcludingProcessStatus.value = false
  }
  selectedOrders.forEach((selectedOrder) => {
    if (selectedOrder.OrderProcessStatusId != null && selectedOrder.OrderProcessStatusId !== ordersConstants.orderProcessStatus.draft) {
      allSelectedOrdersAreDraft.value = false
    }
    if (selectedOrder.OrderProcessStatusId !== ordersConstants.orderProcessStatus.submitted) {
      allSelectedOrdersAreSubmitted.value = false
    }
    if (selectedOrder.OrderProcessStatusId != null && selectedOrder.OrderProcessStatusId !== ordersConstants.orderProcessStatus.draft
      && selectedOrder.OrderProcessStatusId !== ordersConstants.orderProcessStatus.reopened) {
      allSelectedOrdersAreDraftOrReopened.value = false
    }
    if (!isOrderEditable(selectedOrder, false)) {
      allSelectedOrdersEditableExcludingProcessStatus.value = false
    }
  })
})

watch(() => orders.value, (orders) => {
  sBar.setItemValue('ttlOrders', orders.length.toLocaleString())
}, { deep: true })

onMounted(() => {
  sBar.setItems([
    { id: 'ttlOrders', label: t('status.totalOrders'), value: '0', icon: '' },
  ])

  onAppEvent('catalogDataUpdated', init)
})

onUnmounted(() => {
  clearTimeout(trackOrderProcessStatusTimeoutToken)
  stopTrackingOrdersProcessStatus()
  offAppEvent('catalogDataUpdated', init)
})

init()

provide('ordersProvide', {
  addNewlyCreatedOrderToOrdersList,
  addOrderToSubmittingOrderReferenceList,
  updateOrderInOrdersList,
})
</script>
