<template>
  <div class="flex flex-row border rounded-md border-grey bg-grey-light">
    <div class="p-2.5 flex flex-col basis-1/2 overflow-x-hidden grow-0">
      <div class="pb-1 font-medium text-md">
        {{ availableItemsLabel }}
      </div>
      <tx-input v-model="filterAvailable" :placeholder="t('general.typeToFilter')" class="pb-2" clearable />
      <div class="p-1.25 overflow-y-auto flex flex-col h-full">
        <div
          v-for="(itm, index) in filteredAvailableItems" :id="itm[itemKeyProp]" :key="itm[itemKeyProp]" draggable="true"
          :class="{ 'opacity-50': draggedItem === itm }" class="p-1.25 shrink-0 box-border bg-transparent" @dragstart="e => onDragStart(e, itm)"
          @dragend="onDragEnd"
          @click.prevent="addToSelected(itm, selectedItems.length, false)"
        >
          <slot :name="`item-${index}`" :item="itm" :index="index">
            <slot name="item" :item="itm" :index="index">
              <div class="border-zinc-400 border rounded-md bg-zinc-300 p-1.25 text-sm text-center whitespace-nowrap text-ellipsis overflow-x-hidden text-wrap">
                <div>{{ itm[itemDisplayProp] }}</div>
              </div>
            </slot>
          </slot>
        </div>
      </div>
    </div>
    <div class="p-2.5 flex flex-col basis-1/2 overflow-x-hidden grow-0 border-l">
      <div class="pb-1 font-medium text-md">
        {{ selectedItemsLabel }}
      </div>
      <tx-input v-model="filterSelected" :placeholder="t('general.typeToFilter')" class="pb-2" clearable />
      <div class="p-1.25 overflow-y-auto flex flex-col h-full">
        <div
          v-for="(itm, index) in filteredSelectedItems" :id="itm[itemKeyProp]" :key="itm[itemKeyProp]" draggable="true"
          :class="{ 'opacity-50': draggedItem === itm }" class="p-1.25 shrink-0 box-border bg-transparent" @dragstart="e => onDragStart(e, itm)" @dragend="onDragEnd"
          @drop="onDrop" @dragover.prevent
          @click.prevent="removeFromSelected(itm)"
        >
          <div v-if="dragOverAbove && dragOverItem === itm" class="w-full h-1 mb-2 bg-primary" @dragover.prevent />
          <div @dragover.prevent="(e) => onDragOver(e, itm)">
            <slot :name="`item-${index}`" :item="itm" :index="index">
              <slot name="item" :item="itm" :index="index">
                <div
                  :class="{ 'bg-zinc-200': itm.disabled, 'bg-zinc-300': !itm.disabled }"
                  class="border-zinc-400 border rounded-md p-1.25 text-sm text-center whitespace-nowrap text-ellipsis overflow-x-hidden text-wrap"
                >
                  {{ itm[itemDisplayProp] }}
                </div>
              </slot>
            </slot>
          </div>
          <div v-if="!dragOverAbove && dragOverItem === itm" class="w-full h-1 mt-2 bg-primary" @dragover.prevent />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import TxInput from '../TxInput.vue'

const props = defineProps<{
  items: any[]
  selectedItems: any[]
  itemDisplayProp: string
  itemKeyProp: string
  availableItemsLabel: string
  selectedItemsLabel: string
  sortAvailable?: boolean
}>()

const emits = defineEmits<{
  (e: 'update:selectedItems', val: any[])
}>()

const { t } = useI18n()
const filterAvailable = ref('')
const filterSelected = ref('')
const draggedItem = ref<any>()
const dragOverItem = ref<any>()
const dragOverAbove = ref(false)

const sortedItems = computed(() => {
  if (!props.sortAvailable) { return props.items }
  return [...props.items].sort((a, b) => a[props.itemDisplayProp]?.localeCompare(b[props.itemDisplayProp]))
})

const availableItems = computed(() => {
  return sortedItems.value.filter(itm => !props.selectedItems.find(f => f[props.itemDisplayProp] === itm[props.itemDisplayProp]))
})

const filteredAvailableItems = computed(() => {
  return availableItems.value.filter(itm => filterAvailable.value.length === 0 || itm[props.itemDisplayProp].toLowerCase().includes(filterAvailable.value.toLowerCase()))
})

const filteredSelectedItems = computed(() => {
  return props.selectedItems.filter(itm => filterSelected.value.length === 0 || itm[props.itemDisplayProp].toLowerCase().includes(filterSelected.value.toLowerCase()))
})

function onDragStart(e: DragEvent, itm: any) {
  setTimeout(() => {
    draggedItem.value = itm
  }, 0)
}

function onDragEnd() {
  draggedItem.value = null
  dragOverAbove.value = false
  dragOverItem.value = undefined
}

function onDragOver(e: DragEvent, itm: any) {
  const target = e.target as HTMLElement
  if (!e.screenX && !e.screenY) { return }
  if (e.offsetY < 0) { return }

  dragOverAbove.value = e.offsetY < target.clientHeight / 2
  dragOverItem.value = itm
}

function onDrop() {
  if (!dragOverItem.value || !draggedItem.value) { return }
  const elementIndex = props.selectedItems.findIndex(itm => itm === dragOverItem.value)
  if (elementIndex < 0) { return }
  addToSelected(draggedItem.value, elementIndex, dragOverAbove.value)
}

function addToSelected(itm: any, position: number, above: boolean) {
  if (!itm) { return }

  // Check if item already exists
  const currentIndex = props.selectedItems.findIndex(f => f[props.itemDisplayProp] === itm[props.itemDisplayProp])
  const res = [...props.selectedItems]
  // If it exists, remove it
  if (currentIndex >= 0) { res.splice(currentIndex, 1) }
  // Add the item in the correct position
  res.splice(above ? position : position + 1, 0, itm)
  emits('update:selectedItems', res)
}

function removeFromSelected(itm: any) {
  if (!itm) { return }
  if (itm.disabled) { return }
  const currentIndex = props.selectedItems.findIndex(f => f[props.itemDisplayProp] === itm[props.itemDisplayProp])
  const res = [...props.selectedItems]
  if (currentIndex >= 0) { res.splice(currentIndex, 1) }
  emits('update:selectedItems', res)
}
</script>
