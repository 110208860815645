<template>
  <div class="flex flex-wrap items-center p-2">
    <div
      v-for="favorite in article._FavoriteTags" :key="favorite.Tag" class="px-2 py-0.5 mr-1 text-white border rounded-xl"
      :style="{ backgroundColor: favorite.Color }"
      v-text="favorite.Tag"
    />
    <div class="actions">
      <icon-button v-if="allowEditFavorite" faicon="fa-light fa-plus" :width="80" type="inline" :label="t('general.tags')" @click="onEditFav" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import IconButton from '@/shared/components/IconButton.vue'
import type MyArticle from '@/models/myArticle'
import { privileges } from '@/models/constants'
import { useUserStore } from '@/store/userData'

const props = defineProps<{
  article: MyArticle
}>()

const emit = defineEmits<{
  (e: 'onEditFav'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()

const allowEditFavorite = computed(() => {
  let isValid = userStore.userProfile.isValidPrivilege(privileges.favorite.createUpdate)
  if (isValid && userStore.activeCatalog) {
    isValid = props.article.CatalogCode === userStore.activeCatalog.CatalogCode
  }
  return isValid
})

function onEditFav() {
  emit('onEditFav')
}
</script>
