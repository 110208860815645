import type { ArticleDropReasonModel } from '@/api/t1/model/articleModel'

export default class DropReason {
  SellerAccountId: number
  Id: number
  Reason: string
  Status: number

  constructor(SellerAccountId: number, dropReason: ArticleDropReasonModel) {
    this.SellerAccountId = SellerAccountId
    this.Id = dropReason.Id
    this.Reason = dropReason.Reason
    this.Status = dropReason.Status
  }
}
