import { defineStore } from 'pinia'
import type Order from '@/models/order'

export const useOrdersStore = defineStore({
  id: 'orders',
  state: () => ({
    currentOrder: null as Order | null,
  }),
  actions: {
    setCurrentOrder(value: Order | null) {
      this.currentOrder = value
    },
  },
})
