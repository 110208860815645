<template>
  <auth-layout>
    <div class="w-80">
      <div class="w-full text-xl text-center" v-text="t('setPassword.title')" />
      <div class="w-full mt-2 text-sm" v-text="t('setPassword.heading')" />

      <tx-alert class="mt-4" :show="hasError" type="error" :text="errorMessage" dismissible />

      <div class="w-full h-56 mt-10">
        <div class="w-full">
          <tx-input v-model="secretCode" :label="t('setPassword.secretCode')" :readonly="loading" required autofocus />
        </div>

        <div class="w-full mt-4">
          <tx-input v-model="password" type="password" :label="t('general.password')" :readonly="loading" required />
        </div>
      </div>

      <div class="w-full text-right">
        <tx-button class="float-left" type="cancel" width="100px" :disabled="loading" :text="t('general.cancel')" @click="onCancel" />
        <tx-button
          type="confirm" width="180px" height="40px" :disabled="!password || !secretCode" :loading="loading"
          :text="t('setPassword.title')" @click="onSetPassword"
        />
      </div>
    </div>
  </auth-layout>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { ref } from 'vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import Auth from '@/services/auth'
import type { ApiResponse } from '@/models/ApiResponse'
import useErrorMessage from '@/shared/composables/errorMessage'

interface IProps {
  userName?: string
}
const props = withDefaults(defineProps<IProps>(), { userName: '' })

const router = useRouter()
const route = useRoute()
const { t, te } = useI18n()
const { errorMessage, hasError } = useErrorMessage()

const loading = ref(false)
const secretCode = ref('')
const password = ref('')

function setErrorMessage(res: ApiResponse) {
  let key = 'setPassword.errors.unexpectedError'
  if (res.error) {
    if (res.error.errorCode && te(`setPassword.errors.${res.error.errorCode}`)) {
      key = `setPassword.errors.${res.error.errorCode}`
    }
    else if (res.error.status && te(`setPassword.errors.${res.error.status}`)) {
      key = `setPassword.errors.${res.error.status}`
    }
  }
  errorMessage.value = t(key)
}

function goToLogin() {
  router.replace({ name: 'Login' })
}

function onCancel() {
  goToLogin()
}

function getUsername() {
  return props.userName
    ? props.userName
    : route.query && route.query.userName ? decodeURIComponent(route.query.userName.toString()) : ''
}

async function onSetPassword() {
  loading.value = true
  const validUsername = getUsername()
  if (validUsername.length > 0 && secretCode.value.length > 0 && password.value.length > 0) {
    try {
      errorMessage.value = ''
      const res = await Auth.forgotcomplete(validUsername, secretCode.value, password.value)
      if (res.status && res.data) {
        goToLogin()
      }
      else {
        setErrorMessage(res)
      }
    }
    catch (e) {
      console.error(e)
    }
  }
  loading.value = false
}
</script>
