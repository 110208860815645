import { clone, isFunction } from 'lodash-es'
import type { IField } from '../type'
import { getArticleStates, getAssignedCatalogModels } from '@/api/t1/article'
import { getCatalogColors, getCatalogShipmentWindows } from '@/api/t1/catalog'
import type { ArticleStateModel, AssignedModelListItem } from '@/api/t1/model/articleModel'
import type { ICatalogShipmentWindowRange, SellerColorModel } from '@/api/t1/model/catalog'
import type { MasterSizeScaleModel, SizeScaleModel } from '@/api/t1/model/sizeModel'
import { getCatalogSizeScales, getMasterSizeScales } from '@/api/t1/size'
import type Article from '@/models/article'
import type CatalogDetails from '@/models/catalogDetails'
import appConfig from '@/services/appConfig'
import utils from '@/services/utils'
import type DropReason from '@/models/dropReason'
import { AttributeType } from '@/models/catalogAttribute'
import type { ITxDataTableColumn } from '@/shared/components/txDataTable/TxDataTable.types'

interface MasterData {
  catalogModels: Record<number, AssignedModelListItem> | null
  sizeScales: Record<number, SizeScaleModel> | null
  masterSizeScales: Record<number, MasterSizeScaleModel> | null
  retailWindows: Record<number, ICatalogShipmentWindowRange> | null
  articleStates: Record<number, ArticleStateModel> | null
  colors: Record<number, SellerColorModel> | null
  articles: Record<number, Article> | null
  sellerModels: Record<number, Article> | null
  linkedCatalogArticles: Record<number, Article> | null
  dropReasons: Record<number, DropReason> | null
}

export default function useMasterDataLookup() {
  const lookupData: MasterData = {
    catalogModels: null,
    sizeScales: null,
    masterSizeScales: null,
    retailWindows: null,
    articleStates: null,
    colors: null,
    articles: null,
    sellerModels: null,
    linkedCatalogArticles: null,
    dropReasons: null,
  }
  // TODO: Need to merge both the functions loadLookupForTable and loadLookup as both are doing same
  async function loadLookupForTable(fields: ITxDataTableColumn[], activeCatalog: CatalogDetails, reload = false) {
    const dataMap = {}
    const lookupSourceMap = {}
    const lookupFields: ITxDataTableColumn[] = []
    fields.forEach((field) => {
      if ((field.type === AttributeType.Lookup
        || field.type === AttributeType.LookupMany
        || field.type === AttributeType.ModelNumber
        || field.type === AttributeType.ColorPalette
      )
      && (utils.isDefined(field.lookupSource) && isFunction(field.lookupSource))) {
        lookupFields.push(field)
        if (lookupSourceMap[field.lookupSource.toString()]) {
          lookupSourceMap[field.lookupSource.toString()].fields.push(field.property)
        }
        else {
          lookupSourceMap[field.lookupSource.toString()] = { fields: [field.property], action: field.lookupSource }
        }
      }
    })

    const lookupPromises = Object.keys(lookupSourceMap).map(lookupSourceString =>
      lookupSourceMap[lookupSourceString].action(activeCatalog, reload).then((data) => {
        lookupSourceMap[lookupSourceString].fields.forEach((field) => {
          dataMap[field] = data
        })
      }),
    )
    // promises to resolve
    // check with saad when loading what needed to be done in UI
    // check the performance when testing if any lag we can show a loading
    return await Promise.all(lookupPromises).then(() => {
      console.log('All lookup data loaded:')
      return dataMap
    }).catch((error) => {
      console.error('Error loading lookup data:', error)
      return dataMap
    })
  }
  async function loadLookup(fields: IField[], activeCatalog: CatalogDetails, reload = false) {
    const dataMap = {}
    const lookupSourceMap = {}
    const lookupFields: IField[] = []
    fields.forEach((field) => {
      if ((field.AttributeType === AttributeType.Lookup
        || field.AttributeType === AttributeType.LookupMany
        || field.AttributeType === AttributeType.ModelNumber
        || field.AttributeType === AttributeType.ColorPalette
      )
      && (utils.isDefined(field.lookupSource) && isFunction(field.lookupSource))) {
        lookupFields.push(field)
        if (lookupSourceMap[field.lookupSource.toString()]) {
          lookupSourceMap[field.lookupSource.toString()].fields.push(field.SystemName)
        }
        else {
          lookupSourceMap[field.lookupSource.toString()] = { fields: [field.SystemName], action: field.lookupSource }
        }
      }
    })

    const lookupPromises = Object.keys(lookupSourceMap).map(lookupSourceString =>
      lookupSourceMap[lookupSourceString].action(activeCatalog, reload).then((data) => {
        lookupSourceMap[lookupSourceString].fields.forEach((field) => {
          dataMap[field] = data
        })
      }),
    )
    // promises to resolve
    // check with saad when loading what needed to be done in UI
    // check the performance when testing if any lag we can show a loading
    return await Promise.all(lookupPromises).then(() => {
      console.log('All lookup data loaded:')
      return dataMap
    }).catch((error) => {
      console.error('Error loading lookup data:', error)
      return dataMap
    })
  }
  async function getModelsLookup(catalogDetails: CatalogDetails, reload = false) {
    if (!utils.isDefined(lookupData.catalogModels) || reload) {
      const response = await getAssignedCatalogModels(catalogDetails.CatalogCode)
      lookupData.catalogModels = utils.arrayToNumberDictionary(response.data, 'ModelId')
    }

    return lookupData.catalogModels
  }

  async function getSizeScalesLookup(catalogDetails: CatalogDetails, reload = false) {
    if (!utils.isDefined(lookupData.sizeScales) || reload) {
      const response = await getCatalogSizeScales(catalogDetails.CatalogCode)
      lookupData.sizeScales = utils.arrayToNumberDictionary(response.data, 'Id')
    }
    return lookupData.sizeScales
  }

  async function getMasterSizeScalesLookup(catalogDetails: CatalogDetails, reload = false) {
    if (!utils.isDefined(lookupData.masterSizeScales) || reload) {
      const data = await getMasterSizeScales(catalogDetails.AccountId)
      lookupData.masterSizeScales = utils.arrayToNumberDictionary(data, 'Id')
    }
    return lookupData.masterSizeScales
  }

  async function getRetailWindowsLookup(catalogDetails: CatalogDetails, reload = false) {
    // TODO implement parent article filter for child catalog when implementing Edit article action
    // for import its not needed.
    if (!utils.isDefined(lookupData.retailWindows) || reload) {
      const response = await getCatalogShipmentWindows(catalogDetails.CatalogCode)
      lookupData.retailWindows = utils.arrayToNumberDictionary(response.data, 'Id')
    }
    return lookupData.retailWindows
  }

  async function getArticleStatesLookup(catalogDetails: CatalogDetails, reload = false) {
    if (!utils.isDefined(lookupData.articleStates) || reload) {
      const data = await getArticleStates(catalogDetails.AccountId, catalogDetails.AttributeGroupId)
      lookupData.articleStates = utils.arrayToNumberDictionary(data, 'StateId')
    }
    return lookupData.articleStates
  }

  async function getColorsLookup(catalogDetails: CatalogDetails, reload = false) {
    if (!utils.isDefined(lookupData.colors) || reload) {
      const response = await getCatalogColors(catalogDetails.CatalogCode)
      const uniqueColors: SellerColorModel[] = []
      response.data.forEach((sellerColor) => {
        if (sellerColor.Status) { // consider active palette only
          const colorCriteria = sellerColor.ColorCriteria

          for (let j = 0; j < sellerColor.Colors.length; j++) {
            const color = clone(sellerColor.Colors[j])
            color.ColorCriteria = colorCriteria

            if (!uniqueColors.some((existingColor) => { return existingColor.ColorId === color.ColorId })) {
              uniqueColors.push(color)
            }
          }
        }
      })
      lookupData.colors = utils.arrayToNumberDictionary(uniqueColors, 'ColorName')
    }
    return lookupData.colors
  }

  async function getArticlesLookup(catalogDetails: CatalogDetails, reload = false) {
    if (!utils.isDefined(lookupData.articles) || reload) {
      const data = await appConfig.DB!.getAdminArticles(catalogDetails.CatalogCode)
      lookupData.articles = utils.arrayToNumberDictionary(data, 'Id')
    }
    return lookupData.articles
  }

  async function getLinkedCatalogArticleNumberAttributesLookup(catalogDetails: CatalogDetails, reload = false) {
    if (!utils.isDefined(lookupData.linkedCatalogArticles) || reload) {
      if (utils.isDefined(catalogDetails.LinkedCatalogList) && catalogDetails.LinkedCatalogList.length) {
        const articlePromises = catalogDetails.LinkedCatalogList.map(catalog =>
          appConfig.DB!.getAdminArticles(catalog.CatalogCode),
        )
        const linkedCatalogArticlesArray = await Promise.all(articlePromises)
        lookupData.linkedCatalogArticles = utils.arrayToNumberDictionary(linkedCatalogArticlesArray.flat(), 'Id')
      }
      else {
        lookupData.linkedCatalogArticles = {}
      }
    }
    return lookupData.linkedCatalogArticles
  }

  async function getSellerModelsLookup(catalogDetails: CatalogDetails, reload = false) {
    if (!utils.isDefined(lookupData.sellerModels) || reload) {
      const data = await appConfig.DB!.articles.where('CatalogCode').equals(catalogDetails.CatalogCode).toArray()
      const uniqueModelNumbers = new Set<string>()
      const result: Article[] = []
      data.forEach((article) => {
        if (!uniqueModelNumbers.has(article.ModelNumber)) {
          result.push(article)
          uniqueModelNumbers.add(article.ModelNumber)
        }
      })
      lookupData.sellerModels = utils.arrayToNumberDictionary(result, 'ModelId')
    }
    return lookupData.sellerModels
  }

  async function getDropReasons(catalogDetails: CatalogDetails, reload = false) {
    if (!utils.isDefined(lookupData.dropReasons) || reload) {
      const data = await appConfig.DB!.getArticleDropReasons(catalogDetails.AccountId, 1)
      lookupData.dropReasons = utils.arrayToNumberDictionary(data, 'Id')
    }
    return lookupData.dropReasons
  }

  return {
    loadLookup,
    loadLookupForTable,
    getModelsLookup,
    getSizeScalesLookup,
    getMasterSizeScalesLookup,
    getRetailWindowsLookup,
    getArticleStatesLookup,
    getColorsLookup,
    getArticlesLookup,
    getLinkedCatalogArticleNumberAttributesLookup,
    getSellerModelsLookup,
    getDropReasons,
  }
}
