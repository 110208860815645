export default class CatalogConfiguration {
  ConfigId: number
  Id: number
  Name: string
  Value: string

  constructor({ ConfigId, Id, Name, Value }: CatalogConfiguration) {
    this.ConfigId = ConfigId
    this.Id = Id
    this.Name = Name
    this.Value = Value
  }
}
