import { ApplicationInsights } from '@microsoft/applicationinsights-web'

async function fetchAppConfig() {
  const response = await fetch('/config.json')
  if (!response.ok) {
    throw new Error('Failed to fetch config.json')
  }
  return response.json()
}

async function initializeAppInsights() {
  try {
    const appConfig = await fetchAppConfig()

    if (appConfig.ConnectionString && appConfig.ConnectionString.trim().length) {
      const appInsights = new ApplicationInsights({
        config: {
          connectionString: appConfig.ConnectionString,
        },
      })

      appInsights.loadAppInsights()
      appInsights.trackPageView()

      return appInsights
    }
    else {
      console.log('No connection string provided. Skipping Application Insights initialization.')
      return null
    }
  }
  catch (error) {
    console.error('Error initializing Application Insights', error)
  }
}

const appInsightsInstancePromise = initializeAppInsights()
export default appInsightsInstancePromise
