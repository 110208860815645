export default class Allocation {
  CatalogCode: number
  Id: number
  AllocationGroupName: string
  Status: number
  Articles: number[]

  constructor(CatalogCode: number, allocation: any) {
    this.CatalogCode = CatalogCode
    this.Id = allocation.Id
    this.AllocationGroupName = allocation.AllocationGroupName
    this.Status = allocation.Status
    this.Articles = allocation.Articles.filter(a => a.Status > 0).map((a: any) => a.ArticleId || a.Id)
  }
}
