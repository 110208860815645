import { isEqual, isObject, uniqBy } from 'lodash-es'
import type { Ref } from 'vue'
import type Article from '@/models/article'
import type MyArticle from '@/models/myArticle'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import { useUserStore } from '@/store/userData'
import type { ArticleStateModel } from '@/api/t1/model/articleModel'
import appConfig from '@/services/appConfig'

// this compossible is a helper and must not contains any hook, watch, computed, etc, it should only contains methods which takes some arguments and return a value or perform a side effect
export function useArticleFormHelper() {
  const userStore = useUserStore()
  const skipRestrictingPropertyUpdateBasedOnArticleState: boolean = userStore.userProfile.Permissions.has('SpecialPermissionToIgnoreArticleState')

  async function doesModelBelongsToActiveCatalog(modelIds: number[]) {
    const modelsExistInActiveCatalog = {}
    const queryCriterion: Array<[number, number]> = [[userStore.activeCatalog!.CatalogCode, modelIds[0]]]
    modelsExistInActiveCatalog[modelIds[0]] = false
    for (let i = 1; i < modelIds.length; i++) {
      queryCriterion.push([+userStore.activeCatalog!.CatalogCode, modelIds[i]])
      modelsExistInActiveCatalog[modelIds[i]] = false
    }
    const res = await appConfig.DB!.getArticlesOrMyArticlesByCatalogCodeModelId(userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, queryCriterion)
    res.forEach((article) => {
      modelsExistInActiveCatalog[article.ModelId] = true
    })
    return modelsExistInActiveCatalog
  }

  function getArticlesMaxStateDetails(articles: Array<MyArticle | Article>, considerModelStateRank = true): ArticleStateModel | undefined {
    if (articles.length) {
      const articleMaxStateRank = getArticlesMaxStateRank(articles, considerModelStateRank)
      return userStore.articleStateList.find(articleState => articleState.StateRank === articleMaxStateRank) as ArticleStateModel
    }
    else {
      return undefined
    }
  }

  function getArticlesMaxStateRank(articles: Array<MyArticle | Article>, considerModelStateRank = true) {
    let maxStateRank = 0
    if (considerModelStateRank) {
      articles.forEach((article) => {
        maxStateRank = Math.max(article.StateRank || 0, article.ModelStateRank || 0, maxStateRank)
      })
    }
    else {
      articles.forEach((article) => {
        maxStateRank = Math.max(article.StateRank || 0, maxStateRank)
      })
    }
    return maxStateRank
  }
  async function getArticlesLocked(articles: Array<MyArticle | Article>, considerModel = false) {
    let locked = false
    if (userStore.activeCatalog?.ArticleLocking === 1) {
      const lockedArticle = articles.find(article => article.Locked === 1)
      if (utils.isDefined(lockedArticle)) {
        locked = true
      }
      else {
        if (considerModel) {
          const articleUniqueByModelId = uniqBy(articles, 'ModelId')
          const queryCriterion: Array<[number, number]> = [[userStore.activeCatalog!.CatalogCode, articleUniqueByModelId[0].ModelId]]
          for (let i = 1; i < articleUniqueByModelId.length; i++) {
            queryCriterion.push([+userStore.activeCatalog!.CatalogCode, articleUniqueByModelId[i].ModelId])
          }
          const articlesData = await appConfig.DB!.getArticlesOrMyArticlesByCatalogCodeModelId(userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, queryCriterion)
          for (let i = 0; i < articlesData.length; i++) {
            if (articlesData[i].Locked === 1) {
              locked = true
              break
            }
          }
        }
      }
    }
    return locked
  }

  function getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank(articles: Array<MyArticle | Article>): Record<string, {
    AllowChangeRequest: number
    SystemName: string
    DisplayName: string
    AttributeId: number
  }> {
    let restrictedAttributes: Array<{
      AllowChangeRequest: number
      AttributeDisplayName: string
      AttributeSystemName: string
      AttributeId: number
    }> = []
    // if user has special permission assigned to his role then skip restriction
    if (!skipRestrictingPropertyUpdateBasedOnArticleState) {
      const maxStateRank = getArticlesMaxStateDetails(articles, true)
      if (maxStateRank) {
        restrictedAttributes = maxStateRank.NonEditableAttributes
        if (!maxStateRank.IsModelNameEditable) {
          restrictedAttributes.push({
            AllowChangeRequest: maxStateRank.AllowChangeRequestForModelName,
            AttributeSystemName: 'ModelName',
            AttributeDisplayName: 'Mode lName',
            AttributeId: -1,
          })
          restrictedAttributes.push({
            AllowChangeRequest: maxStateRank.AllowChangeRequestForModelName,
            AttributeSystemName: 'ArticleName',
            AttributeDisplayName: 'Article lName',
            AttributeId: -2,
          })
        }
      }
    }
    return restrictedAttributes.reduce((acu, cur) => (acu[cur.AttributeSystemName] = {
      SystemName: cur.AttributeSystemName,
      DisplayName: cur.AttributeDisplayName,
      AllowChangeRequest: cur.AllowChangeRequest,
      AttributeId: cur.AttributeId,
    }) && acu, {})
  }
  function getIndexedRestrictedAttributesBasedOnArticlesStateRank(articles: Array<MyArticle | Article>): Record<string, {
    AllowChangeRequest: number
    SystemName: string
    DisplayName: string
    AttributeId: number
  }> {
    let restrictedAttributes: Array<{
      AllowChangeRequest: number
      AttributeDisplayName: string
      AttributeSystemName: string
      AttributeId: number
    }> = []
    // if user has special permission assigned to his role then skip restriction
    if (!skipRestrictingPropertyUpdateBasedOnArticleState) {
      const stateRank = getArticlesMaxStateDetails(articles, false)
      if (stateRank) {
        restrictedAttributes = stateRank.NonEditableAttributes
      }
    }
    return restrictedAttributes.reduce((acu, cur) => (acu[cur.AttributeSystemName] = {
      SystemName: cur.AttributeSystemName,
      DisplayName: cur.AttributeDisplayName,
      AllowChangeRequest: cur.AllowChangeRequest,
      AttributeId: cur.AttributeId,
    }) && acu, {})
  }
  /**
   * @description this function can be use to normalize/transform attribute value where forms could generate form value other than expected based on attribute type
   * @param {any} value attribute value
   * @param {IMyAttribute} attribute
   * @returns normalized attribute value
   */
  function normalizeAttributeValue(value: any, attribute: IMyAttribute) {
    // if bool convert it to string value as required by API
    if (attribute.SystemName === 'ColorId') {
      if (utils.isDefined(value) && typeof value === 'string') {
        const parsedValue = utils.tryParse(value)
        if (parsedValue) {
          const keys = Object.keys(parsedValue)
          if (keys.length) {
            value = keys[0]
          }
        }
      }
    }
    else if (attribute.AttributeType === AttributeType.Bool) {
      if (value == null) {
        value = 'false'
      }
      value = value && value !== 'false' && value !== '0' ? 'true' : 'false'
    }
    else if (attribute.AttributeType === AttributeType.Int || attribute.AttributeType === AttributeType.Decimal) {
      value = utils.isValidStringValue(value) ? value : null
    }
    else if (attribute.AttributeType === AttributeType.MultiValue) { // if multivalue convert the value to array
      if (value == null) {
        value = ['']
      }
      if (!Array.isArray(value)) {
        value = value.split(/\r?\n/).reduce((acu, cur) => {
          if (cur != null && cur.toString().trim() !== '') {
            acu.push(cur)
          }
          return acu
        }, [])
      }
    }
    return value
  }

  /**
   * @description this method should be use to validate and assign attribute value to form input
   * this method will assign default value for certain attribute type when input value is undefined,
   * it will also validate the attributes with vetting list to see if the value to prefill matches
   * any of the value in vetting list (case insensitive comparison)
   * @param {Record<string, any>}formModel form model object
   * @param {any}attributeValue value to prefill
   * @param {IMyAttribute} attribute
   */
  function prefillAttributeValue(formModel: Record<string, any>, attributeValue: any, attribute: IMyAttribute) {
    attributeValue = normalizeAttributeValue(attributeValue, attribute)
    if (utils.isDefined(attributeValue)) { // if attribute value is defined
      if (utils.isDefined(attribute.VettingList) && attribute.VettingList.length) { // if attribute has vetting list
        const attributeValueLower = attributeValue.toString().trim().toLowerCase()
        const value = attribute.VettingList.find(allowedValue => allowedValue.toLowerCase() === attributeValueLower)
        if (utils.isDefined(value)) { // if attribute value matches a value from vetting list
          attributeValue = value // assigned matched data from VettingList
          if (attribute.AttributeType !== AttributeType.MultiValue) { // if attribute is multivalue type
            formModel[attribute.SystemName] = attributeValue
          }
          else { // if attribute is not multivalue type
            if (!formModel.hasOwnProperty(attribute.SystemName) || !Array.isArray(formModel[attribute.SystemName])) { // if value of multivalue is not defined or not an array
              formModel[attribute.SystemName] = []
            }
            formModel[attribute.SystemName].push(attributeValue)
          }
        }
      }
      else if (attribute.AttributeType === AttributeType.MultiValue && attributeValue != null && attributeValue.toString.length) { // if attribute is not vetting list and it is a multivalue
        // convert multivalue attributes without vetting list value to newline separated value
        const multivalueAttributeValue = (attributeValue as Array<string | number>).join('\n')
        formModel[attribute.SystemName] = multivalueAttributeValue
      }
      else {
        formModel[attribute.SystemName] = attributeValue
      }
    }
    return formModel[attribute.SystemName]
  }

  function validateAndRemoveInvalidAttributeValues(formAttributes: Ref<IMyAttribute[]>, modelObject: Record<string, any>, dataForValidation: Record<string, any>, attributeSystemName: string = '') {
    updateCriteriaAttributeAllowedValues(formAttributes, modelObject, dataForValidation)
    updateLookupAttributeValues(formAttributes, modelObject, dataForValidation)
    if (attributeSystemName !== 'SizeScaleId') {
      const masterSizeScaleAttribute = formAttributes.value.find(attribute => attribute.SystemName === 'MasterSizeScaleId')
      const sizeScaleAttribute = formAttributes.value.find(attribute => attribute.SystemName === 'SizeScaleId')
      const sizesAttribute = formAttributes.value.find(attribute => attribute.SystemName === 'Sizes')
      // if sizes scale is creatable and and form contains value for size scale validate it against the form values
      if (masterSizeScaleAttribute != null && masterSizeScaleAttribute.Creatable && modelObject.hasOwnProperty(masterSizeScaleAttribute.SystemName) && modelObject[masterSizeScaleAttribute.SystemName] != null) {
        removeInvalidAssignedSizeScale(modelObject, masterSizeScaleAttribute, sizeScaleAttribute, dataForValidation, sizesAttribute)
      }
    }
  }

  /**
   * @description this method will be called on each form input update and do the followings
   * update all the criteria type vetting list based on model object data
   * invalidate (remove) the criteria vetting list attribute value if assigned value does not matched current criteria vetting list
   * if form contains sizeScale and sizeScale is Creatable it will validate the assigned sizeScale based on form value and remove the value in case it is invalid based on form values
   *
   * NOTE: THIS METHOD WILL MUTATE formAttributes and modelObject
   *
   * @param {IMyAttribute[]} formAttributes list of form attributes
   * @param {Record<string, any>} modelObject form's model object
   * @param {Record<string, any>} dataForValidation it should contains all the data in modelObject and possible if there are additional data on article which might not be part of form
   */
  function updateCriteriaAttributeAllowedValues(formAttributes: Ref<IMyAttribute[]>, modelObject: Record<string, any>, dataForValidation: Record<string, any>) {
    formAttributes.value.forEach((attribute) => {
      const criteriaKeys = Object.keys(attribute.Criteria || {}) // denotes possible values
      if (criteriaKeys.length > 0) { // is vetting list attribute with criteria applied
        let validVettingListValues: string[] = []
        let useVettingListAttributePreDefinedAllowedValues = true

        criteriaKeys.forEach((vettingListValue) => {
          let isValidVettingListValue = true
          // criteria for each possible vetting list attribute value
          const vettingListValueCriteria = attribute.Criteria[vettingListValue]
          // dependant attributes are the attributes where the current vetting list attribute's value will be depend on
          const dependantAttributesSystemName = utils.isDefined(vettingListValueCriteria) && isObject(vettingListValueCriteria) ? Object.keys(vettingListValueCriteria) : []

          dependantAttributesSystemName.forEach((dependantAttributeSystemName) => {
            if (utils.isDefined(dataForValidation[dependantAttributeSystemName])) {
              const currentDependantAttributeValue = dataForValidation[dependantAttributeSystemName]
              if (utils.isDefined(currentDependantAttributeValue) && utils.isValidStringValue(currentDependantAttributeValue)) {
                useVettingListAttributePreDefinedAllowedValues = false
                const isDate = utils.validateDate(currentDependantAttributeValue)
                const dependantAttributePreDefinedLowerCaseValues = vettingListValueCriteria[dependantAttributeSystemName].map((value) => {
                  return isDate === true ? new Date(value).getTime().toString().toLowerCase() : value.toString().toLowerCase()
                })
                if (isDate === true) {
                  if (!dependantAttributePreDefinedLowerCaseValues.includes(new Date(currentDependantAttributeValue.toString()).getTime().toString().toLowerCase())) {
                    isValidVettingListValue = false
                  }
                }
                else {
                  if (!dependantAttributePreDefinedLowerCaseValues.includes(currentDependantAttributeValue.toString().toLowerCase())) {
                    isValidVettingListValue = false
                  }
                }
              }
            }
            else {
              isValidVettingListValue = false
            }
          })

          if (isValidVettingListValue) {
            validVettingListValues.push(vettingListValue)
          }
        })

        if (useVettingListAttributePreDefinedAllowedValues) {
          validVettingListValues = attribute.VettingList!
        }

        if (!isEqual(validVettingListValues, attribute.CriteriaVettingList)) {
          attribute.CriteriaVettingList = validVettingListValues
          // make the value on model null if the value on criteria attribute on model does not match criteria vetting list
          if (validVettingListValues.findIndex(v => utils.isDefined(modelObject[attribute.SystemName]) && v.toLowerCase() === modelObject[attribute.SystemName].toString().toLowerCase()) < 0) {
            modelObject[attribute.SystemName] = null
          }
        }
      }
    })
  }
  /**
   * @description this method will be called on each form input update and do the followings
   * update all the criteria type vetting list based on model object data
   * invalidate (remove) the criteria vetting list attribute value if assigned value does not matched current criteria vetting list
   * if form contains sizeScale and sizeScale is Creatable it will validate the assigned sizeScale based on form value and remove the value in case it is invalid based on form values
   *
   * NOTE: THIS METHOD WILL MUTATE formAttributes and modelObject
   *
   * @param {IMyAttribute[]} formAttributes list of form attributes
   * @param {Record<string, any>} modelObject form's model object
   * @param {Record<string, any>} dataForValidation it should contains all the data in modelObject and possible if there are additional data on article which might not be part of form
   */
  function updateLookupAttributeValues(formAttributes: Ref<IMyAttribute[]>, modelObject: Record<string, any>, dataForValidation: Record<string, any>, updatedAttributes: string[] = []) {
    if (userStore.indexedLookupAttributeDefinition) {
      formAttributes.value.forEach((attribute) => {
        if (attribute.AttributeType === AttributeType.LookupTable) {
          if (userStore.indexedLookupAttributeDefinition[attribute.SystemName]) {
            const sourceAttributes = userStore.indexedLookupAttributeDefinition[attribute.SystemName].SourceAttributes
            const values = userStore.indexedLookupAttributeDefinition[attribute.SystemName].Values
            let anyMatched = false
            for (let i = 0; i < values.length; i++) {
              const value = values[i]
              let matched = 0
              sourceAttributes.forEach((attribute) => {
                if (value.Source[attribute] === dataForValidation[attribute]) {
                  matched++
                }
              })
              if (matched === sourceAttributes.length) {
                anyMatched = true
                if (modelObject[attribute.SystemName] !== value.Destination[attribute.SystemName]) {
                  modelObject[attribute.SystemName] = value.Destination[attribute.SystemName]
                  if (!updatedAttributes.includes(attribute.SystemName)) {
                    updatedAttributes.push(attribute.SystemName)
                  }
                }
                break
              }
            }
            if (!anyMatched && utils.isValidStringValue(modelObject[attribute.SystemName])) {
              if (!updatedAttributes.includes(attribute.SystemName)) {
                updatedAttributes.push(attribute.SystemName)
              }
              modelObject[attribute.SystemName] = null
            }
          }
        }
      })
    }
  }
  /**
   * @description this method will be called on each form input update and validate the size scale based on current form state and remove if assigned size scale is invalid based on current form state
   * @param {Record<string, any>} modelObject form's model object
   * @param {IMyAttribute} masterSizeScaleAttribute master size scale attribute
   * @param {IMyAttribute} sizeScaleAttribute size scale attribute
   * @param {Record<string, any>} dataForValidation it should contains all the data in modelObject and possible if there are additional data on article which might not be part of form
   * @param {IMyAttribute} sizesAttribute sizes attribute
   */
  function removeInvalidAssignedSizeScale(modelObject: Record<string, any>, masterSizeScaleAttribute: IMyAttribute, sizeScaleAttribute: IMyAttribute | undefined, dataForValidation: Record<string, any>, sizesAttribute: IMyAttribute | undefined) {
    const assignedSizeScale = userStore.masterSizeScales[modelObject[masterSizeScaleAttribute.SystemName]]
    if (assignedSizeScale == null) {
      modelObject[masterSizeScaleAttribute.SystemName] = null
    }
    else {
      let isValidSizeScale = true
      for (const criteriaProperty in assignedSizeScale.Criteria) {
        const criteriaPropertyValueLower = !Array.isArray(assignedSizeScale.Criteria[criteriaProperty])
          ? [assignedSizeScale.Criteria[criteriaProperty].toString().toLowerCase()]
          : assignedSizeScale.Criteria[criteriaProperty].map(criteriaValue => criteriaValue.toString().toLowerCase())
        if (dataForValidation[criteriaProperty] == null || !criteriaPropertyValueLower.includes(dataForValidation[criteriaProperty].toString().toLowerCase())) {
          isValidSizeScale = false
        }
      }
      if (!isValidSizeScale) {
        modelObject[masterSizeScaleAttribute.SystemName] = null
        if (utils.isDefined(sizeScaleAttribute)) {
          modelObject[sizeScaleAttribute.SystemName] = null
        }
        // reset sizes field
        if (utils.isDefined(sizesAttribute)) {
          modelObject[sizesAttribute.SystemName] = ''
        }
      }
    }
  }

  return {
    doesModelBelongsToActiveCatalog,
    getArticlesMaxStateDetails,
    getArticlesMaxStateRank,
    getArticlesLocked,
    getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank,
    getIndexedRestrictedAttributesBasedOnArticlesStateRank,
    normalizeAttributeValue,
    prefillAttributeValue,
    removeInvalidAssignedSizeScale,
    skipRestrictingPropertyUpdateBasedOnArticleState,
    updateCriteriaAttributeAllowedValues,
    updateLookupAttributeValues,
    validateAndRemoveInvalidAttributeValues,
  }
}
