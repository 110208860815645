<template>
  <tx-dialog v-model="visible" :title="t('orderlines.actions.fill')" width="600px" @closed="onClosed">
    <div class="flex flex-col w-full h-full">
      <!-- STEPS -->
      <div class="px-6 py-4 mb-10 bg-gray-100 border-t border-gray-200">
        <div class="flex justify-between">
          <template v-for="(step, index) in steps" :key="step">
            <div class="flex flex-col items-center">
              <div
                class="flex items-center justify-center w-6 h-6 border-2 rounded-full"
                :class="[index <= currentStepIndex ? 'bg-blue-500 text-white border-blue-500' : 'border-gray-400']"
                v-text="index + 1"
              />
              <div class="text-sm" v-text="step" />
            </div>
            <div
              v-if="index < steps.length - 1" class="flex-1 h-2 mx-1 mt-3"
              :class="[index < currentStepIndex ? 'bg-blue-500' : 'bg-gray-400']"
            />
          </template>
        </div>
      </div>

      <!-- FORM -->
      <div class="overflow-auto h-80">
        <!-- DATA STEP -->
        <div v-show="currentStepIndex === 0" class="flex flex-col">
          <span class="block mb-5">{{ t('orderlines.fillOrder.description') }}</span>
          <tx-tabs v-model="selectedTab" class="mb-5" :tabs="tabs" />

          <div
            v-for="(attribute, index) in visibleAttributes" :key="attribute.SystemName"
            :class="{ 'col-span-2': index === 0, 'col-span-1': index > 0 }" class="mb-5 last-of-type:mb-4"
          >
            <form-editor
              v-model="formModel[attribute.SystemName]"
              :attribute="attribute"
              :form="formModel"
              :required="attribute.IsRequired"
              :errors="v$[attribute.SystemName]?.$errors"
              :show-label="true"
              :clearable="true"
              @blur="v$[attribute.SystemName]?.$touch"
            />
          </div>
        </div>
        <div v-show="currentStepIndex === 1" class="flex flex-col">
          <div v-show="validArticlesNumbers.length" class="p-[5px] mb-5 border border-[#76bceb] bg-[#eff3f6] rounded">
            <span class="block mb-2">{{ t('orderlines.fillOrder.validListDescription', validArticlesNumbers.length) }}</span>
            <span class="block">{{ validArticlesNumbers.join(', ') }}</span>
          </div>
          <div v-show="unavailableArticleNumbers.length" class="p-[5px] border border-[#76bceb] bg-[#eff3f6] rounded">
            <span class="block mb-2">{{ t('orderlines.fillOrder.unavailableListDescription', unavailableArticleNumbers.length) }}</span>
            <span class="block">{{ unavailableArticleNumbers.join(', ') }}</span>
          </div>
          <div v-show="!validArticlesNumbers.length && !unavailableArticleNumbers.length" class="p-[5px] border border-[#76bceb] bg-[#eff3f6] rounded">
            <span class="block mb-2">{{ t('orderlines.fillOrder.noValidArticles') }}</span>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex items-center justify-center px-6 py-4 space-x-3 sm:justify-end bg-gray-50">
        <tx-button
          type="cancel" :loading="loading" :text="t('general.cancel')"
          width="113px" height="40px" @click="close(undefined)"
        />
        <tx-button
          v-show="currentStepIndex > 0" type="cancel" :disabled="currentStepIndex <= 0" :loading="loading" :text="t('general.back')"
          width="113px" height="40px" @click="onBack"
        />
        <tx-button
          v-show="currentStepIndex < (steps.length - 1)" type="confirm" :loading="loading" :text="t('general.next')"
          width="113px" height="40px" :disabled="v$.$invalid" @click="onNext"
        />
        <tx-button
          v-show="currentStepIndex >= (steps.length - 1)" type="confirm" :loading="loading"
          :text="t('general.fill')" width="113px" height="40px" :disabled="v$.$invalid || validArticlesNumbers.length === 0" @click="onFinish"
        />
      </div>
    </template>
  </tx-dialog>
</template>

<script lang='ts' setup>
import useVuelidate from '@vuelidate/core'
import { computed, reactive, ref, watch } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es'
import appConfig from '@/services/appConfig'
import FormEditor from '@/shared/components/FormEditor.vue'
import type FavoriteTag from '@/models/favoriteTag'
import type MyArticle from '@/models/myArticle'
import type Order from '@/models/order'
import type Orderline from '@/models/orderline'
import TxButton from '@/shared/components/TxButton.vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxTabs from '@/shared/components/TxTabs.vue'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import { appConstants } from '@/models/constants'
import { useUserStore } from '@/store/userData'
import type { Segmentation } from '@/models/customerSegmentation'

const props = defineProps<{
  currentOrder: Order
  modelValue: boolean
  indexedArticles: Record<number, MyArticle>
  customerSegmentations: Segmentation[]
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void
  (e: 'closed', createdOrderlines?: Array<Orderline>): void
}>()

const { t } = useI18n()
const userStore = useUserStore()

const visible = ref(false)
const loading = ref(false)
const formModel = reactive({
  ArticlesList: '',
  Favorites: [],
})
const selectedTab = ref<'favorites' | 'articlesList'>('favorites')
const availableFavorites = ref<Array<FavoriteTag>>([])
const tabs: ITabItem[] = [
  { key: 'favorites', label: 'orderlines.fillOrder.tabFavorites', visible: true },
  { key: 'articlesList', label: 'orderlines.fillOrder.tabArticlesList', visible: true },
]
const currentStepIndex = ref(0)
const steps = ['Data', 'Validation']
let indexedArticlesByNumber: Record<string, MyArticle> = {}
let validArticlesNumbers: Array<string> = []
let unavailableArticleNumbers: Array<string> = []

const visibleAttributes = computed(() => {
  const visibleAttributes: Array<IFormEditorAttribute> = []
  if (selectedTab.value === 'favorites') {
    const customersField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'Favorites',
      AttributeType: AttributeType.MultiValue,
      DisplayName: t('orderlines.fillOrder.favoriteTags'),
      Creatable: true,
      DropDownData: availableFavorites.value,
      DropDownValueProp: 'Id',
      DropDownValueDisplayProp: 'Tag',
      DropDownValueSecondaryDisplayProp: 'CreatedByUserName',
      IsRequired: true,
    })
    visibleAttributes.push(customersField)
  }
  else if (selectedTab.value === 'articlesList') {
    const customersField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'ArticlesList',
      AttributeType: AttributeType.Nvarchar,
      DisplayName: t('orderlines.fillOrder.articleListLabel'),
      Creatable: true,
      InputType: 'textarea',
      IsRequired: true,
    })
    visibleAttributes.push(customersField)
  }
  return visibleAttributes
})

const validations = computed(() => {
  const result: Record<string, any> = {}
  if (selectedTab.value === 'articlesList') {
    result.ArticlesList = {}
    result.ArticlesList.required = helpers.withMessage(t('validations.required', { property: t('orderlines.fillOrder.articleListLabel') }), required)
    delete result.Favorites
  }
  else if (selectedTab.value === 'favorites') {
    result.Favorites = {}
    result.Favorites.required = helpers.withMessage(t('validations.required', { property: t('orderlines.fillOrder.articleListLabel') }), required)
    delete result.ArticlesList
  }
  return result
})

const v$ = useVuelidate(validations, formModel)

function init() {
  indexedArticlesByNumber = Object.values(props.indexedArticles).reduce((acu, cur) => (acu[cur.ArticleNumber] = cur) && acu, {})
  loadFavorites()
}

function reset() {
  loading.value = false
  currentStepIndex.value = 0
  indexedArticlesByNumber = {}
  formModel.ArticlesList = ''
  formModel.Favorites = []
  selectedTab.value = 'favorites'
  v$.value.$reset()
}

function onBack() {
  if (currentStepIndex.value < 0) {
    return
  }
  currentStepIndex.value--
}

async function onNext() {
  if (currentStepIndex.value >= (steps.length - 1)) {
    return
  }
  if (currentStepIndex.value === 0) {
    await validateData()
  }
  currentStepIndex.value++
}

async function validateData() {
  validArticlesNumbers = []
  unavailableArticleNumbers = []
  if (selectedTab.value === 'articlesList') {
    const articleNumbers = formModel.ArticlesList.split(/\r?\n/)
    articleNumbers.forEach((articleNumber) => {
      const article = indexedArticlesByNumber[articleNumber.trim()]
      // TODO: check for article stock when we implement stock module
      if (utils.isDefined(article) && article.Status === 1 && !isEmpty(article._Segmentations)
        && appConfig.DB!.isArticleSegmented(article._Segmentations, props.customerSegmentations, userStore.activeCatalog!._IndexedCatalogSegmentation)) {
        validArticlesNumbers.push(articleNumber)
      }
      else {
        unavailableArticleNumbers.push(articleNumber)
      }
    })
  }
  else if (selectedTab.value === 'favorites') {
    try {
      const selectedFavorites = await appConfig.DB!.favoriteTags.where('Id').anyOf(formModel.Favorites).toArray()
      const articleIdTracker: Array<number> = []
      selectedFavorites.forEach((tag) => {
        tag.Articles.forEach((articleId) => {
          if (!articleIdTracker.includes(articleId)) {
            articleIdTracker.push(articleId)
            const article = props.indexedArticles[articleId]
            if (article && article.Status === 1 && !isEmpty(article._Segmentations) && appConfig.DB!.isArticleSegmented(article._Segmentations, props.customerSegmentations, userStore.activeCatalog!._IndexedCatalogSegmentation)) {
              // TODO: check for article stock when we implement stock module
              validArticlesNumbers.push(article.ArticleNumber)
            }
          }
        })
      })
    }
    catch (error) {
      console.error(error)
    }
  }
}

async function loadFavorites() {
  try {
    availableFavorites.value = (await appConfig.DB!.favoriteTags
      .where({ CatalogCode: userStore.activeCatalog!.CatalogCode, Status: 1 })
      .filter((tag) => {
        if (tag.CreatedByUserName === userStore.currentUsername) { return true }
        if (tag.SharedUsers && tag.SharedUsers.length > 0) { return true }
        if (tag.SharedUsersGroups && tag.SharedUsersGroups.length > 0) { return true }
        return false
      })
      .toArray()
    ).sort((a, b) => {
      if (a.CreatedByUserName === userStore.currentUsername) { return -1 }
      return a.Tag.localeCompare(b.Tag)
    })
  }
  catch (error) {
    availableFavorites.value = []
    console.warn(error)
  }
}

function fillOrder(articleNumbers: Array<string>) {
  const articles = articleNumbers.map(articleNumber => indexedArticlesByNumber[articleNumber.trim()])
  // no need to fill the crd object, it will be taken care when adding quantities
  const createdOrderlines = props.currentOrder.fillOrder(articles)
  close(createdOrderlines)
}

function onFinish() {
  fillOrder(validArticlesNumbers)
}

function onClosed() {
  emit('closed')
}

function close(createdOrderlines?: Array<Orderline>) {
  visible.value = false
  emit('closed', createdOrderlines)
}

watch(() => props.modelValue, (modelValue) => {
  visible.value = modelValue
  if (modelValue) {
    init()
  }
  else {
    reset()
  }
})
</script>
