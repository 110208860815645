import type { ObjectRelationModel } from '@/api/t1/model/objectRelationModel'

export default class ObjectRelation implements ObjectRelationModel {
  CatalogCode: number
  ObjectId: number
  EntityId: number
  Id: number
  RelationTypeId: number
  RelationTypeName: string
  RelatedArticleId: number
  RelatedArticleNumber: string
  RelatedArticleName: string
  IsRequestArticle: ZeroOrOneType
  RequestArticleNumber: string
  RequestArticleName: string
  CreatedBy: number
  UpdatedBy: number
  CreatedDate: Date
  UpdatedDate: Date

  constructor(catalogCode: number, objectId: number, entityId: number, objectRelation: ObjectRelationModel) {
    this.CatalogCode = catalogCode
    this.ObjectId = objectId
    this.EntityId = entityId
    this.Id = objectRelation.Id
    this.RelationTypeId = objectRelation.RelationTypeId
    this.RelationTypeName = objectRelation.RelationTypeName
    this.RelatedArticleId = objectRelation.RelatedArticleId
    this.RelatedArticleNumber = objectRelation.RelatedArticleNumber
    this.RelatedArticleName = objectRelation.RelatedArticleName
    this.IsRequestArticle = objectRelation.IsRequestArticle
    this.RequestArticleNumber = objectRelation.RequestArticleNumber
    this.RequestArticleName = objectRelation.RequestArticleName
    this.CreatedBy = objectRelation.CreatedBy
    this.UpdatedBy = objectRelation.UpdatedBy
    this.CreatedDate = new Date(objectRelation.CreatedDate)
    this.UpdatedDate = new Date(objectRelation.UpdatedDate)
  }
}
