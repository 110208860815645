<template>
  <form-attribute-label
    v-show="showLabel"
    v-model:checked="checkedModel"
    :attribute="attribute"
    :disabled="disabled || attribute.AttributeType === AttributeType.LookupTable"
    :required="required"
    :show-checkbox="showCheckbox"
    :show-external-change-management-info="showExternalChangeManagementInfo"
    :show-update-seasonless-attribute-warning="showUpdateSeasonlessAttributeWarning"
    :tooltip-placement="tooltipPlacement"
    :tooltip-width="tooltipWidth"
    @check-changed="onCheckChanged"
  />

  <tx-select
    v-if="attribute.CriteriaVettingList && attribute.CriteriaVettingList.length > 0" ref="controlRef"
    v-model="attributeEditorModelValue"
    :data="attribute.CriteriaVettingList"
    :multiple-values="attribute.AttributeType === AttributeType.MultiValue"
    v-bind="$attrs"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :filterable="true"
    :sort-list="sortList"
    :errors="errors"
    @change="handleChange"
  />
  <tx-select
    v-else-if="attribute.VettingList && attribute.VettingList.length > 0" ref="controlRef"
    v-model="attributeEditorModelValue"
    :data="attribute.VettingList"
    :multiple-values="attribute.AttributeType === AttributeType.MultiValue"
    v-bind="$attrs"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :filterable="true"
    :sort-list="sortList"
    :errors="errors"
    :is-attribute-editor="true"
    :attribute-type="attribute.AttributeType"
    :show-none="attribute.showNone"
    @change="handleChange"
  />
  <tx-lookup
    v-else-if="attribute.DropDownData" ref="controlRef"
    v-model="attributeEditorModelValue"
    :data="attribute.DropDownData"
    :value-key="attribute.DropDownValueKey"
    :value-prop="attribute.DropDownValueProp"
    :display-prop="attribute.DropDownValueDisplayProp"
    :multiple-values="attribute.AttributeType === AttributeType.MultiValue"
    v-bind="$attrs"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :filterable="true"
    :sort-list="sortList"
    :errors="errors"
    :attribute-type="attribute.AttributeType"
    @change="handleChange"
  />
  <tx-input
    v-else-if="attribute.AttributeType === AttributeType.MultiValue" ref="controlRef"
    v-model="attributeEditorModelValue"
    type="textarea"
    v-bind="$attrs"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    @input="handleChange"
  />
  <tx-input
    v-else-if="attribute.AttributeType === AttributeType.Int" ref="controlRef"
    v-model="attributeEditorModelValue"
    type="number"
    v-bind="$attrs"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :min="attribute.minValue"
    :errors="errors"
    :pattern="pattern"
    :external-message="externalMessage"
    @input="handleChange"
  />
  <tx-input
    v-else-if="attribute.AttributeType === AttributeType.Decimal" ref="controlRef"
    v-model="attributeEditorModelValue"
    type="number"
    v-bind="$attrs"
    step="0.01"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    @input="handleChange"
  />
  <tx-input
    v-else-if="attribute.AttributeType === AttributeType.LookupTable" ref="controlRef"
    v-model="attributeEditorModelValue"
    v-bind="$attrs"
    :required="required"
    :disabled="true"
    :clearable="clearable"
    :errors="errors"
    @change="handleChange"
  />
  <tx-switch
    v-else-if="attribute.AttributeType === AttributeType.Bool" ref="controlRef"
    v-model="attributeEditorModelValue"
    v-bind="$attrs"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    @change="handleChange"
  />
  <tx-date-picker
    v-else-if="attribute.AttributeType === AttributeType.Date || attribute.AttributeType === AttributeType.DateOption" ref="controlRef"
    v-model="attributeEditorModelValue"
    v-bind="$attrs"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    auto-apply
    @change="handleChange"
  />
  <tx-date-picker
    v-else-if="attribute.AttributeType === AttributeType.DateTime" ref="controlRef"
    v-model="attributeEditorModelValue"
    v-bind="$attrs"
    :enable-time-picker="true"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    auto-apply
    @change="handleChange"
  />
  <article-number-autocomplete
    v-else-if="attribute.AttributeType === AttributeType.ArticleNumber" ref="controlRef"
    v-model="attributeEditorModelValue"
    v-bind="$attrs"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    @input="handleChange"
    @change="handleChange"
  />
  <linked-article-number-autocomplete
    v-else-if="attribute.AttributeType === AttributeType.LinkedCatalogArticleNumber" ref="controlRef"
    v-model="attributeEditorModelValue"
    v-bind="$attrs"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    @input="handleChange"
  />
  <model-number-autocomplete
    v-else-if="attribute.AttributeType === AttributeType.ModelNumber" ref="controlRef"
    v-model="attributeEditorModelValue"
    v-bind="$attrs"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    @input="handleChange"
    @change="handleChange"
  />
  <seller-model-autocomplete
    v-else-if="attribute.AttributeType === AttributeType.SellerModel" ref="controlRef"
    v-bind="$attrs"
    v-model="attributeEditorModelValue"
    :clearable="clearable"
    :disabled="disabled"
    :errors="errors"
    :required="required"
    @change="handleChange"
  />
  <tx-input-color-picker
    v-else-if="attribute.AttributeType === AttributeType.ColorPicker" ref="controlRef"
    v-model="attributeEditorModelValue"
    v-bind="$attrs"
    :color-type="attribute.inputColorType"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    @input="handleChange"
    @change="handleChange"
  />
  <tx-input
    v-else ref="controlRef"
    v-model="attributeEditorModelValue"
    v-bind="$attrs"
    :type="attribute.InputType ?? 'text'"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    @input="handleChange"
    @change="handleChange"
  />
</template>

<script lang='ts'>
</script>

<script setup lang='ts'>
import type { ErrorObject } from '@vuelidate/core'
import { computed, nextTick, ref, watch } from 'vue'
import FormAttributeLabel from './FormAttributeLabel.vue'
import TxInput from './TxInput.vue'
import TxSelect from './TxSelect.vue'
import TxSwitch from './TxSwitch.vue'
import TxDatePicker from './TxDatePicker.vue'
import TxInputColorPicker from './TxInputColorPicker.vue'
import ArticleNumberAutocomplete from './ArticleNumberAutocomplete.vue'
import LinkedArticleNumberAutocomplete from './LinkedArticleNumberAutocomplete.vue'
import ModelNumberAutocomplete from './ModelNumberAutocomplete.vue'
import SellerModelAutocomplete from './SellerModelAutocomplete.vue'
import TxLookup from './TxLookup.vue'
import { AttributeType } from '@/models/catalogAttribute'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<IProps>(), {
  checked: false,
  clearable: true,
  disabled: false,
  required: false,
  errors: () => [] as ErrorObject[],
  form: () => ({}),
  showCheckbox: false,
  showExternalChangeManagementInfo: false,
  showLabel: true,
  showUpdateSeasonlessAttributeWarning: true,
  sortList: true,
})

const emit = defineEmits<{
  (e: 'change', modelValue: any): void
  (e: 'checkChanged', checked: boolean): void
  (e: 'update:checked', checked: boolean): void
  (e: 'update:modelValue', val: any): void
}>()

interface IProps {
  attribute: IFormEditorAttribute
  checked?: boolean
  clearable?: boolean
  disabled?: boolean
  required?: boolean
  errors?: ErrorObject[]
  form: Record<string, any>
  modelValue?: any
  showCheckbox?: boolean
  showExternalChangeManagementInfo?: boolean
  showLabel?: boolean
  showUpdateSeasonlessAttributeWarning?: boolean
  tooltipPlacement?: string
  tooltipWidth?: string
  sortList?: boolean
  pattern?: string
  externalMessage?: string
}
const controlRef = ref<HTMLElement>()
const attributeEditorModelValue = computed({
  get() {
    return props.modelValue
  },
  set(modelValue) {
    emit('update:modelValue', modelValue)
  },
})

const checkedModel = ref(props.checked)

function onCheckChanged(checked) {
  emit('update:checked', checked)
  emit('checkChanged', checked)
}

function handleChange() {
  nextTick(() => {
    emit('change', attributeEditorModelValue.value)
  })
}

watch(() => props.checked, (checked) => {
  checkedModel.value = checked
})

defineExpose({
  controlRef,
})
</script>
