import type { AttributeType } from './catalogAttribute'

export default class CatalogRequestAttribute {
  Id: number
  AttributeId: number
  AttributeDisplayName: string
  AttributeSystemName: string
  AttributeTypeId: AttributeType
  Criteria: string
  Status: number
  ValidationExpression: string
  ValidationMessage: string

  constructor(obj: CatalogRequestAttribute) {
    this.Id = obj.Id
    this.AttributeId = obj.AttributeId
    this.AttributeDisplayName = obj.AttributeDisplayName
    this.AttributeSystemName = obj.AttributeSystemName
    this.AttributeTypeId = obj.AttributeTypeId
    this.Criteria = obj.Criteria
    this.Status = obj.Status
    this.ValidationExpression = obj.ValidationExpression
    this.ValidationMessage = obj.ValidationMessage
  }
}
