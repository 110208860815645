<template>
  <tx-dialog v-model="visible" :title="t('notificationErrorDialog.title', { jobId })" width="90%" height="94%">
    <div class="flex flex-col w-full h-full grow">
      <tx-data-table
        :data="data" :columns="columns" :loading="loadingData" :visible-columns="visibleColumns"
        :item-actions="itemActions" :list-actions="listActions" :show-toolbar-static-actions="false"
      />
    </div>
  </tx-dialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxDataTable from '@/shared/components/txDataTable'
import type { ITxDataTableColumn, ITxDataTableItemAction, ITxDataTableListAction, ITxDataTableVisibleColumn } from '@/shared/components/txDataTable/TxDataTable.types'
import { getArticlesJobDetails } from '@/api/fileServer/generate'
import { AttributeType } from '@/models/catalogAttribute'

const visible = ref(false)
const { t } = useI18n()
const data = ref<any[]>([])
const loadingData = ref(true)
const itemActions: ITxDataTableItemAction[] = []
const listActions: ITxDataTableListAction[] = []
const jobId = ref()
const columns: ITxDataTableColumn[] = [
  {
    property: 'Id',
    title: 'Id',
    type: AttributeType.Int,
  },
  {
    property: 'ErrorMessage',
    title: 'Error Message',
    type: AttributeType.Nvarchar,
  },
  {
    property: 'ErrorType',
    title: 'Error Type',
    type: AttributeType.Nvarchar,
  },
  {
    property: 'Operation',
    title: 'Operation',
    type: AttributeType.Nvarchar,
  },
]
const visibleColumns: ITxDataTableVisibleColumn[] = [
  {
    property: 'Id',
    order: 1,
    width: 150,
  },
  {
    property: 'ErrorMessage',
    order: 2,
    width: 400,
  },
  {
    property: 'ErrorType',
    order: 1,
    width: 200,
  },
  {
    property: 'Operation',
    order: 1,
    width: 150,
  },
]
async function showDialog(notificationId) {
  visible.value = true
  if (notificationId && notificationId.length) {
    loadingData.value = true
    jobId.value = notificationId
    data.value = []
    getArticlesJobDetails(notificationId).then((response) => {
      data.value = response.data.ImportErrorList
    }).catch((e) => {
      console.warn('unable to fetch the notification details', e)
    }).finally(() => {
      loadingData.value = false
    })
  }
}
defineExpose({
  showDialog,
})
</script>
