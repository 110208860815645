import { defineStore } from 'pinia'
import { useBrowseByStore } from './browseBy'
import useEventsBus from '@/shared/composables/eventBus'
import type { Entity } from '@/services/db'
import FavoriteTag from '@/models/favoriteTag'
import CatalogDetails from '@/models/catalogDetails'
import type CatalogPriceGroup from '@/models/catalogPriceGroup'
import type LinkedCustomer from '@/models/linkedCustomer'
import type CustomerSegmentation from '@/models/customerSegmentation'
import type { FilterCriteria } from '@/models/filterCriteria'
import UserProfile from '@/models/userProfile'
import UserPreferences from '@/models/userPreferences'
import SellerDetails from '@/models/sellerDetails'
import { getUserProfile } from '@/api/t1/user'
import workerWrapper from '@/services/workerWrapper'
import auth from '@/services/auth'
import appConfig from '@/services/appConfig'
import utils from '@/services/utils'
import { getArticleStates } from '@/api/t1/article'
import type { ArticleStateModel } from '@/api/t1/model/articleModel'
import type { MasterSizeScaleModel, SizeScaleModel } from '@/api/t1/model/sizeModel'
import { getMasterSizeScales, getSellerAvailableSizeScales } from '@/api/t1/size'
import { getRestrictedBucketAttributes } from '@/api/t1/buckets'
import type DuneAsset from '@/models/duneAsset'
import { getSilhouetteAssets } from '@/api/dune/asset'
import { getSellerLookup } from '@/api/t1/sellers'
import type { SellerLookupDefinitionModel } from '@/api/t1/model/sellerModel'
import { appConstants, privileges } from '@/models/constants'
import type ConfirmDialog from '@/shared/components/ConfirmDialog.vue'
import { getRolesBasedRestrictedAttributes, getRolesBasedRestrictedRetailWindows, getRolesBasedRestrictedSegmentations } from '@/api/t1/catalog'

export const useUserStore = defineStore({
  id: 'userStore',
  state: () => ({
    currentUsername: '',
    currentUserType: '',
    userProfile: undefined as unknown as UserProfile,
    userPreferences: undefined as unknown as UserPreferences,
    groupByCol: undefined as string | undefined,
    groupByRow: undefined as string | undefined,
    sortBy: undefined as string | undefined,
    activeCatalog: undefined as CatalogDetails | undefined,
    linkedCatalogDetails: {} as Record<number, CatalogDetails>,
    sellerDetails: undefined as SellerDetails | undefined,
    myAttributes: undefined as Record<string, IMyAttribute> | undefined,
    priceGroups: { retail: undefined as CatalogPriceGroup | undefined, wholesale: undefined as CatalogPriceGroup | undefined, outlet: undefined as CatalogPriceGroup | undefined },
    currentCustomer: undefined as LinkedCustomer | undefined,
    currentCustomerSegmentations: undefined as CustomerSegmentation | undefined,
    totalArticles: 0,
    hooked: false,
    thumbnailSize: 3,
    currentTree: undefined as ITreeNode[] | undefined,
    totalUsers: 0,
    articleStateList: [] as Array<ArticleStateModel>,
    masterSizeScales: {} as Record<number, MasterSizeScaleModel>,
    sizeScales: {} as Record<number, SizeScaleModel>,
    indexedLookupAttributeDefinition: {} as Record<string, SellerLookupDefinitionModel>,
    customSortArticlePropertyAndValues: {} as Record<string, string[]>,
    indexedSilhouetteImages: {} as Record<string, DuneAsset>,
    notificationsLastVisited: new Date(),
    confirmDialogEl: undefined as InstanceType<typeof ConfirmDialog> | undefined,
  }),
  actions: {
    async doInitCatalog(catalogCode: number, customerId: number, retailPriceId: number, wholesalePriceId: number, outletPriceId: number, progress: (progress: number) => void) {
      if (this.userProfile) {
        this.userProfile.catalogCode = catalogCode
      }
      else {
        this.setProfile(catalogCode)
      }

      if (this.activeCatalog === undefined) {
        const catalogDetails = new CatalogDetails(await appConfig.DB!.getCatalogDetails(catalogCode), this.userProfile.Roles)
        this.activeCatalog = catalogDetails
        await this.setLinkedCatalogs(catalogDetails)
      }

      this.sellerDetails = new SellerDetails(await appConfig.DB!.getSellerDetails(this.activeCatalog.AccountId))
      this.activeCatalog.TreeDefinitions = await appConfig.DB!.getCatalogTreeDefinition(catalogCode)
      // if (this.activeCatalog.DataSourceTypeId === appConstants.catalogTypes.inherited) {
      //   const masterCatalog = await appConfig.DB!.getCatalogDetails(this.activeCatalog.MasterCatalogCode)
      //   this.activeCatalog.ShipmentWindowRangeList = masterCatalog.ShipmentWindowRangeList
      // }
      if (customerId < 0) {
        this.currentCustomer = undefined
      }
      else {
        this.currentCustomer = await appConfig.DB!.getLinkedCustomer(catalogCode, customerId)
      }
      await this.setCurrentCustomerSegmentations()
      if (this.activeCatalog.CatalogPriceGroupList.length) {
        this.activeCatalog.CatalogPriceGroupList.forEach((catalogPriceGroup) => {
          if (catalogPriceGroup.Id === retailPriceId) {
            this.priceGroups.retail = catalogPriceGroup
          }
          else if (catalogPriceGroup.Id === wholesalePriceId) {
            this.priceGroups.wholesale = catalogPriceGroup
          }
          else if (catalogPriceGroup.Id === outletPriceId) {
            this.priceGroups.outlet = catalogPriceGroup
          }
        })
      }

      appConfig.DB!.trackChanges = false
      // TODO: I am removing the sizes and allocations module for performance reasons, we must bring it back if the catalog has the Orders or Forecast module enabled!
      const entitiesToLoad: Array<Entity> = ['Articles', 'Prices', 'DeliveryDates', 'Segmentations', 'Resources', 'MyFavoriteTags', 'SavedViews', 'ColorPallets', 'Sizes']
      if (this.activeCatalog.RequestsEnabled > 0) {
        entitiesToLoad.push('Requests')
      }
      const isOrderModuleEnabled = !this.activeCatalog.Config.DisableModules.join(',').toLowerCase().split(',').includes('order')
      if (isOrderModuleEnabled) {
        entitiesToLoad.push('Locations')
        entitiesToLoad.push('CustomerSegmentations')
      }
      await this.doLoadData(entitiesToLoad, undefined, false, progress)
      appConfig.DB!.trackChanges = true

      getSilhouetteAssets(this.activeCatalog.DuneContext, this.activeCatalog.ContextKey)
        .then((resp) => {
          if (resp.data.length) {
            resp.data.forEach((imageData) => {
              this.indexedSilhouetteImages[imageData.Key.toLowerCase()] = imageData
            })
          }
        })
        .catch(e => console.warn('Could not load silhouette assets', e))

      try {
        this.articleStateList = await getArticleStates(this.activeCatalog.AccountId, this.activeCatalog.AttributeGroupId)
      }
      catch (error) {
        console.warn('Could not load article state list', error)
      }

      getMasterSizeScales(this.activeCatalog.AccountId)
        .then(data => this.masterSizeScales = utils.arrayToNumberDictionary(data, 'Id'))
        .catch(e => console.warn('Could not load MasterSizeScale list', e))

      getSellerAvailableSizeScales(this.activeCatalog.AccountId)
        .then(data => this.sizeScales = utils.arrayToNumberDictionary(data, 'Id'))
        .catch(e => console.warn('Could not load SizeScale list', e))

      // apply role based restriction data to bucket attributes
      try {
        const roleBasedRestrictedBucketAttributesResponse = await getRestrictedBucketAttributes(this.activeCatalog.CatalogCode)
        this.activeCatalog.updateBucketAttributeListBasedOnUserRoleRestriction(roleBasedRestrictedBucketAttributesResponse.data, this.userProfile)
        const roleBasedRestrictedAttributesResponse = await getRolesBasedRestrictedAttributes(this.activeCatalog.CatalogCode)
        this.activeCatalog.updateRolesBasedRestrictedAttributes(roleBasedRestrictedAttributesResponse.data, this.userProfile)
        const roleBasedRestrictedRetailWindowsResponse = await getRolesBasedRestrictedRetailWindows(this.activeCatalog.CatalogCode)
        this.activeCatalog.updateRolesBasedRestrictedRetailWindows(roleBasedRestrictedRetailWindowsResponse.data, this.userProfile)
        const roleBasedRestrictedSegmentationsResponse = await getRolesBasedRestrictedSegmentations(this.activeCatalog.CatalogCode)
        this.activeCatalog.updateRolesBasedRestrictedSegmentations(roleBasedRestrictedSegmentationsResponse.data, this.userProfile)
      }
      catch (error) {
        console.warn('could not apply user role restrictions', error)
      }
      // for Inherited Catalog dont show not assorted articles articles if the UI_ViewUnAssortedArticles is not assigned.
      let isViewUnAssortedArticlesRestricted: boolean = false
      if (this.activeCatalog.DataSourceTypeId === appConstants.catalogTypes.inherited && !this.userProfile.isValidPrivilege(privileges.article.viewUnAssortedArticles)) {
        isViewUnAssortedArticlesRestricted = true
      }
      const activeArticleStateList = this.articleStateList.filter(articleState => articleState.Status)

      // Load MyCatalog
      this.myAttributes = await appConfig.DB!.buildMyAttributes(this.activeCatalog, this.linkedCatalogDetails, this.currentUsername, true, this.currentCustomer, this.currentCustomerSegmentations, activeArticleStateList, isViewUnAssortedArticlesRestricted)

      getSellerLookup(this.activeCatalog.AccountId, this.activeCatalog.AttributeGroupId, this.myAttributes)
        .then((data) => {
          if (data) {
            this.indexedLookupAttributeDefinition = data
          }
        })
        .catch(e => console.warn('Could not load seller lookups', e))

      // Make sure the default favorite tag is created
      const defaultTag = await appConfig.DB!.favoriteTags.get({ CatalogCode: catalogCode, CreatedByUserName: this.currentUsername, Tag: 'Default' })
      if (!defaultTag) {
        await appConfig.DB!.favoriteTags.add(new FavoriteTag(catalogCode, { Id: utils.hashCode('Default'), Tag: 'Default', Color: '#32C2E0', CreatedByUserName: this.currentUsername, Status: 1 }))
      }

      // Set total articles
      this.setTotalArticles(this.activeCatalog)
    },
    async setCurrentCustomerSegmentations() {
      if (this.activeCatalog) {
        if (utils.isDefined(this.currentCustomer)) {
          this.currentCustomerSegmentations = await appConfig.DB!.getCurrentCustomerSegmentations(this.activeCatalog.AccountId, this.activeCatalog.CatalogCode, this.currentCustomer!.CustomerId)
        }
        else {
          this.currentCustomerSegmentations = undefined
        }
      }
    },
    async doLoadData(entitiesToLoad: Entity[], catalogCodes?: number[], notifyUpdate = true, progress?: (progress: number) => void, newArticleIds?: string[]) {
      const catalogs = catalogCodes || (this.activeCatalog ? [this.activeCatalog.CatalogCode] : [])
      if (catalogs && catalogs.length > 0) {
        // for Inherited Catalog dont show not assorted articles articles if the UI_ViewUnAssortedArticles is not assigned.
        let isViewUnAssortedArticlesRestricted: boolean = false
        if (this.activeCatalog && this.activeCatalog.DataSourceTypeId === appConstants.catalogTypes.inherited && !this.userProfile.isValidPrivilege(privileges.article.viewUnAssortedArticles)) {
          isViewUnAssortedArticlesRestricted = true
        }
        await workerWrapper.sendAsync({ id: '', action: 'LoadCatalogData', content: { token: auth.getLocalToken()!, userId: this.userProfile?.Id, catalogCodes: catalogs, isViewUnAssortedArticlesRestricted, entitiesToLoad } }, progress)
        if (notifyUpdate) {
          const { emitter } = useEventsBus()
          emitter('catalogDataUpdated', { source: 'Full', newArticleIds })
        }
      }
    },
    async setMyAttributes() {
      if (this.activeCatalog) {
        // for Inherited Catalog dont show not assorted articles articles if the UI_ViewUnAssortedArticles is not assigned.
        let isViewUnAssortedArticlesRestricted: boolean = false
        if (this.activeCatalog.DataSourceTypeId === appConstants.catalogTypes.inherited && !this.userProfile.isValidPrivilege(privileges.article.viewUnAssortedArticles)) {
          isViewUnAssortedArticlesRestricted = true
        }
        const activeArticleStateList = this.articleStateList.filter(articleState => articleState.Status)
        this.myAttributes = await appConfig.DB!.buildMyAttributes(this.activeCatalog, this.linkedCatalogDetails, this.currentUsername, true, this.currentCustomer, this.currentCustomerSegmentations, activeArticleStateList, isViewUnAssortedArticlesRestricted)
      }
    },
    async setUserProfile(catalogCode: number) {
      if (this.userProfile) {
        this.userProfile.catalogCode = catalogCode
      }
      else {
        await this.setProfile(catalogCode)
      }
    },

    async setProfile(catalogCode: number = 0) {
      await getUserProfile()
        .then(async (resp) => {
          this.userProfile = new UserProfile(resp.data, catalogCode)
          // load user preferences
          let userPreferences = await appConfig.DB!.userPreferences.get(this.userProfile.Id)
          if (!userPreferences) {
            userPreferences = new UserPreferences({ userId: this.userProfile.Id, targetSummary: { filterCriteria: [] } })
            appConfig.DB!.userPreferences.put(userPreferences)
          }
          this.userPreferences = userPreferences
        })
        .catch(ex => console.error('Error: fetching profile API', ex))
    },
    async doLoadTree(treeDefinitionId: number, customerId: number) {
      if (this.activeCatalog) {
        this.currentTree = await appConfig.DB!.buildTree(this.activeCatalog, treeDefinitionId, customerId, this.currentCustomerSegmentations, this.myAttributes)
      }
    },
    setActiveCatalog(catalog: CatalogDetails) {
      this.activeCatalog = catalog
    },
    setNotificationsLastVisited() {
      this.notificationsLastVisited = new Date()
    },
    async setLinkedCatalogs(catalog: CatalogDetails) {
      this.linkedCatalogDetails = {}
      if (catalog.LinkedCatalogList.length) {
        const linkedCatalogUsed = catalog.LinkedCatalogList.slice(0, (catalog.LinkedCatalogList.length < 2 ? catalog.LinkedCatalogList.length : 2))
        if (linkedCatalogUsed.length) {
          for (let index = 0; index < linkedCatalogUsed.length; index++) {
            this.linkedCatalogDetails[linkedCatalogUsed[index].CatalogCode] = await appConfig.DB!.getCatalogDetails(linkedCatalogUsed[index].CatalogCode)
          }
        }
      }
    },
    async setTotalArticles(catalog: CatalogDetails, criteria?: FilterCriteria[]) {
      if (this.activeCatalog) {
        const browseByStore = useBrowseByStore()
        const catalogCodes = [this.activeCatalog.CatalogCode]
        if (criteria) {
          const seasonIndex = criteria.findIndex(c => c.attribute === '_Seasons')
          if (criteria && seasonIndex >= 0 && criteria[seasonIndex].multipleVals?.length) {
            criteria[seasonIndex].multipleVals?.forEach(v => catalogCodes.push(Number(v)))
          }
        }
        if (!browseByStore.isBrowseByModel) {
          if (utils.isDefined(this.currentCustomer)) { // not fullrange filter only segmentaed articles
            let articleCollection = await appConfig.DB!.articles.where('CatalogCode').anyOf(catalogCodes)
            articleCollection = await appConfig.DB!.filterSegmentedArticlesOfCurrentCustomer(articleCollection, catalog, this.currentCustomerSegmentations)
            this.totalArticles = (await articleCollection.toArray()).length
          }
          else {
            this.totalArticles = await appConfig.DB!.articles.where('CatalogCode').anyOf(catalogCodes).count()
          }
          if (this.activeCatalog.RequestsEnabled) {
            const requestsCount = await appConfig.DB!.requests.where({ CatalogCode: this.activeCatalog.CatalogCode, IsCreateArticleRequest: 1 }).count()
            this.totalArticles += requestsCount
          }
        }
        else {
          const articles = await appConfig.DB!.getModelsByCriteria(this.activeCatalog, this.myAttributes!, criteria || [], true, this.currentUsername, this.currentCustomer, this.currentCustomerSegmentations, true)
          this.totalArticles = articles.length
        }
      }
    },
    setGroupBy(col: string | undefined, row: string | undefined) {
      this.groupByCol = col
      this.groupByRow = row
    },
    setThumbnailSize(size: number) {
      this.thumbnailSize = size
    },
    setSortBy(prop: string | undefined) {
      this.sortBy = prop
      if (this.sortBy !== '_customSort') {
        // reset customSortArticlePropertyAndValues
        this.setCustomSortArticlePropertyAndValues({})
      }
    },
    setToken(token: IT1AuthToken) {
      this.currentUsername = token.unique_name
      this.currentUserType = token.type
    },
    setTotalUser(length: number) {
      this.totalUsers = length
    },
    unsetToken() {
      this.currentUsername = ''
      auth.clearLocalToken()
    },
    setCustomSortArticlePropertyAndValues(customSortArticlePropertyAndValues: Record<string, string[]>) {
      this.customSortArticlePropertyAndValues = customSortArticlePropertyAndValues
    },
    setConfirmDialogEl(confirmDialogEl: InstanceType<typeof ConfirmDialog> | undefined) {
      this.confirmDialogEl = confirmDialogEl
    },
  },
})
