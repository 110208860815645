<template>
  <tx-autocomplete
    :model-value="modelValue" :label="label" :placeholder="placeholder" :errors="errors"
    :fetch-suggestions="searchArticleNumber" :value-prop="valueProp" display-prop="ArticleNumber" :required="required"
    :disabled="disabled" :clearable="clearable" @update:model-value="onModelValueUpdated"
  />
</template>

<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core'
import TxAutocomplete from './TxAutocomplete.vue'
import utils from '@/services/utils'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'

interface IProps {
  modelValue?: string | number | null
  errors?: ErrorObject[]
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  clearable?: boolean
  valueProp?: string
  skippedArticleIds?: number[]
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: null,
  label: '',
  placeholder: '',
  required: false,
  clearable: false,
  disabled: false,
  valueProp: 'ArticleNumber',
  errors: () => [] as ErrorObject[],
  skippedArticleIds: () => [] as number[],
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: string | number | null): void
}>()

const userStore = useUserStore()

function searchArticleNumber(query: string, callback) {
  if (userStore.activeCatalog) {
    let articleCollection = appConfig.DB!.articles.where('CatalogCode').equals(userStore.activeCatalog.CatalogCode)
      .and(a => !props.skippedArticleIds.includes(a.Id))

    if (utils.isDefined(query)) {
      articleCollection = appConfig.DB!.articles.where('ArticleNumber').startsWithIgnoreCase(query.trim().toLowerCase())
        .and(a => a.CatalogCode === userStore.activeCatalog!.CatalogCode)
        .and(a => !props.skippedArticleIds.includes(a.Id))
    }

    articleCollection
      .limit(200)
      .sortBy('ArticleNumber')
      .then((articles) => {
        callback(articles)
      })
  }
  else {
    callback([])
  }
}

function onModelValueUpdated(val?: string | number) {
  emit('update:modelValue', val || null)
}
</script>
