<template>
  <footer id="status" class="box-border flex w-full m-auto overflow-hidden text-xs border-t border-gray-300 min-h-4">
    <div class="flex justify-start grow">
      <div v-show="getCatalogName" class="flex items-center h-3 mx-2 my-auto whitespace-nowrap">
        <span class="mr-1 font-semibold text-dark" v-text="getCatalogName" />
      </div>
      <div v-for="item in getItems" :key="item.id" class="flex items-center h-3 mx-2 my-auto whitespace-nowrap">
        <span class="mr-1 font-semibold text-dark" v-text="item.value" />
        <span class="text-gray-500" v-text="item.label" />
      </div>
    </div>
    <div class="flex justify-end grow">
      <div v-if="getCustomerName" class="flex items-center h-3 mx-2 my-auto whitespace-nowrap">
        <span class="text-gray-500" v-text="getCustomerName" />
      </div>
      <div class="flex items-center h-3 mx-2 my-auto whitespace-nowrap">
        <span class="mr-1 font-semibold text-dark">{{ getMemUsage }}</span>
        <span class="text-gray-500" v-text="t('status.memory')" />
      </div>
      <div class="flex items-center h-3 mx-2 my-auto whitespace-nowrap">
        <span class="mr-1 font-semibold text-dark">{{ getStorage }}</span>
        <span class="text-gray-500" v-text="t('status.storage')" />
      </div>
      <div class="flex items-center h-3 mx-2 my-auto whitespace-nowrap">
        <span class="mr-1 font-semibold text-dark">{{ buildDate }} ({{ buildDateAgo }})</span>
        <span class="text-gray-500" v-text="t('status.build')" />
      </div>
      <div class="w-2 h-2 mx-2 my-auto rounded-full" :class="online ? 'bg-success' : 'bg-error'" />
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useOnline, useTimeAgo } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { getItems, getMemAvailable, getMemUsed, getStorageAvailable, getStorageUsed } from '@/store/status'
import utils from '@/services/utils'
import { useUserStore } from '@/store/userData'

const buildDateIso = '__DATE__'

const userStore = useUserStore()
const { t } = useI18n()

const getMemUsage = computed(() => {
  return `${utils.formatBytes(getMemUsed.value)} of ${utils.formatBytes(getMemAvailable.value)}`
})

const getStorage = computed(() => {
  return `${utils.formatBytes(getStorageUsed.value)} of ${utils.formatBytes(getStorageAvailable.value)}`
})

const getCatalogName = computed(() => userStore.activeCatalog?.CatalogName)

const getCustomerName = computed(() => userStore.currentCustomer ? userStore.currentCustomer.Name : t('general.fullRange'))

const online = useOnline()

const buildDateAgo = useTimeAgo(buildDateIso)
const buildDate = new Date(buildDateIso).toLocaleDateString()
</script>
