import { fabric } from 'fabric'
import type { Ref } from 'vue'
import { ref, watch } from 'vue'
import Utility from '../services/utility'
import WbConnector from '../services/connector'
import type Whiteboard from '../services/whiteboard'
import { whiteboardConstants } from '@/models/constants'

export default function useConnecters(whiteboard: Ref<Whiteboard | undefined>, collaborationReadyReactive: Ref<boolean> = ref(false)) {
  const drawingConnector: Ref<boolean> = ref(false)
  let currentConnecter: WbConnector | null = null
  let currentConnectorOriginObject: fabric.Object | null // object which is added to current connector start point
  let wb: Whiteboard
  let initialized = false
  let hoverObjectCurrentOptions = {}

  watch(collaborationReadyReactive, () => {
    if (collaborationReadyReactive.value && !initialized) {
      initialized = true
      init()
    }
  }, { immediate: true })

  function init() {
    wb = whiteboard.value!
    wb.canvas.on('object:removed', onCanvasObjectRemoved)
  }

  function onCanvasObjectRemoved(opt: fabric.IEvent<Event>) {
    const connectors: Array<WbConnector> = wb.canvas.getObjects(whiteboardConstants.objectTypes.connector) as Array<WbConnector>
    const deletedConnectableObjectIdList: Array<string> = []
    if (opt.target?.hasOwnProperty('_objects')) {
      (opt.target as fabric.ActiveSelection)._objects.forEach((object) => {
        const wbObject: IWbObject = object as IWbObject
        if (wbObject.connectable) {
          deletedConnectableObjectIdList.push(wbObject.id!)
        }
      })
    }
    else if ((opt.target as IWbObject).connectable) {
      deletedConnectableObjectIdList.push((opt.target as IWbObject).id!)
    }
    connectors.forEach((connector) => {
      if (deletedConnectableObjectIdList.includes(connector.startObjectId) || deletedConnectableObjectIdList.includes(connector.endObjectId!)) {
        wb.canvas.remove(connector)
      }
    })
  }

  function switchAddConnectorMode() {
    // add mouse events when its add selector action, remove once action is ended
    wb.canvas.on('mouse:move', onCanvasMouseMove)
    wb.canvas.on('mouse:over', onCanvasMouseOver)
    wb.canvas.on('mouse:out', onCanvasMouseOut)
  }

  /**
   * @description: track mouse movement on canvas when user wants to add connector and draw the path
   * update mouse hover cursor effect
   * the listener will be removed once the action is completed/aborted
   * @param {fabric.IEvent<MouseEvent | Event>} opt
   */
  function onCanvasMouseMove(opt: fabric.IEvent<MouseEvent | Event>) {
    // update mouse hover cursor
    if ((opt.target as IWbObject)?.connectable) {
      wb.canvas.hoverCursor = 'crosshair'
    }
    else {
      wb.canvas.hoverCursor = 'default'
    }

    if (drawingConnector.value && currentConnecter && currentConnectorOriginObject) {
      currentConnecter.drawConnectorPathWithMouseMove(opt, currentConnectorOriginObject, wb.canvas)
    }
  }

  /**
   * @description:
   */
  function onCanvasMouseOver(opt: fabric.IEvent<MouseEvent | Event>) {
    const wbObject = opt.target as IWbObject
    if (wbObject?.connectable) {
      // cache existing options
      hoverObjectCurrentOptions = (({ selectable, transparentCorners, _controlsVisibility, cornerStyle, lockScalingX, lockScalingY, lockSkewingX, lockSkewingY, hoverCursor }) =>
        ({ selectable, transparentCorners, _controlsVisibility, cornerStyle, lockScalingX, lockScalingY, lockSkewingX, lockSkewingY, hoverCursor }))(wbObject)
      addCustomControls(wbObject)
      // add custom options
      wbObject.setControlsVisibility({
        ml: false,
        mt: false,
        mr: false,
        mb: false,
        tl: false,
        tr: false,
        bl: false,
        br: false,
        mtr: false,
      })

      // make active object
      wb.canvas.setActiveObject(wbObject)
      wb.canvas.requestRenderAll()
    }
  }

  /**
   * @description:
   */
  function onCanvasMouseOut(opt: fabric.IEvent<MouseEvent | Event>) {
    const wbObject = opt.target as IWbObject
    if (wbObject?.connectable) {
      for (const key in hoverObjectCurrentOptions) {
        wbObject[key] = hoverObjectCurrentOptions[key]
      }
      hoverObjectCurrentOptions = {}
      // delete the custom controls
      removeCustomControls(wbObject)
      wb.canvas.discardActiveObject(opt.e)
      wb.canvas.requestRenderAll()
    }
  }

  // called on mouse up
  function manageAddConnector(option: fabric.IEvent<MouseEvent | Event>) {
    const objectOnMouseUp = option.target as IWbObject | null
    if (objectOnMouseUp?.connectable) {
      const point = Utility.getClosestObjectCornerToCurrentPointer(option.e, wb.canvas, option.target, 'connectable', ['mt', 'mr', 'mb', 'ml'])
      if (currentConnecter == null) { // if mouse up on first objectOnMouseUp (connector start point)
        currentConnecter = addConnector(point, objectOnMouseUp)
        currentConnectorOriginObject = objectOnMouseUp
        drawingConnector.value = true
      }
      else { // if mouse up on second objectOnMouseUp (connector end point)
        addConnectorEndPoint(point, objectOnMouseUp, option)
      }
    }
    else {
      // it could be either null (click on canvas) or it could be non selectable objects like other connectors or if connecter made selectable it could be currentConnecter
      if (currentConnecter != null && currentConnecter.endObjectId != null && drawingConnector && currentConnecter.endPoint != null) { // if drawing and end snapped without click
        const snappedObject: IWbObject = (wb.canvas.getObjects() as Array<IWbObject>).filter(object => object.id === currentConnecter!.endObjectId)[0] as IWbObject
        const point = { x: currentConnecter.endPoint.x, y: currentConnecter.endPoint.y, corner: currentConnecter.endCorner }
        addConnectorEndPoint(point, snappedObject, option)
      }
      else if (objectOnMouseUp == null || (!(objectOnMouseUp instanceof WbConnector) || objectOnMouseUp.id === currentConnecter?.id)) { // if objectOnMouseUp is null (click on canvas) or objectOnMouseUp not null and not connector except current connector (since now its selectable)
        wb.canvas.defaultCursor = 'default'
        wb.canvas.hoverCursor = 'move'
        if (currentConnecter != null && currentConnecter.endObjectId == null) {
          wb.canvas.remove(currentConnecter)
        }
        onAddConnectorActionEnded()
      }
    }
  }

  function addConnector(point: IObjectCornerCoords, target: IWbObject) {
    const targetCenter = target.getCenterPoint()
    const connector = new WbConnector(undefined, {
      startPoint: point,
      rStartPoint: { x: point.x - targetCenter.x, y: point.y - targetCenter.y },
      startCorner: point.corner!,
      startObjectId: target.id as string,
      endPoint: null,
      rEndPoint: null,
      endCorner: null,
      endObjectId: null,
      c1: null,
      rC1: null,
      c2: null,
      rC2: null,
      preventUnlock: false,
    })
    Utility.addWbConnectorsConnectedObjectListeners(wb.canvas, connector, target, undefined)
    // add it to canvas
    wb.canvas.add(connector)
    wb.canvas.discardActiveObject()
    return connector
  }

  function addConnectorEndPoint(point: IObjectCornerCoords, target: IWbObject, option) {
    currentConnecter!.addConnectorEndPoint(point, target)
    Utility.addWbConnectorsConnectedObjectListeners(wb.canvas, currentConnecter!, undefined, target)
    // do not remove below line, if need to keep the end object selected, write the selection logic programmatically
    wb.canvas.discardActiveObject()
    onAddConnectorActionEnded(option)
  }

  function onAddConnectorActionEnded(option?) {
    if (option) {
      onCanvasMouseOut(option)
    }
    wb.canvas.off('mouse:over', onCanvasMouseOver)
    wb.canvas.off('mouse:out', onCanvasMouseOut)
    wb.canvas.off('mouse:move', onCanvasMouseMove)
    drawingConnector.value = false
    currentConnecter = null
    currentConnectorOriginObject = null
  }

  function addCustomControls(target: IWbObject) {
    target.controls.connectingPortMt = new fabric.Control({
      x: 0,
      y: -0.5,
      cursorStyle: 'crosshair',
      // mouseUpHandler: deleteObject, // maybe instead of dual click tracking can use this event
      render: (ctx, left, top, styleOverride, fabricObject) =>
        Utility.drawArc(ctx, left, top, styleOverride, fabricObject, 0, 0, 4, 0, 2 * Math.PI, '#f9fafb', '#a9a9a9'),
    })
    target.controls.connectingPortMr = new fabric.Control({
      x: 0.5,
      y: 0,
      cursorStyle: 'crosshair',
      // mouseUpHandler: deleteObject, // maybe instead of dual click tracking can use this event
      render: (ctx, left, top, styleOverride, fabricObject) =>
        Utility.drawArc(ctx, left, top, styleOverride, fabricObject, 0, 0, 4, 0, 2 * Math.PI, '#f9fafb', '#a9a9a9'),
    })
    target.controls.connectingPortMb = new fabric.Control({
      x: 0,
      y: 0.5,
      cursorStyle: 'crosshair',
      // mouseUpHandler: deleteObject, // maybe instead of dual click tracking can use this event
      render: (ctx, left, top, styleOverride, fabricObject) =>
        Utility.drawArc(ctx, left, top, styleOverride, fabricObject, 0, 0, 4, 0, 2 * Math.PI, '#f9fafb', '#a9a9a9'),
    })
    target.controls.connectingPortMl = new fabric.Control({
      x: -0.5,
      y: 0,
      cursorStyle: 'crosshair',
      // mouseUpHandler: deleteObject, // maybe instead of dual click tracking can use this event
      render: (ctx, left, top, styleOverride, fabricObject) =>
        Utility.drawArc(ctx, left, top, styleOverride, fabricObject, 0, 0, 4, 0, 2 * Math.PI, '#f9fafb', '#a9a9a9'),
    })
  }

  function removeCustomControls(target) {
    // target.controls = fabric.Object.prototype.controls
    delete target.controls.connectingPortMt
    delete target.controls.connectingPortMr
    delete target.controls.connectingPortMb
    delete target.controls.connectingPortMl
  }

  return {
    /* Properties */
    drawingConnector,
    /* Methods */
    switchAddConnectorMode,
    manageAddConnector,
  }
}
