import type sharedb from 'sharedb/lib/client'
import type { Ref } from 'vue'
import { onUnmounted, ref, watch } from 'vue'
import _debounce from 'lodash-es/debounce'
import tinycolor from 'tinycolor2'
import type Whiteboard from '../services/whiteboard'
import WbCursor from '../services/cursor'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import useEventsBus from '@/shared/composables/eventBus'
import useArticleLocalDataUpdater from '@/modules/articleDetails/composables/articleLocalDataUpdater'
import { generateUserColor } from '@/services/utils'

export interface IPresenceUser {
  UserName: string
  Email: string
  FirstName: string
  LastName: string
  Color: string
}

export interface IPresenceRecord {
  user: IPresenceUser | null
  dt: string
  pointer: IPoint | null
  selectedObjectId: string
  dataUpdated: boolean
}

export default function usePresence(whiteboardId: number, wb: Ref<Whiteboard | undefined>, dbConn: Ref<sharedb.Connection | undefined>) {
  const boardId = `${appConfig.T1Env}${whiteboardId}`
  const users = ref(new Map<string, IPresenceUser>())
  const usersPresence = ref<Record<string, IPresenceRecord>>({})
  const userCursors = new Map<string, WbCursor>()
  let presence: sharedb.Presence
  let localPresence: sharedb.LocalPresence<IPresenceRecord>
  const userStore = useUserStore()
  const { onAppEvent, offAppEvent } = useEventsBus()
  const { refreshActiveCatalogLocalArticlesData } = useArticleLocalDataUpdater()
  const intervalId = setInterval(() => {
    const date = new Date()
    for (const key in usersPresence.value) {
      const userPresence = usersPresence.value[key]
      const diff = date.getTime() - new Date(userPresence.dt).getTime()
      if (diff >= 300000) { // 5 Min
        deleteUser(key)
      }
    }
  }, 5000)

  watch([wb, dbConn], () => {
    register()
  }, { immediate: true })

  onUnmounted(() => {
    if (localPresence) { localPresence.destroy() }
    if (presence) { presence.destroy() }
    if (intervalId) { clearInterval(intervalId) }
    offAppEvent('catalogDataUpdated', onCatalogDataUpdated)
  })

  function register() {
    if (localPresence) {
      localPresence.destroy()
    }
    if (presence) {
      presence.destroy()
    }
    if (wb.value && dbConn.value) {
      // Create presence
      presence = dbConn.value.getPresence(boardId)
      console.log('Received initial presence', presence)
      usersPresence.value = presence.remotePresences as Record<string, IPresenceRecord>
      localPresence = presence.create(userStore.currentUsername)

      // Listen to presence
      presence.subscribe()
      presence.on('receive', (id, value: IPresenceRecord | null) => {
        if (value) {
          usersPresence.value[id] = value
          if (value.user && !users.value.has(id)) {
            users.value.set(id, value.user)
          }
          let cursor = userCursors.get(id)
          if (!cursor && value.user && value.pointer) {
            cursor = new WbCursor(value.user, { left: value.pointer.x, top: value.pointer.y, fill: value.user.Color })
            cursor.bringToFront()
            userCursors.set(id, cursor)
            wb.value?.canvas.add(cursor)
          }
          else if (cursor && value.pointer) {
            cursor.bringToFront()
            cursor.setPosition(value.pointer)
          }

          if (value.dataUpdated) {
            refreshActiveCatalogLocalArticlesData(true, 'Remote')
          }
        }
        else {
          deleteUser(id)
        }
      })

      // Send presence data
      wb.value.canvas.on('mouse:move', _debounce((e: fabric.IEvent<MouseEvent>) => {
        if (e.pointer) {
          sendPresence(e.e, false)
        }
      }, 100))

      onAppEvent('catalogDataUpdated', onCatalogDataUpdated)
    }
  }

  function sendPresence(e: MouseEvent | null, dataUpdated: boolean = false) {
    let user: IPresenceUser | null = null
    if (userStore.userProfile) {
      user = {
        UserName: userStore.userProfile.UserName,
        Email: userStore.userProfile.Email,
        FirstName: userStore.userProfile.FirstName,
        LastName: userStore.userProfile.LastName,
        Color: generateUserColor(userStore.userProfile.UserName),
      }
    }
    localPresence.submit({
      user,
      dt: new Date().toISOString(),
      pointer: e ? wb.value?.canvas.getPointer(e) as IPoint : null,
      selectedObjectId: '',
      dataUpdated,
    })
  }

  function onCatalogDataUpdated(payload: IDataUpdatedEvent) {
    if (payload && payload.source !== 'Remote') {
      sendPresence(null, true)
    }
  }

  function deleteUser(username: string) {
    if (users.value.has(username)) {
      users.value.delete(username)
    }
    const cursor = userCursors.get(username)
    if (cursor) {
      wb.value?.canvas.remove(cursor)
      userCursors.delete(username)
    }
    if (usersPresence.value[username]) {
      delete usersPresence.value[username]
    }
  }

  return {
    users,
    usersPresence,
  }
}
