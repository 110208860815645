<template>
  <tx-autocomplete
    :model-value="modelValue" :label="label" :placeholder="placeholder" :errors="errors"
    :fetch-suggestions="searchModelNumber" value-prop="ModelNumber" display-prop="ModelNumber"
    :required="required" :disabled="disabled" :clearable="clearable" @update:model-value="onModelValueUpdated"
  />
</template>

<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core'
import TxAutocomplete from './TxAutocomplete.vue'
import type Article from '@/models/article'
import utils from '@/services/utils'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'

interface IProps {
  modelValue?: string | number | null
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  clearable?: boolean
  errors?: ErrorObject[]
}

withDefaults(defineProps<IProps>(), {
  modelValue: null,
  label: '',
  placeholder: '',
  required: false,
  clearable: false,
  disabled: false,
  errors: () => [] as ErrorObject[],
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: string | number | null): void
}>()

const userStore = useUserStore()

function searchModelNumber(query: string, callback) {
  if (userStore.activeCatalog) {
    let articleCollection = appConfig.DB!.articles.where('CatalogCode').equals(userStore.activeCatalog.CatalogCode)

    if (utils.isDefined(query)) {
      articleCollection = appConfig.DB!.articles.where('ModelNumber').startsWithIgnoreCase(query.trim().toLowerCase())
        .and(a => a.CatalogCode === userStore.activeCatalog!.CatalogCode)
    }

    articleCollection
      .limit(200)
      .sortBy('ModelNumber')
      .then((articles) => {
        const set = new Set<number>()
        const result: Article[] = []
        articles.forEach((article) => {
          if (!set.has(article.ModelId)) {
            result.push(article)
            set.add(article.ModelId)
          }
        })
        callback(result)
      })
  }
  else {
    callback([])
  }
}

function onModelValueUpdated(val?: string | number) {
  emit('update:modelValue', val || null)
}
</script>
