<template>
  <div v-show="visible" class="flex flex-col w-full h-full">
    <!-- SUB HEADER -->
    <div class="flex-shrink-0 text-center">
      <!-- SUB TITLE -->
      <div class="text-base text-gray-600 mb-7">
        {{ t('modelCreate.subTitle.modelForm') }}
      </div>
      <!-- NOTES -->
      <div class="text-sm text-gray-600" :class="{ 'mb-8': headerNotes.length }">
        {{ headerNotes }}
      </div>
    </div>

    <tx-checkbox
      v-if="Object.keys(modelsFormModel).length" v-model="setCommonValueForAllDynamicAttributes" class="mb-3 ml-[106px] cursor-pointer"
      :indeterminate="setCommonValueForAllDynamicAttributesIsIndeterminate" :label="t('modelCreate.allAttributesSameValue')"
      @change="onSetCommonValueForAllDynamicAttributesCheckChange"
    />

    <!-- BODY -->
    <div class="flex-grow mx-24 my-3 overflow-auto">
      <!-- HEADER ROW -->
      <div class="sticky top-0 z-20 flex items-start h-10 bg-white w-fit flex-nowrap">
        <!-- HEADER COLUMN -->
        <!-- TODO: added h-15 due to issue with tooltip that needs to be fixed -->
        <div
          v-for="attribute in refVisibleAttributes" :key="attribute.SystemName"
          class="w-48 h-15 flex flex-col shrink-0 items-baseline whitespace-nowrap text-ellipsis px-[10px] pb-[10px] m-0"
        >
          <form-attribute-label
            class="w-full font-bold" :attribute="attribute"
            :checked="headerCheckBoxModel[attribute.SystemName]"
            :required="attribute.IsRequired"
            :show-checkbox="Object.keys(modelsFormModel).length > 1"
            :show-external-change-management-info="true" :show-update-seasonless-attribute-warning="false"
            @check-changed="onSetCommonValueForCurrentDynamicAttributesCheckChange(attribute, $event)"
          />
        </div>
      </div>
      <!-- DATA -->
      <div v-for="[rowKey, modelForArticle] in Object.entries(modelsFormModel)" :key="modelForArticle.key">
        <!-- DATA ROW -->
        <div class="flex h-14 w-fit flex-nowrap">
          <!-- DATA COLUMN -->
          <div
            v-for="attribute in refVisibleAttributes" :key="attribute.SystemName"
            class="w-48 flex flex-col shrink-0 items-baseline justify-center overflow-hidden whitespace-nowrap text-ellipsis p-[10px] m-0"
          >
            <!-- ARTICLE PLACEHOLDER -->
            <attribute-editor
              v-model="modelsFormModel[rowKey][attribute.SystemName]"
              :attribute="attribute"
              :disabled="attribute.ReadOnly || attributeRowStatus[rowKey][attribute.SystemName] || (disabledColumnsBasedOnSetCommonValue[rowKey] && disabledColumnsBasedOnSetCommonValue[rowKey][attribute.SystemName])"
              :show-label="false"
              :required="attribute.IsRequired" :form="modelsFormModel[rowKey]"
              :articles="targetedArticles"
              @blur="v$[rowKey][attribute.SystemName].$touch"
              @change="handleChange(attribute)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="flex flex-row justify-end flex-shrink-0 mx-24 mb-16 flex-nowrap">
      <tx-button type="cancel" :text="t('general.cancel')" @click="onCancel" />
      <tx-button class="ml-5" type="confirm" :disabled="v$.$invalid" :text="t('general.next')" @click="nextStep" />
    </div>
  </div>
</template>

<script lang='ts' setup>
import useVuelidate from '@vuelidate/core'
import { uniqBy } from 'lodash-es'
import { computed, reactive, ref, watch } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import FormAttributeLabel from '@/shared/components/FormAttributeLabel.vue'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import type MyArticle from '@/models/myArticle'
import TxButton from '@/shared/components/TxButton.vue'
import TxCheckbox from '@/shared/components/TxCheckbox.vue'
import useErrorMessage from '@/shared/composables/errorMessage'
import utils from '@/services/utils'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import { useUserStore } from '@/store/userData'
import { AttributeType } from '@/models/catalogAttribute'
import type CatalogDetails from '@/models/catalogDetails'

interface IFormItem {
  ModelId?: number
  ModelNumber?: string
  ModelName?: string
  MasterModelId?: any
  key: string
  [key: string]: any
}

const props = defineProps<{
  createType: StyleCreateType
  formLayoutConfig?: Record<string, IStyleCreateFormLayoutConfig>
  showOnlySeasonalAttributes: boolean
  targetedArticles: MyArticle[]
  visible: boolean
  visibleAttributes: IMyAttribute[]
  carryoverModelList: Record<number, boolean>
}>()
const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'next', payload: Record<string, any>): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage } = useErrorMessage()
const { prefillAttributeValue, validateAndRemoveInvalidAttributeValues } = useArticleFormHelper()

const headerNotes = ref('')
const modelsFormModel = reactive<Record<string, IFormItem>>({})
const setCommonValueForAllDynamicAttributes = ref(false)
const setCommonValueForAllDynamicAttributesIsIndeterminate = ref(false)
const disabledColumnsBasedOnSetCommonValue = reactive<Record<string, Record<string, boolean>>>({})
const headerCheckBoxModel = reactive<Record<string, boolean>>({})
let formInitialized = false
const refVisibleAttributes = ref(props.visibleAttributes)
const attributeRowStatus = reactive<Record<string, Record<string, boolean>>>({})

// COMPUTED
const validations = computed(() => {
  const result: Record<string, any> = {}
  const requireFields: Record<string, any> = {}

  refVisibleAttributes.value.forEach((attribute) => {
    requireFields[attribute.SystemName] = {}
    if (attribute.IsRequired) {
      requireFields[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
    }
  })
  for (const rowKey in modelsFormModel) {
    result[rowKey] = requireFields
  }
  return result
})
// COMPUTED-ENDS

// WATCHERS
watch(() => props.visibleAttributes, (val) => {
  refVisibleAttributes.value = val
})

// if user change style create type (eg from copy model to carryover model) or periods quantities or to articlesToCarryover form, make formInitialized false to the form will be reinitialized
watch(() => props.createType, () => {
  formInitialized = false
})

watch(() => props.visible, (visible) => {
  if (visible && !formInitialized) {
    initForm()
    formInitialized = true
  }
})
// WATCHERS-ENDS

// FUNCTIONS
function initForm() {
  utils.resetReactiveObject(modelsFormModel)

  // INIT column checkboxes initial value
  refVisibleAttributes.value.forEach((visibleAttribute) => {
    headerCheckBoxModel[visibleAttribute.SystemName] = !!headerCheckBoxModel[visibleAttribute.SystemName]
  })

  // TODO: @aanchal please remove the unwanted comments to avoid confusions later on
  // targeted articles are always 1 article in case of copyModel, childModel and carryoverModel, in case of carryoverArticles it will be multiple articles that belongs to one model from other seasons
  const linkedCatalogWithHighestSeasonalSequence = uniqBy(props.targetedArticles, 'CatalogCode').map(article => article.CatalogCode).reduce((acu: Array<CatalogDetails>, cur) => {
    if (userStore.linkedCatalogDetails[cur]) {
      acu.push(userStore.linkedCatalogDetails[cur])
    }
    return acu
  }, []).sort((a, b) => (b.SeasonalSequence || 0) - (a.SeasonalSequence || 0))[0]
  for (const [modelIdString, isModelExists] of Object.entries(props.carryoverModelList)) {
    const modelId = Number.parseInt(modelIdString)
    const article = props.targetedArticles.find(article => article.ModelId === modelId)
    if (article) {
      // as articles that does not belongs to this catalog match prices by price group name
      const indexedLinkedCatalogPricesByPriceGroupName = {}
      for (const linkedCatalogPriceGroupId in article._Prices) {
        if (linkedCatalogWithHighestSeasonalSequence._IndexedCatalogPriceGroup.hasOwnProperty(linkedCatalogPriceGroupId)) {
          // index prices by price name
          const priceGroupName = userStore.linkedCatalogDetails[article.CatalogCode]._IndexedCatalogPriceGroup[linkedCatalogPriceGroupId].Name.toString().trim().toLowerCase()
          indexedLinkedCatalogPricesByPriceGroupName[priceGroupName] = article._Prices[linkedCatalogPriceGroupId]
        }
      }
      const rowKey = article.ModelId
      modelsFormModel[rowKey] = {} as IFormItem
      modelsFormModel[rowKey].ModelId = article.ModelId
      modelsFormModel[rowKey].ModelNumber = article.ModelNumber
      modelsFormModel[rowKey].ModelName = article.ModelName
      modelsFormModel[rowKey].MasterModelId = article.MasterModelId

      refVisibleAttributes.value.forEach((visibleAttribute) => {
        // ATTRIBUTE'S DEFAULT VALUE - set attribute's DefaultValue if model's attribute value is empty and cell is not disabled, keep parent_model_type empty for create child model
        if (
          (!utils.isDefined(modelsFormModel[rowKey][visibleAttribute.SystemName]) || !modelsFormModel[rowKey][visibleAttribute.SystemName].toString().length)
          && utils.isDefined(visibleAttribute.DefaultValue) && visibleAttribute.DefaultValue.toString().length && !visibleAttribute.ReadOnly
        ) {
          modelsFormModel[rowKey][visibleAttribute.SystemName] = visibleAttribute.DefaultValue
        }

        // PRE POPULATE - always pre populate (if there are targeted articles) unless pre populate is false (for carryover model and carryover article it should copy only seasonal attributes)
        let prePopulate = false
        if ((!visibleAttribute.IsSeasonless || visibleAttribute.SystemName === 'SizeScale' || visibleAttribute.SystemName === 'MasterModelId') && (
          props.formLayoutConfig == null || !props.formLayoutConfig.hasOwnProperty(visibleAttribute.SystemName)
          || !props.formLayoutConfig[visibleAttribute.SystemName].hasOwnProperty('prePopulate') || props.formLayoutConfig[visibleAttribute.SystemName].prePopulate
        )
        ) {
          prePopulate = true
        }

        // COPY DATA
        if (visibleAttribute.AttributeSource !== '_Prices') { // copy non price attributes
          if (prePopulate) {
            // assign default value in case of undefined
            modelsFormModel[rowKey][visibleAttribute.SystemName] = prefillAttributeValue(modelsFormModel[rowKey], article[visibleAttribute.SystemName], visibleAttribute)
          }
          else if (!modelsFormModel[rowKey].hasOwnProperty(visibleAttribute.SystemName)) {
            modelsFormModel[rowKey][visibleAttribute.SystemName] = visibleAttribute.AttributeType === AttributeType.Bool && visibleAttribute.IsRequired ? 'false' : undefined
          }
        }
        else { // copy prices
          modelsFormModel[rowKey][visibleAttribute.SystemName] = 0
          if (prePopulate) {
            const currentCatalogCurrentPriceGroupName = userStore.activeCatalog!._IndexedCatalogPriceGroup[visibleAttribute.SystemName].Name.toString().trim().toLowerCase()
            if (indexedLinkedCatalogPricesByPriceGroupName.hasOwnProperty(currentCatalogCurrentPriceGroupName)) {
              modelsFormModel[rowKey][visibleAttribute.SystemName] = indexedLinkedCatalogPricesByPriceGroupName[currentCatalogCurrentPriceGroupName].Price
            }
            else {
              modelsFormModel[rowKey][visibleAttribute.SystemName] = 0
            }
          }
        }
        // initialize attributeRowStatus[rowKey] if undefined
        if (!attributeRowStatus[rowKey]) {
          attributeRowStatus[rowKey] = {}
        }
        // existing models will be readonly readonly #50948
        attributeRowStatus[rowKey][visibleAttribute.SystemName] = isModelExists
      })
      const dataForValidation = Object.assign({}, article, modelsFormModel[rowKey])
      validateAndRemoveInvalidAttributeValues(refVisibleAttributes, modelsFormModel[rowKey], dataForValidation)
    }
    else {
      console.error('Some problem in logic this condition should never happen', modelId)
    }
  }
}

function handleChange(attribute: IMyAttribute) {
  Object.keys(modelsFormModel).forEach((key) => {
    const article = props.targetedArticles.find(article => article.ModelId.toString() === key.toString())
    if (article) {
      const dataForValidation = Object.assign({}, article, modelsFormModel[key])
      validateAndRemoveInvalidAttributeValues(refVisibleAttributes, modelsFormModel[key], dataForValidation)
    }
  })
  setCurrentAttributeValueForAllRecords(attribute)
}
const v$ = useVuelidate(validations, modelsFormModel)

function onCancel() {
  emit('cancel')
}

function onSetCommonValueForAllDynamicAttributesCheckChange(isChecked: TxBooleanish) {
  for (const attributeSystemName in refVisibleAttributes.value) {
    const attribute = refVisibleAttributes.value[attributeSystemName]
    if (!attribute.ReadOnly) {
      headerCheckBoxModel[attribute.SystemName] = !!isChecked
    }
    setCurrentAttributeValueForAllRecords(attribute)
  }
}

function onSetCommonValueForCurrentDynamicAttributesCheckChange(attribute: IMyAttribute, isChecked) {
  // there is some issue with checkbox which is not setting the model value
  headerCheckBoxModel[attribute.SystemName] = isChecked
  const nonStaticEditableAttributes = refVisibleAttributes.value.filter(visibleAttribute => !visibleAttribute.ReadOnly)
  const firstAttributeCheckboxValue = headerCheckBoxModel[nonStaticEditableAttributes[0].SystemName]
  // set indeterminate for table check box to false
  setCommonValueForAllDynamicAttributesIsIndeterminate.value = false
  for (let i = 1; i < nonStaticEditableAttributes.length; i++) {
    if (headerCheckBoxModel[nonStaticEditableAttributes[i].SystemName] !== firstAttributeCheckboxValue) {
      // if first checkbox value is not the same as any of the leading column check boxes, make table check value indeterminate
      setCommonValueForAllDynamicAttributes.value = false
      setCommonValueForAllDynamicAttributesIsIndeterminate.value = true
      break
    }
  }
  // if it is not indeterminate and if first one is checked (firstAttributeCheckboxValue is true) then set setCommonValueForAllDynamicAttributes to true else set it to false
  if (!setCommonValueForAllDynamicAttributesIsIndeterminate.value) {
    if (firstAttributeCheckboxValue) {
      setCommonValueForAllDynamicAttributes.value = true
    }
    else {
      setCommonValueForAllDynamicAttributes.value = false
    }
  }

  setCurrentAttributeValueForAllRecords(attribute)
}

function setCurrentAttributeValueForAllRecords(attribute: IMyAttribute) {
  const rowKyeList = Object.keys(modelsFormModel)
  const firstArticleRecord = modelsFormModel[rowKyeList[0]]
  const setCommonValue = headerCheckBoxModel[attribute.SystemName] || setCommonValueForAllDynamicAttributes.value
  for (let i = 1; i < rowKyeList.length; i++) {
    // disable all rows except the first for current attribute
    if (!disabledColumnsBasedOnSetCommonValue.hasOwnProperty(rowKyeList[i])) {
      disabledColumnsBasedOnSetCommonValue[rowKyeList[i]] = {}
    }
    disabledColumnsBasedOnSetCommonValue[rowKyeList[i]][attribute.SystemName] = setCommonValue

    // set common values
    if (setCommonValue && !attribute.ReadOnly) {
      modelsFormModel[rowKyeList[i]][attribute.SystemName] = firstArticleRecord[attribute.SystemName]
    }
  }
}

async function nextStep() {
  if (!await v$.value.$validate()) {
    errorMessage.value = t('validations.formInvalid')
    return
  }
  emit('next', { modelsFormModel, modelArticlesAndPeriodsFormModel: undefined })
}
</script>
