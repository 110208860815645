<template>
  <div
    v-tooltip="{ text: chip.TooltipText, show: chip.TooltipText.length, theme: { placement: 'top' } }"
    class="flex relative px-2 max-w-[300px] h-8 m-0.5 border shadow cursor-pointer bg-default"
    :style="utils.isValidStringValue(chip.HexCode) ? `background-color: ${chip.HexCode}; color: ${tinycolor(chip.HexCode!).getBrightness() < 128 ? 'white' : 'black'}` : ''"
    :class="{ 'border-blue-50 border-2': active && chip.CatalogArticleId === active.CatalogArticleId, 'rounded': chip.Type === 'rect', 'rounded-full': chip.Type === 'circle' }" @click="onClick"
  >
    <div v-if="chip.Status !== 1" class="w-full h-8 inactive-chip" />
    <div class="m-auto overflow-hidden whitespace-nowrap text-ellipsis">
      {{ chip.ColorCode }} {{ (chip.Text.length) ? '-' : '' }} {{ chip.Text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import tinycolor from 'tinycolor2'
import utils from '@/services/utils'

export interface IColorCodeChip {
  Type: 'rect' | 'circle'
  CatalogCode: number
  ArticleId: number
  ArticleNumber: string
  CatalogArticleId: string
  SourceArticleId?: number | null
  IsRequest: boolean
  ColorCode: string
  Status: number
  HexCode: string | null
  Text: string | ''
  TooltipText: string | ''
}

const props = defineProps<{
  chip: IColorCodeChip
  active?: IColorCodeChip
}>()

const emit = defineEmits<{
  (e: 'click', data: IColorCodeChip): void
}>()

function onClick() {
  emit('click', props.chip)
}
</script>

<style scoped>
.inactive-chip {
  position: absolute;
  border-top: 2px solid red;
  top: 50%;
  left: 0;
  /* transform-origin: 0% 0%;
    transform: rotate(45deg);*/
}
</style>
